var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container page_mobile"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"area"},[_c('InputBox',{attrs:{"data":{
          shape: 'search',
          type: 'text',
          placeholder: '기업명으로 검색',
        }}}),_vm._v(" "),_vm._m(0)],1),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"inner"},[_c('div',{staticClass:"contents"},[_c('div',{staticClass:"list_cs"},[_vm._l((10),function(i){return _c('div',{key:i,staticClass:"unit"},[_vm._m(2,true),_vm._v(" "),_vm._m(3,true),_vm._v(" "),_vm._m(4,true),_vm._v(" "),_vm._m(5,true),_vm._v(" "),_vm._m(6,true)])}),_vm._v(" "),_c('button',{staticClass:"btn full mt15"},[_vm._v("더보기")])],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btns_filter"},[_c('button',{staticClass:"btn"},[_vm._v("1개월")]),_vm._v(" "),_c('button',{staticClass:"btn on"},[_vm._v("3개월")]),_vm._v(" "),_c('button',{staticClass:"btn"},[_vm._v("직접설정")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area_date"},[_c('div',{staticClass:"a_inner"},[_c('div',{staticClass:"wrap_datepicker"},[_c('div',{staticClass:"tui-datepicker-input tui-datetime-input tui-has-focus"},[_c('input',{attrs:{"type":"text","id":"tui-date-picker-target","aria-label":"Date-Time"}}),_vm._v(" "),_c('span',{staticClass:"tui-ico-date"})]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"-1px"},attrs:{"id":"tui-date-picker-container"}})]),_vm._v(" "),_c('div',{staticClass:"wrap_datepicker"},[_c('div',{staticClass:"tui-datepicker-input tui-datetime-input tui-has-focus"},[_c('input',{attrs:{"type":"text","id":"tui-date-picker-target","aria-label":"Date-Time"}}),_vm._v(" "),_c('span',{staticClass:"tui-ico-date"})]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"-1px"},attrs:{"id":"tui-date-picker-container"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("기업명")]),_vm._v(" "),_c('dd',[_c('b',[_vm._v("유니콘랜치")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("대표자명")]),_vm._v(" "),_c('dd',[_vm._v("전호")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"full"},[_c('dt',[_vm._v("소재지")]),_vm._v(" "),_c('dd',[_vm._v("수원시 권선구 000로 00 0000호")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("신청일")]),_vm._v(" "),_c('dd',[_vm._v("2022.09.01")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("진행상태")]),_vm._v(" "),_c('dd',[_vm._v("신청중")])])
}]

export { render, staticRenderFns }