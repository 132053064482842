// https://axios.nuxtjs.org/helpers
export default function ({ $axios, redirect, store }) {

  // 응답 에러 결과 처리
  $axios.onError(error => {

    // 인증에 문제가 있는 경우
    if(error.response.status === 401) {

      // 스토어에서 접속자 관련 데이터 삭제
      store.commit('LOGOUT');

      // 로그인 페이지로 리다이렉션
      redirect('/login');
    }
  })
};
