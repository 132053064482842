<template>
	<div class="slct_search" :class="{classNm, on:on}" :style="styleAttr" v-click-outside="fnClickOutside">
		<button class="s_tit" type="button" @click="toggleSlct">{{name}}</button>
		<div class="s_cont">
			<Input name="searchKeyword" ref="searchKeyword" :value.sync="searchKeyword" :placeholder="placeholder"/>
			<div class="item" v-for="item in filteredItemList"
           :class="{select : fnCheckSelected(item.value)}"
           :key="'slt_'+item.value"
           @click="()=>fnClick(item)">
				<span>
					{{item.name}}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import Input from "~/components/common/Input";
export default {
  props: {
    itemList: {
      type:Array,
      required:true,
    },
    classNm:{
      type:String
    },
    // 스타일
    styleAttr:{
      type:Object
    },
    value:{
      //type:String,
      required: false
    },
    defaultName:{
      type:String,
      default : '선택해주세요'
    },
    // 사용여부
    disabled:{
      type:Boolean,
      default: false
    },
    placeholder:{
      type:String,
    },
  },
  components: {
    Input,
  },
	data() {
    return {
      on: false,
      searchKeyword:'',
    }
	},
  computed:{
    name(){
      if(this.itemList){
        const foundItem = this.itemList.find(item => item.value === this.value)
        if(foundItem)
          return foundItem.name;
      }
      return this.defaultName;
    },
    filteredItemList(){
      if(this.searchKeyword.length>0){
        return this.itemList.filter( item => {
          return item.name.indexOf(this.searchKeyword)>=0;
        });
      }
      return this.itemList;
    }
  },
  methods: {
    onFocus(){
      this.toggleSlct();
    },
    toggleSlct() {
      if(!this.disabled)
        this.on = !this.on;
    },
    fnClick(item) {
      this.toggleSlct();
      this.$emit('update:value', item.value)
      this.$emit('change', item)
    },
    fnCheckSelected(itemValue){
      return itemValue === this.value;
    },
    fnClickOutside() {
      this.on = false;
    },
  }
}
</script>
