var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_contents contents_col2"},[(_vm.$store.state.authUser.gaId.indexOf('TXO')!==-1||_vm.$store.state.authUser.gaName==='유니콘랜치')?_c('div',{staticClass:"left"},[_c('ol',[_c('li',[_vm._m(0),_vm._v(" "),_c('Input',{attrs:{"id":"stk","value":_vm.consultantGaNm2,"name":"consultant","type":"text","placeholder":"GA를 선택해주세요","disabled":true,"styleAttr":{ width: '100%' }},on:{"update:value":function($event){_vm.consultantGaNm2=$event}}})],1)]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"area_search"},[_c('div',{staticClass:"unit"},[_vm._m(2),_vm._v(" "),_c('Input',{attrs:{"id":"consultantNm","value":_vm.form2.consultantNm,"name":"consultantNm","type":"text","shape":"search","placeholder":"GA코드 혹은 GA명을 입력해주세요","styleAttr":{ width: '100%' }},on:{"update:value":function($event){return _vm.$set(_vm.form2, "consultantNm", $event)}}})],1)]),_vm._v(" "),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"tbl_area",staticStyle:{"min-width":"auto"}},[_c('table',{staticClass:"tbl"},[_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('tbody',[_vm._l((_vm.consultantGaList),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v("\n              "+_vm._s(item.id)+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(item.name)+"\n            ")]),_vm._v(" "),_c('td',[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.fnSelect2(item)}}},[_vm._v("선택")])])])}),_vm._v(" "),(_vm.consultantGaList.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("데이터가 없습니다.")])]):_vm._e()],2)]),_vm._v(" "),(!_vm.form.consultantNm)?_c('Pagination',{attrs:{"page":_vm.pageNum2+1,"perPage":_vm.pageSize2,"totalCount":_vm.gaPagination.totalCount},on:{"change":p => {
              this.pageNum2=p-1;
            }}}):_vm._e()],1)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"right"},[_c('ol',[_c('li',[_vm._m(5),_vm._v(" "),_c('Input',{attrs:{"id":"stk","value":_vm.consultantNm2,"name":"consultant","type":"text","placeholder":"담당자를 선택해주세요","disabled":true,"styleAttr":{ width: '100%' }}})],1)]),_vm._v(" "),_vm._m(6),_vm._v(" "),_c('div',{staticClass:"area_search"},[_c('div',{staticClass:"unit"},[_vm._m(7),_vm._v(" "),_c('Input',{attrs:{"id":"'consultantNm'","value":_vm.form.consultantNm,"name":"consultantNm","type":"text","shape":"search","placeholder":"FC코드 혹은 담당자 이름을 입력해주세요"},on:{"update:value":function($event){return _vm.$set(_vm.form, "consultantNm", $event)}}})],1)]),_vm._v(" "),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"tbl_area",staticStyle:{"min-width":"auto"}},[_c('table',{staticClass:"tbl"},[_vm._m(8),_vm._v(" "),_vm._m(9),_vm._v(" "),_c('tbody',[_vm._l((_vm.consultantList),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v("\n              "+_vm._s(item.consultant_cd)+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(item.name)+"\n            ")]),_vm._v(" "),_c('td',[_c('button',{staticClass:"btn",class:{gray: !_vm.consultantGaNm2},attrs:{"disabled":!_vm.consultantGaNm2},on:{"click":function($event){return _vm.fnSelect(item)}}},[_vm._v("선택")])])])}),_vm._v(" "),(_vm.consultants.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("데이터가 없습니다.")])]):_vm._e()],2)]),_vm._v(" "),(!_vm.form.consultantNm)?_c('Pagination',{attrs:{"page":_vm.pageNum+1,"perPage":_vm.pageSize,"totalCount":_vm.pagination.totalCount},on:{"change":p => {
              this.pageNum=p-1;
            }}}):_vm._e()],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"stk"}},[_c('b',[_vm._v("배정된 GA:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"line-height":"90%"}},[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"tit",attrs:{"for":"consultantNm"}},[_vm._v("GA코드"),_c('br'),_vm._v("GA명")])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"20%"}}),_vm._v(" "),_c('col',{attrs:{"width":"63%"}}),_vm._v(" "),_c('col',{attrs:{"width":"17%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_c('b',[_vm._v("GA코드")])]),_vm._v(" "),_c('th',[_c('b',[_vm._v("GA명")])]),_vm._v(" "),_c('th',[_c('b',[_vm._v(" ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"stk"}},[_c('b',[_vm._v("배정된 담당자:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"line-height":"90%"}},[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"tit",attrs:{"for":"consultantNm"}},[_vm._v("FC코드"),_c('br'),_vm._v("담당자 이름")])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"20%"}}),_vm._v(" "),_c('col',{attrs:{"width":"63%"}}),_vm._v(" "),_c('col',{attrs:{"width":"17%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_c('b',[_vm._v("FC코드")])]),_vm._v(" "),_c('th',[_c('b',[_vm._v("담당자")])]),_vm._v(" "),_c('th',[_c('b',[_vm._v(" ")])])])])
}]

export { render, staticRenderFns }