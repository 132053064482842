<template>
  <div class="popup p_cs">
    <div class="p_wrap">
      <div class="p_top">
        <h2>고용지원금 상세 : {{compNm}}</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button :class="{ tab: true, on: tab == 'tab1' }"
                      @click="()=>{this.tab = 'tab1'}">고객상세</button>
              <button :class="{ tab: true, on: tab == 'tab2' }"
                      v-if="$menuRoleCheck([this.mixMenuRoles.고용지원금_진단결과_조회])"
                      @click="()=>{this.tab = 'tab2'}">진단결과</button>
              <button :class="{ tab: true, on: tab == 'tab3' }"
                      v-if="$menuRoleCheck([this.mixMenuRoles.고용지원금_제출서류_조회])"
                      @click="()=>{this.tab = 'tab3'}">제출서류 확인</button>
            </div>
            <div class="btns" v-if="tab=='tab2'">
              <button class="btn" @click="fnDownload">진단결과 리포트</button>
            </div>
            <div class="btns">
              <button class="btn dark" @click="fnClosePop">닫기</button>
            </div>
          </div>
          <BizInfoTab :id='id' :bizNo="bizNo" v-if="tab=='tab1'"/>
          <EmployTab2 :id='id' v-if="tab=='tab2'" />
          <CommonSubmit v-if="tab=='tab3'"
                        :customer-id="compObj.id"
                        :env="submitTab"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BizInfoTab from "./BizInfoTab";
import EmployTab2 from './EmployTab2.vue';
import CommonSubmit from "./CommonSubmit";
// import CommonAssignManager from "./CommonAssignManager";
import rolesMixin from "../../../mixins/rolesMixin";

export default {
  mixins:[rolesMixin],
  components: { EmployTab2,CommonSubmit, BizInfoTab },
  name: 'TaxDetail',
  props: ['id', 'compNm', 'bizNo', 'compObj'],
  data() {
    return {
      tab: 'tab1',
      submitTab:{
        path:'employ',
        fileTypes:[
          {
            fileType:'salaryList',
            title:'급여대장',
          },
          {
            fileType:'contractList',
            title:'근로계약서',
          }
        ],
        fileMap:{
          salaryList:null,
          contractList:null,
        }
      }
    };
  },
  computed:{
    managerTab(){
      return {
        salesCompanyType:[this.mixCompanyTypes.ADMIN, this.mixCompanyTypes.GA],
        managerCompanyType:[this.mixCompanyTypes.LABOR],
        manageType:this.mixManageTypes.LABOR
      }
    }
  },
  methods:{
    fnClosePop() {
      this.$store.dispatch('ui/setPopCsState', false);
      this.$emit('reload');
    },
    fnDownload(){
      this.pdfDownloading = true;
      const url = process.env.allOkKKUrl
      window.open(`${url}/api/pdf/employ?compNm=${this.compNm}&bizNo=${this.bizNo}`);
      setTimeout(()=> {
        this.pdfDownloading = false;
      }, 1000 * 6);
    }
  }
};
</script>
