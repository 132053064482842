const email = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
const regPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
const phone = /^[0-9]{3}[0-9]{3,4}[0-9]{4}/;
const tel = /^\d{2,3}-?\d{3,4}-?\d{4}$/;

const types = {
  email: email,
  password: regPassword,
  phone: phone,
  tel: tel,
};

export default (type, value) => {
  if (value === null || value === undefined) return false;
  const validator = types[type];
  if (validator) {
    return validator.test(value);
  }
  return false;
};
