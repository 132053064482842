<template>
  <div>
    <GaList />
  </div>
</template>

<script>
import GaList from '../../components/pages/allOk/GaList.vue';
export default {
  components: { GaList },
  data() {
    return {
      roles:['ADMIN'],
    };
  },
  beforeMount(){
    if(!this.$roleCheck(['ADMIN'])){
      this.$router.push('/')
    }
  }
};
</script>
