<template>
  <client-only>
    <VueTreeList
      @click="onClick"
      @change-name="onChangeName"
      @delete-node="onDelete"
      @add-node="onAddNode"
      @drop="onDrop"
      @drop-before="onDropBefore"
      :model="treeData"
      default-tree-node-name="new node"
      default-leaf-node-name="new leaf"
      v-bind:default-expanded="true">
      <template v-slot:leafNameDisplay="slotProps">
        <span>
          {{ slotProps.model.name }}
        </span>
      </template>
      <span class="icon" slot="addTreeNodeIcon">＋</span>
      <span class="icon" slot="editNodeIcon">📃</span>
      <span class="icon" slot="delNodeIcon">✂️</span>
      <template v-slot:treeNodeIcon="slotProps">
        <span class="icon">{{ slotProps.model.children ? '📂' : '' }}</span>
      </template>
    </VueTreeList>
  </client-only>
</template>

<script>
let vueTree;
if (process.client) {
  vueTree = require('vue-tree-list');
} else {
  vueTree = { VueTreeList: { template: '<div></div>' } };
}
export default {
  props: ['data'],

  components: {
    VueTreeList: vueTree.VueTreeList,
  },

  data() {
    return {
      treeData: null,
    };
  },

  methods: {
    fnSetTreeData() {
      this.treeData = new vueTree.Tree(this.data);
    },
    onClick(params) {
      this.$emit('onClick', params);
    },
    onChangeName(params) {
      this.$emit('onChangeName', params);
    },
    onDelete(node) {
      this.$emit('onDelete', node);
    },
    onAddNode(params) {
      this.$emit('onAddNode', params);
    },
    onDrop(params) {
      this.$emit('onDrop', params);
    },
    onDropBefore(params) {
      this.$emit('onDropBefore', params);
    },
  },

  mounted() {
    this.fnSetTreeData();
  },

  watch: {
    data(v) {
      this.fnSetTreeData();
    },
  },
};
</script>
