export const 어드민 = 'admin'
export const 고용지원금_고객상세_조회 = 'emp_dtl_r'
export const 고용지원금_제출서류_조회 = 'emp_file_r'
export const 고용지원금_제출서류_업로드_삭제 = 'emp_file_ud'
export const 고용지원금_담당자_조회_수정_삭제 = 'emp_mng_r'
export const 고용지원금_진단결과_조회 = 'emp_rslt_r'
export const 고용지원금_진단결과_레포트_다운 = 'emp_rslt_rpt_r'
export const 사대보험_고객상세_조회 = 'ins_dtl_r'
export const 사대보험_제출서류_조회 = 'ins_file_r'
export const 사대보험_제출서류_업로드_삭제 = 'ins_file_ud'
export const 사대보험_담당자_조회_수정_삭제 = 'ins_mng_rud'
export const 사대보험_진단결과_조회 = 'ins_rslt_r'
export const 사대보험_진단결과_레포트_다운 = 'ins_rslt_rpt_r'
export const 사대보험_고용현황_및_진단과정_엑셀다운 = 'ins_scrp_exl_r'
export const 사대보험_고용현황_및_진단과정_조회 = 'ins_scrp_r'
export const 노무관리_근로계약서_조회 = 'lbr_cntrt_r'
export const 노무관리_근로계약서_계약직_조회 = 'lbr_cntrt_tmp_r'
export const 노무관리_고객상세_조회 = 'lbr_dtl_r'
export const 노무관리_제출서류_조회 = 'lbr_file_r'
export const 노무관리_제출서류_업로드_삭제 = 'lbr_file_ud'
export const 노무관리_입력폼_조회 = 'lbr_form_r'
export const 노무관리_급여대장_조회 = 'lbr_slr_r'
export const 노무관리_비밀번호_조회_수정 = 'lbr_pwd_ru'
export const 기업대출_고객상세_조회 = 'ln_dtl_r'
export const 기업대출_제출서류_조회 = 'ln_file_r'
export const 기업대출_제출서류_업로드_삭제 = 'ln_file_ud'
export const 기업대출_담당자_조회_수정_삭제 = 'ln_mng_rud'
export const 기업대출_진행단계_조회 = 'ln_prcs_r'
export const 기업대출_기술평가_조회 = 'ln_tch_r'
export const 경정청구_분석_엑셀_다운로드 = 'tx_anal_exl_r'
export const 경정청구_분석_알고리즘_조회 = 'tx_anal_r'
export const 경정청구_분석_레포트_다운로드 = 'tx_anal_rpt_r'
export const 경정청구_고객상세_조회 = 'tx_dtl_r'
export const 경정청구_제출서류_조회 = 'tx_file_r'
export const 경정청구_제출서류_업로드_삭제 = 'tx_file_ud'
export const 경정청구_담당자_조회_수정_삭제 = 'tx_mng_rud'
export const 경정청구_진단결과_조회 = 'tx_rslt_r'
export const 경정청구_진단결과_레포트_다운 = 'tx_rslt_rpt_r'
export const 경정청구_국세청_신고_PDF_조회 = 'tx_scrp_pdf_r'
export const 경정청구_국세청_신고내역_목록_조회 = 'tx_scrp_r'
