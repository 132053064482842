<template>
  <button class="btn" @click="fnPwReset">
    FC 퇴사
  </button>
</template>

<script>
import allOkService from "../../../../../service/allOk/allOkService";
import organizationUserService from "../../../../../service/organizationUserService";
export default {
  props: {
    user: {
      type: Object,
    }
  },
  methods: {
    fnPwReset(){
      if(this._fnValidation()){
        this.$popupConfirm({
          title: 'FC 탈퇴',
          msg: `${this.user.name}을 탈퇴 처리하시겠습니까?`,
          btnName: '예',
          btnFunc: this.fnSave,
          closeBtnName: '아니오'
        })
      } else {
        this.$popupAlert({
          title: '주의',
          msg: '탈퇴처리할 회원을 선택해주세요',
        });
      }
    },
    _fnValidation(){
      return this.user && this.user.id
    },
    async fnSave(){
      await this._fnCloseBeforeAlert()

      let msg;
      try{
        const res = await organizationUserService.secedeUser(this.$axios, this.user.id);
        if(res.result){
          msg = `${this.user.name}님의 탈퇴 처리를 완료했습니다.`;
          this.$emit('reload', Math.random());
        } else {
          msg = res.data.error.msg;
        }
      }catch (e){
        msg = '죄송합니다. 알 수 없는 에러가 발생했습니다.'
      }
      await this.$popupAlert({
        title: 'FC 탈퇴',
        msg,
      });

    },
    async _fnCloseBeforeAlert(){
      await this.$store.dispatch('ui/setPopAlertData', {
        state:false,
        type: null,
        title: null,
        msg: null,
        btn:{
          link:'',
          name:null,
        },
        closeBtn:{
          name:null,
        }
      });
    }
  }
}
</script>
