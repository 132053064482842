<template>
 <div class="popup p_cs">
    <div class="p_wrap">
      <div class="p_top">
        <h2>FC 상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button class="tab on">FC 상세</button>
            </div>
            <div class="btns">
              <button class="btn" @click="fnSave">저장</button>
              <button class="btn dark" @click="fnClosePop">취소</button>
            </div>
          </div>
          <div class="c_contents">
            <div class="c_contents contents_col2">
              <div class="left">
                <ul class="form_list type2">
                  <li class="item_ipt">
                    <label for="fcCode"><h4><b>FC코드</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id="fcCode"
                        name="fcCode"
                        type="text"
                        disabled
                        :value="user.username"
                      />
                    </div>
                  </li>
                  <li class="item_ipt">
                    <label for="fcName"><h4><b>FC이름</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id='fcName'
                        type='text'
                        name="fcName"
                        disabled
                        :value.sync="user.name"
                      />
                    </div>
                  </li>
                  <li class="item_ipt">
                    <label for="tel"><h4><b>전화번호</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id="tel"
                        name="tel"
                        type="text"
                        placeholder="전화번호를 입력하세요"
                        :value.sync="form.tel"
                      />
                    </div>
                  </li>
                  <li class="item_ipt">
                    <label for="birth"><h4><b>생년월일</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id='birth'
                        type='text'
                        name="birth"
                        placeholder="yymmdd(숫자만, 6자리)"
                        :value.sync="form.birthDt"
                      />
                    </div>
                  </li>
                  <li class="item_ipt">
                    <h4>성별</h4>
                    <div class="i_wrap">
                      <Select title="성별" id="gender" :itemList="genderSelect" :value.sync="form.gender"/>
                    </div>
                  </li>
                  <li class="item_ipt" v-if="gaId == company.프리랜서FC.code">
                    <label for="fcName"><h4><b>소속</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id='affiliation'
                        type='text'
                        name="affiliation"
                        placeholder='소속을 입력하세요'
                        :value.sync="form.affiliation"
                      />
                    </div>
                  </li>
                  <li class="item_ipt" v-if="gaId == company.프리랜서FC.code">
                    <label for="fcName"><h4><b>직책</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id='position'
                        type='text'
                        name="position"
                        placeholder='직책을 입력하세요'
                        :value.sync="form.position"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';
import Select from '~/components/common/Select';
import organizationUserService from "../../../service/organizationUserService";
import * as company from '~/constants/company';

export default {
  name: 'FCdetail',
  components: {
    InputBox, Select
  },
  props: {
    user:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form:{
        tel:null,
        birthDt:null,
        gender:null,
        mngYn: null,
        positionCd:null,
        affiliation: null,
        position: null,
        orgId:null,
      },
      genderSelect:[
        {name: '남', value:'male'},
        {name: '여', value:'female'},
      ],
      managerSelect:[
        {name: 'Y', value:'Y'},
        {name: 'N', value:'N'},
      ],
      company: company
    };
  },
  computed:{
    gaId(){
      return this.$store.state.authUser?.gaId;
    },
  },
  methods: {
    fnClosePop(){
      this.$store.dispatch('ui/setPopFc2State', false);
    },
    fnSave(){
      if(this._fnSaveValidation()){
        const payload={
          userId: this.user.id,
          form: this.form
        }
        organizationUserService.modifyUser(this.$axios, payload)
          .then(res => {
            if(res.result){
              this.$popupAlert({
                title: '확인',
                msg: '회원정보를 수정했습니다.',
              });
              this.$emit('reload', Math.random());
            }else {
              this.$popupAlert({
                title: '경고',
                msg: res.data.error.msg,
              });
            }
          })
          .catch(err => {
            this.$popupAlert({
              title: '경고',
              msg: '알 수 없는 원인으로 저장에 실패했습니다.',
            });
          })
      }
    },

    _fnSaveValidation(){
      let success = true;
      let title = null;
      let msg = null;

      if(!this.form.tel){
          title= '경고 : 전화번호';
          msg = '전화번호를 입력해 주십시오.';
          success = false;
      } else if(!this.$validate('phone', this.form.tel)){
          title= '경고 : 전화번호';
          msg = '전화번호 형식으로 입력해 주십시오.';
          success = false;
      } else if(!this.form.birthDt){
          title= '경고 : 생년월일';
          msg = '생년월일을 입력해 주십시오.';
          success = false;
      } else if(this.form.birthDt.length!=6){
          title= '경고 : 생년월일';
          msg = '생년월일을 yymmdd형식으로 입력해 주십시오.';
          success = false;
      } else if(isNaN(this.form.birthDt)){
          title= '경고 : 생년월일';
          msg = '숫자로 입력해 주십시오.';
          success = false;
      } else if(!this.form.gender){
          title= '경고 : 성별';
          msg = '성별을 선택해 주십시오.';
          success = false;
      } else if(!this.form.mngYn){
          title= '경고 : 관리자여부';
          msg = '관리자여부를 선택해 주십시오.';
          success = false;
      }

      if(!success) this.$popupAlert({title, msg});
      return success;
    }
  },
  beforeMount(){
    if(this.user){
      this.form.tel = this.user.tel;
      this.form.birthDt = this.user.birth_dt;
      this.form.gender = this.user.gender;
      this.form.mngYn = this.user.mngYn;
      this.form.positionCd = this.user.position_cd;
      this.form.affiliation = this.user.affiliation;
      this.form.position = this.user.position
      this.form.orgId = this.user.orgId
    }
  }
};
</script>
