<template>
  <section class="container">
    <Location />
    <div class="inner">
      <div class="colr">
        <button class="btn_reset" @click="fnResetForm">전체초기화</button>
      </div>
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="compNm">회사명</label>
          <Input id="compNm" name="query.compNm" shape="search" placeholder="검색어를 입력하세요." :value.sync="query.compNm" @enter="fnResetPageAndFetch" />
        </div>
        <div class="unit" v-if="showGaData">
          <label class="tit" for="compId">GA</label>
          <Select id="compId" defaultName="GA를 선택하세요" :itemList="gaList" :value.sync="query.compId" @change="fnFetchList" />
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <div>
            <button class="btn" @click="fnOpenPop2('popCsState')">열기</button>
<!--            <button class="btn" @click="fnDelete">삭제</button>-->
<!--            <button class="btn" :class="{dis: loading||(contents && contents.length === 0)}" :disabled="loading||(contents && contents.length === 0)" @click="fnExelDown">리스트 다운</button>-->
            <Loading v-if="loading" />
          </div>
        </div>
        <div class="col unit_page">
          <h4 class="l_tit">페이지당 개수 :</h4>
          <Select id="perPage" title="20개" :itemList="perPageList" :value.sync="query.perPage" @change="fnFetchList" />
          <button
            class="btn"
            @click="
              () => {
                query.page = 1;
                fnFetchList();
              }
            ">
            검색
          </button>
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <thead>
              <tr>
                <th class="c1" style="width: 10%"><b>사업자번호</b></th>
                <th class="c2"><b>회사(고객)명</b></th>
                <th class="c4" style="width: 10%"><b>전화번호</b></th>
                <th class="c4" style="width: 20%"><b>이메일</b></th>
                <th class="c5"><b>담당자</b></th>
                <th class="c6">
                  <b>등기변경신청</b>
                  <div class="bx_srot">
                    <button class="btn_up" :class="{ on: query.sortReq === null || query.sortReq === 'asc' }" @click="() => fnSortValue({ key: 'sortReq', value: 'asc' })">오름차순정렬</button>
                    <button class="btn_down" :class="{ on: query.sortReq === null || query.sortReq === 'desc' }" @click="() => fnSortValue({ key: 'sortReq', value: 'desc' })">내림차순정렬</button>
                  </div>
                </th>
                <th class="c7">
                  <b>개정일</b>
                </th>
                <th class="c5" style="width: 8%" v-if="showGaId"><b>회원구분</b></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in contents" :key="item.id" :class="{ on: item.id === id }" @click="fnOnClick(item)" @dblclick="fnOpenPop2('popCsState')">
                <td class="c1" style="width: 10%">{{ item.bizNo }}</td>
                <td class="c2">{{ mixCorpType(item.corporation) + ' ' + item.compNm }}</td>
                <td class="c4" style="width: 10%">{{ item.tel }}</td>
                <td class="c4" style="width: 20%">{{ item.email }}</td>
                <td class="c5" v-html="mixFormatManager(item)">-</td>
                <!--              <td class="c7">{{ mixRequestYn(item.crpDtm, item.reqDtm)}}</td>-->
                <td class="c6">{{ mixCut(item.modDtm) }}</td>
                <td class="c7">{{ mixCut(item.crpDtm) }}</td>
                <td class="c5" style="width: 8%" v-if="showGaId">{{ fnMemberType(item.memberTypes) }}</td>
              </tr>
              <tr v-if="contents && contents.length === 0">
                <td colspan="8">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="query.page"
          :perPage="query.perPage"
          :totalCount="pagination.totalCount"
          @change="
            p => {
              this.query.page = p;
              this.fnFetchList();
            }
          " />
      </div>
      <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
        <Detail
          v-if="$store.state.ui.popCsState == true"
          :id="id"
          :bizNo="bizNo"
          :compNm="compNm"
          :consultantGaNm="consultantGaNm"
          :consultantNm="consultantNm"
          :compObj="compObj"
          :key="$store.state.ui.popCsState"
          @reload="fnReload" />
      </transition>
    </div>
  </section>
</template>

<script>
import Header from '~/components/layouts/Header';
import Location from '~/components/layouts/LocationDataWrapper';
import CsPop from '~/components/popup/Cs.vue';
import Detail from '~/components/pages/allOkMng/WelfareFundDetail.vue';
import Input from '~/components/common/Input.vue';
import Pagination from '~/components/common/Pagination';
import Select from '~/components/common/Select';
import Loading from '~/components/common/Loading.vue';
import customersListMixin from '../../mixins/customersListMixin';
import * as companyType from '../../constants/companyType';
import consultantsService from '~/service/allOk/consultantsService';
import * as company from '../../constants/company';

export default {
  mixins: [customersListMixin],
  components: {
    Header,
    Location,
    CsPop,
    Detail,
    Input,
    Select,
    Pagination,
    Loading
  },
  data() {
    return {
      //roles: ['CPMNG_INS'],
      roles: null,
      loading: false,
      id: '',
      compNm: '',
      bizNo: '',
      consultantGaNm: '',
      consultantNm: '',
      compObj: null,
      query: {
        page: 1,
        perPage: 20, // 한 페이지에 보여줄 rows의 갯수
        bizNo: null,
        compNm: null,
        compId: null,
      },
      contents: [],
      consultantGa: [],
      pagination: {
        totalCount: 0,
      },
      perPageList_: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
        { name: '30개', value: 30 },
      ],
    };
  },
  computed: {
    showGaData() {
      return this.$store.state.authUser?.gaType === companyType.ADMIN;
    },
    showGaId(){
      return this.$store.state.authUser?.gaId === company.메인비즈.code||this.$store.state.authUser?.gaId === company.유니콘랜치.code;
    },
    gaList() {
      if (this.consultantGa.length > 0) {
        const mapped = this.consultantGa.map(m => {
          return {
            name: m.name,
            value: m.id,
          };
        });
        return [{ name: '전체', value: null }, ...mapped];
      }
      return [];
    },
    perPageList() {
      return this.perPageList_.map(item => {
        return {
          ...item,
          select: item.value === this.query.perPage ? true : false,
        };
      });
    },
  },
  methods: {
    fnResetForm() {
      const newQuery = {
        page: 1,
        bizNo: null,
        compNm: null,
        compId: null,
      };
      this.query = {
        ...this.query,
        ...newQuery,
      };
      this.fnFetchList();
    },
    fnOnClick(e) {
      this.id = e.id;
      this.compNm = e.compNm;
      this.bizNo = e.bizNo;
      this.consultantGaNm = e.consultantGaNm;
      this.consultantNm = e.consultantNm;
      this.compObj = e;
    },
    fnOpenPop2(payload) {
      if (this.id) {
        this.$store.dispatch('ui/setPopState', { key: payload, value: true });
      } else {
        this.$popupAlert({
          title: '경고',
          msg: '고객을 선택해 주세요.',
        });
        return;
      }
    },
    fnResetPageAndFetch() {
      this.query.page = 1;
      this.fnFetchList();
    },
    fnFetchList() {
      const params = this.$generateQueryParam(this.query);
      this.$axios.get('/apiMngAo/customers/service/articleIncorp?' + params).then(res => {
        if (res.data.result === true) {
          const { contents, pagination } = res.data.data;
          this.contents = contents;
          this.pagination = pagination;
        }
      });
    },
    fetchConsultantGaList() {
      consultantsService.fetchConsultantGa(this.$axios).then(res => {
        const {
          data: { data, result },
        } = res;
        if (result === true) {
          this.consultantGa = data.contents;
        } else {
          this.consultantGa = [];
        }
      });
    },
    fnReload() {
      this.fnFetchList();
    },
    fnSortValue({ key, value }) {
      if (this.query[key] !== value) {
        this.query[key] = value;
      } else {
        this.query[key] = null;
      }
      this.fnFetchList();
    },
    fnMemberType(memberTypes){
      if(memberTypes){
        const mem = memberTypes.split(", ")
        return mem.includes("정회원") ? "정회원" : mem[0]
      } else {
        return "비회원"
      }
    }
  },
  mounted() {
    this.fnFetchList();
    this.fetchConsultantGaList();
  },
};
</script>
