<template>
  <div class="c_contents contents_col2">
    <div class="left bg_gray">
      <section class="list_goods">
        <h2>신규채용지원금 <span class="txt_org"> {{신규.cnt?신규.cnt: '0'}}</span>개</h2>
        <article class="unit" v-for="(item, i) in 신규.fundItemList" :key="i">
          <h3>{{item.title}}</h3>
          <dl>
            <dt>최대지원금</dt>
            <dd><b>{{item.amount}} (인당)</b></dd>
          </dl>
          <div class="col2">
            <dl class="col">
              <dt>지급기간</dt>
              <dd>{{item.period}}</dd>
            </dl>
          </div>
        </article>
      </section>
    </div>
    <div class="right bg_gray">
      <section class="list_goods">
        <h2>재직자지원금 <span class="txt_org"> {{재직자.cnt?재직자.cnt:'0'}}</span>개</h2>
        <article class="unit" v-for="(item, i) in 재직자.fundItemList" :key="i">
          <h3>{{item.title}}</h3>
          <dl>
            <dt>최대지원금</dt>
            <dd><b>{{item.amount}} (인당)</b></dd>
          </dl>
          <div class="col2">
            <dl class="col">
              <dt>지급기간</dt>
              <dd>{{item.period}}</dd>
            </dl>
          </div>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
import employService from '~/service/allOkMng/employService.js'
export default {
  props: ['id'],
  data() {
    return {
      신규: {
        cnt:null,
        fundItemList:[],
        id:null,
        title:null
      },
      재직자: {
        cnt:null,
        fundItemList:[],
        id:null,
        title:null
      },
    }
  },
  async mounted(){
    const result = await employService.getEmployDetail(this.$axios, this.id)
    if(result.result){
      const newList = result.data.contents.fundTypeList.filter(e=>e.title=='신규채용 지원금(고용창출)')
      const oldList = result.data.contents.fundTypeList.filter(e=>e.title=='재직자 지원금(고용유지)')
      this.신규 = newList[0]
      this.재직자 = oldList[0]
    }
  }
};
</script>
