<template>
  <div class="area_search">
    <div class="unit">
      <label class="tit" for="username">FC코드</label>
      <Input
        id="username"
        name="username"
        shape="search"
        placeholder='검색어를 입력하세요.'
        :value.sync="code"
        @enter="fnSearchFormEmit"
      />
    </div>
    <div class="unit">
      <label class="tit" for="name">FC이름</label>
      <Input
        id="name"
        name="name"
        shape="search"
        placeholder='검색어를 입력하세요.'
        :value.sync="name"
        @enter="fnSearchFormEmit"
      />
    </div>
    <div class="unit" v-if="![company.유니콘랜치.code, company.프리랜서FC.code].includes(gaId)">
      <h3 class="tit">본부</h3>
      <Select
        id="central"
        :itemList="orgCodeDepth01"
        defaultName="선택하세요"
        :value="orgCode01"
        @change="e=>fnOrgCodeChange(1, 'orgCode01',e)"
      />
    </div>
    <div class="unit" v-if="![company.유니콘랜치.code, company.프리랜서FC.code].includes(gaId)">
      <h3 class="tit">사업단</h3>
      <Select
        id="business"
        :itemList="orgCodeDepth02"
        defaultName="선택하세요"
        :value="orgCode02"
        @change="e=>fnOrgCodeChange(2, 'orgCode02', e)"
      />
    </div>
    <div class="unit" v-if="![company.유니콘랜치.code, company.프리랜서FC.code].includes(gaId)">
      <h3 class="tit">지역단</h3>
      <Select
        id="area"
        :itemList="orgCodeDepth03"
        defaultName="선택하세요"
        :value="orgCode03"
        @change="e=>fnOrgCodeChange(3, 'orgCode03',e)"
      />
    </div>
    <div class="unit unit_date" v-if="![company.유니콘랜치.code, company.프리랜서FC.code].includes(gaId)">
      <h3 class="tit">지점</h3>
      <Select
        id="branch"
        :itemList="orgCodeDepth04"
        defaultName="선택하세요"
        :value="orgCode04"
        @change="e=>fnOrgCodeChange(4, 'orgCode04',e)"
      />
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input.vue';
import Select from "~/components/common/Select";
import * as company from '~/constants/company';
export default {
  props: {
    organization:{
      type: Array,
      required: true,
    }
  },
  components:{Input, Select},
  data(){
    return {
      code: null,
      name: null,
      orgCode01:null,
      orgCode02:null,
      orgCode03:null,
      orgCode04:null,
      company: company,
    }
  },
  computed: {
    gaId(){
      return this.$store.state.authUser?.gaId;
    },

    orgCodeDepth01() {
      return this.convertSelectList(this.findSelectList(this.organization, null));
    },
    orgCodeDepth02() {
      if(this.orgCode01 && this.orgCode01 !== '_none')
        return this.convertSelectList(this.findSelectList(this.organization, [this.orgCode01]), true);
      return [];
    },
    orgCodeDepth03() {
      if(this.orgCode01 && this.orgCode02 && this.orgCode02!== '_none')
        return this.convertSelectList(this.findSelectList(this.organization, [this.orgCode01, this.orgCode02]), true);
      return [];
    },
    orgCodeDepth04() {
      if(this.orgCode01 && this.orgCode02 && this.orgCode03 && this.orgCode03 !== '_none')
        return this.convertSelectList(this.findSelectList(this.organization, [this.orgCode01, this.orgCode02, this.orgCode03]), true);
      return [];
    }
  },
  methods:{
    findSelectList(orgList, parentCodes){
      if(parentCodes && parentCodes.length > 0){
        let resultList;
        const parentOrg = orgList.find( o => o.id === parentCodes[0]);
        if( parentOrg ) {
          if( parentOrg.subList && parentOrg.subList.length>0){
            resultList = this.findSelectList(parentOrg.subList, parentCodes.slice(1));
          } else {
            resultList = []
          }
        } else {
          resultList = []
        }
        return resultList;
      }else{
        return orgList;
      }
    },
    convertSelectList(list, hasNoneItem){
      let resultList = [{name:'선택하세요', value:null}];
      if(hasNoneItem){
        let noneItem = {name:'없음', value:'_none'};
        resultList.push(noneItem);
      }
      list.forEach( a => {
        resultList.push({name: a.name, value: a.id});
      })
      return resultList;
    },
    fnOrgCodeChange(level, target, {value}){
      if(level < 4) this.orgCode04 = null;
      if(level < 3) this.orgCode03 = null;
      if(level < 2) this.orgCode02 = null;
      this[target] = value ? value : null;
      this.fnSearchFormEmit();
    },
    fnSearchFormEmit(){
      let _orgCode02 = this._convertNoneToNull(this.orgCode02);
      let _orgCode03 = this._convertNoneToNull(this.orgCode03);
      let _orgCode04 = this._convertNoneToNull(this.orgCode04);
      const orgId = _orgCode04 ? _orgCode04
                    : _orgCode03 ? _orgCode03
                    : _orgCode02 ? _orgCode02
                    : this.orgCode01;

      // 조직 코드중에 한개라도 _none이 없는 경우
      // 하위 조직의 회원도 검색한다.
      let includeChildOrg = this.orgCode02 !== '_none' && this.orgCode03 !== '_none' && this.orgCode04 !== '_none';

      this.$emit('changed', {
        code: this.code,
        name: this.name,
        orgCode01: this.orgCode01,
        orgCode02: this.orgCode02,
        orgCode03: this.orgCode03,
        orgCode04: this.orgCode04,
        includeChildOrg: includeChildOrg,
        orgId
      });
    },
    _convertNoneToNull(value){
      if(value === null || value === '_none'){
        return null;
      }
      return value;
    }
  },
}
</script>
