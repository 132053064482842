<template>
  <li class="item_ipt">
    <h4>{{colName}}</h4>
    <div v-if="selectBoxList" class="i_wrap">
      <Select title="본부"
              id="central"
              :itemList="selectBoxList"
              :value="tempValue"
              @change="fnChange"
      />
    </div>
  </li>
</template>

<script>
import Select from '~/components/common/Select';
export default {
  components: {
    Select
  },
  props: {
    colName: { type: String },
    selectBoxList: { type: Array, required: false },
    value: {},
  },
  data(){
    return {
      tempValue: this.value
    }
  },
  methods: {
    fnChange(e){
      this.tempValue = e.value;
      this.$emit('change', e.value);
    }
  }
}
</script>
