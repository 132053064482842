<template>
  <div class="popup confirm">
    <div class="p_wrap">
      <div class="p_top" style="text-align: center;">
        <h3>기업삭제</h3>
      </div>
      <div class="p_container">
        <div>
          <label style="padding-bottom: 15px;">사업자번호</label>
          <input type="text" readonly :value="bizNo" style="width: 100%; margin-bottom: 20px" />
          <input type="text" style="width: 100%" v-model='typedBizNo' placeholder="삭제할 사업자 번호를 입력하세요" />
          <p style="color: red; padding-top: 20px">기업 삭제처리시 데이터를 다시 되돌릴 수 없습니다.</p>
        </div>
      </div>
      <div class="p_bottom">
        <button class="btn"
                :class="{dark:disableDeleteBtn, noclick:disableDeleteBtn}"
                :disabled="disableDeleteBtn"
                @click="fnDelete"
        >
          삭제
        </button>
        <button class="btn dark" @click="fnClose">
          <b>취소</b>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customerId: { type:String, required: true},
    bizNo: { type:String, required: true },
  },
  methods:{
    fnClose(){
      this.typedBizNo=null;
      this.disableDeleteBtn=true;
      this.$emit('close');
    },
    fnDelete() {
      if (!this.disableDeleteBtn) {
        if ( confirm("삭제하시겠습니까?\n삭제 후 데이터를 되돌릴 수 없습니다.") ){
          this.$axios.delete(`/apiMngAo/landing/customers/${this.customerId}`)
            .then(res => {
              console.log(res)
              if ( res.data.result ){
                this.$emit('refresh');
              }
            })
            .catch(err => {
              console.log(err);
            })
        }
      }
    }
  },
  data(){
    return {
      typedBizNo: null,
      disableDeleteBtn: true,
    }
  },
  watch: {
    typedBizNo(_bizNo) {
      if( _bizNo == this.bizNo ) {
        this.disableDeleteBtn = false;
      } else {
        if ( !this.disableDeleteBtn ) {
          this.disableDeleteBtn = true;
        }
      }
    }
  }
}
</script>

<style>
.dark.noclick{
  cursor: default;
  color: grey;
}
</style>
