const allOkCodeMapper = (store, codeGroup, code) => {
  for(const a in store){
    const codeList = store[codeGroup];
    if(codeList !== undefined){
      const foundCode = codeList.find(o => {
        return o.cd ===  code
      });
      if(foundCode != null)
        return foundCode.nm;
    }
  }
  return "";
};

export default {
  allOkCodeMapper
}

