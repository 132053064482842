<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>노무관리 상세 : {{ compNm }}</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button :class="{ tab: true, on: on == 'tab1' }" @click="() => (this.on = 'tab1')">고객상세</button>
              <button :class="{ tab: true, on: on == 'tab2' }" @click="() => (this.on = 'tab2')">서류제출</button>
              <button :class="{ tab: true, on: on == 'tab3' }" @click="() => (this.on = 'tab3')">입력내용</button>
              <button :class="{ tab: true, on: on == 'tab4' }" @click="() => (this.on = 'tab4')">급여대장</button>
              <button :class="{ tab: true, on: on == 'tab5' }" @click="() => (this.on = 'tab5')">근로계약서</button>
              <button :class="{ tab: true, on: on == 'tab6' }" @click="() => (this.on = 'tab6')">근로계약서(계약직)</button>
              <button :class="{ tab: true, on: on == 'tab_pwd' }"
                      v-if="$menuRoleCheck([mixMenuRoles.어드민])"
                      @click="() => (this.on = 'tab_pwd')">온라인 노무 비밀번호</button>
              <!-- <button :class="{ tab: true, on: on == 'tab10' }" @click="() => (this.on = 'tab10')">담당자 배정</button> -->
            </div>
            <div class="btns">
              <button class="btn dark" @click="fnClosePop">닫기</button>
            </div>
          </div>
          <BizInfoTab v-if="on == 'tab1'" :id="id" :bizNo="bizNo" />
          <LaborTab2 v-if="on == 'tab2'" :id="id" />
          <LaborTab3 v-if="on == 'tab3'" :id="id" />
          <LaborTab4 v-if="on == 'tab4'" :id="id" />
          <LaborTab5 v-if="on == 'tab5'" :id="id" :compNm="compNm" :bizNo="bizNo"/>
          <LaborTab6 v-if="on == 'tab6'" :id="id" :compNm="compNm" :bizNo="bizNo"/>
          <LaborTab10 v-if="on == 'tab10'" :id="id" :bizNo="bizNo" :consultantGaNm="consultantGaNm" :consultantNm="consultantNm" />
          <labor-pwd v-if="on == 'tab_pwd'" :id="id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BizInfoTab from "./BizInfoTab";
import LaborTab2 from './LaborTab2.vue';
import LaborTab3 from './LaborTab3.vue';
import LaborTab4 from './LaborTab4.vue';
import LaborTab5 from './LaborTab5.vue';
import LaborTab6 from './LaborTab6.vue';
import LaborTab10 from './LaborTab10.vue';
import LaborPwd from "./LaborPwd";
import rolesMixin from "../../../mixins/rolesMixin";

export default {
  name: 'LaborDetail',
  mixins: [rolesMixin],
  data() {
    return {
      on: 'tab1',
      contents: {},
    };
  },
  props: ['id', 'bizNo', 'compNm', 'consultantGaNm', 'consultantNm'],
  components: {
    BizInfoTab,
    LaborTab2,
    LaborTab3,
    LaborTab4,
    LaborTab5,
    LaborTab6,
    LaborTab10,
    LaborPwd
  },
  methods: {
    fnClosePop() {
      this.$store.dispatch('ui/setPopCsState', false);
      // this.$emit('reload');
    },
  },
};
</script>
