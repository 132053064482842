<template>
  <section class="container">
    <Location />
    <div class="inner">
      <div class="colr">
        <button class="btn_reset" @click="fnResetForm">전체초기화</button>
      </div>
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="bizNo">사업자번호</label>
          <Input id="bizNo" name="query.bizNo" shape="search" placeholder="검색어를 입력하세요." :value.sync="query.bizNo" @enter="fnResetPageAndFetch" />
        </div>
        <div class="unit">
          <label class="tit" for="compNm">고객명</label>
          <Input id="compNm" name="query.compNm" shape="search" placeholder="검색어를 입력하세요." :value.sync="query.compNm" @enter="fnResetPageAndFetch" />
        </div>
        <client-only>
          <div class="unit" v-if="$roleCheck(['ADMIN'])">
            <label class="tit" for="gaNm">GA</label>
            <Select id="gaNm" defaultName="GA를 선택하세요" :itemList="gaList" :value.sync="query.gaNm" @change="fnFetchList" />
          </div>
        </client-only>
        <div class="unit">
          <label class="tit" for="cnsltntNm">담당자</label>
          <Input id="cnsltntNm" shape="search" name="query.cnsltntNm" placeholder="검색어를 입력하세요." :value.sync="query.cnsltntNm" @enter="fnResetPageAndFetch" />
        </div>
        <div class="unit">
          <label class="tit" for="keyword">키워드</label>
          <Input id="keyword" name="query.keyword" shape="search" placeholder="검색어를 입력하세요." :value.sync="query.keyword" @enter="fnResetPageAndFetch" />
        </div>
        <!-- <div class="unit">
          <h3 class="tit">관리단계</h3>
          <Select
            id="stateNm"
            defaultName="관리단계를 선택하세요"
            :itemList="stateList"
            :value.sync="query.stateNm"
            @change="fnFetchList"
          />
        </div> -->
        <div class="unit">
          <h3 class="tit">진행단계</h3>
          <Select id="statusCd" defaultName="진행단계를 선택하세요" :itemList="statusList" :value.sync="query.statusCd" @change="fnFetchList" />
        </div>
        <div class="unit unit_date">
          <h3 class="tit">기간설정</h3>
          <DateRangeTabs
            ref="dateRangeTabs"
            @change="
              ({ fromDate, toDate }) => {
                this.query.fromDate = fromDate;
                this.query.toDate = toDate;
                query.page = 1;
                fnFetchList();
              }
            " />
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <div>
            <!--
            22.12.23 : 새로운 고객은 B2C에서 등록하는 것으로 변경
            -->
            <!-- <button class="btn" @click="fnOpenB2C('popCreateState')">
              새로운 고객
            </button> -->
            <button class="btn" @click="fnOpenPop2('popCsState')">열기</button>
            <button class="btn" @click="fnDownload">보고서</button>
          </div>
        </div>
        <div class="col unit_page">
          <h4 class="l_tit">페이지당 개수 :</h4>
          <Select id="perPage" title="20개" :itemList="perPageList" :value.sync="query.perPage" @change="fnFetchList" />
          <button
            class="btn"
            @click="
              () => {
                query.page = 1;
                fnFetchList();
              }
            ">
            검색
          </button>
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <thead>
              <tr>
                <th class="c1"><b>사업자번호</b></th>
                <th class="c2"><b>회사(고객)명</b></th>
                <th class="c4"><b>전화번호</b></th>
                <th class="c4"><b>이메일</b></th>
                <th class="c4"><b>담당자</b></th>
                <th class="c3"><b>진행단계</b></th>
                <!-- <th class="c5">
                <b>진행상태</b>
                <div class="bx_srot">
                  <button class="btn_up" :class="{on:query.sortPrg===null || query.sortPrg==='asc'}" @click="()=>fnSortValue({key:'sortPrg', value:'asc'})">오름차순정렬</button>
                  <button class="btn_down" :class="{on:query.sortPrg===null || query.sortPrg==='desc'}" @click="()=>fnSortValue({key:'sortPrg', value:'desc'})">내림차순정렬</button>
                </div>
              </th> -->
                <th class="c6">
                  <b>신청일</b>
                  <div class="bx_srot">
                    <button class="btn_up" :class="{ on: query.sortReq === null || query.sortReq === 'asc' }" @click="() => fnSortValue({ key: 'sortReq', value: 'asc' })">오름차순정렬</button>
                    <button class="btn_down" :class="{ on: query.sortReq === null || query.sortReq === 'desc' }" @click="() => fnSortValue({ key: 'sortReq', value: 'desc' })">내림차순정렬</button>
                  </div>
                </th>
                <th class="c7">
                  <b>마지막처리일</b>
                  <div class="bx_srot">
                    <button class="btn_up" :class="{ on: query.sortUp === null || query.sortUp === 'asc' }" @click="() => fnSortValue({ key: 'sortUp', value: 'asc' })">오름차순정렬</button>
                    <button class="btn_down" :class="{ on: query.sortUp === null || query.sortUp === 'desc' }" @click="() => fnSortValue({ key: 'sortUp', value: 'desc' })">내림차순정렬</button>
                  </div>
                </th>
                <th class="c8" style="width: 8%" v-if="showGaId"><b>회원구분</b></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in contents"
                :key="item.cnsltId"
                :class="{ on: item.cnsltId === id }"
                @click="fnOnClick({ id: item.cnsltId, customerId: item.customerId, compNm: item.compNm, bizNo: item.bizNo, statusCd: item.statusCd })"
                @dblclick="fnOpenPop2('popCsState')">
                <td class="c1">{{ item.bizNo }}</td>
                <td class="c2">{{ item.compNm }}</td>
                <td class="c4">{{ item.clientTel }}</td>
                <td class="c4">{{ item.clientEmail }}</td>
                <td class="c4">{{ item.cnsltntGaNm && `영업 [${item.cnsltntGaNm}] ${item.cnsltntNm || ''}` }}</td>
                <td class="c3">{{ item.statusNm }}</td>
                <!-- <td class="c5">{{ item.progress_nm }}</td> -->
                <td class="c6">{{ item.regDt }}</td>
                <td class="c7">{{ item.updDtm }}</td>
                <td class="c8" v-if="showGaId">{{ fnMemberType(item.memberTypes) }}</td>
              </tr>
              <tr v-if="contents && contents.length === 0">
                <td colspan="9">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="query.page"
          :perPage="query.perPage"
          :totalCount="pagination.totalCount"
          @change="
            p => {
              this.query.page = p;
              this.fnFetchList();
            }
          " />
      </div>
      <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
        <!--
        22.12.23 신규고객은 B2C 페이지로 이동함으로 인해 해당 기능 제거
        <NewCustomer v-if="$store.state.ui.popCreateState == true" :key="$store.state.ui.popCreateState" @reload="fnReload"/>
        -->
        <CustomerDetail v-if="$store.state.ui.popCsState == true" :key="$store.state.ui.popCsState" :id="id" :customerId="customerId" :compNm="compNm" @reload="fnReload" />
      </transition>
    </div>
  </section>
</template>

<script>
import Header from '~/components/layouts/Header';
import Location from '~/components/layouts/LocationDataWrapper';
//import NewCustomer from '~/components/pages/allOk/NewCustomer.vue';
import CsPop from '~/components/popup/Cs.vue';
import CustomerDetail from '~/components/pages/allOk/CustomerDetail.vue';
import Input from '~/components/common/Input.vue';
import Pagination from '~/components/common/Pagination';
import Select from '~/components/common/Select';
import DateRangeTabs from './DateRangeTabs';
import DatePicTooltip from './DatePicTooltip';
import consultantsService from '~/service/allOk/consultantsService';
import * as company from '~/constants/company';

export default {
  components: {
    Header,
    Location,
    //NewCustomer,
    CsPop,
    CustomerDetail,
    Input,
    Select,
    Pagination,
    DateRangeTabs,
    DatePicTooltip,
  },
  data() {
    return {
      id: '',
      compNm: '',
      customerId: '',
      bizNo: '',
      query: {
        page: 1,
        perPage: 20, // 한 페이지에 보여줄 rows의 갯수
        cnsltntId: null,
        bizNo: null,
        compNm: null,
        gaNm: null,
        cnsltntNm: null,
        fromDate: null,
        toDate: null,
        // stateNm:null,
        // processCd:null,
        statusCd: null,
        keyword: null,
        sortPrg: null,
        sortReq: 'desc',
        sortUp: null,
      },
      contents: [],
      consultantGa: [],
      pagination: {
        totalCount: 0,
      },
      reload: 0,
      stateList: [
        { name: '전체', value: null },
        { name: '재신청', value: '재신청' },
        { name: '완료', value: '완료' },
        { name: '상담중', value: '상담중' },
        { name: '상담사배정', value: '상담사배정' },
        { name: '신청', value: '신청' },
        { name: '조회', value: '조회' },
      ],
      perPageList_: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
        { name: '30개', value: 30 },
      ],
    };
  },
  computed: {
    showGaId(){
      return this.$store.state.authUser?.gaId === company.메인비즈.code||this.$store.state.authUser?.gaId === company.유니콘랜치.code;
    },
    gaList() {
      if (this.consultantGa.length > 0) {
        const mapped = this.consultantGa.map(m => {
          return {
            name: m.name,
            value: m.name,
          };
        });
        return [{ name: '전체', value: null }, ...mapped];
      }
      return [];
    },
    statusList() {
      const codeList = this.$store.state.allOk.allOkStore.codeGroup['ConsultingStatus'];
      if (codeList) {
        const mapped = codeList.map(p => {
          return {
            name: p.nm,
            value: p.cd,
          };
        });
        return [{ name: '전체', value: null }, ...mapped];
      }
      return [];
    },
    perPageList() {
      return this.perPageList_.map(item => {
        return {
          ...item,
          select: item.value === this.query.perPage ? true : false,
        };
      });
    },
  },
  methods: {
    fetchConsultantGaList() {
      consultantsService.fetchConsultantGa(this.$axios).then(res => {
        const {
          data: { data, result },
        } = res;
        if (result === true) {
          this.consultantGa = data.contents;
        } else {
          this.consultantGa = [];
        }
      });
    },
    fnDownload() {
      if (!this.id) {
        this.$popupAlert({
          title: '경고',
          msg: '고객을 선택해주세요.',
        });
        return;
      }
      window.open(`/customer/report?id=${this.id}&bizNo=${this.bizNo}&dp=pdf`);
      //location.href=`/apiAo/reportDown?id=${this.id}`
    },
    fnResetForm() {
      const newQuery = {
        page: 1,
        bizNo: null,
        compNm: null,
        keyword: null,
        cnsltntNm: null,
        statusCd: null,
      };
      this.query = {
        ...this.query,
        ...newQuery,
      };
      let resetResult = this.$refs.dateRangeTabs.fnReset();
      if (!resetResult) {
        this.fnFetchList();
      }
    },
    fnOnClick(e) {
      if (this.id !== e.id) {
        this.id = e.id;
        this.compNm = e.compNm;
        this.customerId = e.customerId;
        this.bizNo = e.bizNo;
        this.$store.commit('allOk/customersStore/UPDATE_CUSTOMER_ID', e.customerId);
        this.$store.commit('allOk/customersStore/UPDATE_CNSLT_ID', e.id);
        this.$store.commit('allOk/customersStore/UPDATE_CUSTOMER_NAME', e.compNm);
        this.$store.commit('allOk/customersStore/UPDATE_STATUSCD', e.statusCd);
      }
      // else {
      //
      // }
      // this.fnOpenPop2('popCsState');
    },
    fnChangeValue(e) {
      this.query[e.id] = e.value;
      this.query.page = 1;
      this.fnFetchList();
    },
    fnOpenB2C() {
      window.open('http://www.all-ok.co.kr', '_blank');
    },
    // 22.12.23 신규고객은 B2C에서 등록함
    // fnOpenPop(payload) {
    //   this.$store.dispatch('ui/setPopState', { key: payload, value: true });
    // },
    fnOpenPop2(payload) {
      if (this.id) {
        this.$store.dispatch('ui/setPopState', { key: payload, value: true });
      } else {
        this.$popupAlert({
          title: '경고',
          msg: '고객을 선택해 주세요.',
        });
        return;
      }
    },
    fnResetPageAndFetch() {
      this.query.page = 1;
      this.fnFetchList();
    },
    fnFetchList() {
      if (this.query.fromDate && this.query.toDate) {
        const params = this.$generateQueryParam(this.query);
        this.$axios.get('/apiAo/users/customers?' + params).then(res => {
          if (res.data.result === true) {
            const { contents, pagination } = res.data.data;
            this.contents = contents;
            this.pagination = pagination;
          }
        });
      }
    },
    fnReload() {
      // this.reload = Math.random();
      this.fnFetchList();
    },
    fnNewCustomer() {
      const id = this.$store.state.authUser.id;
      window.open('http://localhost:3000/lookup?q=' + id);
    },
    fnSortValue({ key, value }) {
      if (this.query[key] !== value) {
        this.query[key] = value;
      } else {
        this.query[key] = null;
      }
      this.fnFetchList();
    },
    fnMemberType(memberTypes){
      if(memberTypes){
        const mem = memberTypes.split(", ")
        return mem.includes("정회원") ? "정회원" : mem[0]
      } else {
        return "비회원"
      }
    }
  },
  mounted() {
    this.fetchConsultantGaList();
    this.fnFetchList();
  },
};
</script>
