<template>
 <div class="popup p_cs">
    <div class="p_wrap">
      <div class="p_top">
        <h2>마이 페이지</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button class="tab on">마이페이지</button>
            </div>
            <div class="btns">
              <button class="btn" @click="fnSave">저장</button>
              <button class="btn dark" @click="fnCancel">취소</button>
            </div>
          </div>
          <div class="c_contents">
            <div class="c_contents contents_col2">
              <div class="left">
                <ul class="form_list type2">
                  <label><b>내 정보</b></label>
                  <li class="item_ipt">
                    <label for="email"><h4><b>전화번호</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id="email"
                        name="email"
                        type="text"
                        disabled
                        :value.sync="user.tel"
                      />
                    </div>
                  </li>
                  <li class="item_ipt">
                    <label for="name"><h4><b>이름</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id='name'
                        type='text'
                        name="name"
                        disabled
                        :value.sync="user.name"
                      />
                    </div>
                  </li>
                  <li class="item_ipt" v-if="![company.유니콘랜치.code, company.프리랜서FC.code].includes(gaId)">
                    <label for="department"><h4><b>지점</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id="department"
                        name="department"
                        type="text"
                        disabled
                        :value.sync="user.orgName"
                      />
                    </div>
                  </li>
                  <li class="item_ipt" v-if="![company.유니콘랜치.code, company.프리랜서FC.code].includes(gaId)">
                    <label for="position"><h4><b>직급</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id='position'
                        type='text'
                        name="position"
                        disabled
                        :value.sync="position"
                      />
                    </div>
                  </li>
                  <label><br><b>비밀번호 변경</b></label>
                  <li class="item_ipt">
                    <label for="password"><h4><b>현재 비밀번호</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id="password"
                        name="password"
                        style="-webkit-text-security:disc;"
                        placeholder="현재 비밀번호 입력"
                        :value.sync="form.password"
                      />
                    </div>
                  </li>
                  <li class="item_ipt">
                    <label for="newPassword"><h4><b>새 비밀번호</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id='newPassword'
                        style="-webkit-text-security:disc;"
                        name="newPassword"
                        placeholder='새 비밀번호 입력'
                        :value.sync="form.newPassword"
                      />
                    </div>
                  </li>
                  <li class="item_ipt">
                    <label for="newPassword2"><h4><b>비밀번호 확인</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id="newPassword2"
                        name="newPassword2"
                        style="-webkit-text-security:disc;"
                        placeholder="비밀번호 확인"
                        :value.sync="newPassword2"
                      />
                    </div>
                  </li>
                  <li class="item_ipt" v-if="qr.url">
                    <label for="qrcode"><h4><b>QR코드</b></h4></label>
                    <div class="i_wrap">
                      <div style="text-align: center">
                        <qrcode-vue id="qr-code" :value="qr.url" :size="qr.size" level="H" />
                        <div class="f18 mt5" style="text-decoration: underline; cursor: pointer" @click="fnQrCodeDownload">QR코드 다운로드</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';
import Select from '~/components/common/Select';
import allOkService from "../../service/allOk/allOkService";
import * as company from '~/constants/company';
import * as partnerInfo from '~/constants/partnerInfo';
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'mypage',
  components: {
    InputBox, Select, QrcodeVue,
  },
  data() {
    return {
      user: {},
      form:{
        password: '',
        newPassword: '',
      },
      newPassword2: '',
      position:'',
      company: company,
      qr: {
        url: '',
        imgNm: '',
        size: 140,
      }
    };
  },

  computed: {
    gaId(){
      return this.$store.state.authUser?.gaId;
    },
  },

  methods: {
    fnCancel(){
      window.history.go(-1)
    },
    fnClosePop(){
      this.$store.dispatch('ui/setPopFc2State', false);
    },
    async fnSave(){
      if(!this.form.password){
        this.$popupAlert({
          title: '경고 : 현재 비밀번호',
          msg: '현재 비밀번호를 입력해 주십시오.',
        });
      } else if(!this.form.newPassword){
        this.$popupAlert({
          title: '경고 : 새 비밀번호',
          msg: '새 비밀번호를 입력해 주십시오.',
        });
      } else if(this.form.newPassword!=this.newPassword2){
        this.$popupAlert({
          title: '경고 : 비밀번호 확인',
          msg: '비밀번호가 일치하지 않습니다.',
        });
      } else{
        const payload={
          userId: this.user.id,
          form: this.form
        }
        const result = await allOkService.changePw(this.$axios, payload)
        if(result.result){
          this.$popupAlert({
            title: '확인',
            msg: '비밀번호가 변경되었습니다.',
          });
          this.form.password=''
          this.form.newPassword=''
          this.newPassword2=''
        } else {
          this.$popupAlert({
            title: '경고',
            msg: '비밀번호 변경이 실패했습니다.',
          });
          return;
        }
      }
    },
    fnSetQrCode(){
      const allOkKKUrl = process.env.allOkKKUrl
      const {username, gaId} = this.$store.state.authUser
      const infoKeys = Object.keys(partnerInfo);
      for(let i=0; i<infoKeys.length; i++){
        const key = infoKeys[i];
        if(gaId == partnerInfo[key].companyId){
          this.qr.url = allOkKKUrl + partnerInfo[key].path + username
          this.qr.imgNm = `QR(${username}).png`
          break;
        }
      }
    },
    fnQrCodeDownload(){
      const link = document.createElement('a')
      link.download = this.qr.imgNm
      link.href = document.getElementById('qr-code').childNodes[0].toDataURL()
      link.click()
    }
      
  },
  async mounted() {
    this.fnSetQrCode()
    const result = await allOkService.getOrganizationsUserOne(this.$axios, this.$store.state.authUser.id)
    if(result.data){
      this.user=result.data.contents
      if(result.data.contents.position_cd==='PS0001'){
        this.position = '대표이사'
      } else if(result.data.contents.position_cd==='PS0002'){
        this.position = '전무이사'
      } else if(result.data.contents.position_cd==='PS0003'){
        this.position = '상무이사'
      } else if(result.data.contents.position_cd==='PS0004'){
        this.position = '상임고문'
      } else if(result.data.contents.position_cd==='PS0005'){
        this.position = '감사'
      } else if(result.data.contents.position_cd==='PS0006'){
        this.position = '주임'
      } else if(result.data.contents.position_cd==='PS0007'){
        this.position = '차장'
      } else if(result.data.contents.position_cd==='PS0008'){
        this.position = '대리'
      } else if(result.data.contents.position_cd==='PS0009'){
        this.position = '과장'
      } else if(result.data.contents.position_cd==='PS0010'){
        this.position = '매니저'
      } else if(result.data.contents.position_cd==='PS0011'){
        this.position = '사업단장'
      } else if(result.data.contents.position_cd==='PS0012'){
        this.position = '설계사'
      } else if(result.data.contents.position_cd==='PS0013'){
        this.position = '지역단장(RM)'
      } else if(result.data.contents.position_cd==='PS0014'){
        this.position = '지점장(BM)'
      } else if(result.data.contents.position_cd==='PS0015'){
        this.position = '팀장(SM)'
      } else if(result.data.contents.position_cd==='PS0016'){
        this.position = 'BS'
      }
    }
  }
};
</script>
