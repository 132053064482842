const baseUrl = '/apiAo';

const getOrganiztions = (axios) => {
  return axios.get(`${baseUrl}/organizations`).then(res => {
    return res.data;
  });
}

const getOrganiztionsType2 = (axios) => {
  return axios.get(`${baseUrl}/organizations?type=type2`).then(res => {
    return res.data;
  });
}

export default {
  getOrganiztions,
  getOrganiztionsType2
}
