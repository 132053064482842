<template>
  <section class="container">
    <div class="inner">
      <div class="contents">
        <div class="c_top">
          <h2>{{compNm}} 대상 인원: 총 <span class="txt_org">{{estimate.totalEmpCnt?estimate.totalEmpCnt:'-'}}</span>인</h2>
          <button class="btn" @click="downloadPdf">진단결과 리포트</button>
        </div>
        <br/>
        <div class="top_tit">
          <label><h2>회사/근로자 TOTAL 절감액</h2></label>
          <p class="txt_gray"><span>소득세 절감액을 포함하지 않은 4대보험료 절감 총액 입니다.</span></p>
        </div>
        <div class="tbl_area">
          <table class="tbl">
            <thead>
              <tr>
                <th colspan="6">
                  <h2><b>연간TOTAL &nbsp;&nbsp;&nbsp; <span class="txt_org">{{fnToLocaleString(estimate.yearTotal)}}</span>원</b></h2>
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th class="c1"><b>국민연금 절감액</b></th>
                <th class="c2"><b>건강보험 절감액</b></th>
                <th class="c4"><b>장기요양 절감액</b></th>
                <th class="c6"><b>고용보험 절감액</b></th>
                <th class="c7"><b>산재보험 절감액</b></th>
                <th class="c8"><b>소득세 절감액</b></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="c1">{{fnToLocaleString(estimate.natPensionTotal)}} 원</td>
                <td class="c2">{{fnToLocaleString(estimate.healthTotal)}} 원</td>
                <td class="c4">{{fnToLocaleString(estimate.longTermCareTotal)}} 원</td>
                <td class="c6">{{fnToLocaleString(estimate.empTotal)}} 원</td>
                <td class="c7">{{fnToLocaleString(estimate.industTotal)}} 원</td>
                <th class="c8">{{fnToLocaleString(estimate.allTaxReducedYearTotal)}} 원</th>
              </tr>
            </tbody>
          </table>
        </div>
        <br/>
        <label><h2>회사 TOTAL 절감액</h2></label>
        <div class="tbl_area">
          <table class="tbl">
            <thead>
              <tr>
                <th colspan="5">
                  <h2><b>연간TOTAL &nbsp;&nbsp;&nbsp; <span class="txt_org">{{fnToLocaleString(estimate.yearCmpTotal)}}</span> 원</b></h2>
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th class="c1"><b>국민연금 절감액</b></th>
                <th class="c2"><b>건강보험 절감액</b></th>
                <th class="c4"><b>장기요양 절감액</b></th>
                <th class="c6"><b>고용보험 절감액</b></th>
                <th class="c6"><b>산재보험 절감액</b></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="c1">{{fnToLocaleString(estimate.natPensionCmpTotal)}} 원</td>
                <td class="c2">{{fnToLocaleString(estimate.healthCmpTotal)}} 원</td>
                <td class="c4">{{fnToLocaleString(estimate.longTermCareCmpTotal)}} 원</td>
                <td class="c6">{{fnToLocaleString(estimate.empCmpTotal)}} 원</td>
                <td class="c6">{{fnToLocaleString(estimate.industCmpTotal)}} 원</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br/>
        <label><h2>근로자 TOTAL 절감액</h2></label>
        <div class="tbl_area">
          <table class="tbl">
            <thead>
              <tr>
                <th colspan="5">
                  <h2><b>연간TOTAL &nbsp;&nbsp;&nbsp; <span class="txt_org">{{fnToLocaleString(estimate.yearEmpTotal)}}</span>원</b></h2>
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th class="c1"><b>국민연금 절감액</b></th>
                <th class="c2"><b>건강보험 절감액</b></th>
                <th class="c4"><b>장기요양 절감액</b></th>
                <th class="c6"><b>고용보험 절감액</b></th>
                <th class="c6"><b>소득세 절감액</b></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="c1">{{fnToLocaleString(estimate.natPensionEmpTotal)}} 원</td>
                <td class="c2">{{fnToLocaleString(estimate.healthEmpTotal)}} 원</td>
                <td class="c4">{{fnToLocaleString(estimate.longTermCareEmpTotal)}} 원</td>
                <td class="c6">{{fnToLocaleString(estimate.empEmpTotal)}} 원</td>
                <td class="c6">{{fnToLocaleString(estimate.allTaxReducedYearTotal)}} 원</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import insuranceService from '~/service/allOkMng/insuranceService';
export default {
  props:['id', 'bizNo', 'compNm'],
  name:"InsuranceTab1",
  layout:"my",
  data() {
    return {
      openReport:false,
      estimate:{}
    };
  },
  methods: {
    downloadPdf(){
      this.pdfDownloading = true;
      const url = process.env.allOkKKUrl
      window.open(`${url}/api/pdf/insurance?compNm=${this.compNm}&bizNo=${this.bizNo}`);
      setTimeout(()=> {
        this.pdfDownloading = false;
      }, 1000 * 6);
    },
    fnToLocaleString(payload){
      if(payload)
        return payload.toLocaleString()
      return '-'
    },
  },
  async fetch() {
    const result = await insuranceService.getInsuranceDetail(this.$axios, this.id)
    if(result.result){
      this.estimate = result.data.contents.estimate;
    }
  },
};
</script>
