<template>
  <ul class="form_list type2">
    <OrgSelect
      v-for="(item,idx) in orgCdList" :key="idx"
      :col-name="item.colName"
      :select-box-list="fnOrgCdMap(orgList[idx])"
      :value="fnSavedId(idx)"
      @change="(value)=>fnSelectChange(idx, value)"
    />
  </ul>
</template>

<script>
import OrgSelect from "./OrgSelect";
export default {
  components: { OrgSelect },
  props: {
    orgCdList: {
      type: Array
    },
    user: {
      type: Object,
    }
  },
  created() {
    //
    this.fnInit();
  },
  data(){
    return {
      orgList:null,
      orgId:null,
    }
  },
  computed:{
    organizations(){
      return this.$store.state.allOk.allOkStore.organiztions;
    }
  },
  methods: {
    fnInit(){
      this.orgList=[];
      this.orgList.push(this.organizations);
      if ( this.user ) {
        for(let i=0; i<this.orgCdList.length; i++){
          let idx = i;
          let value = this.user[`orgId${i}`];
          if ( value ) {
            this.fnUpdateOrgList( idx, value );
          }
        }
      }
    },
    fnOrgCdMap(_orgList) {
      return _orgList != null
        ? _orgList.map(o => {
          return {name: o.name, value: o.id}
        })
        : null;
    },
    fnUpdateOrgList(idx, value){
      let _orgList = this.orgList[idx];
      let _findOrg = _orgList.find( _org => _org.id == value );
      if ( _findOrg && _findOrg.subList ) {
        this.orgList.push(_findOrg.subList);
      }
    },
    fnSelectChange(idx, value){
      //
      this.orgId = value;
      //
      this.orgList.splice(idx+1);
      //
      this.fnUpdateOrgList(idx, value);
      //
      this.$emit('change', this.orgId);
    },
    fnSavedId(idx){
      if ( this.user ) {
        const id = this.user[`orgId${idx}`]
        return id;
      }
      return null;
    }
  },
  watch: {
    organizations(){
      this.fnInit();
    }
  }
}
</script>
