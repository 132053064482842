var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup p_cs"},[_c('div',{staticClass:"p_wrap"},[_c('div',{staticClass:"p_top"},[_c('h2',[_vm._v("고객상세")]),_vm._v(" "),_c('button',{staticClass:"btn_close",on:{"click":_vm.fnClosePop}},[_vm._v("닫기")])]),_vm._v(" "),_c('div',{staticClass:"p_container"},[_c('div',{staticClass:"p_inner"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"c_contents contents_col2"},[_c('div',{staticClass:"left no_scroll"},[_c('h4',[_vm._v("1.상담내역")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"timeline"},[_vm._m(2),_vm._v(" "),_vm._l((5),function(i){return _c('div',{key:i,staticClass:"unit"},[_vm._m(3,true),_vm._v(" "),_c('div',{staticClass:"u_cont"},[_vm._v("\n                  대출 원금 및 이자 연체시, 연체이자가 발생되고 신용등급 또는 개인신용평점이 하락될 수 있으며, 채무/ 신용 상의 불이익이 발생할 수 있으니 주의하시기 바랍니다.\n                ")]),_vm._v(" "),_c('div',{staticClass:"u_bottom"})])})],2)]),_vm._v(" "),_c('div',{staticClass:"right"},[_c('h4',[_vm._v("2. 상담 내용 or 새로운 상담 내용")]),_vm._v(" "),_c('ul',{staticClass:"form_list mt20"},[_c('li',[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"view_date"},[_c('span',{staticClass:"start"},[_vm._v("2022년 6월 16일")]),_vm._v(" "),_c('div',{staticClass:"search_date"},[_vm._m(5),_vm._v(" "),_c('div',{staticClass:"s_cont"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"bx"},[_c('Select',{attrs:{"title":"2022년","list":[
                                { name: '셀렉트1' },
                                { name: '셀렉트2' },
                                { name: '셀렉트3' },
                                { name: '셀렉트4' },
                                { name: '셀렉트5' },
                                { name: '셀렉트6' },
                                { name: '셀렉트7' },
                                { name: '셀렉트8' },
                              ],"classNm":"ipt_year"}}),_vm._v(" "),_c('Select',{attrs:{"title":"12월","list":[
                                { name: '셀렉트1' },
                                { name: '셀렉트2' },
                                { name: '셀렉트3' },
                                { name: '셀렉트4' },
                                { name: '셀렉트5' },
                                { name: '셀렉트6' },
                                { name: '셀렉트7' },
                                { name: '셀렉트8' },
                              ],"classNm":"ipt_month"}}),_vm._v(" "),_c('Select',{attrs:{"title":"31일","list":[
                                { name: '셀렉트1' },
                                { name: '셀렉트2' },
                                { name: '셀렉트3' },
                                { name: '셀렉트4' },
                                { name: '셀렉트5' },
                                { name: '셀렉트6' },
                                { name: '셀렉트7' },
                                { name: '셀렉트8' },
                              ],"classNm":"ipt_day"}})],1)]),_vm._v(" "),_vm._m(6)])])])]),_vm._v(" "),_c('li',[_vm._m(7),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name', placeholder: '삼성전자 법무팀 아무개씨 (010-0000-0000)' }}})],1),_vm._v(" "),_c('li',[_c('h4',[_vm._v("상담유형")]),_vm._v(" "),_c('Select',{attrs:{"title":"특허문의","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_c('h4',[_vm._v("미팅")]),_vm._v(" "),_c('Select',{attrs:{"title":"미팅","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_c('h4',[_vm._v("진행단계")]),_vm._v(" "),_c('Select',{attrs:{"title":"진행단계","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_c('h4',[_vm._v("계약확률")]),_vm._v(" "),_c('Select',{attrs:{"title":"계약확률","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_c('h4',[_vm._v("구분")]),_vm._v(" "),_c('Select',{attrs:{"title":"구분","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_c('h4',[_vm._v("관리여부")]),_vm._v(" "),_c('Select',{attrs:{"title":"관리여부","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_vm._m(8),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name', placeholder: '이름' }}}),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name', placeholder: '전화번호' }}}),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name', placeholder: '이메일' }}})],1),_vm._v(" "),_vm._m(9)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_top"},[_c('div',{staticClass:"tabs1"},[_c('button',{staticClass:"tab on"},[_vm._v("고객상세")]),_vm._v(" "),_c('button',{staticClass:"tab"},[_vm._v("대출 상담 내역")])]),_vm._v(" "),_c('div',{staticClass:"btns"},[_c('button',{staticClass:"btn",attrs:{"to":""}},[_vm._v("저장 후 계속")]),_vm._v(" "),_c('button',{staticClass:"btn",attrs:{"to":""}},[_vm._v("저장")]),_vm._v(" "),_c('button',{staticClass:"btn dark",attrs:{"to":""}},[_vm._v("취소")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs_top"},[_c('div',{staticClass:"col"},[_c('span',[_vm._v("#1234567890")]),_vm._v(" "),_c('span',[_vm._v("2022-12-12")]),_vm._v(" "),_c('span',[_vm._v("유니콘랜치")])]),_vm._v(" "),_c('div',{staticClass:"btns"},[_c('button',{staticClass:"btn b_add"},[_c('b',[_vm._v("새로운 상담 내용")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"unit on"},[_c('div',{staticClass:"u_top colb"},[_c('div',{staticClass:"col"},[_c('strong',[_vm._v("아무나")]),_vm._v(" "),_c('span',[_vm._v("유니콘랜치 삼성전자 담당자")]),_vm._v(" "),_c('button',{staticClass:"btn_phone"},[_vm._v("전화")]),_vm._v(" "),_c('button',{staticClass:"btn_mail"},[_vm._v("메일")]),_vm._v(" "),_c('em',{staticClass:"new"},[_vm._v("new")])]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('span',[_vm._v("2022.01.23")])])]),_vm._v(" "),_c('div',{staticClass:"u_cont"},[_vm._v("\n                  대출 원금 및 이자 연체시, 연체이자가 발생되고 신용등급 또는 개인신용평점이 하락될 수 있으며, 채무/ 신용 상의 불이익이 발생할 수 있으니 주의하시기 바랍니다.\n                ")]),_vm._v(" "),_c('div',{staticClass:"u_bottom"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u_top colb"},[_c('div',{staticClass:"col"},[_c('strong',[_vm._v("아무나")]),_vm._v(" "),_c('span',[_vm._v("유니콘랜치 삼성전자 담당자")]),_vm._v(" "),_c('button',{staticClass:"btn_phone"},[_vm._v("전화")]),_vm._v(" "),_c('button',{staticClass:"btn_mail"},[_vm._v("메일")]),_vm._v(" "),_c('em',{staticClass:"new"},[_vm._v("new")])]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('span',[_vm._v("2022.01.23")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"date"}},[_c('b',[_vm._v("상담일시")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s_top"},[_c('span',{staticClass:"start"},[_vm._v("2022년 12월 16일")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btns"},[_c('button',{staticClass:"btn"},[_vm._v("확인")]),_vm._v(" "),_c('button',{staticClass:"btn gray"},[_vm._v("취소")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("협의한 고객")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("미팅자 정보")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('label',{attrs:{"for":"msg"}},[_c('b',[_vm._v("상담내용")])]),_vm._v(" "),_c('textarea',{staticClass:"textarea",attrs:{"name":"msg","id":"","placeholder":"특허 추천 및 내용 설명 등…"}})])
}]

export { render, staticRenderFns }