
const whiteList = [
  '/login',
  '/mainbiz/login',
  '/customer/report',
  '/tab1example/customerDetail'
];

const whiteListReg = [
  /^\/appDownload/,
  /^\/ui\//,
  /^\/login/,
  /^\/mainbiz\/login/,
  /^\/customer\/report/,
  /^\/customer\/contract/,
  /^\/customer\/payroll/,
  /^\/customer\/input-form/,
  /^\/tab1example\/customerDetail/,
  /^\/allOkMng\/reports/
]

export default function ({ store, route, redirect }) {
  const userId = store.state.authUser;
  if( !isContainsWhiteList(route.path)){
      if (userId === null) {
        return redirect('/login');
      }
  }
}

const isContainsWhiteList = (path) => {
  let isContains = false;
  for(let i=0; i<whiteListReg.length; i++){
    if( whiteListReg[i].test(path) ) {
      isContains = true;
      break;
    }
  }
  return isContains
}
