<template>
  <div class="container">
    <div class="c_top">
      <div class="taps_btn">
        <button class="tap">
          보증형 정책자금
        </button>
        <button class="tap">
          직접대출형 정책자금
        </button>
      </div>
      <div class="right">
        <button class="btn_arr">한도 높은순</button>
        <button class="btn_arr">보증료율 낮은순</button>
      </div>
    </div>
    <section class="list_finance">
      <article class="item" v-for="i in 5" :key="i">
        <div class="bx_label">
          <span class="txt_label">TCB기술금융</span>
          <span class="txt_label grean">정부정책자금</span>
          <span class="txt_label orange">IP금융</span>
        </div>
        <h3>스마트기업 소상공인</h3>
        <dl>
          <dt>지원한도</dt>
          <dd><b>5억원 (특별지원 10억원)</b></dd>
        </dl>
        <div class="col2">
          <dl class="col">
            <dt>보증비율</dt>
            <dd>95%</dd>
          </dl>
          <dl class="col">
            <dt>보증료율</dt>
            <dd><b>연 0.8%</b></dd>
          </dl>
          <dl class="col">
            <dt>대출금리</dt>
            <dd><b>연 2.1%~(차등적용)</b></dd>
          </dl>
        </div>
        <dl>
          <dt>대출기간</dt>
          <dd>5년 이내</dd>
        </dl>
        <dl>
          <dt>금융기관</dt>
          <dd>경기도중소기업육성자금</dd>
        </dl>
        <dl>
          <dt>비고</dt>
          <dd>온라인사업자/전자상거래 영위 기업 한정</dd>
        </dl>
      </article>
    </section>
  </div>
</template>

<script>

export default {
  name: "tab1",
};
</script>
