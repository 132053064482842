<template>
  <div class="c_contents contents_col2">
    <div class="left">
      <ul class="form_list type2">
        <li class="half">
          <h4>회사명</h4>
          <div class="i_wrap"><span>유니콘랜치</span></div>
        </li>
        <li class="half">
          <h4>대표자명</h4>
          <div class="i_wrap"><span>유니콘랜치</span></div>
        </li>
        <li class="half">
          <h4>이메일</h4>
          <div class="i_wrap"><span>유니콘랜치</span></div>
        </li>
        <li class="half">
          <h4>연락처</h4>
          <div class="i_wrap"><span>유니콘랜치</span></div>
        </li>
        <li class="half">
          <h4>설립일</h4>
          <div class="i_wrap"><span>유니콘랜치</span></div>
        </li>
        <li class="half">
          <h4>업종</h4>
          <div class="i_wrap"><span>유니콘랜치</span></div>
        </li>
        <li>
          <h4>소재지</h4>
          <div class="i_wrap"><span>유니콘랜치</span></div>
        </li>
        <li class="item_radio">
          <h4>인증보유현황</h4>
          <div class="i_wrap">
            <RadioBox
              label='연구소'
              id='lab_yn_1'
              name='lab_yn'
              :defaultValue="1"
              disabled
            />
            <RadioBox
              label='연구전단부서'
              id='dept_yn_2'
              name='dept_yn'
              :defaultValue="1"
              disabled
              />
            <RadioBox
              label='벤처인증'
              id='venture_yn_3'
              name='venture_yn'
              :defaultValue="1"
              disabled
            />
            <RadioBox
              label='이노비즈'
              id='innobiz_yn_4'
              name='innobiz_yn'
              :defaultValue="1"
              disabled
            />
            <RadioBox
              label='미보유'
              id='5'
              name='no'
              :defaultValue="1"
              disabled
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>대표자 연령</h4>
          <div class="i_wrap">
            <RadioBox
              label='만 39세 미만'
              id='prst_age_cd_1'
              name='prst_age_cd'
              :defaultValue="'ETC301'"
              disabled
            />
            <RadioBox
              label='만 39세 이상'
              id='prst_age_cd_2'
              name='prst_age_cd'
              :defaultValue="'ETC302'"
              disabled
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>직원수</h4>
          <div class="i_wrap">
            <RadioBox
              label='5인미만'
              id='emp_num_cd_1'
              name='emp_num_cd'
              :defaultValue="'ETC101'"
              disabled
            />
            <RadioBox
              label='5인 이상 ~ 10인 미만'
              id='emp_num_cd_2'
              name='emp_num_cd'
              :defaultValue="'ETC102'"
              disabled
            />
            <RadioBox
              label='10인 이상'
              id='emp_num_cd_3'
              name='emp_num_cd'
              :defaultValue="'ETC103'"
              disabled
            />
            <RadioBox
              label='잘 모르겠어요'
              id='emp_num_cd_4'
              name='emp_num_cd'
              :defaultValue="'ETC104'"
              disabled
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>고용계획</h4>
          <div class="i_wrap">
            <RadioBox
              label='네, 있어요'
              id='1'
              name='hire_cd'
              :defaultValue="'ETC201'"
              disabled
            />
            <RadioBox
              label='잘 모르겠어요'
              id='2'
              name='hire_cd'
              :defaultValue="'ETC202'"
              disabled
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>특허추천</h4>
          <div class="i_wrap">
            <RadioBox
              label='네, 추천받고 싶어요'
              id='1'
              name='tech_rcm_cd'
              :defaultValue="'ETC401'"
              disabled
            />
            <RadioBox
              label='잘 모르겠어요'
              id='2'
              name='tech_rcm_cd'
              :defaultValue="'ETC402'"
              disabled
            />
          </div>
        </li>
        <li>
          <h4>기업 신용등급</h4>
          <div class="i_wrap"><span>BBB~B</span></div>
        </li>
        <li>
          <h4>대표자<br/>신용등급</h4>
          <div class="i_wrap" style="margin-top: 8px;"><span>899</span></div>
        </li>
        <li class="item_skill">
          <h4>기술보유현황</h4>
          <div class="i_wrap">
            <div class="bx_line">
              <h5>보유특허수</h5>
              <strong class="txt_org">9개</strong>
            </div>
            <div class="bx_line">
              <h5>특허가치</h5>
              <strong class="txt_red">99</strong>
            </div>
            <div class="bx_line">
              <h5>기술등급</h5>
              <strong class="txt_red">A-</strong>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="right bg_gray">
      <div class="tabs2">
        <button :class="{ tab: true, on: on == 'tab1' }" @click="() => (this.on = 'tab1')">정책자금</button>
        <button :class="{ tab: true, on: on == 'tab2' }" @click="() => (this.on = 'tab2')">금융상품</button>
        <button :class="{ tab: true, on: on == 'tab3' }" @click="() => (this.on = 'tab3')">특허분석</button>
        <button :class="{ tab: true, on: on == 'tab4' }" @click="() => (this.on = 'tab4')">특허추천</button>
      </div>
      <br/>
      <div v-if="this.on=='tab1'">
        <CustomerPrdcTab1/>
      </div>
      <div v-if="this.on=='tab2'">
        <CustomerPrdcTab2/>
      </div>
      <div v-if="this.on=='tab3'">
        <CustomerPrdcTab3/>
      </div>
      <div v-if="this.on=='tab4'">
        <CustomerPrdcTab4/>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';
import ItemPatent from '~/components/common/ItemPatent';
import Select from '~/components/common/Select';
import RadioBox from '~/components/common/RadioBox';
import SelectSearch from "~/components/common/SelectSearch";
import CustomerPrdcTab1 from "./CustomerPrdcTab1.vue";
import CustomerPrdcTab2 from "./CustomerPrdcTab2.vue";
import CustomerPrdcTab3 from "./CustomerPrdcTab3.vue";
import CustomerPrdcTab4 from "./CustomerPrdcTab4.vue";

export default {
  name: 'CustomerTab1',
  data() {
    return {
      on: 'tab1'
    };
  },
  props: ['id'],
  components: {
    InputBox,
    ItemPatent,
    Select,
    RadioBox,
    SelectSearch,
    CustomerPrdcTab1,
    CustomerPrdcTab2,
    CustomerPrdcTab3,
    CustomerPrdcTab4,
  },
  computed: {
    
  },
  async beforeMount() {
    
  },
  methods: {
   
  },
};
</script>
