<template>
  <div class="popup p_cs">
    <div class="p_wrap">
      <div class="p_top">
        <h2>고객상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button :class="{ tab: true, on: on == 'tab1' }" @click="() => (this.on = 'tab1')">고객상세</button>
              <button :class="{ tab: true, on: on == 'tab3' }">담당자배정</button>
              <button :class="{ tab: true, on: on == 'tab5' }">서류 및 기술평가</button>
              <button :class="{ tab: true, on: on == 'tab6' }">진행단계</button>
            </div>
            <div class="btns" v-if="on == 'tab1'">
              <button class="btn dark">닫기</button>
            </div>
          </div>
          <div class="c_contents" v-if="this.on == 'tab1'">
            <tab1 :id="this.id" ref="save1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';
import ItemPatent from '~/components/common/ItemPatent';
import Select from '~/components/common/Select';
import tab1 from './tab1.vue';

export default {
  name: 'CustomerDetail',
  data() {
    return {
      on: 'tab1',
    };
  },
  props: ['id'],
  components: {
    InputBox,
    ItemPatent,
    Select,
    tab1
  },
  methods: {
   
  },
  mounted () {
  },
};
</script>
