<template>
  <div>
    <div class="colb area_tap">
      <div class="taps_btn">
        <button v-for="(grade,idx) in gradeList" :key="'grd_'+idx"
                class="tap" :class="{on:gradeOn===grade.value}"
                @click="()=>fnGrade(grade.value)">
          {{grade.name}}
        </button>
      </div>
    </div>
    <section class="list_goods">
      <article class="unit" v-for="(patn, i) in patns" :key="i">
        <div class="bx_label">
          <span class="txt_label full_blue" v-if="patn.rcmd">추천</span>
          <span class="txt_label full_red" v-if="evGrade(patn.evGrade)==='AA'">AA</span>
          <span class="txt_label full_red" v-if="evGrade(patn.evGrade)==='A+'">A+</span>
          <span class="txt_label full_red" v-if="evGrade(patn.evGrade)==='A'">A</span>
          <span class="txt_label full_red" v-if="evGrade(patn.evGrade)==='A-'">A-</span>
          <span class="txt_label full_orange" v-if="evGrade(patn.evGrade)==='B+'">B+</span>
          <span class="txt_label full_orange" v-if="evGrade(patn.evGrade)==='B'">B</span>
          <span class="txt_label full_orange" v-if="evGrade(patn.evGrade)==='B-'">B-</span>
          <span class="txt_label full_mint" v-if="evGrade(patn.evGrade)==='C'">C</span>
        </div>
        <h3>{{ cutString(patn.prdcNm) }}</h3>
        <dl>
          <dt>특허등급</dt>
          <dd>{{ evGrade(patn.evGrade) }}</dd>
        </dl>
        <dl>
          <dt>만료일</dt>
          <dd>{{ patn.expPatnDate }}</dd>
        </dl>
        <dl>
          <dt>특허추정가치</dt>
          <dd><b>{{ $dpPatnPrice(patn.prdcPrice) }}</b></dd>
        </dl>
      </article>
      <InfiniteLoading @infinite="handleListScroll" :identifier="infiniteId" spinner="spiral">
        <div slot="no-results">
          <div style="display: flex; justify-content: center; align-items: center; height: 40vh;">
            <b>조건에 맞는 특허가 없어요</b>
          </div>
        </div>
        <div slot="no-more"></div>
      </InfiniteLoading>
    </section>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
export default{
  props:['customer'],
  components:{InfiniteLoading},
  data() {
    return {
      gradeOn: 'ALL',
      loading: false,
      query:{
        bizNo: null,
        pageNo: 1,
        pageSize: 10,
        searchCatgNo: 'TM0000000005',
        searchGrade: 'ALL'
      },
      patentParamData:{
        rank:[
          {name:'전체', value:'ALL'},
          {name:'추천', value:'R'},
          {name:'A등급', value:'A'},
          {name:'B등급', value:'B'},
          {name:'C등급', value:'C'},
        ]
      },
      patentResult:[],
      rPatn:[],
      infiniteId: +new Date()
    }
  },
  computed:{
    patns(){
      if(this.patentResult){
        return this.patentResult;
      }
      return [];
    },
    gradeList() {
      return this.patentParamData.rank;
    }
  },
  methods: {
    fnGrade(grade) {
      this.gradeOn=grade;
      this.query.searchGrade = grade;
      this.query.pageNo = 1;
      this.patentResult = [];
      this.infiniteId++;
    },
    evGrade(payload){
      let title;
      if(payload==9){
        title = 'AA'
      } else if(payload==8){
        title = 'A+'
      } else if(payload==7){
        title = 'A'
      } else if(payload==6){
        title = 'A-'
      } else if(payload==5){
        title = 'B+'
      } else if(payload==4){
        title = 'B'
      } else if(payload==3){
        title = 'B-'
      } else{
        title = 'C'
      }
      return title;
    },
    cutString(payload){
      const result = payload.split('{')
      return result[0]
    },
    async handleListScroll($state) {
      const params = {
        bizNo: this.customer.bizNo,
        pageNo: this.query.pageNo,
        pageSize: this.query.pageSize,
        searchCatgNo: this.query.searchCatgNo,
        searchGrade: this.query.searchGrade,
      }
      const result = await this.$store.dispatch('allOk/allOkStore/fetchRecommendPatents', params)
      if(result.result) {
        if(result.data.contents.prdcs.length!=0){
          this.patentResult.push(...result.data.contents.prdcs)
          this.query.pageNo++;
          $state.loaded()
        } else{
          $state.complete()
        }
      }
    },
  },
}
</script>
