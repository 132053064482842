<template>
  <div class="c_contents">
    <div v-if="result">
      <div class="tbl_doc_scroll full mt10 pb30">
        <div class="t_inner">
          <div class="t_cont">
            <div class="item b_double">
              <div class="cel" style="width: 360px">
                <h3>{{ 입력개요.회사명 }}</h3>
              </div>
              <div class="cel bg" style="width: 120px">기업신용등급</div>
              <div class="cel" style="width: 120px">{{ 입력개요.신용등급 }}</div>
              <div class="cel bg" style="width: 120px">현금흐름등급</div>
              <div class="cel" style="width: 120px">{{ 입력개요.현금등급 }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px">대표자</div>
              <div class="cel" style="width: 120px">{{ 입력개요.경영진현황.경영진[0]?.성명 }}</div>
              <div class="cel bg" style="width: 120px">설립일</div>
              <div class="cel" style="width: 120px">{{ 입력개요.설립일 }}</div>
              <div class="cel bg" style="width: 120px">업종</div>
              <div class="cel" style="width: 120px">{{ 입력개요.업종 }}</div>
              <div class="cel" style="width: 120px">{{ 입력개요.주요품목 }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px">연락처</div>
              <div class="cel" style="width: 120px">{{ 입력개요.연락처 }}</div>
              <div class="cel bg" style="width: 120px">주소</div>
              <div class="cel" style="width: 480px">{{ 입력개요.주소 }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px">자본금</div>
              <div class="cel" style="width: 120px">{{ 입력개요.자본금_억 && Number(입력개요.자본금_억).toFixed(1) + '억' }}</div>
              <div class="cel" style="width: 120px">{{ 입력개요.자본금_원 && fnFormatNumber(입력개요.자본금_원) + '원' }}</div>
              <div class="cel" style="width: 120px">{{ 입력개요.자본금_주 && fnFormatNumber(입력개요.자본금_주) + '주' }}</div>
              <div class="cel bg" style="width: 120px">주식가치평가액</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(reportData.주식가치평가금액.평가금액_1주당.toFixed(0)) + '원' }}</div>
              <div class="cel" style="width: 120px">
                {{ fnFormatNumber(reportData.주식가치평가금액.비상장주식가치평가금액.toFixed(0)) + '원' }}
              </div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 60px; flex-direction: column">
                <small>{{ 입력개요.경영진현황.기준일 }}</small>
                <h4>임원현황</h4>
              </div>
              <div class="t_group">
                <div class="row">
                  <div class="cel bg" style="width: 60px">관계</div>
                  <div class="cel bg" style="width: 120px">직위</div>
                  <div class="cel bg" style="width: 120px">성명</div>
                  <div class="cel bg" style="width: 120px">보수액</div>
                  <div class="cel bg" style="width: 120px">최초 취임일</div>
                  <div class="cel bg" style="width: 120px">근속년수</div>
                  <div class="cel bg" style="width: 120px">생년월일</div>
                </div>
                <div class="row" v-for="(el, idx) in 입력개요.경영진현황.경영진" :key="idx">
                  <div class="cel" style="width: 60px">{{ el.실권자관계 }}</div>
                  <div class="cel" style="width: 120px">{{ el.구분 }}</div>
                  <div class="cel" style="width: 120px">{{ el.성명 }}</div>
                  <div class="cel" style="width: 120px"></div>
                  <div class="cel" style="width: 120px">{{ el.임원취임일 }}</div>
                  <div class="cel" style="width: 120px">{{ el.근속연수 }}</div>
                  <div class="cel" style="width: 120px">{{ el.생년월일 }}</div>
                </div>
              </div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 60px; flex-direction: column">
                <small>{{ 입력개요.주요주주현황.기준일 }}</small>
                <h4>주주현황</h4>
              </div>
              <div class="t_group">
                <div class="row">
                  <div class="cel bg" style="width: 60px">관계</div>
                  <div class="cel bg" style="width: 120px">주주명</div>
                  <div class="cel bg" style="width: 120px">지분율</div>
                  <div class="cel bg" style="width: 120px">가치평가액</div>
                  <div class="cel bg" style="width: 120px">양도세</div>
                  <div class="cel bg" style="width: 120px">증여상속세</div>
                  <div class="cel bg" style="width: 120px">의제배당세</div>
                </div>
                <div class="row" v-for="(el, idx) in 입력개요.주요주주현황.주요주주" :key="idx">
                  <div class="cel" style="width: 60px">{{ el.실권자관계 }}</div>
                  <div class="cel" style="width: 120px">{{ el.주주명 }}</div>
                  <div class="cel" style="width: 120px">{{ el.지분율 && el.지분율 + '%' }}</div>
                  <div class="cel" style="width: 120px">{{ fnFormatNumber(reportData.주주별평가.주주[idx].결산기준.toFixed(0)) }}</div>
                  <div class="cel" :class="{ 'border-bottom': idx == 입력개요.주요주주현황.주요주주.length - 1 }" style="width: 120px">
                    {{ fnFormatNumber(reportData.양도시.주주[idx].양도세.toFixed(0)) }}
                  </div>
                  <div class="cel" :class="{ 'border-bottom': idx == 입력개요.주요주주현황.주요주주.length - 1 }" style="width: 120px">
                    {{ fnFormatNumber(reportData.유고시.주주[idx].증여세율최고.toFixed(0)) }}
                  </div>
                  <div class="cel" :class="{ 'border-bottom': idx == 입력개요.주요주주현황.주요주주.length - 1 }" style="width: 120px">
                    {{ fnFormatNumber(reportData.청산시.주주[idx].산출세액.toFixed(0)) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px"><strong>구분</strong></div>
              <div class="cel bg2" style="width: 120px">
                <strong>{{ 입력재무.year1 }}</strong>
              </div>
              <div class="cel bg2" style="width: 120px">
                <strong>{{ 입력재무.year2 }}</strong>
              </div>
              <div class="cel bg2" style="width: 120px">
                <strong>{{ 입력재무.year3 }}</strong>
              </div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px"><strong>자산총계</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.자산총계.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.자산총계.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.자산총계.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px"><strong>부채총계</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.부채총계.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.부채총계.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.부채총계.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px"><strong>자본총계</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.자본총계.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.자본총계.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.자본총계.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px"><strong>부채비율</strong></div>
              <div class="cel" style="width: 120px">{{ ((Number(입력재무.부채총계.year1) / Number(입력재무.자본총계.year1)) * 100).toFixed(0) + '%' }}</div>
              <div class="cel" style="width: 120px">{{ ((Number(입력재무.부채총계.year2) / Number(입력재무.자본총계.year2)) * 100).toFixed(0) + '%' }}</div>
              <div class="cel" style="width: 120px">{{ ((Number(입력재무.부채총계.year3) / Number(입력재무.자본총계.year3)) * 100).toFixed(0) + '%' }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg3" style="width: 120px"><strong>이익준비금</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.이익준비금.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.이익준비금.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.이익준비금.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg3" style="width: 120px"><strong>미처분이익잉여금</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.미처분이익잉여금.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.미처분이익잉여금.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.미처분이익잉여금.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg4" style="width: 120px"><strong>매출액</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.매출액.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.매출액.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.매출액.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg4" style="width: 120px"><strong>영업이익</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.영업이익.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.영업이익.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.영업이익.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg4" style="width: 120px"><strong>영업이익률</strong></div>
              <div class="cel" style="width: 120px">{{ 입력손익.영업이익률.year1 && (Number(입력손익.영업이익률.year1) * 100).toFixed(0) + '%' }}</div>
              <div class="cel" style="width: 120px">{{ 입력손익.영업이익률.year2 && (Number(입력손익.영업이익률.year2) * 100).toFixed(0) + '%' }}</div>
              <div class="cel" style="width: 120px">{{ 입력손익.영업이익률.year3 && (Number(입력손익.영업이익률.year3) * 100).toFixed(0) + '%' }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg4" style="width: 120px"><strong>법인세차감전순손익</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.비용차감전손익.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.비용차감전손익.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.비용차감전손익.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg4" style="width: 120px"><strong>법인세비용</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.법인세비용.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.법인세비용.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.법인세비용.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg4" style="width: 120px"><strong>당기순이익</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.당기순이익.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.당기순이익.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.당기순이익.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg4" style="width: 120px"><strong>순이익률</strong></div>
              <div class="cel" style="width: 120px">{{ 입력손익.순이익률.year1 && (Number(입력손익.순이익률.year1) * 100).toFixed(0) + '%' }}</div>
              <div class="cel" style="width: 120px">{{ 입력손익.순이익률.year2 && (Number(입력손익.순이익률.year2) * 100).toFixed(0) + '%' }}</div>
              <div class="cel" style="width: 120px">{{ 입력손익.순이익률.year3 && (Number(입력손익.순이익률.year3) * 100).toFixed(0) + '%' }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg2" style="width: 120px"><strong>가지급금(주임종단기)</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.주임종단기대여금.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.주임종단기대여금.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.주임종단기대여금.year3) }}</div>
              <div class="cel" style="width: 120px">추정금액</div>
            </div>
            <div class="item b_double">
              <div class="cel bg2" style="width: 120px"><strong>B/S 미수수익</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.미수수익.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.미수수익.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.미수수익.year3) }}</div>
              <div class="cel border-bottom" style="width: 120px">
                <strong>{{ 입력재무.가지급금추정액.미수수익.year3 && fnFormatNumber(Number(입력재무.가지급금추정액.미수수익.year3).toFixed(0)) }}</strong>
              </div>
            </div>
            <div class="item b_double">
              <div class="cel bg2" style="width: 120px"><strong>I/S 기타영업외 수익</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.기타수익.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.기타수익.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력손익.기타수익.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px"><strong>가수금(주임종단기)</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.가수금.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.가수금.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.가수금.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px"><strong>퇴직급여충당부채(DB)</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.퇴직급여충당부채.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.퇴직급여충당부채.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.퇴직급여충당부채.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px"><strong>퇴직연금운용자산</strong></div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.퇴직연금운용자산.year1) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.퇴직연금운용자산.year2) }}</div>
              <div class="cel" style="width: 120px">{{ fnFormatNumber(입력재무.퇴직연금운용자산.year3) }}</div>
            </div>
            <div class="item b_double">
              <div class="cel bg" style="width: 120px"><strong>퇴직급여(DC)</strong></div>
              <div class="cel" style="width: 120px"></div>
              <div class="cel" style="width: 120px"></div>
              <div class="cel" style="width: 120px"></div>
            </div>
            <div class="item b_double">
              <div class="cel bg border-bottom" style="width: 120px"><strong>장기금융상품</strong></div>
              <div class="cel border-bottom" style="width: 120px"></div>
              <div class="cel border-bottom" style="width: 120px"></div>
              <div class="cel border-bottom" style="width: 120px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2>{{ errMsg }}</h2>
    </div>
  </div>
</template>

<script>
import diagnosisService from '~/service/allOkMng/diagnosisService.js';
export default {
  props: ['bizNo'],
  data() {
    return {
      입력개요: null,
      입력재무: null,
      입력손익: null,
      reportData: {},
      result: false,
      errMsg: null,
    };
  },

  methods: {
    fnFormatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },

  mounted() {
    diagnosisService.getDiagnosisReport(this.$axios, this.bizNo).then(({ result, data }) => {
      if (result) {
        this.입력개요 = data.입력개요;
        this.입력재무 = data.입력재무;
        this.입력손익 = data.입력손익;
        this.reportData = data.reportData;
        this.result = result;
      } else {
        this.errMsg = data.error.msg;
      }
    });
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #ddd;
}

.tbl_doc_scroll .t_cont .item {
  min-height: 40px;
}

.bg {
  background-color: #f4f4f4;
}

.bg2 {
  background-color: #fde9d9;
}

.bg3 {
  background-color: #ebf1de;
}

.bg4 {
  background-color: #daeef3;
}
</style>
