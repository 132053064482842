import * as companyTypes from "../constants/companyType";

export default (store, allowedRoles, ignoreCompanyType) => {
  if(store.state.authUser){
    if ( ! ignoreCompanyType ) {
      if( companyTypes.ADMIN == store.state.authUser.gaType ) {
        return true;
      }
    }

    const userRoles = store.state.authUser.roles;
    if(userRoles){
      const find = userRoles.find( ur => {
        return allowedRoles.includes(ur);
      });
      return find ? true : false;
    }
  }
  return false;
}

