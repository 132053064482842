const BASE_URL = "/api/allok/customers"

const fetchCustomerDetail = (axios, cnsltId) => {
  return axios.get(`/apiAo/users/customers/${cnsltId}`);
}
const fetchCustomerDetailReport = (axios, customerId) => {
  return axios.get(`/apiAo/users/customersReport/${customerId}`);
}

const fetchLookUpResult = (axios, body) => {
  return axios.post(`${BASE_URL}/lookupResult`, body);
}

const addConsultant = (axios, customerId, payload) => {
  return axios.post(`/apiAo/users/consultants`, payload);
}

const getCompanyInfo = async(params, axios) => {
  const bizNo = params
  return axios.get(`/apiCmnAo/loan-corp/nice/companyOutline/${bizNo}`).then(res =>{
    return res.data
  })
}

const getFileInfo = (axios, customerId) => {
  return axios.get(`/apiAo/customers/${customerId}/files`)
    .then( res=> res.data);
}

const postFile = (axios, customerId, formData) => {
  return axios.post(`/apiAo/customers/${customerId}/files`,formData, {
    headers: {
      'Content-Type' : 'multipart/form-data'
    }
  })
    .then( res=> res.data)
}

const deleteFile = ( axios, customerId, fileId ) => {
  return axios.delete(`/apiAo/customers/${customerId}/files/${fileId}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return null;
    })
}

const getReport = (axios, customerId) => {
  return axios.get(`/apiAo/customers/${customerId}/getReport`)
  .then(res => {
    return res.data;
  })

}

const getStatus = (axios, cnsltId) => {
  return axios.get(`/apiAo/users/consulting/${cnsltId}/status`)
  .then(res => {
    return res.data;
  })
}

const updateStatus = (axios, cnsltId, body) => {
  return axios.post(`/apiAo/users/consulting/${cnsltId}/status`, body)
  .then(res => {
    return res.data;
  })
}

const getHistory = (axios, cnsltId) => {
  return axios.get(`/apiAo/users/consulting/${cnsltId}/status/history`)
  .then(res => {
    return res.data;
  })
}

const addConsultantGa = (axios, customerId, payload) => {
  return axios.post(`/apiAo/users/consultantGa`, payload);
}

export default {
  fetchCustomerDetail,
  fetchLookUpResult,
  addConsultant,
  getCompanyInfo,
  getFileInfo,
  postFile,
  deleteFile,
  getReport,
  fetchCustomerDetailReport,
  getStatus,
  updateStatus,
  getHistory,
  addConsultantGa
}
