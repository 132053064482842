const url = '/api/signUp';

const postEmail = (body, axios) => {
  return axios
    .post(`${url}/sendMail`, body)
    .then(res => {
      return res.status;
    })
    .catch(err => {
      console.log(err);
    });
};

const emailValidation = (body, axios) => {
  return axios
    .post(`${url}/emailValidation`, body)
    .then(res => {
      console.log(res.data);
      return res.data;
    })
    .catch(err => {
      console.log(err);
    });
};

const postSignUp = (body, axios) => {
  return axios
    .post(`${url}`, body)
    .then(res => {
      return res.status;
    })
    .catch(err => {
      console.log(err);
    });
};

export default {
  postEmail,
  postSignUp,
  emailValidation,
};
