<template>
  <div class="cover">
    <div class="cTit">
      <div class="unicornLogo">unicornranch</div>
      <div class="reportTit">비상장주식가치평가</div>
    </div>
    <div class="contents">
      <div class="Flist" style="display: flex;">1.<span class="Ftit">재무 현황<br/><span class="titInfo">대상기업의 재무 현황을 확인합니다.</span></span></div>
      <div class="compNm">{{입력개요?.회사명}}</div>
      <section>
        <div class="graphTit">{{입력개요?.회사명}} 재무상태표</div>
        <div class="year">{{재무현황?.년도1.년도}}</div>
        <div class="year1">{{재무현황?.년도2.년도}}</div>
        <div class="year2">{{재무현황?.년도3.년도}}</div>
        <div class="graph">
          <div class="graphContent" id="chart1">자산총계<br/><span class="amount">{{재무현황?.자산총계}}억원</span></div>
          <div class="graphContent" id="chart2">
            <!-- <div id="chart2-1">부채 5.2억원</div>
            <div id="chart2-2">자본금 0.5억원</div> -->
            <div :style="{ height: calculateHeight(재무현황?.부채) }" id="chart2-1">부채<span class="amount">{{재무현황?.부채}}억원</span></div>
            <div :style="{ height: calculateHeight(재무현황?.자본금) }" id="chart2-2">자본금<span class="amount">{{재무현황?.자본금}}억원</span></div>
            <div id="chart2-3">미처분잉여금<br/><span class="amount">{{재무현황?.미처분잉여금}}억원</span></div>
          </div>
          <div class="equal">=</div>
          <div class="graphContent2" id="chart3">
            <div id="chartContent1">
              <div class="chartContent1-1">매출액</div>
              <div class="chartContent1-1" style="color: #B50A45;">{{재무현황?.년도1.매출액}}억원</div>
            </div>
            <div id="chartContent2">
              <div class="chartContent2-1"><span style="margin-right: 15px;">– 매출원가</span></div>
              <div class="chartContent2-2"><span style="margin-left: 15px;">= 매출총이익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px; color: #B50A45;">– 판매관리비</span></div>
              <div class="chartContent2-2"><span style="margin-left: 15px;">= 영업이익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px;">+ 영업외 수익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px;">– 영업외 비용</span></div>
              <div class="chartContent2-2"><span style="margin-left: 15px;">= 법인세차감전손익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px; color: #B50A45;">– 법인세비용</span></div>
            </div>
            <div id="chartContent3">
              <div class="chartContent1-1">당기순이익</div>
              <div class="chartContent1-1">{{재무현황?.년도1.당기순이익}}억원</div>
            </div>
            <div id="chartContent4">
              <div class="chartContent1-1" style="color: #B50A45;">처 분(배당)</div>
              <div class="chartContent1-1">-</div>
            </div>
            <div id="chartContent5">
              <div class="chartContent1-1">미처분(잉여금)</div>
              <div class="chartContent1-1 amount">{{재무현황?.년도1.당기순이익}}억원</div>
            </div>
          </div>
          <div class="plus">+</div>
          <div class="graphContent2" id="chart4">
            <div id="chartContent1">
              <div class="chartContent1-1">매출액</div>
              <div class="chartContent1-1" style="color: #B50A45;">{{재무현황?.년도2.매출액}}억원</div>
            </div>
            <div id="chartContent2">
              <div class="chartContent2-1"><span style="margin-right: 15px;">– 매출원가</span></div>
              <div class="chartContent2-2"><span style="margin-left: 15px;">= 매출총이익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px; color: #B50A45;">– 판매관리비</span></div>
              <div class="chartContent2-2"><span style="margin-left: 15px;">= 영업이익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px;">+ 영업외 수익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px;">– 영업외 비용</span></div>
              <div class="chartContent2-2"><span style="margin-left: 15px;">= 법인세차감전손익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px; color: #B50A45;">– 법인세비용</span></div>
            </div>
            <div id="chartContent3">
              <div class="chartContent1-1">당기순이익</div>
              <div class="chartContent1-1">{{재무현황?.년도2.당기순이익}}억원</div>
            </div>
            <div id="chartContent4">
              <div class="chartContent1-1" style="color: #B50A45;">처 분(배당)</div>
              <div class="chartContent1-1">-</div>
            </div>
            <div id="chartContent5">
              <div class="chartContent1-1">미처분(잉여금)</div>
              <div class="chartContent1-1 amount">{{재무현황?.년도2.당기순이익}}억원</div>
            </div>
          </div>
          <div class="plus2">+</div>
          <div class="graphContent2" id="chart5">
            <div id="chartContent1">
              <div class="chartContent1-1">매출액</div>
              <div class="chartContent1-1" style="color: #B50A45;">{{재무현황?.년도3.매출액}}억원</div>
            </div>
            <div id="chartContent2">
              <div class="chartContent2-1"><span style="margin-right: 15px;">– 매출원가</span></div>
              <div class="chartContent2-2"><span style="margin-left: 15px;">= 매출총이익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px; color: #B50A45;">– 판매관리비</span></div>
              <div class="chartContent2-2"><span style="margin-left: 15px;">= 영업이익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px;">+ 영업외 수익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px;">– 영업외 비용</span></div>
              <div class="chartContent2-2"><span style="margin-left: 15px;">= 법인세차감전손익</span></div>
              <div class="chartContent2-1"><span style="margin-right: 15px; color: #B50A45;">– 법인세비용</span></div>
            </div>
            <div id="chartContent3">
              <div class="chartContent1-1">당기순이익</div>
              <div class="chartContent1-1">{{재무현황?.년도3.당기순이익}}억원</div>
            </div>
            <div id="chartContent4">
              <div class="chartContent1-1" style="color: #B50A45;">처 분(배당)</div>
              <div class="chartContent1-1">-</div>
            </div>
            <div id="chartContent5">
              <div class="chartContent1-1">미처분(잉여금)</div>
              <div class="chartContent1-1 amount">{{재무현황?.년도3.당기순이익}}억원</div>
            </div>
          </div>
        </div>
      </section>
      <div class="addEx">※ 추가 설명</div>
      <div class="ex"><span class="exText">투자한 주주들에게 나가야 할 배당금 의무도 무시할 수 없다. 사실 어떤 기업이든 자기 돈만 충분하다면 굳이 엄격한 심사와 자격까지 갖추면서 상장할 이유는 없다.[2] 실제로 2015년 기준 전경련에서 조사한 자료에서는 총 600여개 기업이 코스피 상장 요건을 갖추고 있었지만 단 7개 기업만이 상장을 했다. 그리고 다른 회사에게 자신의 회사를 팔 때도 매우 쉬운데. 상장회사들을 인수 할 때 과반의 주식을 확보하거나 합병까지 하려면 전체 주식을 사서 소각해야 하는데, 이 과정도 매우 복잡해서 전체 주주들에게 동의를 받아야 하고 주식시장을 관리감독하는 기관의 심사까지 받아야 해서 규모가 큰 회사들 끼리 합병시 년단위로 걸린다. 비상장은 경영권을 가진 임원들에게 만족하는 가격만 제시한다면 매우 수월하게 인수가 가능하다.</span></div>
    </div>
    <div class="footer">
      01
    </div>
  </div>
</template>
<script>
export default {
  props: ['입력개요', '재무현황'],
  data() {
    return {
      chartData: []
    }
  },
  mounted(){
    this.chartData.push(this.재무현황?.부채, this.재무현황?.자본금);
  },
  methods:{
    calculateHeight(value) {
      const total = this.chartData.reduce((sum, val) => sum + val, 0);
      const maxHeight = 780; // 최대 높이
      const ratio = value / total; // 비율 계산
      return `${maxHeight * ratio}px`; // 높이 계산
    },
  }
}
</script>
<style scoped>
  @import '~/static/css/report.css';
  .chartContent1-1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 80px;
    font-size: 13px;
    font-family: 'Pretendard-Medium';
  }
  .chartContent2-1{
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    font-family: 'Pretendard-Medium';
    font-size: 13px;
    align-items: center;
  }
  .chartContent2-2{
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: 'Pretendard-Medium';
  }
  .equal{
    position: absolute;
    text-align: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    top: 865px;
    left: 525px;
    color: #21BDC6;
    font-family: 'Pretendard-Medium';
    font-size: 30px;
    background: #001736 0% 0% no-repeat padding-box;
  }
  .plus{
    position: absolute;
    text-align: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    top: 865px;
    left: 735px;
    color: #21BDC6;
    font-family: 'Pretendard-Medium';
    font-size: 30px;
    background: #001736 0% 0% no-repeat padding-box;
  }
  .plus2{
    position: absolute;
    text-align: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    top: 865px;
    left: 945px;
    color: #21BDC6;
    font-family: 'Pretendard-Medium';
    font-size: 30px;
    background: #001736 0% 0% no-repeat padding-box;
  }
  .footer{
    width: 100px;
    left: 30px;
    position: absolute;
    border: solid 1px #001736;
    background-color: #001736;
    height: 60px;
    bottom: 0px;
    color: #1FBDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .Ftit{
    display: flex;
    flex-direction: column;
  }
  .titInfo{
    font-size: 10px;
    font-family: 'Pretendard-Light';
  }
  .compNm{
    color: #1FBDC6;
    font-size: 24px;
    font-family: 'Pretendard-SemiBold';
    position: absolute;
    top: 36px;
    right: 30px;
  }
  .graphTit{
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    position: absolute;
    top: 130px;
    left: 30px;
  }
  .graph{
    position: absolute;
    top: 211px;
    left: 10px;
    display: flex;
    justify-content: space-between;
    right: 30px;
  }
  .graphContent{
    width: 250px;
    height: 1000px;
    margin-left: 20px;
    font-family: 'Pretendard-Bold';
  }
  .graphContent2{
    width: 200px;
    height: 1000px;
    margin-left: 10px;
  }
  #chart1{
    background-color: #001736;
    color: #21BDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Pretendard-Medium';
  }
  #chart2{
    color: #001736;
    font-family: 'Pretendard-Medium';
  }
  #chart3{
    color: #001736;
    font-family: 'Pretendard-Medium';
  }
  #chart4{
    color: #001736;
    font-family: 'Pretendard-Medium';
  }
  #chart5{
    color: #001736;
    font-family: 'Pretendard-Medium';
  }
  #chart2-1{
    width: 250px;
    height: 710px;
    background-color: #99A2AF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #chart2-2{
    width: 250px;
    height: 70px;
    background-color: #667486;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #chart2-3{
    width: 250px;
    height: 220px;
    background-color: #21BDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #chartContent1{
    width: 200px;
    height: 80px;
    background-color: #99A2AF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #chartContent2{
    width: 200px;
    height: 539px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #chartContent3{
    width: 200px;
    height: 80px;
    background-color: #99A2AF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #chartContent4{
    width: 200px;
    height: 80px;
    background-color: #667486;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #chartContent5{
    width: 200px;
    height: 220px;
    background-color: #21BDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .year{
    position: absolute;
    top: 184px;
    left: 648px;
  }
  .year1{
    position: absolute;
    top: 184px;
    left: 859px;
  }
  .year2{
    position: absolute;
    top: 184px;
    left: 1068px;
  }
  .amount{
    font-size: 18px;
  }
  .addEx{
    font-size: 18px;
    color: #33455E;
    position: absolute;
    left: 30px;
    bottom: 195px;
  }
  .ex{
    position: absolute;
    width: 1160.5px;
    height: 75px;
    border-top: solid 1px #001736;
    left: 30px;
    bottom: 100px;
  }
  .exText{
    display: block;
    margin-top: 15px;
    font-size: 13px;
    color: #33455E;
    font-family: 'Pretendard-Regular';
  }
</style>