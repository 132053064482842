<template>
  <nav class="gnb">
    <!--
    <nuxt-link to="">10Bagger 공통</nuxt-link>
    <nuxt-link to="">B2B 서비스</nuxt-link>
    <nuxt-link to="">OPENIP</nuxt-link>
    <nuxt-link to="">다된다</nuxt-link>
    <nuxt-link to="">홈페이지</nuxt-link>
    <nuxt-link to="">백오피스</nuxt-link>
    -->
    <button v-for="menu in menus" :key="menu.id" @click="()=>fnMenuClick(menu.id)">
      {{menu.title}}
    </button>
  </nav>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  computed:{
    menus() {
      const menus = this.$store.state.backoffice.configMenuStore.menus;
      return menus;
    }
  },
  methods: {
    fnMenuClick(id){
      this.$store.commit('backoffice/configMenuStore/SELECT_ROOT_MENU', id);
      this.$store.commit('ui/TOGGLE_MENU');
    }
  },
};
</script>
