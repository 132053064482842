<template>
  <div class="tbl_doc_scroll full mt10">
    <div class="t_inner">
      <div class="t_top">
        <div class="item">
          <div class="t_group">
            <div class="row">
              <div class="cel" style="width: 200px">회사명</div>
              <div class="cel" style="width: 150px">날짜</div>
              <div class="cel" style="width: 75px">해당년도<br />최저시급</div>
              <div class="cel" style="width: 75px">3000</div>
              <div class="cel" style="width: 945px">계약 시간</div>
              <div class="cel" style="width: 675px">급여데이터</div>
              <div class="cel" style="width: 380px">비과세 금액</div>
              <div class="cel" style="width: 300px">일할계산</div>
              <div class="cel" style="width: 700px">근로계약 기본정보</div>
              <div class="cel" style="width: 460px">비과세 내역</div>
              <div class="cel" style="width: 150px">최저시급 계산</div>
            </div>
            <div class="row">
              <div class="cel" style="width: 50px">순번</div>
              <div class="cel" style="width: 75px">사번</div>
              <div class="cel" style="width: 75px">입사일</div>
              <div class="cel" style="width: 75px">성명</div>
              <div class="cel" style="width: 75px">부서</div>
              <div class="cel" style="width: 75px">직책</div>
              <div class="cel" style="width: 75px">직급</div>
              <div class="cel" style="width: 75px">통상시간급</div>
              <div class="cel" style="width: 80px">기본 근로시간</div>
              <div class="cel" style="width: 75px">연장근무시간</div>
              <div class="cel" style="width: 75px">연장근무수당</div>
              <div class="cel" style="width: 75px">휴일근무시간</div>
              <div class="cel" style="width: 75px">휴일근무수당</div>
              <div class="cel" style="width: 95px">휴일연장근무시간</div>
              <div class="cel" style="width: 95px">휴일연장근무수당</div>
              <div class="cel" style="width: 75px">야간근무시간</div>
              <div class="cel" style="width: 75px">야간근무수당</div>
              <div class="cel" style="width: 75px">당직근무시간</div>
              <div class="cel" style="width: 75px">당직근무수당</div>
              <div class="cel" style="width: 75px">연봉계약금액</div>
              <div class="cel" style="width: 75px">통상시급</div>
              <div class="cel" style="width: 75px">최저시급</div>
              <div class="cel" style="width: 75px">월고정급여액</div>
              <div class="cel" style="width: 75px">수습급여율</div>
              <div class="cel" style="width: 75px">고정상여금</div>
              <div class="cel" style="width: 75px">추가연장수당</div>
              <div class="cel" style="width: 75px">근태공제</div>
              <div class="cel" style="width: 75px">연차수당</div>
              <div class="cel" style="width: 95px">6세미만보육수당<br />(20만원까지)</div>
              <div class="cel" style="width: 95px">연구개발수당<br />(20만원까지)</div>
              <div class="cel" style="width: 95px">식대<br />(20만원까지)</div>
              <div class="cel" style="width: 95px">차량유지비<br />(20만원까지)</div>
              <div class="cel" style="width: 75px">중간입퇴사자<br />근무일수</div>
              <div class="cel" style="width: 75px">해당월총일수</div>
              <div class="cel" style="width: 75px">복리후생률</div>
              <div class="cel" style="width: 75px">고정상여율</div>
              <div class="cel" style="width: 200px">기본근로시간</div>
              <div class="cel" style="width: 350px">기본급산정시간</div>
              <div class="cel" style="width: 150px">휴게시간</div>
              <div class="cel" style="width: 75px">보육수당</div>
              <div class="cel" style="width: 75px">연구개발수당</div>
              <div class="cel" style="width: 75px">당직근무수당</div>
              <div class="cel" style="width: 75px">식대</div>
              <div class="cel" style="width: 75px">차량유지비</div>
              <div class="cel" style="width: 85px">비과세 합계액</div>
              <div class="cel" style="width: 75px">최저시급</div>
              <div class="cel" style="width: 75px">기본급</div>
            </div>
          </div>
        </div>
      </div>
      <div class="t_cont">
        <div class="item b_double" v-for="(el, idx) in empList" :key="idx">
          <div class="cel" style="width: 50px">{{ el.no }}</div>
          <div class="cel" style="width: 75px">{{ el.empNo }}</div>
          <div class="cel" style="width: 75px">{{ el.empStartDt }}</div>
          <div class="cel" style="width: 75px">{{ el.name }}</div>
          <div class="cel" style="width: 75px">{{ el.dept }}</div>
          <div class="cel" style="width: 75px">{{ el.position }}</div>
          <div class="cel" style="width: 75px">{{ el.rank }}</div>
          <div class="cel" style="width: 75px">{{ el.regularHourlyPay }}</div>
          <div class="cel" style="width: 80px">{{ el.basicWorkHours }}</div>
          <div class="cel" style="width: 75px">{{ el.extendedWorkHours }}</div>
          <div class="cel" style="width: 75px">{{ el.extendedWorkPay }}</div>
          <div class="cel" style="width: 75px">{{ el.holidayWorkHours }}</div>
          <div class="cel" style="width: 75px">{{ el.holidayWorkPay }}</div>
          <div class="cel" style="width: 95px">{{ el.holidayOverWorkHours }}</div>
          <div class="cel" style="width: 95px">{{ el.holidayOverWorkPay }}</div>
          <div class="cel" style="width: 75px">{{ el.nightShiftHours }}</div>
          <div class="cel" style="width: 75px">{{ el.nightShiftPay }}</div>
          <div class="cel" style="width: 75px">{{ el.dutyHours }}</div>
          <div class="cel" style="width: 75px">{{ el.dutyPay }}</div>
          <div class="cel" style="width: 75px">{{ el.annualContractAmount }}</div>
          <div class="cel" style="width: 75px">{{ el.regularHourlyPay }}</div>
          <div class="cel" style="width: 75px">{{ el.minimumHourlyPay }}</div>
          <div class="cel" style="width: 75px">{{ el.monthFixedSalary }}</div>
          <div class="cel" style="width: 75px">{{ el.probationarySalaryRate }}</div>
          <div class="cel" style="width: 75px">{{ el.fixedBonus }}</div>
          <div class="cel" style="width: 75px">{{ el.extraOvertimePay }}</div>
          <div class="cel" style="width: 75px">{{ el.attendanceDeduction }}</div>
          <div class="cel" style="width: 75px">{{ el.annualAllowance }}</div>
          <div class="cel" style="width: 95px">{{ el.childCareAllowance }}</div>
          <div class="cel" style="width: 95px">{{ el.rndAllowance }}</div>
          <div class="cel" style="width: 95px">{{ el.meals }}</div>
          <div class="cel" style="width: 95px">{{ el.carMaintenanceCost }}</div>
          <div class="cel" style="width: 75px">{{ el.midJoinLeaveWorkDay }}</div>
          <div class="cel" style="width: 75px">{{ el.monthTotDay }}</div>
          <div class="cel" style="width: 75px">복리후생률</div>
          <div class="cel" style="width: 75px">고정상여율</div>
          <div class="cel" style="width: 200px">{{ el.basicWorkHoursDesc }}</div>
          <div class="cel" style="width: 350px">{{ el.basicPayTimeDesc }}</div>
          <div class="cel" style="width: 150px">{{ el.restTime }}</div>
          <div class="cel" style="width: 75px">{{ el.tfChildCareAllowance }}</div>
          <div class="cel" style="width: 75px">{{ el.tfRndAllowance }}</div>
          <div class="cel" style="width: 75px">{{ el.tfDutyPay }}</div>
          <div class="cel" style="width: 75px">{{ el.tfMeals }}</div>
          <div class="cel" style="width: 75px">{{ el.tfCarMaintenanceCost }}</div>
          <div class="cel" style="width: 85px">{{ el.tfTotal }}</div>
          <div class="cel" style="width: 75px">{{ el.minimumHourlyPay }}</div>
          <div class="cel" style="width: 75px">{{ el.basicPay }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  layout: 'blank',
  data() {
    return {
      empList: [],
    };
  },

  fetch() {
    const { customerId, laborFileId } = this.$route.query;
    return axios
      .get(`${process.env.api_all_ok_mng_url}/v2/customers/service/labor/${customerId}/file/${laborFileId}/emp`)
      .then(({ data }) => {
        if (data.data) this.empList = data.data;
      })
      .catch(err => {
        console.error(err.response.data);
      });
  },
};
</script>

<style></style>
