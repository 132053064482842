<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>고객상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button :class="{ tab: true, on: on == 'tab1' }" @click="() => (this.on = 'tab1')">고객상세</button>
              <button :class="{ tab: true, on: on == 'tab2' }" @click="() => (this.on = 'tab2')">담당자배정</button>
            </div>
            <div class="btns">
              <button class="btn dark" @click="fnClosePop">닫기</button>
            </div>
          </div>
          <div class="c_contents" v-if="on == 'tab1'" >
            <Tab1 :contents="contents" />
          </div>
          <div class="c_contents" v-if="on == 'tab2'" >
            <Tab2 
              :id="id" 
              :allowedSalesCompTypes="managerTab.salesCompanyType"
              :salesCompId="compObj.gaCd"
              :salesCompNm="compObj.gaNm"
              :salesId="compObj.fcCd"
              :salesNm="compObj.fcNm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab1 from './Tab1.vue'
import Tab2 from './Tab2.vue'
import rolesMixin from "../../../mixins/rolesMixin";
export default {
  mixins:[rolesMixin],
  props: ['id', 'compObj'],
  data() {
    return {
      on: 'tab1',
      contents: {}
    };
  },
  components: {
    Tab1, Tab2
  },
  computed:{
    managerTab(){
      return {
        salesCompanyType:[this.mixCompanyTypes.ADMIN, this.mixCompanyTypes.GA]
      }
    },
  },
  async mounted () {
    if(this.id){
      const result = await this.$axios.get(`/apiMngAo/landing/customers/${this.id}`)
      if(result.data.result){
        this.contents = result.data.data.contents
      }
    };
  },
  methods: {
    fnClosePop() {
      this.$store.dispatch('ui/setPopCsState', false);
      this.$emit('reload');
    },
  },
};
</script>
