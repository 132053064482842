<template>
  <div class="c_contents contents_col2">
    <div class="left">
      <ul class="form_list type2">
        <li class="item_add_file">
          <h4>서류제출</h4>
          <div class="i_wrap">
            <CommonSubmitFileType
              v-for="(type,idx) in env.fileTypes" :key="idx"
              :title="type.title"
              :customer-id="customerId"
              :path="env.path"
              :file-list="prp_fileMap[type.fileType]"
              :file-type="type.fileType"
              @updated="()=>{
                fnFetchFiles();
              }"
            />
          </div>
        </li>
        <div class="wrap_file">
          <div class="colr">
            <button v-if="$roleCheck([ADMIN])" class="btn b_down" @click="fnZipDownload">전체다운로드</button>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import CommonSubmitFileType from "./CommonSubmitFileType";
import {ADMIN} from "~/constants/userRole";
export default {
  components:{CommonSubmitFileType},
  name: 'CommonSubmit',
  props: {
    customerId:{
      type:String,
      required:true,
    },
    env:{
      type:Object,
      default: ()=>{
        return {
          path:'tax',
          fileTypes:[
            {
              fileType:'incomeList',
              title:'소득자별근로소득',
            },
            {
              fileType:'adjustList',
              title:'세무조정계산서',
            }
          ],
          fileMap:{
            incomeList:null,
            adjustList:null,
          }
        }
      }
    },
  },
  beforeMount() {
    this.fnFetchFiles();
  },
  data(){
    return{
      prp_fileMap: this.env.fileMap,
      ADMIN
    }
  },
  methods:{
    fnFetchFiles(){
      this.$axios.get(`/apiMngAo/customers/service/${this.env.path}/${this.customerId}/files`)
        .then(res => {
          if(res.data.result){
            this.env.fileTypes.forEach( type => {
              if( res.data.data.contents[type.fileType] != null){
                this.prp_fileMap[type.fileType] = res.data.data.contents[type.fileType];
              }
            })
          }
        })
    },
    fnZipDownload(){
      window.open(`/apiMngAo/customers/service/${this.path}/${this.customerId}/filesDownloads`, '_blank')
    },
  },
  watch:{
    fileMap:{
      deep:true,
      handler(v){
        console.log(v);
      }
    }
  }
};
</script>
