var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container"},[_c('div',{staticClass:"inner contents_col2 bg_white"},[_c('div',{staticClass:"left"},[(_vm.gridProp)?_c('grid',{key:_vm.gridKey,ref:"grid",attrs:{"data":_vm.gridProp.data,"columns":_vm.gridProp.columns,"options":_vm.gridProp.options},on:{"drop":_vm.fnOnDrop2,"editingFinish":_vm.fnOnChangeName2,"click":_vm.fnOnClick2}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"right"},[_c('div',{staticClass:"colb mb5"},[_c('div',{staticClass:"top"},[_c('div',[_c('button',{staticClass:"btn",on:{"click":_vm.fnClickMove}},[_vm._v("이동하기")]),_vm._v(" "),_c('strong',{staticClass:"ml5"},[_vm._v("선택한 조직 : "+_vm._s(_vm.selectedOrgNm || '전체'))])])]),_vm._v(" "),(_vm.selectedOrg)?_c('div',[_c('CheckBox',{attrs:{"label":"하위조직 포함","name":"org","value":_vm.isContainSub},on:{"update:value":function($event){_vm.isContainSub=$event},"change":checked => {
                if (checked) _vm.fnFetchOrgUser(_vm.selectedOrg, 'Y');
                else _vm.fnFetchOrgUser(_vm.selectedOrg, null);
              }}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"tbl_area",staticStyle:{"min-width":"auto"}},[_c('table',{staticClass:"tbl"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('tbody',[_vm._l((_vm.pagingUserList),function(el){return _c('tr',{key:el.userNo},[_c('td',[_c('CheckBox',{attrs:{"label":"<br />","name":el.userNo,"value":_vm.selectedUser.indexOf(el.userNo) > -1},on:{"change":checked => _vm.fnClickCheck(el.userNo, checked)}})],1),_vm._v(" "),_c('td',[_vm._v("\n                  "+_vm._s(el.userNo)+"\n                ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(el.userNm))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(el.orgNm))])])}),_vm._v(" "),(_vm.userList.length == 0)?_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_vm._v("데이터가 없습니다.")])]):_vm._e()],2)])]),_vm._v(" "),_c('Pagination',{attrs:{"page":_vm.pagination.pageNo,"perPage":_vm.pagination.pageSize,"totalCount":_vm.pagination.totalCnt},on:{"change":p => {
              this.pagination.pageNo = p;
            }}})],1)])]),_vm._v(" "),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(_vm.popState == true)?_c('MoveOrgUserModal',{on:{"closePop":function($event){_vm.popState = false},"onSelect":_vm.fnSelectOrg}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"7%"}}),_vm._v(" "),_c('col',{attrs:{"width":"30%"}}),_vm._v(" "),_c('col',{attrs:{"width":"30%"}}),_vm._v(" "),_c('col',{attrs:{"width":"33%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_c('b')]),_vm._v(" "),_c('th',[_c('b',[_vm._v("사용자번호")])]),_vm._v(" "),_c('th',[_c('b',[_vm._v("이름")])]),_vm._v(" "),_c('th',[_c('b',[_vm._v("조직명")])])])])
}]

export { render, staticRenderFns }