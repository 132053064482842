
const alert = ($store, {title, msg, btn}) => {

  let defaultTitle = "경고";

  let defaultBtn = {
    name:"닫기",
  }

  let data = {
    state:true,
    type: "alert",
    title: title ? title : defaultTitle,
    msg: "<p>"+msg+"</p>",
    btn: btn ? btn : defaultBtn
  };
  $store.dispatch('ui/setPopAlertData', data);
};

const confirm = ($store, { title, msg, btnName, btnFunc, closeBtnName }) => {
  let data = {
    state: true,
    title: title ? title : 'Confirm',
    msg: '<p>' + msg + '</p>',
    btn: {
      name: btnName ? btnName : 'save',
      func: btnFunc
    },
    closeBtn: {
      name: closeBtnName ? closeBtnName : null
    }
  };
  $store.dispatch('ui/setPopAlertData', data);
};

export default {
  alert,
  confirm
};
