<template>
  <div class="c_contents contents_col2">
    <div class="left">
      <ul class="form_list type2">
        <li class="item_add_file">
          <h4>서류제출</h4>
          <div class="i_wrap">
            <template>
              <div class="wrap_file">
                <div class="w_top">
                  <h5>급여대장 파일 목록</h5>
                </div>
                <div class="bx_fileview">
                  <dl>
                    <div class="item_file" v-for="(file, i) in salaryList" :key="i">
                      <span @click="() => fnFileDownload(file.fileId)">{{ file.orgName }}</span>
                      <button class="btn_delete" @click="() => fnFileDelete('salaryList', file)">삭제</button>
                    </div>
                    <div v-if="salaryList.length === 0"></div>
                    <button class="btn sm" style="float: right" @click="e => fnOpenFileBrowser('salaryList')">업로드</button>
                  </dl>
                </div>
              </div>
            </template>
            <template>
              <div class="wrap_file">
                <div class="w_top">
                  <h5>고용계약 파일 목록</h5>
                </div>
                <div class="bx_fileview">
                  <dl>
                    <div class="item_file" v-for="(file, i) in contractList" :key="i">
                      <span @click="() => fnFileDownload(file.fileId)">{{ file.orgName }}</span>
                      <button class="btn_delete" @click="() => fnFileDelete('contractList', file)">삭제</button>
                    </div>
                    <div v-if="contractList.length === 0"></div>
                    <button class="btn sm" style="float: right" @click="e => fnOpenFileBrowser('contractList')">업로드</button>
                  </dl>
                </div>
              </div>
            </template>
            <div class="wrap_file">
              <div class="colr">
                <button class="btn b_down" @click="fnZipDownload">전체다운로드</button>
              </div>
            </div>
            <input ref="uploadFile" type="file" style="display: none" @change="fnFileChange" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id', 'bizNo'],
  data() {
    return {
      salaryList: [],
      contractList: [],
      type: null,
    };
  },
  computed: {},
  methods: {
    fnFetchFiles() {
      this.$axios.get(`/apiMngAo/customers/service/labor/${this.id}/files`).then(res => {
        if (res.data.result) {
          if (res.data.data.contents.salaryList !== null) this.salaryList = res.data.data.contents.salaryList;
          if (res.data.data.contents.contractList !== null) this.contractList = res.data.data.contents.contractList;
        }
      });
    },
    // 22.12.23 업로드 기능 삭제
    fnOpenFileBrowser(type) {
      this.type = type;
      this.$refs.uploadFile.click();
    },
    // 22.12.23 다운로드 기능  삭제
    fnFileDownload(fileId) {
      window.open(`/apiMngAo/customers/service/labor/${this.id}/filesDownloads/${fileId}`, '_blank');
    },
    fnZipDownload() {
      window.open(`/apiMngAo/customers/service/labor/${this.id}/filesDownloads`, '_blank');
    },
    // 22.12.23 삭제 기능 삭제
    fnFileDelete(type, deleteFile) {
      const btn = {
        name: '삭제',
        func: () => {
          this._closePop();
          this.$axios.delete(`/apiMngAo/customers/service/labor/${this.id}/files/${type}/${deleteFile.fileId}`).then(result => {
            this._alert('파일삭제', '삭제가 완료되었습니다.');
            this.fnFetchFiles();
          });
        },
      };
      this._confirm('파일삭제', '해당 파일을 삭제하시겠습니까?', btn);
    },
    // 22.12.23 파일 업로드 삭제
    fnFileChange(e) {
      const formData = new FormData();
      const uploadFile = e.target.files[0];
      formData.append('uploadFile', uploadFile);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const btn = {
        name: '업로드',
        func: () => {
          this._closePop();
          this.$axios.post(`/apiMngAo/customers/service/labor/${this.id}/files/${this.type}`, formData, config).then(result => {
            if (result.data.result) {
              this._alert('파일업로드', '업로드가 완료되었습니다.');
              this.fnFetchFiles();
              this.$refs.uploadFile.value = '';
            } else {
              this._alert('파일업로드', '업로드가 실패했습니다. 관리자에게 문의하세요');
            }
          });
        },
      };
      if (e.target.files.length > 0) this._confirm('파일 업로드', '파일을 업로드하시겠습니까?', btn);
    },
    _alert(title, msg) {
      let data = {
        state: true,
        type: 'alert',
        title: title,
        msg: msg,
        btn: {
          name: '확인',
        },
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _confirm(title, msg, btn) {
      let data = {
        state: true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closePop() {
      this.$store.dispatch('ui/setPopAlertData', {
        state: false,
        type: null,
        title: null,
        msg: null,
        btn: {
          link: '',
          name: null,
        },
      });
    },
  },
  beforeMount() {
    this.fnFetchFiles();
  },
};
</script>
