<template>
  <section class="container">
    <Location />
    <div class="inner">
      <div class="colr">
        <button class="btn_reset"
                @click="fnResetForm"
        >전체초기화</button>
      </div>
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="bizNo">사업자번호</label>
          <Input
            id="bizNo"
            name="query.bizNo"
            shape="search"
            placeholder='검색어를 입력하세요.'
            :value.sync="query.bizNo"
            @enter="fnResetPageAndFetch"
          />
        </div>
        <div class="unit">
          <label class="tit" for="compNm">회사명</label>
          <Input
            id="compNm"
            name="query.compNm"
            shape="search"
            placeholder='검색어를 입력하세요.'
            :value.sync="query.compNm"
            @enter="fnResetPageAndFetch"
            :disabled=true
          />
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <div>
            <button class="btn" @click="fnOpenPop2('popCsState')">
              열기
            </button>
          </div>
        </div>
        <div class="col unit_page">
          <h4 class="l_tit">페이지당 개수 : </h4>
          <Select
            id="perPage"
            title="20개"
            :itemList="perPageList"
            :value.sync="query.perPage"
            @change="fnFetchList"
          />
          <button class="btn" @click="()=>{
            query.page=1;
            fnFetchList();
          }">검색</button>
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <thead>
            <tr>
              <th class="c1"><b>사업자번호</b></th>
              <th class="c1"><b>회사명</b></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item) in contents" :key="item.id" :class="{on:item.id===id}"
                @click="fnOnClick({id:item.id, compNm:item.compNm, bizNo:item.bizNo})"
                @dblclick="fnOpenPop2('popCsState')">
              <td class="c1">{{ item.bizNo }}</td>
              <td class="c1">{{ item.compNm }}</td>
            </tr>
            <tr v-if="contents && contents.length === 0">
              <td colspan="7">데이터가 없습니다.</td>
            </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="query.page"
          :perPage="query.perPage"
          :totalCount="pagination.totalCount"
          @change="p => {
            this.query.page=p;
            this.fnFetchList();
          }"
        />
      </div>
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <EducationDetail v-if="$store.state.ui.popCsState == true" :id="id" :bizNo="bizNo" :compNm="compNm" :key="$store.state.ui.popCsState" @reload="fnReload"/>
      </transition>
    </div>
  </section>
</template>

<script>
import Header from '~/components/layouts/Header';
import Location from '~/components/layouts/LocationDataWrapper';
import CsPop from '~/components/popup/Cs.vue';
import EducationDetail from '~/components/pages/allOkMng/EducationDetail.vue';
import Input from '~/components/common/Input.vue';
import Pagination from "~/components/common/Pagination";
import Select from "~/components/common/Select";

export default {
  components: {
    Header,
    Location,
    CsPop,
    EducationDetail,
    Input, Select,
    Pagination,
  },
  data() {
    return {
      id: '',
      compNm:'',
      bizNo: '',
      query: {
        page:1,
        perPage: 20, // 한 페이지에 보여줄 rows의 갯수
        bizNo:null,
        compNm:null,
      },
      contents:[],
      pagination:{
        totalCount:0,
      },
      perPageList_:[
        { name: '10개', value:10 },
        { name: '20개', value:20 },
        { name: '30개', value:30 },
      ]
    };
  },
  computed:{
    perPageList(){
      return this.perPageList_.map( item => {
        return {
          ...item,
          select: item.value === this.query.perPage ? true : false
        }
      });
    }
  },
  methods: {
    fnConsultant(consultantGaNm, consultantNm){
      let result = '';
      if ( consultantGaNm ) {
        result += `[${consultantGaNm}]`;
        if ( consultantNm ) {
          result += ` ${consultantNm}`
        }
      }
      return result;
    },
    fnResetForm(){
      const newQuery = {
        page: 1,
        bizNo: null,
        compNm: null,
      };
      this.query = {
        ...this.query,
        ...newQuery
      }
      this.fnFetchList();
    },
    fnOnClick(e) {
      if(this.id !== e.id){
        this.id = e.id;
        this.compNm = e.compNm
        this.bizNo = e.bizNo
      }
    },
    fnOpenPop2(payload) {
      if (this.id) {
        this.$store.dispatch('ui/setPopState', { key: payload, value: true });
      } else{
        this.$popupAlert({
          title: '경고',
          msg: '고객을 선택해 주세요.',
        });
        return;
      }
    },
    fnResetPageAndFetch(){
      this.query.page=1;
      this.fnFetchList();
    },
    fnFetchList(){
      const params = this.$generateQueryParam(this.query);
      this.$axios.get('/apiMngAo/customer/service/education?'+params)
        .then(res=> {
          if(res.data.result === true){
            const {contents, pagination} = res.data.data;
            this.contents = contents;
            this.pagination = pagination;
          }
        });
    },
    fnReload(){
      this.fnFetchList();
    },
    fnSortValue({key,value}){
      if( this.query[key] !== value ){
        this.query[key]=value;
      } else {
        this.query[key] = null;
      }
      this.fnFetchList();
    },
    fnCut(payload){
      if(payload){
        return payload.slice(0,10)+' '+payload.slice(11,19)
      }
    }
  },
  mounted() {
    this.fnFetchList();
  },
};
</script>
