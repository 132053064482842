<template>
  <div>
    <div class="area_search">
      <div class="unit">
        <label class="tit" for="compNm">고객명</label>
        <Input
          id="name"
          name="name"
          shape="search"
          placeholder='검색어를 입력하세요.'
          :value.sync="cName"
        />
      </div>
    </div>
    <grid v-if="gridProp1" :data="gridProp1.data" :columns="gridProp1.columns" :options="gridProp1.options" ref="grid1" :key="key"/>
  </div>
</template>

<script>
import insuranceService from '~/service/allOkMng/insuranceService';
import { CustomColumnHeader1 } from '~/util/TuiGridCustomClass.js';
import Input from '~/components/common/Input';
export default {
  props:['id'],
  components: {
    Input,
  },
  data() {
    return {
      key: +new Date(),
      amountMasking: false,
      cName: null,
      gridProp1: null,
      employList:[],
    }
  },
  watch: {
    cName(newValue) {
      let list = this.employList.filter( e => e.name && e.name.indexOf(newValue) >= 0)
      if(list)
        this.fnSettingGridProp(list)
      else {
        this.fnSettingGridProp([])
      }
    }
  },
  async mounted() {
    const result = await insuranceService.getInsuranceDetail(this.$axios, this.id)
    if(result.result){
      if(result.data.contents.employList.length!==0){
        this.employList = result.data.contents.employList;
        this.amountMasking = this.employList[0].amountMasking
      }
    }
    this.fnSettingGridProp(this.employList)
  },
  methods: {
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fnSettingGridProp(list) {
      //gridprop1 세팅
      let data1 = []
      data1 = list.map(e => {
        return {
          amountMasking: e.amountMasking,
          c1: e.no,
          c2: e.empType,
          c3: e.jumin,
          c4: e.name,
          c5: e.insuranceInfo.empStartDate,
          c6: e.insuranceInfo.empEndDate,
          c7: e.insuranceInfo.leaveStartDate,
          c8: e.insuranceInfo.leaveEndDate,
          c9: e.insuranceInfo.transferDate,
          c10: e.insuranceInfo.empState,
          c11: e.hireInfo.empStartDate,
          c12: e.hireInfo.empEndDate,
          c13: e.hireInfo.leaveStartDate,
          c14: e.hireInfo.leaveEndDate,
          c15: e.hireInfo.transferDate,
          c16: e.hireInfo.empState,
          c17: e.insAvgSalary, //1산재
          c18: e.hireSaveSalary,
          c19: e.resourceNo,
          c20: e.gesiNo,
          c21: e.hasugubinNo,
          c22: e.taxFree ? this.fnCheck2(e.taxFree.researcher) : '-',
          c23: e.taxFree ? this.fnCheck2(e.taxFree.rearer) : '-',
          c24: e.taxFree ? this.fnCheck2(e.taxFree.blueCollar) : '-',
          c25: this.fnCheck1(e.exceptCondition1),
          c26: this.fnCheck1(e.exceptCondition2),
          c27: this.fnCheck1(e.applyCondition1),
          c28: this.fnSub(e.insAvgSalary), //돈뺀거
          c29: e.taxFreeAmount,
          c30: e.insAvgSalary, //기존
          c31: e.modAvgSalary,
          c32: e.natPensionCmp,
          c33: e.natPensionEmp,
          c34: e.healthCmp,
          c35: e.healthEmp,
          c36: e.longTermCareCmp,
          c37: e.longTermCareEmp,
          c38: e.empCmp,
          c39: e.empEmp,
          c40: e.industCmp,
          c41: e.industEmp,
          c42:e.insuranceReduceCmpTotal,
          c43: e.insuranceReduceEmpTotal,
          c44: e.insuranceReduceTotal,
          c45: e.tax,
          c46: e.taxMod,
          c47: e.taxReducedYear,
          c48: e.localTax,
          c49: e.localTaxMod,
          c50: e.localTaxReducedYear,
          c51: e.totalTaxReducedYear,
        }
      })
      const columns = [
        {
          header: '<div style="font-weight: bold;">연번</div>',
          width: 100,
          align: 'center',
          name: 'c1'
        },
        {
          header: '<div style="font-weight: bold;">근로자구분</div>',
          width: 100,
          align: 'center',
          name: 'c2'
        },
        {
          header: '<div style="font-weight: bold;">주민등록번호</div>',
          width: 100,
          align: 'center',
          name: 'c3'
        },
        {
          header: '<div style="font-weight: bold;">성명</div>',
          width: 100,
          align: 'center',
          name: 'c4'
        },
        {
          header: '<div style="font-weight: bold;">고용일</div>',
          width: 100,
          align: 'center',
          name: 'c5'
        },
        {
          header: '<div style="font-weight: bold;">고용종료일</div>',
          width: 100,
          align: 'center',
          name: 'c6'
        },
        {
          header: '<div style="font-weight: bold;">휴직시작일</div>',
          width: 100,
          align: 'center',
          name: 'c7'
        },
        {
          header: '<div style="font-weight: bold;">휴직종료일</div>',
          width: 100,
          align: 'center',
          name: 'c8'
        },
        {
          header: '<div style="font-weight: bold;">전근일</div>',
          width: 100,
          align: 'center',
          name: 'c9'
        },
        {
          header: '<div style="font-weight: bold;">①고용상태</div>',
          width: 100,
          align: 'center',
          name: 'c10'
        },
        {
          header: '<div style="font-weight: bold;">취득일</div>',
          width: 100,
          align: 'center',
          name: 'c11'
        },
        {
          header: '<div style="font-weight: bold;">상실일</div>',
          width: 100,
          align: 'center',
          name: 'c12'
        },
        {
          header: '<div style="font-weight: bold;">휴직시작일</div>',
          width: 100,
          align: 'center',
          name: 'c13'
        },
        {
          header: '<div style="font-weight: bold;">휴직종료일</div>',
          width: 100,
          align: 'center',
          name: 'c14'
        },
        {
          header: '<div style="font-weight: bold;">전근일</div>',
          width: 100,
          align: 'center',
          name: 'c15'
        },
        {
          header: '<div style="font-weight: bold;">고용상태</div>',
          width: 100,
          align: 'center',
          name: 'c16'
        },
        {
          header: '<div style="font-weight: bold;">①산재</div>',
          width: 100,
          align: 'center',
          name: 'c17',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">고용</div>',
          width: 100,
          align: 'center',
          name: 'c18',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">근로자원부번호</div>',
          width: 100,
          align: 'center',
          name: 'c19'
        },
        {
          header: '<div style="font-weight: bold;">사업개시번호</div>',
          width: 130,
          align: 'center',
          name: 'c20'
        },
        {
          header: '<div style="font-weight: bold;">미승인하수급인번호</div>',
          width: 130,
          align: 'center',
          name: 'c21'
        },
        {
          header: '<div style="font-weight: bold;">연구원</div>',
          width: 130,
          align: 'center',
          name: 'c22'
        },
        {
          header: '<div style="font-weight: bold;">6세미만<br/>자녀<br/>양육자</div>',
          width: 130,
          align: 'center',
          name: 'c23'
        },
        {
          header: '<div style="font-weight: bold;">생산직<br/>근로자</div>',
          width: 130,
          align: 'center',
          name: 'c24'
        },
        {
          header: '<div style="font-weight: bold;">제외조건1</div>',
          width: 100,
          align: 'center',
          name: 'c25'
        },
        {
          header: '<div style="font-weight: bold;">제외조건2</div>',
          width: 100,
          align: 'center',
          name: 'c26'
        },
        {
          header: '<div style="font-weight: bold;">제외조건3</div>',
          width: 100,
          align: 'center',
          name: 'c27',
        },
        {
          header: '<div style="font-weight: bold;">보수금액-2,010,580원</div>',
          width: 140,
          align: 'center',
          name: 'c28',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">비과세 신설금액</div>',
          width: 140,
          align: 'center',
          name: 'c29',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">기존</div>',
          width: 100,
          align: 'center',
          name: 'c30',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">변경</div>',
          width: 100,
          align: 'center',
          name: 'c31',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">회사(4.5%)</div>',
          width: 120,
          align: 'center',
          name: 'c32',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">근로자(4.5%)</div>',
          width: 120,
          align: 'center',
          name: 'c33',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">회사(3.545%)</div>',
          width: 120,
          align: 'center',
          name: 'c34',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">근로자(3.545%)</div>',
          width: 120,
          align: 'center',
          name: 'c35',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">회사(50%)</div>',
          width: 140,
          align: 'center',
          name: 'c36',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">근로자(50%)</div>',
          width: 140,
          align: 'center',
          name: 'c37',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">회사(0.9%)</div>',
          width: 120,
          align: 'center',
          name: 'c38',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">근로자(0.9%)</div>',
          width: 120,
          align: 'center',
          name: 'c39',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">회사(1%)</div>',
          width: 120,
          align: 'center',
          name: 'c40',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">근로자(0%)</div>',
          width: 120,
          align: 'center',
          name: 'c41',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">회사</div>',
          width: 100,
          align: 'center',
          name: 'c42',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">근로자</div>',
          width: 100,
          align: 'center',
          name: 'c43',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">TOTAL</div>',
          width: 100,
          align: 'center',
          name: 'c44',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">기존</div>',
          width: 100,
          align: 'center',
          name: 'c45',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">변경</div>',
          width: 100,
          align: 'center',
          name: 'c46',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">절감액</div>',
          width: 100,
          align: 'center',
          name: 'c47',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">기존</div>',
          width: 100,
          align: 'center',
          name: 'c48',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">변경</div>',
          width: 100,
          align: 'center',
          name: 'c49',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">절감액</div>',
          width: 100,
          align: 'center',
          name: 'c50',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        },
        {
          header: '<div style="font-weight: bold;">절감액</div>',
          width: 100,
          align: 'center',
          name: 'c51',
          formatter: ({value}) => this.amountMasking? '*,***,***' : this.fnFormatNumber(value)
        }
      ]
      const options1 = {
        scrollX: true,
        scrollY: true,
        minRowHeight: 20,
        rowHeight: 20,
        minBodyHeight: 0,
        columnOptions: {
          frozenCount: 4,
          frozenBorderWidth: 2,
        },
        header: {
          height: 160,
          columns: columns.map(c=>{
            return {
              name: c.name,
              renderer: CustomColumnHeader1
            }
          }),
          complexColumns: [
            {
              header: '<div style="font-weight: bold;">1)산재보험</div>',
              name: 'mergeColumn1',
              childNames: ['c5', 'c6', 'c7', 'c8', 'c9', 'c10'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">고용보험</div>',
              name: 'mergeColumn2',
              childNames: ['c11', 'c12', 'c13', 'c14', 'c15', 'c16'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">2)월평균보수</div>',
              name: 'mergeColumn3',
              childNames: ['c17', 'c18'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">고용보험</div>',
              name: 'mergeColumn4',
              childNames: ['c20', 'c21'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">제외조건 (or)</div>',
              name: 'mergeColumn5',
              childNames: ['c25', 'c26', 'c27'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">비과세 신설 금액 산정</div>',
              name: 'mergeColumn6',
              childNames: ['c28', 'c29'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">월평균 보수금액</div>',
              name: 'mergeColumn7',
              childNames: ['c30', 'c31'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">국민연금 보험료 (9%)</div>',
              name: 'mergeColumn8',
              childNames: ['c32', 'c33'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">건강보험료 (7.09%)</div>',
              name: 'mergeColumn9',
              childNames: ['c34', 'c35'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">장기요양보험료 (0.9%)<br/>(건강보험료의 12.81%)</div>',
              name: 'mergeColumn10',
              childNames: ['c36', 'c37'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">고용보험료 (1.8%)</div>',
              name: 'mergeColumn11',
              childNames: ['c38', 'c39'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">산재보험료 (평균 1%)</div>',
              name: 'mergeColumn12',
              childNames: ['c40', 'c41'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">4대보험료 연간 절감 총액</div>',
              name: 'mergeColumn13',
              childNames: ['c42', 'c43', 'c44'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">소득세 절감액</div>',
              name: 'mergeColumn14',
              childNames: ['c45', 'c46', 'c47'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">지방세 절감액</div>',
              name: 'mergeColumn15',
              childNames: ['c48', 'c49', 'c50'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">TOTAL</div>',
              name: 'mergeColumn16',
              childNames: ['c51'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">근로자고용현황</div>',
              name: 'mergeColumn17',
              childNames: ['mergeColumn1', 'mergeColumn2', 'mergeColumn3', 'c19', 'mergeColumn4'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">비과세 해당자</div>',
              name: 'mergeColumn18',
              childNames: ['c22', 'c23', 'c24'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">조건설정 및 신설금액 산정</div>',
              name: 'mergeColumn19',
              childNames: ['mergeColumn5', 'mergeColumn6', 'mergeColumn7'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">연간 4대보험료 절감 알고리즘</div>',
              name: 'mergeColumn20',
              childNames: ['mergeColumn8', 'mergeColumn9', 'mergeColumn10', 'mergeColumn11', 'mergeColumn12', 'mergeColumn13'],
              renderer: CustomColumnHeader1
            },
            {
              header: '<div style="font-weight: bold;">연간 소득세 및 지방세 절감알고리즘</div>',
              name: 'mergeColumn21',
              childNames: ['mergeColumn14', 'mergeColumn15', 'mergeColumn16'],
              renderer: CustomColumnHeader1
            },
          ],
        },
        summary: {
          position: 'bottom',
          height: 25,
          columnContent: {
            c28: '<div style="color: red; font-weight: bold; text-align: center;">신설 금액 총계</div>',
            c29: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c30: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c31: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c32: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c33: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c34: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c35: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c36: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c37: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c38: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c39: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c40: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c41: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c42: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c43: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c44: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c45: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c46: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c47: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c48: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c49: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c50: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
            c51: {
              template: (summary) => {
                return `<div style="text-align: center;">${this.amountMasking ? '*,***,***' : this.fnFormatNumber(summary.sum)}</div>`
              }
            },
          },
        }
      };
      
      if(data1.length > 24) options1.bodyHeight = 500
      
      this.gridProp1 = {
        data: data1,
        columns: columns,
        options: options1,
      };
      
      this.key++
    },
    fnSub(payload){
      if(payload)
        return (payload-2010580)
      else
        return '-'
    },
    fnCheck1(payload){
      if(payload){
        return '해당'
      } else{
        return '제외'
      }
    },
    fnCheck2(payload){
      if(payload){
        return '해당'
      } else{
        return '비해당'
      }
    },
  }
}
</script>

<style scoped>
  .xlsx::-webkit-scrollbar{
    height: 5px;
  }
</style>