import configMenuService from "../../service/backoffice/configMenuService";

export const state = () => ({
  menus:[],
  selectedRootMenu:null,
  selectedDepth1Menu:null,
});

export const mutations = {
  UPDATE_MENUS(state, payload) {
    state.menus = payload;
  },
  SELECT_ROOT_MENU(state, id){
    const rootMenu = state.menus.find(menu => menu.id === id);
    state.selectedRootMenu = rootMenu;
  },
  SELECT_DEPTH1_MENU(state, id){
    const depth1Menu = state.menus[0].subList.find(menu => menu.id === id);
    state.selectedDepth1Menu = depth1Menu;
  },
  ADD_ROOT_MENU(state, payload){
    const rootMenu = payload;
    state.selectedRootMenu = rootMenu;
  },
  ADD_DEPTH1_MENU(state, payload){
    const depth1Menu = payload;
    state.selectedDepth1Menu = depth1Menu;
  }
};

export const actions = {
  fetchMenus({commit}){
    return configMenuService.fetchAll(this.$axios)
      .then(({data:res}) => {
        let result = [];
        if(res.result){
          let menuMap = {};
          for(let i=0; i<res.data.contents.length; i++){
            const {root_id, root_title,
            depth1_id, depth1_title, depth1_page_url, roles} = res.data.contents[i];
            if(!menuMap[root_id]){
              menuMap[root_id] = {
                id: root_id,
                title : root_title,
                subList : [],
              }
            }
            menuMap[root_id].subList.push({
              id:depth1_id,
              title:depth1_title,
              page_url:depth1_page_url,
              roles: roles&&roles.split(',')
            })
          }
          let menuList = [];
          for(let key in menuMap){
            menuList.push(menuMap[key]);
          }
          commit('UPDATE_MENUS', menuList);
        }else{
          commit('UPDATE_MENUS', []);
        }
        return result;
      })
      .catch(err => {
        commit('UPDATE_MENUS', []);
      });
  },
};
