<template>
  <div class="cover">
    <div class="cTit">
      <div class="unicornLogo">unicornranch</div>
      <div class="reportTit">비상장주식가치평가</div>
    </div>
    <div class="contents">
      <div class="Flist" style="display: flex;">16.<span class="Ftit">미래 주식가치평가액 및 미처분이익잉여금 전망<br/><span class="titInfo">순자산가치 3과 순손익가치 2의 가중치를 적용하여 비상장주식</span></span></div>
      <div class="compNm">{{입력개요?.회사명}}</div>
      <div>
        <div class="tit1">※ 가정</div>
        <div class="table1">
          <div class="tContents">
            <div class="fCont bold"><span style="display: flex; justify-content: center;">제목</span></div>
            <div class="sCont bold">내용</div>
          </div>
          <div class="tContents divide">
            <div class="fCont semibold"><span style="margin-left: 30px;">매출액증가율</span></div>
            <div class="sCont semibold">{{fnPercent(기업가치미래.매출액_연평균증가율)}}%</div>
          </div>
          <div class="tContents divide">
            <div class="fCont semibold"><span style="margin-left: 30px;">순이익률</span></div>
            <div class="sCont semibold">{{fnPercent(기업가치미래.순이익률)}}%</div>
          </div>
          <div class="tContents divide borderBottom">
            <div class="fCont semibold"><span style="margin-left: 30px;">3년 평균 순이익률</span></div>
            <div class="sCont semibold">{{fnPercent(기업가치미래.매출액대비_순이익률_3년평균).toFixed(1)}}%</div>
          </div>
        </div>
        <div class="tit2">1) 주식가치평가액 및 미처분이익잉여금 전망</div>
        <div class="table2">
          <div class="t2row1">
            <div class="t2r1cell1">구분</div>
            <div class="t2r1cell1">매출액</div>
            <div class="t2r1cell1">순이익</div>
            <div class="t2r1cell1">총자산</div>
            <div class="t2r1cell1">자본총계</div>
            <div class="t2r1cell1">미처분잉여금</div>
            <div class="t2r1cell1">1주당주식가치</div>
            <div class="t2r1cell1">기업가치평가액</div>
          </div>
          <div class="t2row1" v-for="(기업가치미래, i) in 기업가치미래?.기업가치미래" :key="i">
            <div class="t2r1cell1">{{기업가치미래.구분}}년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">{{(기업가치미래.매출액).toFixed(2)}}억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">{{(기업가치미래.순이익).toFixed(2)}}억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">{{(기업가치미래.총자산).toFixed(2)}}억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">{{(기업가치미래.자본총계).toFixed(2)}}억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">{{(기업가치미래.미처분잉여금).toFixed(2)}}억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">{{기업가치미래.주식가치_1주당?fnFormatNumber((기업가치미래.주식가치_1주당).toFixed(2))+'원': '-'}}</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">{{기업가치미래.기업가치평가액?fnFormatNumber((기업가치미래.기업가치평가액).toFixed(2))+'억원': '-'}}</span></div>
          </div>
          <!-- <div class="t2row1">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div>
          <div class="t2row2">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div>
          <div class="t2row1">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div>
          <div class="t2row1">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div>
          <div class="t2row1">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div>
          <div class="t2row1">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div>
          <div class="t2row2">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div>
          <div class="t2row1">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div>
          <div class="t2row1">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div>
          <div class="t2row1">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div>
          <div class="t2row1">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div>
          <div class="t2row2">
            <div class="t2r1cell1">2019년</div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">40.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">6.7억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.1억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">1.0억원</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
            <div class="t2r1cell2"><span style="margin-right: 30px;">-</span></div>
          </div> -->
        </div>
      </div>
      <div class="footer">
        <div class="addEx">※ 추가 설명</div>
        <div class="ex"><span class="exText">투자한 주주들에게 나가야 할 배당금 의무도 무시할 수 없다. 사실 어떤 기업이든 자기 돈만 충분하다면 굳이 엄격한 심사와 자격까지 갖추면서 상장할 이유는 없다.[2] 실제로 2015년 기준 전경련에서 조사한 자료에서는 총 600여개 기업이 코스피 상장 요건을 갖추고 있었지만 단 7개 기업만이 상장을 했다. 그리고 다른 회사에게 자신의 회사를 팔 때도 매우 쉬운데. 상장회사들을 인수 할 때 과반의 주식을 확보하거나 합병까지 하려면 전체 주식을 사서 소각해야 하는데, 이 과정도 매우 복잡해서 전체 주주들에게 동의를 받아야 하고 주식시장을 관리감독하는 기관의 심사까지 받아야 해서 규모가 큰 회사들 끼리 합병시 년단위로 걸린다. 비상장은 경영권을 가진 임원들에게 만족하는 가격만 제시한다면 매우 수월하게 인수가 가능하다.</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['입력개요', '기업가치미래'],
  methods: {
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fnPercent(num){
      return num*100;
    }
  },
}
</script>

<style scoped>
  @import '~/static/css/report.css';
  .t2r1cell2{
    width: 145px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .t2r1cell1{
    width: 145px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .t2row3{
    display: flex;
    height: 40px;
    width: 1160px;
    background-color: #CCD1D7;
  }
  .t2row2{
    display: flex;
    height: 40px;
    width: 1160px;
    border-bottom: solid #001736 1px;
    background-color: #CCD1D7;
  }
  .t2row1{
    display: flex;
    height: 40px;
    width: 1160px;
    border-bottom: solid #001736 1px;
  }
  .Ftit{
    display: flex;
    flex-direction: column;
  }
  .titInfo{
    font-size: 10px;
    font-family: 'Pretendard-Light';
  }
  .compNm{
    color: #1FBDC6;
    font-size: 24px;
    font-family: 'Pretendard-SemiBold';
    position: absolute;
    top: 36px;
    right: 30px;
  }
  .tit1{
    margin-top: -400px;
    color: #33455E;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
  }
  .table1{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .tContents{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid #001736 1px;
    width: 1160px;
    height: 40px;
    font-family: 'Pretendard-Bold';
    font-size: 13px;
  }
  .divide{
    background: linear-gradient(to right, transparent 840px, #DBDCDE 840px);
  }
  .borderBottom{
    border-bottom: solid #001736 1px;
  }
  .fCont{
    width: 300px;
  }
  .sCont{
    margin-right: 30px;
  }
  .tit2{
    margin-top: 61px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .table2{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 1160px;
    height: 560px;
    border-top: solid #001736 1px;
    border-bottom: solid #001736 1px;
  }
  .footer{
    margin-bottom: 50px;
  }
  .footer::before{
    position: absolute;
    width: 100px;
    bottom: -30px;
    right: 0px;
    content: '16';
    width: 100px;
    border: solid 1px #001736;
    background-color: #001736;
    height: 60px;
    color: #1FBDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .addEx{
    font-size: 18px;
    color: #33455E;
  }
  .ex{
    width: 1160.5px;
    height: 75px;
    border-top: solid 1px #001736;
  }
  .exText{
    display: block;
    margin-top: 15px;
    font-size: 13px;
    color: #33455E;
    font-family: 'Pretendard-Regular';
  }
</style>