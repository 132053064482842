<template>
  <div>
    <grid v-if="data.length>0" class="pb20" :data="data" :columns="columns" :options="options" ref="grid" :key="key" />
  </div>
</template>
<script>
import { CustomColumnHeader1 } from '~/util/TuiGridCustomClass.js';
export default {
  props: ['originData'],
  data() {
    return {
      basicComplexColumnsData: {
        headerStyle: '',
      },
      complexColumnsData: [
        { label: `2. 환급예상 공제세액 조정명세표`, name: 'A_F', childNames: ['A','B','D','E','F'],},
      ],
      columnsData: [
        { label: '구분',name: 'A', width: 200, },
        { label: '',name: 'B', width: 200, },
        { label: '통합고용 세액공제',name: 'D', width: 400, },
        { label: '사회보험료 세액공제', name: 'E', width: 400, },
        { label: '고용증대 세액공제',name: 'F', width: 400, },
      ],
      key: Math.random()
    }
  },
  computed: {
    data() {
      let _data = [];
      let _taxData = ( this.originData && this.originData.Tax ) ? this.originData.Tax : null;
      if ( _taxData ) {
        //
        let _taxDataStartIdx = _taxData.findIndex( el => {
          return el.A && el.A.indexOf('환급예상 공제세액 조정명세표')>0
        });
        _taxDataStartIdx = _taxDataStartIdx + 2; // Header Label
        let _taxDataMeta = [];
        let _groupIdx = 0;
        let _groupType ='group';
        // _taxDataMeta
        for (let _taxDataIdx = _taxDataStartIdx; _taxDataIdx<_taxData.length; _taxDataIdx++ ) {
          if ( _taxData[_taxDataIdx].A ) {
            if ( _taxData[_taxDataIdx].A.indexOf('합계') > 0 ) {
              _taxDataMeta.push({ idx: _taxDataIdx, type: 'summary', rowSpanSize: 1})
              _groupIdx = _taxDataIdx - _taxDataStartIdx;
              _groupType ='summary';
            }
            else {
              _taxDataMeta.push({ idx: _taxDataIdx, type: 'group', rowSpanSize: 1})
              _groupIdx = _taxDataIdx - _taxDataStartIdx;
              _groupType ='group';
            }
          }
          else {
            if ( _groupType == 'group' ) {
              _taxDataMeta.push({ idx: _taxDataIdx, type: 'data', });
            }
            else if ( _groupType == 'summary' ) {
              _taxDataMeta.push({ idx: _taxDataIdx, type: 'summary_data', });
            }
            _taxDataMeta[_groupIdx].rowSpanSize++;
          }
        }
        //
        _taxDataMeta.forEach( _meta => {
          if ( _meta.type == 'group' ) {
            _data.push({
              A: _taxData[_meta.idx].A,
              B: _taxData[_meta.idx].B,
              D: this.fnFormatNumber(_taxData[_meta.idx].D),
              E: this.fnFormatNumber(_taxData[_meta.idx].E),
              F: this.fnFormatNumber(_taxData[_meta.idx].F),
              _attributes: {
                rowSpan: {
                  A: _meta.rowSpanSize,
                },
              }
            });
          }
          else if ( _meta.type == 'data' ) {
            if (_taxData[_meta.idx].E == '비대상') {
              _data.push({
                B: _taxData[_meta.idx].B,
                D: this.fnFormatNumber(_taxData[_meta.idx].D),
                E: this.fnFormatNumber(_taxData[_meta.idx].E),
                F: this.fnFormatNumber(_taxData[_meta.idx].F),
                _attributes: {
                  rowSpan: {
                    E: 3
                  },
                }
              });
            }else{
              _data.push({
                B: _taxData[_meta.idx].B,
                D: this.fnFormatNumber(_taxData[_meta.idx].D),
                E: this.fnFormatNumber(_taxData[_meta.idx].E),
                F: this.fnFormatNumber(_taxData[_meta.idx].F),
              });
            }
          }
          else if ( _meta.type == 'summary' ) {
            _data.push({
              A: _taxData[_meta.idx].A,
              B: _taxData[_meta.idx].B,
              F: this.fnFormatNumber(_taxData[_meta.idx].D),
              _attributes: {
                rowSpan: {
                  A: _meta.rowSpanSize,
                },
                className: {
                  row: ['color7'],
                },
              }
            });
          }
          else if ( _meta.type == 'summary_data' ) {
            _data.push({
              B: _taxData[_meta.idx].B,
              F: this.fnFormatNumber(_taxData[_meta.idx].D),
              _attributes: {
                className: {
                  row: ['color7'],
                },
              }
            });
          }
        });
      }
      return _data;
    },
    columns() {
      return this.columnsData.map( el => {
        return {
          header: `<div style="font-weight: bold; font-size:12px">${el.label}</div>`,
          name: el.name,
          width: el.width,
          align: 'center',
        }
      });
    },
    options() {
      return {
        width:1600,
        scrollX: true,
        scrollY: false,
        header: {
          height: 100,
          complexColumns: this.complexColumnsData.map( el => ({
            header: `<div style="font-weight: bold; font-size: 15px;">${el.label}</div>`,
            name: el.name,
            childNames: el.childNames,
            renderer: CustomColumnHeader1,
          })),
          columns: this.columns.map( el => ({
            name: el.name,
            renderer: CustomColumnHeader1,
          })),
        },
        // summary: {
        //   height: 100,
        //   position: 'bottom', // or 'top'
        // },
      }
    }
  },
  methods: {
    fnFormatNumber(num) {
      if ( num ) {
        return isNaN(num) ? num : parseInt(num).toLocaleString();
      }
      return '';
    }
  }
}
</script>
<style>
.tui-grid-cell.color6 {
  background-color: #ecf0ff
}
.tui-grid-cell.color7 {
  background-color: #F7F8FA
}
</style>
