const DEFAULT_STATE = {
  device: '',
  view: '',
  menuState: false,
  popCreateState:false,
  popCsState:false,
  popCs2State:false,
  newFcState: false,
  newFc2State: false,
  fcDetail: false,
  popAlertData: {
    state: false,
    type: null,
    title: null,
    msg: null,
    btn: {
      link: '',
      name: null,
      fnName: null,
    },
  },
  popConfirmData: {
    state: false,
    id: null,
    title: null,
    msg: null,
    btn: {
      name: null,
    },
  },
};

export default {
  namespaced: true,
  state: () => ({ ...DEFAULT_STATE }),
  mutations: {
    UPDATE_STATE(state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key];
      });
    },
    TOGGLE_MENU(state) {
      state.menuState = !state.menuState;
    },
  },
  actions: {
    setDevice({ commit }, payload) {
      commit('UPDATE_STATE', { device: payload });
    },
    setView({ commit }, payload) {
      commit('UPDATE_STATE', { view: payload });
    },
    setMenuState({ commit }, payload) {
      commit('UPDATE_STATE', { menuState: payload });
    },
    setPopCreateState({ commit }, payload) {
      commit('UPDATE_STATE', { popCreateState: payload });
    },
    setPopState({ commit }, payload) {
      commit('UPDATE_STATE', { [payload.key]: payload.value });
    },
    setPopAlertData({ commit }, payload) {
      commit('UPDATE_STATE', { popAlertData: payload });
    },
    setPopConfirmData({ commit }, payload) {
      commit('UPDATE_STATE', { popConfirmData: payload });
    },
    setPopCsState({ commit }, payload) {
      commit('UPDATE_STATE', { popCsState: payload });
    },
    setPopCs2State({ commit }, payload) {
      commit('UPDATE_STATE', { popCs2State: payload });
    },
    setPopFcState({ commit }, payload) {
      commit('UPDATE_STATE', { newFcState: payload });
    },
    setPopFc3State({ commit }, payload) {
      commit('UPDATE_STATE', { newFc2State: payload });
    },
    setPopFc2State({ commit }, payload) {
      commit('UPDATE_STATE', { fcDetail: payload });
    },
  },
};
