<template>
  <div class="right">
    <div class="view_date" @click="()=>fnSearchRangeType('etc')">
      <span class="start">{{ fromDate }}</span>
      <span class="end">{{ toDate }}</span>
      <DatePicTooltip :onOff="tooltipOnOff"
                      :diffMonth="diffMonth"
                      @date="fnDateChanged"
                      @cancel="()=>this.tooltipOnOff=false"
      />
    </div>
    <button class="btn" :class="{ l_gray: searchRangeType !== '1m' }" @click="()=>fnSearchRangeType('1m')"><b>1개월</b></button>
    <button class="btn" :class="{ l_gray: searchRangeType !== '3m' }" @click="()=>fnSearchRangeType('3m')"><b>3개월</b></button>
  </div>
</template>

<script>
import DatePicTooltip from "./DatePicTooltip";
export default {
  components:{
    DatePicTooltip,
  },
  data(){
    return {
      fromDate: null,
      toDate: null,
      diffMonth: null,
      defaultRangeType: '1m',
      searchRangeType : '1m',
      rangeFormDisplay : 'none',
      tooltipOnOff: false,
    }
  },
  methods:{
    fnSearchRangeType(type){
      this.searchRangeType=type;
      if('etc' === type){
        this.diffMonth = null;
        this.rangeFormDisplay = 'block';
        this.tooltipOnOff = true;
      }
      else if ('1m' === type){
        this._fnSetDate();
        this.rangeFormDisplay = 'none';
        this.tooltipOnOff = false;
        // this.fnChanged();
      }
      else if ('3m' === type){
        this._fnSetDate()
        this.rangeFormDisplay = 'none';
        this.tooltipOnOff = false;
        // this.fnChanged();
      }
    },
    _fnSetDefaultDate(){
      this._fnSetDate()
    },
    _fnSetDate(){
      this.diffMonth = parseInt(this.searchRangeType.replace('m',''));
    },
    _fnDateFormat(date){
      let year = date.getFullYear();
      let month = (date.getMonth() < 10) ? month = '0' + (date.getMonth() + 1) : month = date.getMonth() + 1;
      let day = (date.getDate() < 10) ? this.day = '0' + date.getDate() : this.day = date.getDate();
      return year + '-' + month + '-' + day;
    },
    fnDateChanged({fromDate, toDate}) {
      // console.log('fnDateChanged', fromDate, toDate)
      this.fromDate = fromDate;
      this.toDate = toDate;
      this.tooltipOnOff = false;
      this.fnChanged();
    },
    fnChanged(){
      // console.log('fnChanged', this.fromDate, this.toDate)
      this.$emit('change', {fromDate: this.fromDate, toDate:this.toDate})
    },
    fnReset(){
      if(this.searchRangeType !== this.defaultRangeType){
        this.searchRangeType = this.defaultRangeType;
        this._fnSetDate()
        return true;
      }
      return false;
    }
  },
  mounted() {
    this._fnSetDefaultDate();
  },
}
</script>
