<template>
  <div class="c_contents">
    <section class="container">
      <div class="colb">
        <div class="col unit_page">
          <h4 class="l_tit">임직원 선택:</h4>
          <Select id="month" :itemList="empList" :value="empNo" @change="fnSelectEmp" defaultName="선택" :styleAttr="{ width: '80px' }" />
          <h4 class="l_tit"><span class="mr5 ml5" style="font-size: 15px">/</span>계약서 선택:</h4>
          <Select id="month" :itemList="contractList" :value="contractId" @change="fnSelectContract" defaultName="" :styleAttr="{ width: '150px' }" />
        </div>
        <div>
          <button class="btn" :class="{ dis: disablePdfDowload }" :disabled="disablePdfDowload" @click="fnClickPdfDownload">PDF 다운</button>
          <button class="btn" :class="{ dis: disableExcelDowload }" :disabled="disableExcelDowload" @click="fnClickExcelDownload">Excel 다운</button>
          <button class="btn" :class="{ dis: disableEdit }" :disabled="disableEdit" @click="fnSave">저장</button>
        </div>
      </div>
      <hr style="background: #dddddd; height: 1px; border: 0" />
      <div class="colb">
        <div></div>
        <div class="doc contract" style="width: 820px" v-show="contractId">
          <div class="d_wrap">
            <div class="d_top">
              <h2 class="tit">연봉 근로계약서</h2>
              <p class="sub_tit">
                <b>{{ detailData.i1 }}</b> 와(과) 근로자 <b>{{ detailData.i2 }}</b> 는(은) 다음과 같은 근로계약을 체결하고 이를 성실히 이행할 것을 약정한다.
              </p>
              <p><b>- 다 음 -</b></p>
            </div>
            <div class="d_container">
              <div class="d_content">
                <h3>1.&nbsp;&nbsp;&nbsp; 근로계약기간</h3>
                <p v-if="disableEdit">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ detailData.i3 }}</p>
                <p v-else><Input class="ml30" name="i3" :value.sync="detailData.i3" :styleAttr="{ width: '500px', height: '25px' }" /></p>
                <div class="d_unit" style="padding-left: 23px">
                  <h3>연봉계약기간</h3>
                  <p v-if="disableEdit"><pre style="font-family: Pretendard">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ detailData.i4 }}</pre></p>
                  <p v-else><Input class="ml30" name="i4" :value.sync="detailData.i4" :styleAttr="{ width: '500px', height: '25px' }" /></p>
                </div>
              </div>
              <div class="d_content">
                <h3>2.&nbsp;&nbsp; 근무장소 및 업무내용</h3>
                <div class="d_unit">
                  <table class="tbl_doc">
                    <thead>
                      <tr>
                        <th>근무장소</th>
                        <th>부서</th>
                        <th>직급</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span v-if="disableEdit">{{ detailData.i5 }}</span>
                          <Input v-else class="ml30" name="i5" :value.sync="detailData.i5" :styleAttr="{ width: '170px', height: '25px' }" />
                        </td>
                        <td>
                          <span v-if="disableEdit">{{ detailData.i6 }}</span>
                          <Input v-else class="ml30" name="i6" :value.sync="detailData.i6" :styleAttr="{ width: '170px', height: '25px' }" />
                        </td>
                        <td>
                          <span v-if="disableEdit">{{ detailData.i7 }}</span>
                          <Input v-else class="ml30" name="i7" :value.sync="detailData.i7" :styleAttr="{ width: '170px', height: '25px' }" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>사업주는 필요하다고 인정하는 경우에는 근로자의 의견을 들어 근무장소 또는 업무를 변경할 수 있다.</p>
                </div>
              </div>
              <div class="d_content">
                <h3>3.&nbsp;&nbsp; 근로시간(근로형태) 및 휴게시간</h3>
                <div class="d_unit">
                  <p>
                    가. 근로시간<br />
                    <span>
                      ① 기본근로시간&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
                      <span v-if="disableEdit">{{ detailData.i8 }}</span>
                      <Input v-else name="i8" :value.sync="detailData.i8" :styleAttr="{ width: '450px', height: '20px', display: 'inline-block' }" />
                    </span>
                    <br />
                    <span>
                      ② 기본급산정시간&nbsp;&nbsp;&nbsp;&nbsp; :
                      <span v-if="disableEdit">{{ detailData.i8_1 }}</span>
                      <Input v-else name="i8_1" :value.sync="detailData.i8_1" :styleAttr="{ width: '450px', height: '20px', display: 'inline-block' }" />
                    </span>
                  </p>
                  <p style="display: block">
                    나. 휴게시간&nbsp;&nbsp;&nbsp;&nbsp; :
                    <span v-if="disableEdit">{{ detailData.i9 }}</span>
                    <Input v-else name="i9" :value.sync="detailData.i9" :styleAttr="{ width: '450px', height: '20px', display: 'inline-block' }" />
                  </p>
                  <p style="display: block">
                    다.
                    <span v-if="disableEdit">{{ detailData.i9_1 }}</span>
                    <Input v-else name="i9_1" :value.sync="detailData.i9_1" :styleAttr="{ width: '700px', height: '20px', display: 'inline-block' }" />
                  </p>
                  <p>
                    라.
                    <span v-if="disableEdit">{{ detailData.i9_2 }}</span>
                    <Input v-else name="i9_2" :value.sync="detailData.i9_2" :styleAttr="{ width: '700px', height: '20px', display: 'inline-block' }" />
                  </p>
                </div>
              </div>
              <div class="d_bottom" style="margin-top: 0">
                <div class="sign" style="margin-top: 0">&nbsp;<span>(인)</span></div>
              </div>
              <div class="d_content">
                <h3>4.&nbsp;&nbsp; 임금 ( 근로기준법상의 주40시간제, 월 209시간제에 의함)</h3>
                <div class="d_unit">
                  <p>가. 급여내역</p>
                  <div class="tbls">
                    <table class="tbl_doc">
                      <thead class="border_black">
                        <tr>
                          <th colspan="3">연봉계약금액 및 내역</th>
                        </tr>
                      </thead>
                      <colgroup>
                        <col width="150px" />
                        <col width="195px" />
                      </colgroup>
                      <tbody class="border_black">
                        <tr class="bg_yellow" style="font-weight: 500">
                          <td style="text-align: left">연봉계약금액</td>
                          <td>₩{{ fnFormatValue(detailData.i10) }}</td>
                          <td style="text-align: left">월급여 * 12개월분을 합산한 금액</td>
                        </tr>
                        <!-- <tr>
                          <td style="text-align: left">최저시급</td>
                          <td>₩{{ detailData.i11 }}</td>
                          <td style="text-align: left">
                            기본급, (상여금, 복리후생 등) 최저임금에 산입하는<br />
                            임금의 범위 합산액을 상기 기본급 산정시간으로 나눈 금액
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left">통상시급</td>
                          <td>₩{{ detailData.i12 }}</td>
                          <td style="text-align: left">
                            기본급, 연구개발수당, 식대 합산액을<br />
                            상기 기본급 산정시간으로 나눈 금액
                          </td>
                        </tr> -->
                        <tr>
                          <td style="text-align: left">연장근무시간</td>
                          <td>{{ fnFormatValue(detailData.i13) }}시간</td>
                          <td style="text-align: left">월간 연장근무에 대한 시간</td>
                        </tr>
                        <tr>
                          <td style="text-align: left">휴일근무시간</td>
                          <td>{{ fnFormatValue(detailData.i14) }}시간</td>
                          <td style="text-align: left">월간 휴일근무에 대한 시간</td>
                        </tr>
                        <!-- <tr>
                          <td style="text-align: left">휴일연장근무시간</td>
                          <td>{{ detailData.i15 }}시간</td>
                          <td style="text-align: left">월간 휴일연장근무에 대한 시간</td>
                        </tr> -->
                        <tr>
                          <td style="text-align: left">당직근무시간</td>
                          <td>{{ fnFormatValue(detailData.i14_1) }}시간</td>
                          <td style="text-align: left">월간 당직근무에 대한 시간</td>
                        </tr>
                        <tr>
                          <td style="text-align: left">야간근무시간</td>
                          <td>{{ fnFormatValue(detailData.i16) }}시간</td>
                          <td style="text-align: left">월간 야간근무에 대한 시간</td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="tbl_doc">
                      <colgroup>
                        <col width="90px" />
                        <col width="118px" />
                        <col width="118px" />
                      </colgroup>
                      <tbody class="border_black">
                        <tr class="border_black">
                          <td style="text-align: left">기본급</td>
                          <td colspan="2">₩{{ fnFormatValue(detailData.i17) }}</td>
                          <td style="text-align: left">기본급에는 상기 3항 가호의 기본급산정시간에 의한 금액을 지급</td>
                        </tr>
                        <tr>
                          <td rowspan="14"></td>
                          <td style="text-align: left">연장근무수당</td>
                          <td>₩{{ fnFormatValue(detailData.i18) }}</td>
                          <td style="text-align: left">연장근무시간 * 통상시급의 1.5배 지급</td>
                        </tr>
                        <tr>
                          <td style="text-align: left">휴일근무수당</td>
                          <td>₩{{ fnFormatValue(detailData.i19) }}</td>
                          <td style="text-align: left">휴일근무시간 * 통상시급의 1.5배 지급</td>
                        </tr>
                        <!-- <tr>
                          <td style="text-align: left">휴일연장근무수당</td>
                          <td>₩{{ detailData.i20 }}</td>
                          <td style="text-align: left">휴일연장근무시간 * 통상시급의 2.0배 지급</td>
                        </tr> -->
                        <tr>
                          <td style="text-align: left">당직근무수당</td>
                          <td>₩{{ fnFormatValue(detailData.i19_1) }}</td>
                          <td style="text-align: left">당직근무시간 * 통상시간급의 1.5배 지급 (비과세항목)</td>
                        </tr>
                        <tr>
                          <td style="text-align: left">야간근무수당</td>
                          <td>₩{{ fnFormatValue(detailData.i21) }}</td>
                          <td style="text-align: left">야간근무시간 * 통상시급의 0.5배 지급</td>
                        </tr>
                        <tr style="height: 36.5px" v-if="detailData.i16_1">
                          <td style="text-align: left">{{ detailData.i16_1 }}</td>
                          <td>₩{{ fnFormatValue(detailData.i16_2) }}</td>
                          <td style="text-align: left"></td>
                        </tr>
                        <tr style="height: 36.5px" v-if="detailData.i16_3">
                          <td style="text-align: left">{{ detailData.i16_3 }}</td>
                          <td>₩{{ fnFormatValue(detailData.i16_4) }}</td>
                          <td style="text-align: left"></td>
                        </tr>
                        <tr style="height: 36.5px" v-if="detailData.i16_5">
                          <td style="text-align: left">{{ detailData.i16_5 }}</td>
                          <td>₩{{ fnFormatValue(detailData.i16_6) }}</td>
                          <td style="text-align: left"></td>
                        </tr>
                        <tr style="height: 36.5px" v-if="detailData.i16_7">
                          <td style="text-align: left">{{ detailData.i16_7 }}</td>
                          <td>₩{{ fnFormatValue(detailData.i16_8) }}</td>
                          <td style="text-align: left"></td>
                        </tr>
                        <tr style="height: 36.5px" v-if="detailData.i16_9">
                          <td style="text-align: left">{{ detailData.i16_9 }}</td>
                          <td>₩{{ fnFormatValue(detailData.i16_10) }}</td>
                          <td style="text-align: left"></td>
                        </tr>
                        <tr>
                          <td style="text-align: left">보육수당</td>
                          <td>₩{{ fnFormatValue(detailData.i22) }}</td>
                          <td style="text-align: left">6세미만 유아 보육자에 한하여 10만원 지급 (비과세항목)</td>
                        </tr>
                        <tr>
                          <td style="text-align: left">연구개발수당</td>
                          <td>₩{{ fnFormatValue(detailData.i23) }}</td>
                          <td style="text-align: left">연구직으로 등록된 자에 한하여 20만원 지급 (비과세항목)</td>
                        </tr>
                        <tr>
                          <td style="text-align: left">식대</td>
                          <td>₩{{ fnFormatValue(detailData.i24) }}</td>
                          <td style="text-align: left">복리후생비로 10만원까지 지급 (비과세항목)</td>
                        </tr>
                        <tr>
                          <td style="text-align: left">차량유지비</td>
                          <td>₩{{ fnFormatValue(detailData.i25) }}</td>
                          <td style="text-align: left">본인의 차량으로 업무용으로 사용하는 자에게 지급 (비과세항목)</td>
                        </tr>
                        <tr class="bg_yellow" style="font-weight: 500">
                          <td style="text-align: left">월급여 총계</td>
                          <td>₩{{ fnFormatNumber(fnFormatValue(detailData.i26)) }}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <pre v-if="disableEdit" style="padding-left: 0; text-indent: 0; font-family: Pretendard" v-html="fnChangeNewLine(detailData.i27)"></pre>
                  <textarea v-else class="mt5" v-model="detailData.i27" cols="103" rows="5" style="resize: none"></textarea>
                </div>
              </div>
              <div class="d_content">
                <h3>5.&nbsp;&nbsp; 근로일 및 휴일</h3>
                <div class="d_unit">
                  <p v-if="disableEdit" style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i28)"></p>
                  <textarea v-else v-model="detailData.i28" cols="103" rows="5" style="resize: none"></textarea>
                </div>
              </div>
              <div class="d_content">
                <h3>6.&nbsp;&nbsp; 연차유급휴가</h3>
                <div class="d_unit">
                  <p v-if="disableEdit" style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i29)"></p>
                  <textarea v-else v-model="detailData.i29" cols="103" rows="2" style="resize: none"></textarea>
                </div>
              </div>
              <div class="d_content">
                <h3>7.&nbsp;&nbsp; 생리휴가</h3>
                <div class="d_unit">
                  <p v-if="disableEdit" style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i29_1)"></p>
                  <textarea v-else v-model="detailData.i29_1" cols="103" rows="2" style="resize: none"></textarea>
                </div>
              </div>
              <div class="d_content">
                <h3>8.&nbsp;&nbsp; 근태사항</h3>
                <div class="d_unit">
                  <p v-if="disableEdit" style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i30)"></p>
                  <textarea v-else v-model="detailData.i30" cols="103" rows="3" style="resize: none"></textarea>
                </div>
              </div>
              <div class="d_content">
                <h3>9.&nbsp;&nbsp; 시용 수습근로자</h3>
                <div class="d_unit">
                  <p v-if="disableEdit" style="padding-left: 0; text-indent: 0; white-space: pre-wrap;" v-html="fnChangeNewLine(detailData.i31)">
                  </p>
                  <textarea v-else v-model="detailData.i31" cols="103" rows="3" style="resize: none"></textarea>
                </div>
              </div>
              <div class="d_content">
                <h3>10. 퇴직금</h3>
                <div class="d_unit">
                  <p v-if="disableEdit" style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i32)"></p>
                  <textarea v-else v-model="detailData.i32" cols="103" rows="2" style="resize: none"></textarea>
                </div>
              </div>
              <div class="d_content">
                <h3>11.&nbsp; 퇴직절차</h3>
                <div class="d_unit">
                  <p v-if="disableEdit" style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i33)"></p>
                  <textarea v-else v-model="detailData.i33" cols="103" rows="2" style="resize: none"></textarea>
                </div>
              </div>
              <div class="d_content">
                <h3>12. 비밀유지 및 계약내용 중도변경</h3>
                <div class="d_unit">
                  <p v-if="disableEdit" style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i34)"></p>
                  <textarea v-else v-model="detailData.i34" cols="103" rows="3" style="resize: none"></textarea>
                </div>
              </div>
              <div class="d_bottom" style="margin-top: 0">
                <div class="sign" style="margin-top: 0">&nbsp;<span>(인)</span></div>
              </div>
              <!-- <div class="d_content">
                <h3>13. 계약갱신</h3>
                <div class="d_unit">
                  <p>가. 차기 연봉계약은 인사위원회가 평정한 인사고과에 의하여 계약을 갱신한다.</p>
                  <p>
                    나. 근로계약 기간 만료일까지 새로운 근로계약이 체결되지 아니한 경우에는 당사자간 근로계약은 합의 연장된<br />
                    것으로 본다.
                  </p>
                </div>
              </div> -->
              <div class="d_content">
                <h3>13. 계약의 해지</h3>
                <div class="d_unit">
                  <p v-if="disableEdit" style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i35)"></p>
                  <textarea v-else v-model="detailData.i35" cols="103" rows="2" style="resize: none"></textarea>
                </div>
              </div>
              <div class="d_content">
                <h3>14. 공제 동의</h3>
                <div class="d_unit">
                  <p v-if="disableEdit" style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i36)"></p>
                  <textarea v-else v-model="detailData.i36" cols="103" rows="3" style="resize: none"></textarea>
                </div>
              </div>
              <div class="d_bottom" style="margin-top: 0; margin-bottom: 10px">
                <div class="sign" style="margin-top: 0">&nbsp;<span>(인)</span></div>
              </div>
              <div class="d_content">
                <h3>15. 이 계약서에서 정하지 아니한 사항은 근로기준법 등 노동관계법령, 취업규칙에서 정하는 바에 따른다.</h3>
              </div>
              <div class="d_bottom">
                <p>
                  <span v-if="disableEdit"><pre style="font-family: Pretendard">{{ detailData.i37 }}년{{ detailData.i38 }}월{{ detailData.i39 }}일</pre></span>
                  <span v-else>
                    <Input name="i37" :value.sync="detailData.i37" :styleAttr="{ width: '80px', height: '25px', display: 'inline-block' }" />년
                    <Input name="i38" :value.sync="detailData.i38" :styleAttr="{ width: '60px', height: '25px', display: 'inline-block' }" />월
                    <Input name="i39" :value.sync="detailData.i39" :styleAttr="{ width: '60px', height: '25px', display: 'inline-block' }" />일
                  </span>
                </p>
                <div class="d_unit">
                  <dl style="line-height: 25px">
                    <dt>사업주</dt>
                    <dt>주소</dt>
                    <dd>
                      <span v-if="disableEdit">{{ detailData.i40 }}</span>
                      <Input v-else class="" name="i40" :value.sync="detailData.i40" :styleAttr="{ width: '250px', height: '25px' }" />
                    </dd>
                  </dl>
                  <dl style="line-height: 25px">
                    <dt></dt>
                    <dt>회사명</dt>
                    <dd>
                      <span v-if="disableEdit">{{ detailData.i41 }}</span>
                      <Input v-else class="" name="i41" :value.sync="detailData.i41" :styleAttr="{ width: '250px', height: '25px' }" />
                    </dd>
                  </dl>
                  <dl style="line-height: 25px">
                    <dt></dt>
                    <dt>대표자</dt>
                    <dd v-if="disableEdit" style="position: relative">
                      <span>{{ detailData.i42 }}</span>
                      <span style="position: absolute; left: 120px">(인)</span>
                    </dd>
                    <dd v-else>
                      <Input class="" name="i42" :value.sync="detailData.i42" :styleAttr="{ width: '250px', height: '25px', display: 'inline-block' }" />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(인)
                    </dd>
                  </dl>
                </div>
                <div class="d_unit">
                  <dl style="line-height: 25px">
                    <dt>근로자</dt>
                    <dt>주소</dt>
                    <dd></dd>
                  </dl>
                  <dl style="line-height: 25px">
                    <dt></dt>
                    <dt style="width: 90px">주민등록번호</dt>
                    <dd></dd>
                  </dl>
                  <dl style="line-height: 25px">
                    <dt></dt>
                    <dt>성명</dt>
                    <dd style="position: relative">
                      <span style="position: absolute; left: 120px">(인)</span>
                    </dd>
                  </dl>
                </div>
                <h3>{{ detailData.i1 }}</h3>
                <div class="sign">근로기준법 제17조 2항에 따라 근로계약서를 교부 받았음을 확인합니다.<span>(인)</span></div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </section>
  </div>
</template>

<script>
import Select from '../../common/Select.vue';
import laborService from '~/service/allOkMng/laborService';
import customersService from '~/service/allOk/customersService';
import Loading from '../../common/Loading.vue';
import Input from '../../common/Input.vue';
export default {
  props: ['id', 'compNm', 'bizNo'],
  components: {
    Select,
    Loading,
    Input,
  },
  data() {
    return {
      compInfo: {},
      empList: [],
      contractList: [],
      empNo: null,
      contractId: null,
      detailData: {},
      empDetail: null,
      disableEdit: true,
      disableExcelDowload: true,
      disablePdfDowload: true,
      loading: false,
    };
  },

  methods: {
    fnSetEmpList() {
      laborService.getLaborContractEmp(this.$axios, this.id).then(({ result, data }) => {
        if (result) {
          this.empList = data.contents.map(el => ({
            name: el.name,
            value: el.empNo,
            laborFileId: el.laborFileId,
          }));
        } else {
          console.error(data.error.msg);
        }
      });
    },
    fnSelectEmp(payload) {
      this.empNo = payload.value;
      this.laborFileId = payload.laborFileId;
      this.fnSetContractList('new');
    },
    async fnSetContractList(selectType) {
      this.contractList = [];
      this.contractId = null;
      this.detailData = {};
      this.empDetail = null;

      //customAllowance 데이터가져오기
      const { result, data } = await laborService.getLaborFile(this.$axios, this.id, this.laborFileId)
      if(!result){
        this._alert('알림', err);
        return;
      }
      const customAllowance = this._parseCustomAllowanceName(data.contents.uploadData)

      //empDetail정보가져온후 계약서 리스트 생성
      laborService
        .getLaborFileEmpDetail(this.$axios, this.id, this.laborFileId, this.empNo)
        .then(({ result, data }) => {
          if (result) {
            return data.contents;
          } else {
            return Promise.reject(data.error.msg);
          }
        })
        .then(empDetail => {
          this.empDetail = empDetail;
          if(!this.empDetail){
            return Promise.reject("해당 근로자 정보가 없습니다.");
          }
          return laborService.getLaborContract(this.$axios, this.id, 'R', this.empNo).then(({ result, data }) => {
            if (result) {
              const contractList = data.contents.map(el => ({
                name: el.contractNm,
                value: el.contractId,
                detailData: JSON.parse(el.detailData),
              }));

              const newContract = {
                name: '새로운 계약서',
                value: '새로운 계약서',
              };
              if (contractList.length > 0) {
                newContract.detailData = {
                  ...contractList[0].detailData, //마지막버전에서 입력한 데이터
                  i1: this.compNm, //회사명,
                  i2: this.empDetail.name, //이름,
                  i10: this.empDetail.annualContractAmount, //연봉계약금액
                  i11: this.empDetail.minimumHourlyPay, //최저시급
                  i12: this.empDetail.regularHourlyPay, //통상시급
                  i13: this.empDetail.extendedWorkHours, //연장근무시간
                  i14: this.empDetail.holidayWorkHours, //휴일근무시간
                  i14_1: this.empDetail.dutyHours, //당직근무시간 (2023/09/21 추가)
                  i15: this.empDetail.holidayOverWorkHours, //휴일연장근무시간
                  i16: this.empDetail.nightShiftHours, //야간근무시간
                  i16_1: customAllowance.name1, //수당1 이름 (2024/01/23 추가)
                  i16_2: this.empDetail.annualAllowance, //수당1 값 (2024/01/23 추가)
                  i16_3: customAllowance.name2, //수당2 이름 (2024/01/23 추가)
                  i16_4: this.empDetail.customAllowance2, //수당2 값 (2024/01/23 추가)
                  i16_5: customAllowance.name3, //수당3 이름 (2024/01/23 추가)
                  i16_6: this.empDetail.customAllowance3, //수당3 값 (2024/01/23 추가)
                  i16_7: customAllowance.name4, //수당4 이름 (2024/01/23 추가)
                  i16_8: this.empDetail.customAllowance4, //수당4 값 (2024/01/23 추가)
                  i16_9: customAllowance.name5, //수당5 이름(2024/01/23 추가)
                  i16_10: this.empDetail.customAllowance5, //수당5 값(2024/01/23 추가)
                  i17: this.empDetail.basicPay, //기본급
                  i18: this.empDetail.extendedWorkPay, //연장근무수당
                  i19: this.empDetail.holidayWorkPay, //휴일근무수당
                  i19_1: this.empDetail.dutyPay, //당직근무수당 (2023/09/21 추가)
                  i20: this.empDetail.holidayOverWorkPay, //휴일연장근무수당
                  i21: this.empDetail.nightShiftPay, //야간근무수당
                  i22: this.empDetail.childCareAllowance, //보육수당
                  i23: this.empDetail.rndAllowance, //연구개발수당
                  i24: this.empDetail.meals, //식대
                  i25: this.empDetail.carMaintenanceCost, //차량유지비
                  i26: this.empDetail.monthSalary, //월급여 총계
                };
              } else {
                newContract.detailData = {
                  i1: this.compNm, //회사명
                  i2: this.empDetail.name, //이름
                  i3: `${this.empDetail.empStartDt ? this.fnFormatDate(this.empDetail.empStartDt) : '년 월 일'} 부터 취업규칙에서 정한 정년까지`, //근로계약기간
                  i4: '년 월 일 부터 년 월 일 까지', //연봉계약기간
                  i5: this.compNm, //근무장소
                  i6: this.empDetail.dept, //부서
                  i7: this.empDetail.rank, //직급
                  i8: this.empDetail.basicWorkHoursDesc, //기본근로시간
                  i8_1: this.empDetail.basicPayTimeDesc,//기본급산정시간 (2024/01/30 추가)
                  i9: this.empDetail.restTime, //휴게시간
                  i9_1: "근로시간과 휴식시간은 업무의 특성에 따라 변경될 수 있다.",//근로시간(근로형태) 및 휴게시간 -> 다 (2024/01/30 추가)
                  i9_2: "임금 조항의 연장, 휴일근무시간을 초과하여 근무하고자 할 경우에는 사전에 연장근로에 대한 업무내용, 시간이 명시 된 신청서를 제출하고 승인을 받아야하며, 승인을 받지 않은 연장, 휴일근무의 경우 별도의 수당을 지급하지 않는다.",//근로시간(근로형태) 및 휴게시간 -> 라 (2024/01/30 추가)
                  i10: this.empDetail.annualContractAmount, //연봉계약금액
                  i11: this.empDetail.minimumHourlyPay, //최저시급
                  i12: this.empDetail.regularHourlyPay, //통상시급
                  i13: this.empDetail.extendedWorkHours, //연장근무시간
                  i14: this.empDetail.holidayWorkHours, //휴일근무시간
                  i14_1: this.empDetail.dutyHours, //당직근무시간 (2023/09/21 추가)
                  i15: this.empDetail.holidayOverWorkHours, //휴일연장근무시간
                  i16: this.empDetail.nightShiftHours, //야간근무시간
                  i16_1: customAllowance.name1 == undefined ? null : customAllowance.name1, //수당1 이름 (2024/01/23 추가)
                  i16_2: this.empDetail.annualAllowance, //수당1 값 (2024/01/23 추가)
                  i16_3: customAllowance.name2  == undefined ? null : customAllowance.name2, //수당2 이름 (2024/01/23 추가)
                  i16_4: this.empDetail.customAllowance2, //수당2 값 (2024/01/23 추가)
                  i16_5: customAllowance.name3  == undefined ? null : customAllowance.name3, //수당3 이름 (2024/01/23 추가)
                  i16_6: this.empDetail.customAllowance3, //수당3 값 (2024/01/23 추가)
                  i16_7: customAllowance.name4  == undefined ? null : customAllowance.name4, //수당4 이름 (2024/01/23 추가)
                  i16_8: this.empDetail.customAllowance4, //수당4 값 (2024/01/23 추가)
                  i16_9: customAllowance.name5  == undefined ? null : customAllowance.name5, //수당5 이름(2024/01/23 추가)
                  i16_10: this.empDetail.customAllowance5, //수당5 값(2024/01/23 추가)
                  i17: this.empDetail.basicPay, //기본급
                  i18: this.empDetail.extendedWorkPay, //연장근무수당
                  i19: this.empDetail.holidayWorkPay, //휴일근무수당
                  i19_1: this.empDetail.dutyPay, //당직근무수당 (2023/09/21 추가)
                  i20: this.empDetail.holidayOverWorkPay, //휴일연장근무수당
                  i21: this.empDetail.nightShiftPay, //야간근무수당
                  i22: this.empDetail.childCareAllowance, //보육수당
                  i23: this.empDetail.rndAllowance, //연구개발수당
                  i24: this.empDetail.meals, //식대
                  i25: this.empDetail.carMaintenanceCost, //차량유지비
                  i26: this.empDetail.monthSalary, //월급여 총계
                  i27: '나. 일숙직(당직근무수당)의 경우 평일 또는 휴일날 본연의 업무를 수행하면서 회사 시설정비 및 보안, 물품보안, 작업마무리, 동료 결근에 의한 대체업무, 기타 부대업무, 부수업무를 병행하는 경우에 대하여 아래 다. 항의 기준에 의해 매월 지급하는 것으로 한다.\n다. 일숙직(당직근무수당)은 해당시간에 통상시급의 1.5배를 지급하는 것으로 한다.\n라. 임금은 당월 초일부터 당월 말일까지를 정산하여 (   )월 (   )일에 직접 지급(현금 또는 근로자 명의의 예금계좌)한다.  각종 제세공과금은 본인부담으로 한다.', //임금
                  i28: '가. 근로일 : 주 40시간을 기준으로 주 5일 근무를 원칙으로 한다.\n나. 휴일\n① 주휴일 : 일주간의 소정근로시간을 개근한 근로자에게 1일의 유급휴일(일요일)을 부여한다.  토요일은 무급휴무일로 한다.\n② 근로자의 날(5월 1일)\n③ 「관공서의 공휴일에 관한 규정」에 따른 공휴일 및 대체공휴일', //근로일 및 휴일
                  i29: '연차유급휴가에 관련된 내용은 취업규칙 및 근로기준법에 의한다.\n연차휴가는 근로기준법의 규정에 따라 발생하기로 하며, 하계휴가와 근로자대표의 동의를 얻은 날에 대해서는연차휴가로 갈음하기로 한다.', //연차유급휴가
                  i29_1: '여성근로자에 한하여 사용할 수 있으며, 매월 1일 본인이 원할 경우 무급휴가를 부여한다.',//생리휴가 (2024/01/30 추가)
                  i30: '가. 지각, 조퇴시 해당급여를 삭감하며, 5회 이상시 징계위원회를 통하여 근로계약을 해지할 수 있다.\n나. 무단 결근시 해당급여를 삭감하며, 징계위원회를 통하여 근로계약을 해지 할 수 있다. 또한 3회 이상시 즉시 근로계약을 해지할 수 있다.', //근태사항
                  i31: '가. 입사일로부터 3개월까지는 수습기간으로 하며 동 기간 동안은 월간 급여액의 ( 90 ) % 를 지급하되, 최소 최저임금의 90% 이상을 지급한다.\n나. 수습기간 중 근로자의 업무능력이 현저히 부족하거나 근무태도 등이 불량하여 정식직원으로 채용하기가 부적격하다고 판단되는 경우 사업주는 근로자의 정식채용을 거부할 수 있다.', //시용수습근로자
                  i32: '퇴직금은 1년 이상 계속 근무한 경우 지급하며, 중간정산은 관련법령의 요건에 적합할 경우 인정한다.', //퇴직금
                  i33: '퇴직효력발생일 최소( 30 )일전에 회사에 통보하여 업무의 인수인계에 지장이 없도록 한다. 이를 위반함으로 인해 회사에 손실이 발생한 경우 손해배상의 책임은 근로자가 진다.',//퇴직절차 (2024/01/30 추가)
                  i34: '가. 근로자와 사업주는 합의에 의해 책정된 월급금액을 제3자(회사 직원을 포함한다)에게 공표하여서는 아니되며 근로자이외에 소속 직원의 월급금액에 대하여도 알려고 하지 아니한다.\n나. 근로자는 제1항의 서약에 위배되는 행위로 인하여 발생하는 불이익에 대해서 절대로 이의를 제기하지 아니할 것을 확인한다.',//비밀유지 및 계약내용 중도변경 (2024/01/30 추가)
                  i35: '취업규칙 상의 계약기간의 만료, 채용결격사유, 해고사유 등 계약종료사유에 해당하는 경우 근로계약을 해지한다',//계약의 해지 (2024/01/30 추가)
                  i36: '가. 회사와 근로자간의 발생하는 4대 보험 및 소득세, 경비, 사우회비 등 모든 금액에 대해 매월 일괄 정산함에 동의한다.\n나. 퇴직 정산 시 초과 사용한 연차휴가가 발생할 경우 퇴직금, 퇴사 월 임금 등 일체의 금품에서 공제하는 것에 동의한다.\n다. 퇴사 시에는 임금과 함께 모든 금액의 일괄지급 및 일괄공제 함에 동의한다.',//공제 동의 (2024/01/30 추가)
                  i37: '',//년
                  i38: '',//월
                  i39: '',//일
                  i40: this.compInfo.noltKoraddr == undefined ? null : this.compInfo.noltKoraddr,//사업주 주소
                  i41: this.compNm,//사업주 회사명
                  i42: this.compInfo.korreprnm == undefined ? null : this.compInfo.korreprnm,//사업주 대표자
                };
              }
              this.contractList = [newContract, ...contractList];

              if (selectType == 'new') {
                this.fnSelectContract(this.contractList[0]);
              } else {
                this.fnSelectContract(this.contractList[1]);
              }
            } else {
              return Promise.reject(data.error.msg);
            }
          });
        })
        .catch(err => {
          console.error(err);
          this._alert('알림', err);
        });
    },
    fnSelectContract(payload) {
      this.disableExcelDowload = payload.value == '새로운 계약서' ? true : false;
      this.disablePdfDowload = payload.value == '새로운 계약서' ? true : false;
      this.disableEdit = payload.value == '새로운 계약서' ? false : true;

      this.contractId = payload.value;
      this.detailData = JSON.parse(JSON.stringify(payload.detailData));
    },
    fnSave() {
      const btn = {
        name: '확인',
        func: () => {
          this._closePop();
          const payload = {
            customerId: this.id,
            empNo: this.empNo,
            contractType: 'R',
            detailData: JSON.stringify(this.detailData),
            regId: this.$store.state.authUser.username,
          };
          laborService.addLaborContract(this.$axios, this.id, payload).then(({ result, data }) => {
            if (result) {
              this._alert('알림', '저장이 완료되었습니다');
              this.fnSetContractList();
            } else {
              this._alert('알림', data.error.msg);
            }
          });
        },
      };
      this._confirm('확인', `계약서를 저장하시겠습니까?`, btn);
    },
    fnClickExcelDownload() {
      location.href = `/apiMngAo/customer/service/labor/${this.id}/contract/${this.contractId}/excel`;
      this.disableExcelDowload = true;
      setTimeout(() => {
        this.disableExcelDowload = false;
      }, 5000);
    },
    fnClickPdfDownload() {
      location.href = `/apiMngAo/customer/service/labor/${this.id}/contract/${this.contractId}/pdf`;
      this.disablePdfDowload = true;
      setTimeout(() => {
        this.disablePdfDowload = false;
      }, 5000);
    },
    _alert(title, msg) {
      let data = {
        state: true,
        type: 'alert',
        title: title,
        msg: msg,
        btn: {
          name: '확인',
        },
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _confirm(title, msg, btn) {
      let data = {
        state: true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closePop() {
      this.$store.dispatch('ui/setPopAlertData', {
        state: false,
        type: null,
        title: null,
        msg: null,
        btn: {
          link: '',
          name: null,
        },
      });
    },
    _parseCustomAllowanceName(uploadData) {
      const data = JSON.parse(uploadData);
      return {
        name1: data.Title?.AB,
        name2: data.Title?.AC,
        name3: data.Title?.AD,
        name4: data.Title?.AE,
        name5: data.Title?.AF,
        name6: data.Title?.AG,
        name7: data.Title?.AH,
        name8: data.Title?.AI,
        name9: data.Title?.AJ,
      };
    },
    fnChangeNewLine(str) {
      return str && str.replace(/\n/g, '<br/>');
    },
    fnFormatValue(value) {
      return value === null || value === '-' ? '0' : value;
    },
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fnFormatDate(yyyymmdd){
      return `${yyyymmdd.substring(0, 4)}년 ${yyyymmdd.substring(4, 6)}월 ${yyyymmdd.substring(6, 8)}일`
    }
  },

  mounted() {
    customersService.getCompanyInfo(this.bizNo, this.$axios).then(({result, data}) => {
      if(result) this.compInfo = data.contents
    })
    this.fnSetEmpList();
  },
};
</script>

<style scoped>
.contract .d_content .d_unit p {
  padding-left: 0;
  text-indent: 0;
}
</style>
