<template>
  <section class="container">
    <!-- <Location /> -->
    <div class="inner">
      <div class="colr">
        <button class="btn_reset" @click="fnResetForm">전체초기화</button>
      </div>
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="bizNo">사업자번호</label>
          <Input id="bizNo" name="query.bizNo" shape="search" placeholder="검색어를 입력하세요." :value.sync="query.bizNo" @enter="fnResetPageAndFetch" />
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <div>
            <button class="btn" @click="fnOpenPop('popCsState')">열기</button>
            <button class="btn" @click="fnOpenPop2('popCs2State')">등록</button>
            <button class="btn" @click="fnReport">리포트</button>
          </div>
        </div>
        <div class="col unit_page">
          <h4 class="l_tit">페이지당 개수 :</h4>
          <Select id="perPage" title="20개" :itemList="perPageList" :value.sync="query.perPage" @change="fnFetchList" />
          <button
            class="btn"
            @click="
              () => {
                query.page = 1;
                fnFetchList();
              }
            ">
            검색
          </button>
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <colgroup>
              <col width="30%" />
              <col width="30%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th class="c2" style="width: 10%"><b>사업자번호</b></th>
                <th class="c2" style="width: 10%"><b>회사명</b></th>
                <th class="c4"><b>신청일</b></th>
                <th class="c4"><b>조회일</b></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in contents" :key="item.id" :class="{ on: item.bizNo === bizNo }" @click="fnOnClick(item)" @dblclick="fnOpenPop2('popCsState')">
                <td class="c2" style="width: 10%">{{ item.bizNo }}</td>
                <td class="c2" style="width: 10%">{{ JSON.parse(item.outlineData).회사명 }}</td>
                <td class="c4">{{ mixCut(item.regDtm) }}</td>
                <td class="c4">{{ mixCut(item.modDtm) }}</td>
              </tr>
              <tr v-if="contents && contents.length === 0">
                <td colspan="4">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="query.page"
          :perPage="query.perPage"
          :totalCount="pagination.totalCount"
          @change="
            p => {
              this.query.page = p;
              this.fnFetchList();
            }
          " />
      </div>
      <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
        <DiagDetail v-if="$store.state.ui.popCsState == true" :bizNo="bizNo" :key="$store.state.ui.popCsState" @reload="fnReload" />
      </transition>
      <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
        <DiagRegist v-if="$store.state.ui.popCs2State == true" :bizNo="bizNo" :key="$store.state.ui.popCs2State" @reload="fnReload" />
      </transition>
    </div>
  </section>
</template>

<script>
import diagnosisService from '~/service/allOkMng/diagnosisService.js';
import Header from '~/components/layouts/Header';
import Location from '~/components/layouts/LocationDataWrapper';
import CsPop from '~/components/popup/Cs.vue';
import TaxDetail from '~/components/pages/allOkMng/TaxDetail.vue';
import Input from '~/components/common/Input.vue';
import Pagination from '~/components/common/Pagination';
import Select from '~/components/common/Select';
import customersListMixin from '../../mixins/customersListMixin';
import DiagDetail from '../../components/pages/allOkMng/DiagDetail.vue';
import DiagRegist from '../../components/pages/allOkMng/DiagRegist.vue';

export default {
  mixins: [customersListMixin],
  components: {
    Header,
    Location,
    CsPop,
    TaxDetail,
    Input,
    Select,
    Pagination,
    DiagDetail,
    DiagRegist,
  },
  data() {
    return {
      // roles: ['CPMNG_TAX'],
      bizNo: null,
      compNm: null,
      query: {
        page: 1,
        perPage: 20, // 한 페이지에 보여줄 rows의 갯수
        bizNo: null,
      },
      contents: [],
      pagination: {
        totalCount: 0,
      },
      perPageList_: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
        { name: '30개', value: 30 },
      ],
    };
  },
  computed: {
    perPageList() {
      return this.perPageList_.map(item => {
        return {
          ...item,
          select: item.value === this.query.perPage ? true : false,
        };
      });
    },
  },
  methods: {
    fnResetForm() {
      const newQuery = {
        page: 1,
        bizNo: null,
      };
      this.query = {
        ...this.query,
        ...newQuery,
      };
      this.fnFetchList();
    },
    fnOnClick(e) {
      this.bizNo = e.bizNo;
      this.compNm = JSON.parse(e.outlineData).회사명
    },
    fnOpenPop(payload) {
      if (this.bizNo) {
        this.$store.dispatch('ui/setPopState', { key: payload, value: true });
      } else {
        this.$popupAlert({
          title: '경고',
          msg: '사업자를 선택해 주세요.',
        });
        return;
      }
    },
    fnOpenPop2(payload) {
      this.$store.dispatch('ui/setPopState', { key: payload, value: true });
    },
    fnResetPageAndFetch() {
      this.query.page = 1;
      this.fnFetchList();
    },
    fnFetchList() {
      diagnosisService.getDiagnosisBiz(this.$axios, this.query).then(res => {
        if (res.data.result === true) {
          const { contents, pagination } = res.data.data;
          this.contents = contents;
          this.pagination = pagination;
        }
      });
    },
    fnReload() {
      this.fnFetchList();
    },
    fnReport(){
      if (this.bizNo) {
        location.href=`/apiMngAo/diagnosis/${this.bizNo}/pdf?compNm=${this.compNm}`;
      } else {
        this.$popupAlert({
          title: '경고',
          msg: '사업자를 선택해 주세요.',
        });
        return;
      }
    }
  },
  mounted() {
    this.fnFetchList();
  },
};
</script>
