<template>
  <div class="c_contents contents_col2">
    <div class="left">
      <ul class="form_list type2">
        <li class="half">
          <h4>회사명</h4>
          <div class="i_wrap"><span>{{compNm}}</span></div>
        </li>
        <li class="half">
          <h4>대표자명</h4>
          <div class="i_wrap"><span>{{prstNm}}</span></div>
        </li>
        <li class="half">
          <h4>연락처</h4>
          <div class="i_wrap"><span>{{compTel}}</span></div>
        </li>
        <li class="half">
          <h4>설립일</h4>
          <div class="i_wrap"><span>{{fndDt}}</span></div>
        </li>
        <li class="half">
          <h4>업종</h4>
          <div class="i_wrap"><span>{{sector}}</span></div>
        </li>
        <li>
          <h4>소재지</h4>
          <div class="i_wrap"><span>{{korAddr}}</span></div>
        </li>
        <li class="item_radio">
          <h4>인증보유현황</h4>
          <div class="i_wrap">
            <RadioBox
              label='연구소'
              id='lab_yn_1'
              name='lab_yn'
              :value.sync="labYn"
              :defaultValue="1"
              disabled
            />
            <RadioBox
              label='연구전담부서'
              id='dept_yn_2'
              name='dept_yn'
              :value.sync="deptYn"
              :defaultValue="1"
              disabled
              />
            <RadioBox
              label='벤처인증'
              id='venture_yn_3'
              name='venture_yn'
              :value.sync="ventureYn"
              :defaultValue="1"
              disabled
            />
            <RadioBox
              label='이노비즈'
              id='innobiz_yn_4'
              name='innobiz_yn'
              :value.sync="innobizYn"
              :defaultValue="1"
              disabled
            />
            <RadioBox
              label='미보유'
              id='5'
              name='no'
              :value.sync="allYn"
              :defaultValue="1"
              disabled
            />
          </div>
        </li>
        <li class="item_skill">
          <h4>기술보유현황</h4>
          <div class="i_wrap">
            <div class="bx_line">
              <h5>보유특허수</h5>
              <strong class="txt_org">{{patnCnt}}개</strong>
            </div>
            <div class="bx_line">
              <h5>특허가치</h5>
              <strong class="txt_red">{{fnPoint(patnValue)}}</strong>
            </div>
            <div class="bx_line">
              <h5>기술등급</h5>
              <strong class="txt_red">{{techRate}}</strong>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';
import ItemPatent from '~/components/common/ItemPatent';
import Select from '~/components/common/Select';
import RadioBox from '~/components/common/RadioBox';
import SelectSearch from "~/components/common/SelectSearch";
import customersService from "~/service/allOk/customersService";

export default {
  name: 'CustomerTab1',
  data() {
    return {
      compNm: null,
      prstNm: null,
      compTel: null,
      fndDt: null,
      sector: null,
      korAddr: null,
      labYn: 0,
      deptYn: 0,
      ventureYn: 0,
      innobizYn: 0,
      allYn: 1,
      patnCnt: 0,
      patnValue: null,
      techRate: '-',
    };
  },
  props: ['id', 'bizNo'],
  components: {
    InputBox,ItemPatent,Select,RadioBox,SelectSearch,
  },
  async beforeMount() {
    // 나이스를 통한 기업 정보 조회
    const niceResult = await customersService.getCompanyInfo(this.bizNo, this.$axios)
    // 나이스 정보 조회 결과가 있다.
    if(niceResult.result) {
      // 데이터를 컨버팅 후 this.data에 셋팅한다.
      // 회사 연락처 정보는 사용하지 않는다.
      // 담당자 연락처 정보를 가져온다.
      const apiResult = await this.$axios.get(`/apiMngAo/landing/customers/${this.id}`)
      if(apiResult.data.result){
        niceResult.data.contents.tel = apiResult.data.data.contents.tel;
      }
      this.dataConvertFromNiceAndSet(niceResult.data.contents);
    }
    // 나이스 정보 조회 결과가 없는 경우
    // 데이터베이스에 있는 정보로 표출
    else if(this.id){
      const apiResult = await this.$axios.get(`/apiMngAo/landing/customers/${this.id}`)
      if(apiResult.data.result){
        this.dataConvertingFromApiAndSet(apiResult.data.data.contents);
      };
    }
  },
  methods: {
    dataConvertFromNiceAndSet(c){
      this.compNm=c.korentrnm;
      this.prstNm=c.korreprnm;
      this.compTel=c.tel;
      this.fndDt=c.etbDate;
      this.sector=c.korIdscdnm;
      this.korAddr=c.koraddr;
      this.labYn=c.labYN === 'N' ? 0 : 1;
      this.deptYn=c.deptYn === 'N' ? 0 : 1;
      this.ventureYn=c.ventureYn === 'N' ? 0 : 1;
      this.innobizYn=c.innobizYn === 'N' ? 0 : 1;
      this.allYn=(!this.labYn&&!this.innobizYn&&!this.deptYn&&!this.ventureYn)? 1 : 0
      if(c.companyValue){
        const cv = c.companyValue;
        this.patnCnt=cv.patentCount ? cv.patentCount : 0;
        this.patnValue=cv.technicalAsset;
        this.techRate=cv.tcg ? cv.tcg : '-';
      }
    },
    dataConvertingFromApiAndSet(c){
      this.compNm = c.compNm;
      this.prstNm =c.prstNm;
      this.compTel= c.tel;
      this.fndDt= c.fndDt;
      this.sector= c.sector;
      this.korAddr= c.korAddr
      // this.labYn=
      // this.deptYn=
      // this.ventureYn=
      // this.innobizYn=
      // this.allYn=
      // this.patnCnt=
      // this.patnValue=
      // this.techRate=
    },
    fnPoint(payload){
      if(payload)
        return payload.toFixed(2);
      return payload
    },
    fnClosePop() {
      this.$store.dispatch('ui/setPopCsState', false);
    },
  },
};
</script>
