<template>
  <div class="c_contents">
    <div class="colb mt10">
      <div></div>
      <div>
        <button class="btn" @click="fnSaveData">저장</button>
      </div>
    </div>
    <hr style="background: #dddddd; height: 1px; border: 0" />
    <div class="mt20">
      <div style="display: flex">
        <h2>1.요약 재무상태표</h2>
        <div class="ml10 pt10" style="font-weight: bold">(단위:백만원)</div>
      </div>
      <div class="tbl_doc_scroll full">
        <div class="t_inner">
          <div class="t_top">
            <div class="item">
              <div class="cel" style="width: 100px">구분</div>
              <div class="cel" style="width: 100px">
                <Select defaultName="연도" :itemList="yearList" :value="입력재무.year1" @change="fnSelectYear" />
              </div>
              <div class="cel" style="width: 100px">{{ 입력재무.year2 && `${입력재무.year2}년` }}</div>
              <div class="cel" style="width: 100px">{{ 입력재무.year3 && `${입력재무.year3}년` }}</div>
            </div>
          </div>
          <div class="t_cont">
            <div class="item">
              <div class="cel" style="width: 100px">유동자산</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.유동자산.year1" @input="입력재무.유동자산.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.유동자산.year2" @input="입력재무.유동자산.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.유동자산.year3" @input="입력재무.유동자산.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 600px">가지급금 추정액</div>
              <div class="cel" style="width: 150px">가지급 금액</div>
              <div class="cel" style="width: 300px">차입금액</div>
              <div class="cel" style="width: 150px">이자비용</div>
            </div>
            <div class="item">
              <div class="t_group">
                <div class="row">
                  <div class="cel" style="width: 100px">가지급금</div>
                  <div class="cel" style="width: 100px">
                    <input type="text" class="ipt_cel" v-model="입력재무.가지급금.year1" @input="입력재무.가지급금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                  </div>
                  <div class="cel" style="width: 100px">
                    <input type="text" class="ipt_cel" v-model="입력재무.가지급금.year2" @input="입력재무.가지급금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                  </div>
                  <div class="cel" style="width: 100px">
                    <input type="text" class="ipt_cel" v-model="입력재무.가지급금.year3" @input="입력재무.가지급금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                  </div>
                  <div class="cel" style="width: 300px">미수수익</div>
                  <div class="cel" style="width: 300px">이자수익</div>
                </div>
                <div class="row">
                  <div class="cel" style="width: 100px">주임종단기대여금</div>
                  <div class="cel" style="width: 100px">
                    <input type="text" class="ipt_cel" v-model="입력재무.주임종단기대여금.year1" @input="입력재무.주임종단기대여금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                  </div>
                  <div class="cel" style="width: 100px">
                    <input type="text" class="ipt_cel" v-model="입력재무.주임종단기대여금.year2" @input="입력재무.주임종단기대여금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                  </div>
                  <div class="cel" style="width: 100px">
                    <input type="text" class="ipt_cel" v-model="입력재무.주임종단기대여금.year3" @input="입력재무.주임종단기대여금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                  </div>
                  <div class="cel" style="width: 100px">{{ 입력재무.year1 && `${입력재무.year1}년` }}</div>
                  <div class="cel" style="width: 100px">{{ 입력재무.year2 && `${입력재무.year2}년` }}</div>
                  <div class="cel" style="width: 100px">{{ 입력재무.year3 && `${입력재무.year3}년` }}</div>
                  <div class="cel" style="width: 100px">{{ 입력재무.year1 && `${입력재무.year1}년` }}</div>
                  <div class="cel" style="width: 100px">{{ 입력재무.year2 && `${입력재무.year2}년` }}</div>
                  <div class="cel" style="width: 100px">{{ 입력재무.year3 && `${입력재무.year3}년` }}</div>
                </div>
              </div>
              <div class="cel" style="width: 150px"></div>
              <div class="t_group">
                <div class="row">
                  <div class="cel" style="width: 100px">단기차입금</div>
                  <div class="cel" style="width: 200px">{{ 입력재무.차입금액.단기차입금 && fnFormatNumber(Number(입력재무.차입금액.단기차입금).toFixed(0)) }}</div>
                </div>
                <div class="row">
                  <div class="cel" style="width: 100px">장기차입금</div>
                  <div class="cel" style="width: 200px">{{ 입력재무.차입금액.장기차입금 && fnFormatNumber(Number(입력재무.차입금액.장기차입금).toFixed(0)) }}</div>
                </div>
              </div>
              <div class="cel" style="width: 150px"></div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">미수수익</div>
              <div class="cel" style="width: 100px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력재무.미수수익.year1"
                  @input="입력재무.미수수익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn가지급금추정액_미수수익계산('year1')" />
              </div>
              <div class="cel" style="width: 100px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력재무.미수수익.year2"
                  @input="입력재무.미수수익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn가지급금추정액_미수수익계산('year2')" />
              </div>
              <div class="cel" style="width: 100px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력재무.미수수익.year3"
                  @input="입력재무.미수수익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn가지급금추정액_미수수익계산('year3')" />
              </div>
              <div class="cel" style="width: 100px">{{ 입력재무.가지급금추정액.미수수익.year1 && fnFormatNumber(Number(입력재무.가지급금추정액.미수수익.year1).toFixed(0)) }}</div>
              <div class="cel" style="width: 100px">{{ 입력재무.가지급금추정액.미수수익.year2 && fnFormatNumber(Number(입력재무.가지급금추정액.미수수익.year2).toFixed(0)) }}</div>
              <div class="cel" style="width: 100px">{{ 입력재무.가지급금추정액.미수수익.year3 && fnFormatNumber(Number(입력재무.가지급금추정액.미수수익.year3).toFixed(0)) }}</div>
              <div class="cel" style="width: 100px">{{ 입력재무.가지급금추정액.이자수익.year1 && fnFormatNumber(Number(입력재무.가지급금추정액.이자수익.year1).toFixed(0)) }}</div>
              <div class="cel border-bottom" style="width: 100px">{{ 입력재무.가지급금추정액.이자수익.year2 && fnFormatNumber(Number(입력재무.가지급금추정액.이자수익.year2).toFixed(0)) }}</div>
              <div class="cel border-bottom" style="width: 100px">{{ 입력재무.가지급금추정액.이자수익.year3 && fnFormatNumber(Number(입력재무.가지급금추정액.이자수익.year3).toFixed(0)) }}</div>
              <div class="cel border-bottom" style="width: 150px">
                <input type="text" class="ipt_cel" v-model="입력재무.가지급금액" @input="입력재무.가지급금액 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel border-bottom" style="width: 100px">합계</div>
              <div class="cel border-bottom" style="width: 200px">{{ 입력재무.차입금액.합계 && fnFormatNumber(Number(입력재무.차입금액.합계).toFixed(0)) }}</div>
              <div class="cel border-bottom" style="width: 150px">{{ 입력재무.이자비용 && fnFormatNumber(Number(입력재무.이자비용).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">비유동자산</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.비유동자산.year1" @input="입력재무.비유동자산.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.비유동자산.year2" @input="입력재무.비유동자산.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.비유동자산.year3" @input="입력재무.비유동자산.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">토지</div>
              <div class="cel" style="width: 100px">건물</div>
              <div class="cel" style="width: 100px">구축물</div>
              <div class="cel" style="width: 100px">부동산합계</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">부동산 자산</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.부동산자산.year1" @input="입력재무.부동산자산.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.부동산자산.year2" @input="입력재무.부동산자산.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.부동산자산.year3" @input="입력재무.부동산자산.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel border-bottom" style="width: 100px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력재무.가지급금추정액.토지"
                  @input="입력재무.가지급금추정액.토지 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn가지급금추정액_부동산합계계산()" />
              </div>
              <div class="cel border-bottom" style="width: 100px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력재무.가지급금추정액.건물"
                  @input="입력재무.가지급금추정액.건물 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn가지급금추정액_부동산합계계산()" />
              </div>
              <div class="cel border-bottom" style="width: 100px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력재무.가지급금추정액.구축물"
                  @input="입력재무.가지급금추정액.구축물 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn가지급금추정액_부동산합계계산()" />
              </div>
              <div class="cel border-bottom" style="width: 100px">{{ 입력재무.가지급금추정액.부동산합계 && fnFormatNumber(Number(입력재무.가지급금추정액.부동산합계).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">자산총계</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자산총계.year1" @input="입력재무.자산총계.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자산총계.year2" @input="입력재무.자산총계.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자산총계.year3" @input="입력재무.자산총계.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">유동부채</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.유동부채.year1" @input="입력재무.유동부채.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.유동부채.year2" @input="입력재무.유동부채.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.유동부채.year3" @input="입력재무.유동부채.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">단기차입금</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.단기차입금.year1" @input="입력재무.단기차입금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.단기차입금.year2" @input="입력재무.단기차입금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력재무.단기차입금.year3"
                  @input="입력재무.단기차입금.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn차입금액_단기차입금계산()" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">가수금</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.가수금.year1" @input="입력재무.가수금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.가수금.year2" @input="입력재무.가수금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.가수금.year3" @input="입력재무.가수금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">비유동부채</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.비유동부채.year1" @input="입력재무.비유동부채.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.비유동부채.year2" @input="입력재무.비유동부채.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.비유동부채.year3" @input="입력재무.비유동부채.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">장기차입금</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.장기차입금.year1" @input="입력재무.장기차입금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.장기차입금.year2" @input="입력재무.장기차입금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력재무.장기차입금.year3"
                  @input="입력재무.장기차입금.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn차입금액_장기차입금계산()" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">퇴직급여충당부채</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.퇴직급여충당부채.year1" @input="입력재무.퇴직급여충당부채.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.퇴직급여충당부채.year2" @input="입력재무.퇴직급여충당부채.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.퇴직급여충당부채.year3" @input="입력재무.퇴직급여충당부채.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">퇴직연금운용자산</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.퇴직연금운용자산.year1" @input="입력재무.퇴직연금운용자산.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.퇴직연금운용자산.year2" @input="입력재무.퇴직연금운용자산.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.퇴직연금운용자산.year3" @input="입력재무.퇴직연금운용자산.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">부채총계</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.부채총계.year1" @input="입력재무.부채총계.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.부채총계.year2" @input="입력재무.부채총계.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.부채총계.year3" @input="입력재무.부채총계.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">자본금</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자본금.year1" @input="입력재무.자본금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자본금.year2" @input="입력재무.자본금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자본금.year3" @input="입력재무.자본금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">자본조정</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자본조정.year1" @input="입력재무.자본조정.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자본조정.year2" @input="입력재무.자본조정.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자본조정.year3" @input="입력재무.자본조정.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">이익준비금</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.이익준비금.year1" @input="입력재무.이익준비금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.이익준비금.year2" @input="입력재무.이익준비금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.이익준비금.year3" @input="입력재무.이익준비금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 100px">미처분이익잉여금</div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.미처분이익잉여금.year1" @input="입력재무.미처분이익잉여금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.미처분이익잉여금.year2" @input="입력재무.미처분이익잉여금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.미처분이익잉여금.year3" @input="입력재무.미처분이익잉여금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel border-bottom" style="width: 100px">자본총계</div>
              <div class="cel border-bottom" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자본총계.year1" @input="입력재무.자본총계.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel border-bottom" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자본총계.year2" @input="입력재무.자본총계.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
              <div class="cel border-bottom" style="width: 100px">
                <input type="text" class="ipt_cel" v-model="입력재무.자본총계.year3" @input="입력재무.자본총계.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt20 pb30">
      <div style="display: flex">
        <h2>2.요약 손익계산서</h2>
        <div class="ml10 pt10" style="font-weight: bold">(단위:백만원)</div>
      </div>
      <div class="tbl_doc_scroll full">
        <div class="t_inner">
          <div class="t_top">
            <div class="item">
              <div class="cel" style="width: 120px">구분</div>
              <div class="cel" style="width: 120px">{{ 입력손익.year1 && `${입력손익.year1}년` }}</div>
              <div class="cel" style="width: 120px">{{ 입력손익.year2 && `${입력손익.year2}년` }}</div>
              <div class="cel" style="width: 120px">{{ 입력손익.year3 && `${입력손익.year3}년` }}</div>
              <div class="cel" style="width: 120px">3년평균</div>
              <div class="cel" style="width: 240px; background-color: white">매출액증가율 가정(%)</div>
            </div>
          </div>
          <div class="t_cont">
            <div class="item">
              <div class="cel" style="width: 120px">매출액</div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.매출액.year1"
                  @input="입력손익.매출액.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('매출액');
                      fn영업이익률계산('year1');
                      fn순이익률계산('year1');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.매출액.year2"
                  @input="입력손익.매출액.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('매출액');
                      fn영업이익률계산('year2');
                      fn순이익률계산('year2');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.매출액.year3"
                  @input="입력손익.매출액.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('매출액');
                      fn영업이익률계산('year3');
                      fn순이익률계산('year3');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.매출액['3년평균'] && fnFormatNumber(Number(입력손익.매출액['3년평균']).toFixed(0)) }}</div>
              <div class="cel border-bottom" style="width: 240px">
                <input type="text" class="ipt_cel" v-model="입력손익.매출액증가율_가정" @input="입력손익.매출액증가율_가정 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">매출총이익</div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.매출총이익.year1"
                  @input="입력손익.매출총이익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('매출총이익')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.매출총이익.year2"
                  @input="입력손익.매출총이익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('매출총이익')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.매출총이익.year3"
                  @input="입력손익.매출총이익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('매출총이익')" />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.매출총이익['3년평균'] && fnFormatNumber(Number(입력손익.매출총이익['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">판매비와관리비</div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.판매비와관리비.year1"
                  @input="입력손익.판매비와관리비.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('판매비와관리비')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.판매비와관리비.year2"
                  @input="입력손익.판매비와관리비.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('판매비와관리비')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.판매비와관리비.year3"
                  @input="입력손익.판매비와관리비.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('판매비와관리비')" />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.판매비와관리비['3년평균'] && fnFormatNumber(Number(입력손익.판매비와관리비['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">영업이익</div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.영업이익.year1"
                  @input="입력손익.영업이익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('영업이익');
                      fn영업이익률계산('year1');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.영업이익.year2"
                  @input="입력손익.영업이익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('영업이익');
                      fn영업이익률계산('year2');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.영업이익.year3"
                  @input="입력손익.영업이익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('영업이익');
                      fn영업이익률계산('year3');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.영업이익['3년평균'] && fnFormatNumber(Number(입력손익.영업이익['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">영업외수익</div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.영업외수익.year1"
                  @input="입력손익.영업외수익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('영업외수익')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.영업외수익.year2"
                  @input="입력손익.영업외수익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('영업외수익')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.영업외수익.year3"
                  @input="입력손익.영업외수익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('영업외수익')" />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.영업외수익['3년평균'] && fnFormatNumber(Number(입력손익.영업외수익['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">이자수익</div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.이자수익.year1"
                  @input="입력손익.이자수익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('이자수익');
                      fn가지급금추정액_이자수익계산('year1');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.이자수익.year2"
                  @input="입력손익.이자수익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('이자수익');
                      fn가지급금추정액_이자수익계산('year2');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.이자수익.year3"
                  @input="입력손익.이자수익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('이자수익');
                      fn가지급금추정액_이자수익계산('year3');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.이자수익['3년평균'] && fnFormatNumber(Number(입력손익.이자수익['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">기타</div>
              <div class="cel" style="width: 120px">
                <input type="text" class="ipt_cel" v-model="입력손익.기타수익.year1" @input="입력손익.기타수익.year1 = $event.target.value.replace(/[^0-9.]/g, '')" @blur="fn3년평균계산('기타수익')" />
              </div>
              <div class="cel" style="width: 120px">
                <input type="text" class="ipt_cel" v-model="입력손익.기타수익.year2" @input="입력손익.기타수익.year2 = $event.target.value.replace(/[^0-9.]/g, '')" @blur="fn3년평균계산('기타수익')" />
              </div>
              <div class="cel" style="width: 120px">
                <input type="text" class="ipt_cel" v-model="입력손익.기타수익.year3" @input="입력손익.기타수익.year3 = $event.target.value.replace(/[^0-9.]/g, '')" @blur="fn3년평균계산('기타수익')" />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.기타수익['3년평균'] && fnFormatNumber(Number(입력손익.기타수익['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">영업외비용</div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.영업외비용.year1"
                  @input="입력손익.영업외비용.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('영업외비용')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.영업외비용.year2"
                  @input="입력손익.영업외비용.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('영업외비용')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.영업외비용.year3"
                  @input="입력손익.영업외비용.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('영업외비용')" />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.영업외비용['3년평균'] && fnFormatNumber(Number(입력손익.영업외비용['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">이자비용</div>
              <div class="cel" style="width: 120px">
                <input type="text" class="ipt_cel" v-model="입력손익.이자비용.year1" @input="입력손익.이자비용.year1 = $event.target.value.replace(/[^0-9.]/g, '')" @blur="fn3년평균계산('이자비용')" />
              </div>
              <div class="cel" style="width: 120px">
                <input type="text" class="ipt_cel" v-model="입력손익.이자비용.year2" @input="입력손익.이자비용.year2 = $event.target.value.replace(/[^0-9.]/g, '')" @blur="fn3년평균계산('이자비용')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.이자비용.year3"
                  @input="입력손익.이자비용.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('이자비용');
                      fn이자비용계산();
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.이자비용['3년평균'] && fnFormatNumber(Number(입력손익.이자비용['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">기타</div>
              <div class="cel" style="width: 120px">
                <input type="text" class="ipt_cel" v-model="입력손익.기타비용.year1" @input="입력손익.기타비용.year1 = $event.target.value.replace(/[^0-9.]/g, '')" @blur="fn3년평균계산('기타비용')" />
              </div>
              <div class="cel" style="width: 120px">
                <input type="text" class="ipt_cel" v-model="입력손익.기타비용.year2" @input="입력손익.기타비용.year2 = $event.target.value.replace(/[^0-9.]/g, '')" @blur="fn3년평균계산('기타비용')" />
              </div>
              <div class="cel" style="width: 120px">
                <input type="text" class="ipt_cel" v-model="입력손익.기타비용.year3" @input="입력손익.기타비용.year3 = $event.target.value.replace(/[^0-9.]/g, '')" @blur="fn3년평균계산('기타비용')" />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.기타비용['3년평균'] && fnFormatNumber(Number(입력손익.기타비용['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">비용차감전손익</div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.비용차감전손익.year1"
                  @input="입력손익.비용차감전손익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('비용차감전손익')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.비용차감전손익.year2"
                  @input="입력손익.비용차감전손익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('비용차감전손익')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.비용차감전손익.year3"
                  @input="입력손익.비용차감전손익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('비용차감전손익')" />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.비용차감전손익['3년평균'] && fnFormatNumber(Number(입력손익.비용차감전손익['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">법인세비용</div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.법인세비용.year1"
                  @input="입력손익.법인세비용.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('법인세비용')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.법인세비용.year2"
                  @input="입력손익.법인세비용.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('법인세비용')" />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.법인세비용.year3"
                  @input="입력손익.법인세비용.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="fn3년평균계산('법인세비용')" />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.법인세비용['3년평균'] && fnFormatNumber(Number(입력손익.법인세비용['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">당기순이익</div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.당기순이익.year1"
                  @input="입력손익.당기순이익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('당기순이익');
                      fn순이익률계산('year1');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.당기순이익.year2"
                  @input="입력손익.당기순이익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('당기순이익');
                      fn순이익률계산('year2');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">
                <input
                  type="text"
                  class="ipt_cel"
                  v-model="입력손익.당기순이익.year3"
                  @input="입력손익.당기순이익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                  @blur="
                    () => {
                      fn3년평균계산('당기순이익');
                      fn순이익률계산('year3');
                    }
                  " />
              </div>
              <div class="cel" style="width: 120px">{{ 입력손익.당기순이익['3년평균'] && fnFormatNumber(Number(입력손익.당기순이익['3년평균']).toFixed(0)) }}</div>
            </div>
            <div class="item">
              <div class="cel" style="width: 120px">영업이익률</div>
              <div class="cel" style="width: 120px">{{ 입력손익.영업이익률.year1 && (Number(입력손익.영업이익률.year1) * 100).toFixed(1) + '%' }}</div>
              <div class="cel" style="width: 120px">{{ 입력손익.영업이익률.year2 && (Number(입력손익.영업이익률.year2) * 100).toFixed(1) + '%' }}</div>
              <div class="cel" style="width: 120px">{{ 입력손익.영업이익률.year3 && (Number(입력손익.영업이익률.year3) * 100).toFixed(1) + '%' }}</div>
              <div class="cel" style="width: 120px">{{ 입력손익.영업이익률['3년평균'] && (Number(입력손익.영업이익률['3년평균']) * 100).toFixed(1) + '%' }}</div>
              <div class="cel" style="width: 240px">미래수익률 가정(%)</div>
            </div>
            <div class="item">
              <div class="cel border-bottom" style="width: 120px">순이익률</div>
              <div class="cel border-bottom" style="width: 120px">{{ 입력손익.순이익률.year1 && (Number(입력손익.순이익률.year1) * 100).toFixed(1) + '%' }}</div>
              <div class="cel border-bottom" style="width: 120px">{{ 입력손익.순이익률.year2 && (Number(입력손익.순이익률.year2) * 100).toFixed(1) + '%' }}</div>
              <div class="cel border-bottom" style="width: 120px">{{ 입력손익.순이익률.year3 && (Number(입력손익.순이익률.year3) * 100).toFixed(1) + '%' }}</div>
              <div class="cel border-bottom" style="width: 120px">{{ 입력손익.순이익률['3년평균'] && (Number(입력손익.순이익률['3년평균']) * 100).toFixed(1) + '%' }}</div>
              <div class="cel border-bottom" style="width: 240px">
                <input type="text" class="ipt_cel" v-model="입력손익.미래수익률_가정" @input="입력손익.미래수익률_가정 = $event.target.value.replace(/[^0-9.]/g, '')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import diagnosisService from '~/service/allOkMng/diagnosisService.js';
import Select from '../../common/Select.vue';
export default {
  props: ['bizNo'],
  components: {
    Select,
  },
  data() {
    return {
      입력재무: {
        year1: '',
        year2: '',
        year3: '',
        유동자산: { year1: '', year2: '', year3: '' },
        가지급금: { year1: '', year2: '', year3: '' },
        주임종단기대여금: { year1: '', year2: '', year3: '' },
        미수수익: { year1: '', year2: '', year3: '' },
        비유동자산: { year1: '', year2: '', year3: '' },
        부동산자산: { year1: '', year2: '', year3: '' },
        자산총계: { year1: '', year2: '', year3: '' },
        유동부채: { year1: '', year2: '', year3: '' },
        단기차입금: { year1: '', year2: '', year3: '' },
        가수금: { year1: '', year2: '', year3: '' },
        비유동부채: { year1: '', year2: '', year3: '' },
        장기차입금: { year1: '', year2: '', year3: '' },
        퇴직급여충당부채: { year1: '', year2: '', year3: '' },
        퇴직연금운용자산: { year1: '', year2: '', year3: '' },
        부채총계: { year1: '', year2: '', year3: '' },
        자본금: { year1: '', year2: '', year3: '' },
        자본조정: { year1: '', year2: '', year3: '' },
        이익준비금: { year1: '', year2: '', year3: '' },
        미처분이익잉여금: { year1: '', year2: '', year3: '' },
        자본총계: { year1: '', year2: '', year3: '' },
        가지급금추정액: {
          미수수익: { year1: '', year2: '', year3: '' },
          이자수익: { year1: '', year2: '', year3: '' },
          토지: '',
          건물: '',
          구축물: '',
          부동산합계: '',
        },
        가지급금액: '',
        차입금액: {
          단기차입금: '',
          장기차입금: '',
          합계: '',
        },
        이자비용: '',
      },
      입력손익: {
        year1: '',
        year2: '',
        year3: '',
        매출액: { year1: '', year2: '', year3: '', '3년평균': '' },
        매출총이익: { year1: '', year2: '', year3: '', '3년평균': '' },
        판매비와관리비: { year1: '', year2: '', year3: '', '3년평균': '' },
        영업이익: { year1: '', year2: '', year3: '', '3년평균': '' },
        영업외수익: { year1: '', year2: '', year3: '', '3년평균': '' },
        이자수익: { year1: '', year2: '', year3: '', '3년평균': '' },
        기타수익: { year1: '', year2: '', year3: '', '3년평균': '' },
        영업외비용: { year1: '', year2: '', year3: '', '3년평균': '' },
        이자비용: { year1: '', year2: '', year3: '', '3년평균': '' },
        기타비용: { year1: '', year2: '', year3: '', '3년평균': '' },
        비용차감전손익: { year1: '', year2: '', year3: '', '3년평균': '' },
        법인세비용: { year1: '', year2: '', year3: '', '3년평균': '' },
        당기순이익: { year1: '', year2: '', year3: '', '3년평균': '' },
        영업이익률: { year1: '', year2: '', year3: '', '3년평균': '' },
        순이익률: { year1: '', year2: '', year3: '', '3년평균': '' },
        매출액증가율_가정: '',
        미래수익률_가정: '',
      },
      yearList: [
        { name: '2024', value: '2024' },
        { name: '2023', value: '2023' },
        { name: '2022', value: '2022' },
        { name: '2021', value: '2021' },
        { name: '2020', value: '2020' },
        { name: '2019', value: '2019' },
        { name: '2018', value: '2018' },
        { name: '2017', value: '2017' },
        { name: '2016', value: '2016' },
        { name: '2015', value: '2015' },
      ],
    };
  },

  methods: {
    fnSaveData() {
      const btn = {
        name: '저장',
        func: () => {
          this._closePop();
          const payload = {
            finData: JSON.stringify(this.입력재무),
            incomeData: JSON.stringify(this.입력손익),
            regId: this.$store.state.authUser.username,
          };
          diagnosisService.updateDiagnosis(this.$axios, this.bizNo, payload).then(({ result, data }) => {
            if (result) {
              this.$popupAlert({
                title: '저장',
                msg: '저장이 완료되었습니다.',
              });
            } else {
              this.$popupAlert({
                title: '경고',
                msg: data.error.msg,
              });
            }
          });
        },
      };
      this._confirm('확인', '저장하시겠습니까?', btn);
    },
    fnSelectYear({ value }) {
      const year1 = Number(value).toString();
      const year2 = (Number(value) + 1).toString();
      const year3 = (Number(value) + 2).toString();
      this.입력재무.year1 = year1;
      this.입력손익.year1 = year1;
      this.입력재무.year2 = year2;
      this.입력손익.year2 = year2;
      this.입력재무.year3 = year3;
      this.입력손익.year3 = year3;
    },
    fn가지급금추정액_미수수익계산(year) {
      const d = this.입력재무.미수수익[year];
      this.입력재무.가지급금추정액.미수수익[year] = ((Number(d) / 0.046) * 1000000).toString();
    },
    fn가지급금추정액_이자수익계산(year) {
      const d = this.입력손익.이자수익[year];
      this.입력재무.가지급금추정액.이자수익[year] = ((Number(d) / 0.046) * 1000000).toString();
    },
    fn차입금액_단기차입금계산() {
      const d = this.입력재무.단기차입금.year3;
      this.입력재무.차입금액.단기차입금 = (Number(d) * 1000000).toString();

      //차입금액 합계계산
      this.입력재무.차입금액.합계 = (Number(this.입력재무.차입금액.단기차입금) + Number(this.입력재무.차입금액.장기차입금)).toString();
    },
    fn차입금액_장기차입금계산() {
      const d = this.입력재무.장기차입금.year3;
      this.입력재무.차입금액.장기차입금 = (Number(d) * 1000000).toString();

      //차입금액 합계계산
      this.입력재무.차입금액.합계 = (Number(this.입력재무.차입금액.단기차입금) + Number(this.입력재무.차입금액.장기차입금)).toString();
    },
    fn이자비용계산() {
      const d = this.입력손익.이자비용.year3;
      this.입력재무.이자비용 = (Number(d) * 1000000).toString();
    },
    fn가지급금추정액_부동산합계계산() {
      this.입력재무.가지급금추정액.부동산합계 = (Number(this.입력재무.가지급금추정액.토지) + Number(this.입력재무.가지급금추정액.건물) + Number(this.입력재무.가지급금추정액.구축물)).toString();
    },
    fn3년평균계산(division) {
      const d1 = this.입력손익[division].year1;
      const d2 = this.입력손익[division].year2;
      const d3 = this.입력손익[division].year3;
      this.입력손익[division]['3년평균'] = ((Number(d1) + Number(d2) + Number(d3)) / 3).toString();
    },
    fn영업이익률계산(year) {
      const d1 = this.입력손익.영업이익[year];
      const d2 = this.입력손익.매출액[year];
      this.입력손익.영업이익률[year] = (Number(d1) / Number(d2)).toString();

      //영업이익률 3년평균 계산
      this.입력손익.영업이익률['3년평균'] = ((Number(this.입력손익.영업이익률.year1) + Number(this.입력손익.영업이익률.year2) + Number(this.입력손익.영업이익률.year3)) / 3).toString();
    },
    fn순이익률계산(year) {
      const d1 = this.입력손익.당기순이익[year];
      const d2 = this.입력손익.매출액[year];
      this.입력손익.순이익률[year] = (Number(d1) / Number(d2)).toString();

      //순이익률 3년평균 계산
      this.입력손익.순이익률['3년평균'] = ((Number(this.입력손익.순이익률.year1) + Number(this.입력손익.순이익률.year2) + Number(this.입력손익.순이익률.year3)) / 3).toString();
    },
    fnFormatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    _confirm(title, msg, btn) {
      let data = {
        state: true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closePop() {
      this.$store.dispatch('ui/setPopAlertData', {
        state: false,
        type: null,
        title: null,
        msg: null,
        btn: {
          link: '',
          name: null,
        },
      });
    },
  },

  mounted() {
    diagnosisService.getDiagnosis(this.$axios, this.bizNo).then(({ result, data }) => {
      if (result) {
        this.입력재무 = JSON.parse(data.contents.finData);
        this.입력손익 = JSON.parse(data.contents.incomeData);
      } else {
        console.error(data.error);
      }
    });
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #ddd;
}

.tbl_doc_scroll .t_cont .item {
  min-height: 40px;
}
</style>
