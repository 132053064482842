<template>
  <div>
    <div class="colb area_tap">
      <div class="taps_btn">
        <button v-for="(grade,idx) in gradeList" :key="'grd_'+idx"
                class="tap" :class="{on:currentSearchGrade===grade.value}"
                @click="()=>fnChangeSearchGrade(grade.value)">
          {{grade.name}}
        </button>
      </div>
    </div>
    <section class="list_goods">
      <article v-for="(patn) in patnList" :key="'patn_'+patn.patnNo"
               class="unit">
        <div class="i_top">
          <h3>{{cutString(patn.patnTitle)}}</h3>
          <div class="i_right">
            <CheckBox
              :data="{title:'분석요청',
                      id: patn.patnNo,
                      name:patn.patnNo,
                      value:patn.patnNo}"
              :checked="patnCheck(patn.patnNo)"
              ref="agrTm01"
              :disabled="true"
            />
          </div>
        </div>
        <dl>
          <dt>특허등급</dt>
          <dd><b>{{changeCode(patn.evGrade)}}</b></dd>
        </dl>
        <dl>
          <dt>출원일</dt>
          <dd>{{ patn.appPatnDate }}</dd>
        </dl>
        <dl>
          <dt>특허추정가치</dt>
          <dd>{{ $dpPatnPrice(patn.patnPrice) }}</dd>
        </dl>
      </article>
      <InfiniteLoading @infinite="handleListScroll" :identifier="infiniteId" spinner="spiral">
        <div slot="no-results">
          <div style="display: flex; justify-content: center; align-items: center; height: 40vh;">
            <b>조건에 맞는 특허가 없어요</b>
          </div>
        </div>
        <div slot="no-more"></div>
      </InfiniteLoading>
    </section>
  </div>
</template>

<script>
import CheckBox from "./CheckBox.vue";
import InfiniteLoading from 'vue-infinite-loading';
export default {
  name: "tab3",
  props:['customer'],
  components:{CheckBox, InfiniteLoading},
  data(){
    return {
      analyzeParamData:{
        rank:[
          {name:'전체', value:'ALL'},
          {name:'A등급', value:'A'},
          {name:'B등급', value:'B'},
          {name:'C등급', value:'C'},
        ]
      },
      analyzeParam:{
        pageNo: 1,
        pageSize: 10,
        searchGrade:'ALL',
      },
      analyzeResult: {
        totalCnt:0,
        patnAnlys:[],
      },
      analyzeRequest: [],
      infiniteId: +new Date()
    }
  },
  computed:{
    currentSearchGrade(){
      return this.analyzeParam.searchGrade;
    },
    gradeList(){
      return this.analyzeParamData.rank;
    },
    patnList(){
      const list = this.analyzeResult.patnAnlys;
      return list ? list : [];
    },
    patnTotalCnt(){
      return this.analyzeResult.totalCnt;
    }
  },
  methods:{
    changeCode(payload){
      if(payload){
        if(payload==9||payload==8||payload==7||payload==6){
          return 'A'
        } else if(payload==5||payload==4||payload==3){
          return 'B'
        } else if(payload==2){
          return 'C'
        } else 
          return '-'
      }
    },
    fnClickCheckBox(patnNo, checked){
      if( checked ) {
        const idx = this.analyzeRequest.indexOf(patnNo)
        if (idx == -1) this.analyzeRequest.push(patnNo);
      } else {
        const idx = this.analyzeRequest.indexOf(patnNo)
        if (idx > -1) this.analyzeRequest.splice(idx, 1)
      }
    },
    cutString(payload){
      const result = payload.split('{')
      return result[0]
    },
    // fnClickCheckBox(patnNo, checked){
    //   this.$store.commit(
    //     'lenderLookupStore/UPDATE_ANALYZE_REQUEST',
    //     { patnNo, checked }
    //   );
    // },
    fnChangeSearchGrade(searchGrade){
      this.analyzeParam.pageNo = 1;
      this.analyzeParam.searchGrade = searchGrade;
      this.analyzeResult.totalCnt = 0;
      this.analyzeResult.patnAnlys = [];
      this.infiniteId++;
    },
    async handleListScroll($state) {
      const params = {
        bizNo: this.customer.bizNo,
        pageNo: this.analyzeParam.pageNo,
        pageSize: this.analyzeParam.pageSize,
        searchGrade: this.analyzeParam.searchGrade,
      }
      const result = await this.$store.dispatch('allOk/allOkStore/fetchAnalyze', params)
      let contents = {}
      if(result.result) {
        contents = result.data.contents;
        this.analyzeResult.totalCnt = contents.totalCnt;
        if(contents.patnAnlys.length!=0){
          this.analyzeResult.patnAnlys.push(...contents.patnAnlys)
          this.analyzeParam.pageNo++;
          $state.loaded()
        } else{
          $state.complete()
        }
      }
    },
    patnCheck(payload){
      if(this.customer.analyzePatnNoList){
        let findResult = {}
        for(let i=0; i<this.customer.analyzePatnNoList.length; i++){
          findResult = this.analyzeResult.patnAnlys.find(e => e.patnNo === this.customer.analyzePatnNoList[i])
          if(payload==findResult.patnNo){
            return true
          }
        }
      }
      return false
    },
  },
}
</script>
