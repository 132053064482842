<template>
  <div class="container">
    <div class="c_top align_right">
      <div class="right">
        <button class="btn_arr">한도 높은순</button>
        <button class="btn_arr">금리 낮은순</button>
      </div>
    </div>
    <section class="list_finance">
      <article class="item" v-for="i in 5" :key="i">
        <div class="i_top">
          <h3>수협은행 - Sh더드림 기업신용대출</h3>
              <div class="right">
                <img class="logo_bank" src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok/img/common/logo_temp2.png" alt="신한저축은행">
              </div>>
        </div>
        <dl class="col">
          <dt>금리</dt>
          <dd>4.8%</dd>
        </dl>
        <dl class="col">
          <dt>한도</dt>
          <dd><b>최대 3,000 만원</b></dd>
        </dl>
      </article>
    </section>
  </div>
</template>
<script>
export default {
  name: "tab2",
}
</script>
