<template>
  <div class="cover" v-if="미처분잉여금세부담액">
    <div class="cTit">
      <div class="unicornLogo">unicornranch</div>
      <div class="reportTit">비상장주식가치평가</div>
    </div>
    <div class="contents">
      <div class="Flist" style="display: flex;">14.<span class="Ftit">미래가치 2 : 미처분이익잉여금과 배당세액 부담액 예시<br/><span class="titInfo">순자산가치 3과 순손익가치 2의 가중치를 적용하여 비상장주식</span></span></div>
      <div class="compNm">{{입력개요?.회사명}}</div>
      <div>
        <div class="tit1">※ 가정</div>
        <div class="table1">
          <div class="tContents">
            <div class="fCont bold"><span style="display: flex; justify-content: center;">제목</span></div>
            <div class="sCont bold">내용</div>
          </div>
          <div class="tContents divide">
            <div class="fCont semibold"><span style="margin-left: 30px;">매출액증가율</span></div>
            <div class="sCont semibold">{{fnPercent(미처분잉여금세부담액?.매출액증가율)}}%</div>
          </div>
          <div class="tContents divide">
            <div class="fCont semibold"><span style="margin-left: 30px;">순이익률</span></div>
            <div class="sCont semibold">{{fnPercent(미처분잉여금세부담액?.순이익률)}}%</div>
          </div>
          <div class="tContents divide borderBottom">
            <div class="fCont semibold"><span style="margin-left: 30px;">3년 평균 순이익률</span></div>
            <div class="sCont semibold">{{(fnPercent(미처분잉여금세부담액?.순이익률_3년평균)).toFixed(1)}}%</div>
          </div>
        </div>
        <div class="tit2">1) 미처분이익잉여금과 배당세액 부담액 예시 목록</div>
        <div class="table2">
          <div class="t2row1">
            <div class="t2r1cell1">주주명</div>
            <div class="t2r1cell2">
              <div class="t2r1cell2-1 borderBottom2">2019년 결산기준</div>
              <div class="t2r1cell2-2">
                <div class="t2r1cell2-2-1">지분율</div>
                <div class="t2r1cell2-2-1">미처분잉여금</div>
                <div class="t2r1cell2-2-1">취득가액</div>
                <div class="t2r1cell2-2-1 rColor">의제배당액</div>
                <div class="t2r1cell2-2-1">세율</div>
                <div class="t2r1cell2-2-1 rColor">산출세액</div>
              </div>
            </div>
            <div class="t2r1cell3">
              <div class="t2r1cell3-1 borderBottom2">5년 경과</div>
              <div class="t2r1cell3-2">
                <div class="t2r1cell3-2-1">의제배당액</div>
                <div class="t2r1cell3-2-1 rColor">산출세액</div>
              </div>
            </div>
            <div class="t2r1cell3">
              <div class="t2r1cell3-1 borderBottom2">10년 경과</div>
              <div class="t2r1cell3-2">
                <div class="t2r1cell3-2-1">의제배당액</div>
                <div class="t2r1cell3-2-1 rColor">산출세액</div>
              </div>
            </div>
          </div>
          <div class="t2row2" v-for="(주주, i) in 미처분잉여금세부담액?.주주" :key="i">
            <div class="t2r2cell1">{{주주.주주명}}</div>
            <div class="t2r2cell1">{{fnPercent(주주.지분율)}}%</div>
            <div class="t2r2cell1 leRiBorder">{{미처분잉여금세부담액.미처분잉여금}}백만원</div>
            <div class="t2r2cell1">{{fnFormatNumber((주주.취득가액).toFixed(0))}}백만원</div>
            <div class="t2r2cell1 rColor">{{fnFormatNumber((주주.의제배당액_결산기준).toFixed(0))}}백만원</div>
            <div class="t2r2cell1">{{fnPercent(주주.세율)}}%</div>
            <div class="t2r2cell1 rColor">{{fnFormatNumber((주주.산출세액_결산기준).toFixed(0))}}백만원</div>
            <div class="t2r2cell1">{{fnFormatNumber((주주.의제배당액_5년경과).toFixed(0))}}백만원</div>
            <div class="t2r2cell1 rColor">{{fnFormatNumber((주주.산출세액_5년경과).toFixed(0))}}백만원</div>
            <div class="t2r2cell1">{{fnFormatNumber((주주.의제배당액_10년경과).toFixed(0))}}백만원</div>
            <div class="t2r2cell1 rColor">{{fnFormatNumber((주주.산출세액_10년경과).toFixed(0))}}백만원</div>
          </div>
          <div class="t2row2" style="color: #21BDC6; background-color: #001736;">
            <div class="t2r2cell1">합계</div>
            <div class="t2r2cell1">{{fnPercent(미처분잉여금세부담액?.합계.지분율)}}%</div>
            <div class="t2r2cell1">{{(미처분잉여금세부담액?.미처분잉여금).toFixed(0)}}백만원</div>
            <div class="t2r2cell1">{{fnFormatNumber((미처분잉여금세부담액?.합계.취득가액).toFixed(0))}}백만원</div>
            <div class="t2r2cell1 rColor">{{fnFormatNumber((미처분잉여금세부담액?.합계.의제배당액_결산기준).toFixed(0))}}백만원</div>
            <div class="t2r2cell1"></div>
            <div class="t2r2cell1 rColor">{{fnFormatNumber((미처분잉여금세부담액?.합계.산출세액_결산기준).toFixed(0))}}백만원</div>
            <div class="t2r2cell1">{{fnFormatNumber((미처분잉여금세부담액?.합계.의제배당액_5년경과).toFixed(0))}}백만원</div>
            <div class="t2r2cell1 rColor">{{fnFormatNumber((미처분잉여금세부담액?.합계.산출세액_5년경과).toFixed(0))}}백만원</div>
            <div class="t2r2cell1">{{fnFormatNumber((미처분잉여금세부담액?.합계.의제배당액_10년경과).toFixed(0))}}백만원</div>
            <div class="t2r2cell1 rColor">{{fnFormatNumber((미처분잉여금세부담액?.합계.산출세액_10년경과).toFixed(0))}}백만원</div>
          </div>
        </div>
        <div class="tit3">2) 미처분이익잉여금과 세부담액 예시 차트</div>
        <div class="chart">
          <div id="미처분잉여금세부담액" style="width:100%; height:100%;"></div>
        </div>
      </div>
      <div class="footer">
        <div class="addEx">※ 추가 설명</div>
        <div class="ex"><span class="exText">투자한 주주들에게 나가야 할 배당금 의무도 무시할 수 없다. 사실 어떤 기업이든 자기 돈만 충분하다면 굳이 엄격한 심사와 자격까지 갖추면서 상장할 이유는 없다.[2] 실제로 2015년 기준 전경련에서 조사한 자료에서는 총 600여개 기업이 코스피 상장 요건을 갖추고 있었지만 단 7개 기업만이 상장을 했다. 그리고 다른 회사에게 자신의 회사를 팔 때도 매우 쉬운데. 상장회사들을 인수 할 때 과반의 주식을 확보하거나 합병까지 하려면 전체 주식을 사서 소각해야 하는데, 이 과정도 매우 복잡해서 전체 주주들에게 동의를 받아야 하고 주식시장을 관리감독하는 기관의 심사까지 받아야 해서 규모가 큰 회사들 끼리 합병시 년단위로 걸린다. 비상장은 경영권을 가진 임원들에게 만족하는 가격만 제시한다면 매우 수월하게 인수가 가능하다.</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['입력개요', '미처분잉여금세부담액'],
  methods: {
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fnPercent(num){
      return num*100;
    },
    fnDrawChart() {
      const Chart = toastui.Chart;

      Chart.columnLineChart({
        el: document.getElementById('미처분잉여금세부담액'),
        data: {
          categories: [
            this.미처분잉여금세부담액.결산기준년도 + '년',
            '5년경과',
            '10년경과'
          ],
          series: {
            column:[
              {
                name: `미처분잉여금`,
                data: [
                  this.미처분잉여금세부담액.미처분잉여금,
                  this.미처분잉여금세부담액.합계.의제배당액_5년경과,
                  this.미처분잉여금세부담액.합계.의제배당액_10년경과
                ],
              },
              {
                name: `자본총계`,
                data: [
                  this.미처분잉여금세부담액.합계.의제배당액_결산기준,
                  this.미처분잉여금세부담액.합계.산출세액_5년경과,
                  this.미처분잉여금세부담액.합계.산출세액_10년경과
                ]
              },
            ],
            line: [
              {
                name: '선형(미처분잉여금)',
                data: [
                  this.미처분잉여금세부담액.미처분잉여금,
                  this.미처분잉여금세부담액.합계.의제배당액_5년경과,
                  this.미처분잉여금세부담액.합계.의제배당액_10년경과
                ],
              },
            ],
          },
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
          },
          yAxis: {
            label: {
              formatter: value => (value == 0 ? '-' : this.fnFormatNumber((Number(value)).toFixed(1)) + '억원'),
            },
          },
          plot:{
            visible: false,
          },
          theme: {
            chart:{
              backgroundColor:'#EEEEEE',
            },
            series: {
              barWidth: 40,
              colors: ['#2D415A', '#21BDC6', '#B50A45'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            yAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'top',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            column:{
              dataLabels: {
                visible: true,
                formatter: (value, tooltipDataInfo) => this.fnFormatNumber((Number(value)).toFixed(1) + '억원')
              }
            }
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber((Number(value)).toFixed(1) + '억원'),
          },
        },
      });

    },
  },
  mounted () {
    this.fnDrawChart();
  },
}
</script>

<style scoped>
  @import '~/static/css/report.css';
  .rColor{
    color: #B50A45;
  }
  .leRiBorder{
    border-right: solid 1px #CCD1D7;
    border-left: solid 1px #CCD1D7;
  }
  .t2row1{
    height: 70px;
    display: flex;
    font-size: 13px;
    font-family: 'Pretendard-Bold';
  }
  .t2r2cell1{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 106px;
    border-bottom: solid 1px #001736;
  }
  .t2r2cell2{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 106px;
    border-bottom: solid 1px #001736;
  }
  .t2row2{
    height: 40px;
    display: flex;
    font-size: 13px;
    font-family: 'Pretendard-SemiBold';
  }
  .t2r1cell1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 70px;
    background-color: #DBDCDE;
  }
  .borderBottom2{
    border-bottom: solid 2px #667486;
  }
  .t2r1cell3{
    display: flex;
    width: 212px;
    height: 70px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .t2r1cell3-1{
    width: 212px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
  }
  .t2r1cell3-2{
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #DBDCDE;
  }
  .t2r1cell3-2-1{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 106px;
  }
  .t2r1cell2{
    display: flex;
    width: 636px;
    height: 70px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .t2r1cell2-2-1{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 106px;
  }
  .t2r1cell2-1{
    width: 636px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
  }
  .t2r1cell2-2{
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #DBDCDE;
  }
  .Ftit{
    display: flex;
    flex-direction: column;
  }
  .titInfo{
    font-size: 10px;
    font-family: 'Pretendard-Light';
  }
  .compNm{
    color: #1FBDC6;
    font-size: 24px;
    font-family: 'Pretendard-SemiBold';
    position: absolute;
    top: 36px;
    right: 30px;
  }
  .tit1{
    margin-top: -50px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .table1{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .tContents{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid #001736 1px;
    width: 1160px;
    height: 40px;
    font-family: 'Pretendard-Bold';
    font-size: 13px;
  }
  .divide{
    background: linear-gradient(to right, transparent 840px, #DBDCDE 840px);
  }
  .borderBottom{
    border-bottom: solid #001736 1px;
  }
  .fCont{
    width: 300px;
  }
  .sCont{
    margin-right: 30px;
  }
  .tit2{
    color: #33455E;
    margin-top: 61px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
  }
  .table2{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 1160px;
    border-top: solid #001736 1px;
    border-bottom: solid #001736 1px;
    left: 30px;
  }
  .tit3{
    margin-top: 61px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .chart{
    margin-top: 20px;
    width: 1160px;
    height: 611px;
    border-top: solid 1px #001736;
    border-bottom: solid 1px #001736;
  }
  .footer{
    margin-bottom: 50px;
  }
  .footer::before{
    position: absolute;
    width: 100px;
    bottom: -30px;
    right: 0px;
    content: '14';
    width: 100px;
    border: solid 1px #001736;
    background-color: #001736;
    height: 60px;
    color: #1FBDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .addEx{
    font-size: 18px;
    color: #33455E;
  }
  .ex{
    width: 1160.5px;
    height: 75px;
    border-top: solid 1px #001736;
  }
  .exText{
    display: block;
    margin-top: 15px;
    font-size: 13px;
    color: #33455E;
    font-family: 'Pretendard-Regular';
  }
</style>