<template>
  <div class="popup p_cs">
    <div class="p_wrap">
      <div class="p_top">
        <h2>고객상세</h2>
        <button class="btn_close" @click="fnClosePop">닫기</button>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button class="tab on">고객상세</button>
              <button class="tab">대출 상담 내역</button>
            </div>
            <div class="btns">
              <button to="" class="btn">저장 후 계속</button>
              <button to="" class="btn">저장</button>
              <button to="" class="btn dark">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left no_scroll">
              <h4>1.상담내역</h4>
              <div class="cs_top">
                <div class="col">
                  <span>#1234567890</span>
                  <span>2022-12-12</span>
                  <span>유니콘랜치</span>
                </div>
                <div class="btns">
                  <button class="btn b_add"><b>새로운 상담 내용</b></button>
                </div>
              </div>
              <div class="timeline">
                <div class="unit on">
                  <div class="u_top colb">
                    <div class="col">
                      <strong>아무나</strong>
                      <span>유니콘랜치 삼성전자 담당자</span>
                      <button class="btn_phone">전화</button>
                      <button class="btn_mail">메일</button>
                      <em class="new">new</em>
                    </div>
                    <div class="col">
                      <span>2022.01.23</span>
                    </div>
                  </div>
                  <div class="u_cont">
                    대출 원금 및 이자 연체시, 연체이자가 발생되고 신용등급 또는 개인신용평점이 하락될 수 있으며, 채무/ 신용 상의 불이익이 발생할 수 있으니 주의하시기 바랍니다.
                  </div>
                  <div class="u_bottom">
                  </div>
                </div>
                <div class="unit" v-for="i in 5" :key="i">
                  <div class="u_top colb">
                    <div class="col">
                      <strong>아무나</strong>
                      <span>유니콘랜치 삼성전자 담당자</span>
                      <button class="btn_phone">전화</button>
                      <button class="btn_mail">메일</button>
                      <em class="new">new</em>
                    </div>
                    <div class="col">
                      <span>2022.01.23</span>
                    </div>
                  </div>
                  <div class="u_cont">
                    대출 원금 및 이자 연체시, 연체이자가 발생되고 신용등급 또는 개인신용평점이 하락될 수 있으며, 채무/ 신용 상의 불이익이 발생할 수 있으니 주의하시기 바랍니다.
                  </div>
                  <div class="u_bottom">
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <h4>2. 상담 내용 or 새로운 상담 내용</h4>
              <ul class="form_list mt20">
                <li>
                  <label for="date"><b>상담일시</b></label>
                  <div class="view_date">
                    <span class="start">2022년 6월 16일</span>
                    <div class="search_date"><!-- on 추가 -->
                      <div class="s_top">
                        <span class="start">2022년 12월 16일</span>
                      </div>
                      <div class="s_cont">
                        <div class="item">
                          <div class="bx">
                            <Select
                                title="2022년"
                                :list="[
                                  { name: '셀렉트1' },
                                  { name: '셀렉트2' },
                                  { name: '셀렉트3' },
                                  { name: '셀렉트4' },
                                  { name: '셀렉트5' },
                                  { name: '셀렉트6' },
                                  { name: '셀렉트7' },
                                  { name: '셀렉트8' },
                                ]"
                                classNm="ipt_year"
                              />
                            <Select
                                title="12월"
                                :list="[
                                  { name: '셀렉트1' },
                                  { name: '셀렉트2' },
                                  { name: '셀렉트3' },
                                  { name: '셀렉트4' },
                                  { name: '셀렉트5' },
                                  { name: '셀렉트6' },
                                  { name: '셀렉트7' },
                                  { name: '셀렉트8' },
                                ]"
                                classNm="ipt_month"
                              />
                            <Select
                                title="31일"
                                :list="[
                                  { name: '셀렉트1' },
                                  { name: '셀렉트2' },
                                  { name: '셀렉트3' },
                                  { name: '셀렉트4' },
                                  { name: '셀렉트5' },
                                  { name: '셀렉트6' },
                                  { name: '셀렉트7' },
                                  { name: '셀렉트8' },
                                ]"
                                classNm="ipt_day"
                              />
                          </div>
                        </div>
                        <div class="btns">
                          <button class="btn">확인</button>
                          <button class="btn gray">취소</button>
                        </div>
                      </div>
                    </div> 
                  </div>
                  <!-- <div id="tui-date-picker-container" style="margin-top: -1px;"></div> -->
                  <!-- <InputBox
                    :data="{ type: 'text', id: 'name', placeholder: '삼성전자 법무팀 아무개씨 (010-0000-0000)' }"
                  /> -->
                </li>
                <li>
                  <label for="name"><b>협의한 고객</b></label>
                  <InputBox
                    :data="{ type: 'text', id: 'name', placeholder: '삼성전자 법무팀 아무개씨 (010-0000-0000)' }"
                  />
                </li>
                <li>
                  <h4>상담유형</h4>
                  <Select title="특허문의" :list="[
                    {name: '셀렉트1'},
                    {name: '셀렉트2'},
                    {name: '셀렉트3'},
                    {name: '셀렉트4'},
                  ]"/>
                </li>
                <li>
          <h4>미팅</h4>
          <Select title="미팅" :list="[
                    {name: '셀렉트1'},
                    {name: '셀렉트2'},
                    {name: '셀렉트3'},
                    {name: '셀렉트4'},
                  ]" />
        </li>
        <li>
          <h4>진행단계</h4>
          <Select title="진행단계" :list="[
                    {name: '셀렉트1'},
                    {name: '셀렉트2'},
                    {name: '셀렉트3'},
                    {name: '셀렉트4'},
                  ]" />
        </li>
        <li>
          <h4>계약확률</h4>
          <Select title="계약확률" :list="[
                    {name: '셀렉트1'},
                    {name: '셀렉트2'},
                    {name: '셀렉트3'},
                    {name: '셀렉트4'},
                  ]" />
        </li>
        <li>
          <h4>구분</h4>
          <Select title="구분" :list="[
                    {name: '셀렉트1'},
                    {name: '셀렉트2'},
                    {name: '셀렉트3'},
                    {name: '셀렉트4'},
                  ]" />
        </li>
        <li>
          <h4>관리여부</h4>
          <Select title="관리여부" :list="[
                    {name: '셀렉트1'},
                    {name: '셀렉트2'},
                    {name: '셀렉트3'},
                    {name: '셀렉트4'},
                  ]" />
        </li>
        <li>
          <label for="name"><b>미팅자 정보</b></label>
          <InputBox :data="{ type: 'text', id: 'name', placeholder: '이름' }" />
          <InputBox :data="{ type: 'text', id: 'name', placeholder: '전화번호' }" />
          <InputBox :data="{ type: 'text', id: 'name', placeholder: '이메일' }" />
        </li>
                <li>
                  <label for="msg"><b>상담내용</b></label>
                  <textarea name="msg" id="" class="textarea" placeholder="특허 추천 및 내용 설명 등…"></textarea>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from "~/components/common/Input";
import ItemPatent from "~/components/common/ItemPatent";
import Select from "~/components/common/Select";
import SelectSearch from "~/components/common/SelectSearch";


export default {
  data() {
    return {
    };
  },
  components: {
    InputBox,
    ItemPatent,
    Select,
    SelectSearch,
  },
  mounted() {
    console.log(this.$refs.dateWrapper);
    // const container = document.getElementById('tui-date-picker-container');
    // const target = document.getElementById('tui-date-picker-target');
    // const DatePicker = tui.DatePicker;
    // const datepicker = tui.DatePicker(this.$refs.dateWrapper, {
    //     input: {
    //       element: this.$refs.dateInput,
    //       format: 'yyyy-MM-dd'
    //     },
    // });
    // const instance = new DatePicker(container, {
    //     input: {
    //         element: target
    //     },
    // });
    // instance.getDate();
  },
  methods: {
    fnClosePop(){
        this.$store.dispatch('ui/setPopCsState', false); 
    },
  },
};
</script>