<template>
  <section class="container">
    <Location />
    <div class="inner">
      <div class="col unit_page">
        <h4 class="l_tit">업로드 파일:</h4>
        <InputFile id="file" name="file" placeholder="파일을 선택하세요" :value.sync="file" :styleAttr="{ width: '25%' }" ref="inputFile"/>
        <button class="btn ml5 mr5" :class="{dis: loading}" :disabled="loading" @click="fnFileUpload">파일업로드</button>
        <Loading v-if="loading" />
      </div>
      <div class="bx_fileview" v-if="fileInfo">
        <dl>
          <div class="item_file">
            <span style="background: #aaaaaa; color: white;" @click="() => fnFileDownload(fileInfo.fileId)">{{fileInfo.fileName}}</span>
          </div>
        </dl>
      </div>
      <div class="colr">
        <button class="btn_reset"
                @click="fnResetForm"
        >전체초기화</button>
      </div>
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="bizNo">사업자번호</label>
          <Input
            id="bizNo"
            name="query.bizNo"
            shape="search"
            placeholder='검색어를 입력하세요.'
            :value.sync="query.bizNo"
            @enter="fnResetPageAndFetch"
          />
        </div>
        <div class="unit">
          <label class="tit" for="compNm">회사명</label>
          <Input
            id="compNm"
            name="query.compNm"
            shape="search"
            placeholder='검색어를 입력하세요.'
            :value.sync="query.compNm"
            @enter="fnResetPageAndFetch"
          />
        </div>
      </div>
      <div class="colb">
        <div class="top">
        </div>
        <div class="col unit_page">
          <h4 class="l_tit">페이지당 개수 : </h4>
          <Select
            id="perPage"
            title="20개"
            :itemList="perPageList"
            :value.sync="query.perPage"
            @change="fnFetchList"
          />
          <button class="btn" @click="()=>{
            query.page=1;
            fnFetchList();
          }">검색</button>
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <colgroup>
              <col width="15%" />
              <col width="15%" />
              <col width="13%" />
              <col width="13%" />
              <col width="13%" />
            </colgroup>
            <thead>
            <tr>
              <th class="c1"><b>사업자번호</b></th>
              <th class="c1"><b>기업명</b></th>
              <th class="c2"><b>대표자</b></th>
              <th class="c2"><b>지역</b></th>
              <th class="c2"><b>회원구분</b></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item) in contents" :key="item.id">
              <td class="c2">{{ item.bizNo }}</td>
              <td class="c2">{{ item.compNm }}</td>
              <td class="c1">{{ item.korreprNm }}</td>
              <td class="c4">{{ item.region }}</td>
              <td class="c5">
                <Select defaultName="회원구분" :itemList="회원구분" :value="item.memberType" @change="(payload)=>{fnSelectType(payload, item)}" />
              </td>
            </tr>
            <tr v-if="contents && contents.length === 0">
              <td colspan="5">데이터가 없습니다.</td>
            </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="query.page"
          :perPage="query.perPage"
          :totalCount="pagination.totalCount"
          @change="p => {
            this.query.page=p;
            this.fnFetchList();
          }"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Header from '~/components/layouts/Header';
import Location from '~/components/layouts/LocationDataWrapper';
import Input from '~/components/common/Input.vue';
import Pagination from "~/components/common/Pagination";
import Select from "~/components/common/Select";
import InputFile from '~/components/common/InputFile.vue';
import Loading from '~/components/common/Loading.vue';
import mainbizService from '~/service/allOkMng/mainbizService';

export default {
  components: {
    Header,
    Location,
    Input, Select,
    Pagination,InputFile,Loading
  },
  data() {
    return {
      회원구분:[
        {name: '정회원', value: '정회원', value2: 'REG'},
        {name: '일반회원', value: '일반회원', value2: 'GEN'}
      ],
      file: null,
      loading: false,
      query: {
        page:1,
        perPage: 20, // 한 페이지에 보여줄 rows의 갯수
        bizNo:null,
        compNm:null,
      },
      fileInfo: null,
      contents:[],
      pagination:{
        totalCount:0,
      },
      perPageList_:[
        { name: '10개', value:10 },
        { name: '20개', value:20 },
        { name: '30개', value:30 },
      ]
    };
  },
  computed:{
    perPageList(){
      return this.perPageList_.map( item => {
        return {
          ...item,
          select: item.value === this.query.perPage ? true : false
        }
      });
    },
  },
  methods: {
    fnResetInputFile() {
      this.$refs.inputFile.fnResetValue();
      this.file = null;
    },
    fnResetForm(){
      const newQuery = {
        page: 1,
        bizNo: null,
        compNm: null,
      };
      this.query = {
        ...this.query,
        ...newQuery
      }
      this.fnFetchList();
    },
    fnResetPageAndFetch(){
      this.query.page=1;
      this.fnFetchList();
    },
    fnFetchList(){
      const params = this.$generateQueryParam(this.query);
      this.$axios.get('/apiMngAo/mainbiz/member?'+params)
        .then(res=> {
          if(res.data.result === true){
            const {contents, pagination} = res.data.data;
            this.contents = contents;
            this.pagination = pagination;
          }
        });
    },
    fnFileInfo(){
      this.$axios.get('/apiMngAo/mainbiz/file')
        .then(res=> {
          if(res.data.result === true){
            const {contents} = res.data.data;
            this.fileInfo = contents;
          }
        });
    },
    fnFileDownload(fileId) {
      window.open(`/apiMngAo/mainbiz/filesDownloads/${fileId}`, '_blank');
    },
    fnSelectType(payload, item) {
      const payload2 = {
        bizNo: item.bizNo,
        memberType: payload.value2
      }
      this.$axios.put('/apiMngAo/mainbiz/member', payload2).then(res=>{
        if(res.data.result === true){
          item.memberType = payload.value;
        } else{
          this._alert('실패했습니다.')
        }
      })
    },
    fnFileUpload() {
      if (!this.file) {
        this._alert('알림', '파일을 선택해주세요');
        return;
      }

      const btn = {
        name: '확인',
        func: () => {
          this._closePop();
          this.loading = true;
          mainbizService.uploadMainbizFile(this.$axios, { uploadFile: this.file }).then(({ result, data }) => {
            this.loading = false;
            if (result) {
              this._alert('알림', '파일이 업로드되었습니다.');
              this.fnFileInfo();
              this.fnResetForm();
            } else {
              this._alert('알림', `파일을 업로드하는데 실패하였습니다. (${data.error.msg})`);
              console.error(data);
            }
            this.fnResetInputFile();
          });
        },
      };
      this._confirm('확인', `파일을 업로드하시겠습니까?`, btn);
    },
    _alert(title, msg) {
      let data = {
        state: true,
        type: 'alert',
        title: title,
        msg: msg,
        btn: {
          name: '확인',
        },
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _confirm(title, msg, btn) {
      let data = {
        state: true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closePop() {
      this.$store.dispatch('ui/setPopAlertData', {
        state: false,
        type: null,
        title: null,
        msg: null,
        btn: {
          link: '',
          name: null,
        },
      });
    },
  },
  mounted() {
    this.fnFetchList();
    this.fnFileInfo();
  },
};
</script>
