<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>경영진단 등록</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <div class="btns mb5">
              <button class="btn" @click="fnSaveData">등록</button>
              <button class="btn dark" @click="fnClosePop">닫기</button>
            </div>
          </div>
          <div class="c_contents">
            <div class="tbl_doc_scroll full mt10">
              <div class="t_inner">
                <div class="t_cont">
                  <div class="item b_double">
                    <div class="cel" style="width: 100px">사업자번호</div>
                    <div class="cel" style="width: 300px">
                      <input type="text" class="ipt_cel" placeholder="사업자번호" v-model="bizNo" @input="bizNo = $event.target.value.replace(/[^0-9]/g, '')" maxlength="10" />
                    </div>
                  </div>
                  <div class="item b_double">
                    <div class="cel" style="width: 400px"><input type="text" class="ipt_cel" placeholder="회사명" v-model="입력개요.회사명" /></div>
                    <div class="t_group">
                      <div class="row">
                        <div class="cel" style="width: 100px; background-color: #f4f4f4">주소</div>
                        <div class="cel" style="width: 500px"><input type="text" class="ipt_cel" placeholder="주소" v-model="입력개요.주소" /></div>
                      </div>
                      <div class="row">
                        <div class="cel" style="width: 100px; background-color: #f4f4f4">설립일</div>
                        <div class="c_group" style="width: 700px">
                          <div class="cel_col" style="width: 200px"><input type="date" class="ipt_cel" placeholder="설립일" v-model="입력개요.설립일" /></div>
                          <div class="cel_col" style="width: 100px; background-color: #f4f4f4">연락처</div>
                          <div class="cel_col" style="width: 200px"><input type="text" class="ipt_cel" placeholder="연락처" v-model="입력개요.연락처" /></div>
                          <div class="cel_col" style="width: 100px; background-color: #f4f4f4">신용등급</div>
                          <div class="cel_col" style="width: 100px; background-color: #f4f4f4">현금등급</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item b_double">
                    <div class="cel border-bottom" style="width: 100px; background-color: #f4f4f4">업종</div>
                    <div class="cel border-bottom" style="width: 100px"><input type="text" class="ipt_cel" placeholder="업종" v-model="입력개요.업종" /></div>
                    <div class="cel border-bottom" style="width: 100px; background-color: #f4f4f4">주요품목</div>
                    <div class="cel border-bottom" style="width: 100px"><input type="text" class="ipt_cel" placeholder="주요품목" v-model="입력개요.주요품목" /></div>
                    <div class="cel border-bottom" style="width: 100px; background-color: #f4f4f4">자본금</div>
                    <div class="cel border-bottom" style="width: 200px">{{ 입력개요.자본금_억 && Number(입력개요.자본금_억).toFixed(1) + '억' }}</div>
                    <div class="cel border-bottom" style="width: 100px">
                      <input
                        type="text"
                        class="ipt_cel"
                        placeholder="(단위:주)"
                        v-model="입력개요.자본금_주"
                        @input="입력개요.자본금_주 = $event.target.value.replace(/[^0-9]/g, '')"
                        @blur="
                          () => {
                            fn자본금_억갱신();
                            fn주요주주지분율갱신();
                          }
                        " />
                    </div>
                    <div class="cel border-bottom" style="width: 200px">
                      <input
                        type="text"
                        class="ipt_cel"
                        placeholder="(단위:원)"
                        v-model="입력개요.자본금_원"
                        @input="입력개요.자본금_원 = $event.target.value.replace(/[^0-9]/g, '')"
                        @blur="fn자본금_억갱신()" />
                    </div>
                    <div class="cel border-bottom" style="width: 100px"><input type="text" class="ipt_cel" placeholder="신용등급" v-model="입력개요.신용등급" /></div>
                    <div class="cel border-bottom" style="width: 100px"><input type="text" class="ipt_cel" placeholder="현금등급" v-model="입력개요.현금등급" /></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt20" style="width: 1200px; min-height: 200px">
              <div class="colb">
                <div style="display: flex">
                  <h2>경영진 현황</h2>
                  <button class="btn ml5 mb5" @click="fn경영진추가()">추가</button>
                </div>
                <div class="pt5">
                  <label for="date">기준일: </label>
                  <input type="date" id="date" v-model="입력개요.경영진현황.기준일" />
                </div>
              </div>
              <div class="tbl_doc_scroll full">
                <div class="t_inner">
                  <div class="t_top">
                    <div class="item">
                      <div class="cel" style="width: 150px">구분</div>
                      <div class="cel" style="width: 150px">성명</div>
                      <div class="cel" style="width: 150px">중임기산일</div>
                      <div class="cel" style="width: 150px">생년월일</div>
                      <div class="cel" style="width: 150px">실권자관계</div>
                      <div class="cel" style="width: 150px">임원취임일</div>
                      <div class="cel" style="width: 150px">근속연수</div>
                      <div class="cel" style="width: 150px">중임예정일</div>
                    </div>
                  </div>
                  <div class="t_cont">
                    <div class="item" v-for="(el, idx) in 입력개요.경영진현황.경영진" :key="idx">
                      <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="구분" v-model="el.구분" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="성명" v-model="el.성명" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                        <input type="date" class="ipt_cel" placeholder="설립일" v-model="el.중임기산일" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="생년월일" v-model="el.생년월일" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="실권자관계" v-model="el.실권자관계" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                        <input type="date" class="ipt_cel" placeholder="설립일" v-model="el.임원취임일" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="근속연수" v-model="el.근속연수" @input="el.근속연수 = $event.target.value.replace(/[^0-9]/g, '')" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                        <input type="date" class="ipt_cel" placeholder="설립일" v-model="el.중임예정일" />
                      </div>
                      <div><button class="btn dark ml5 mt5" @click="fn경영진삭제(idx)">-</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt20 pb20" style="width: 1200px">
              <div class="colb">
                <div style="display: flex">
                  <h2>주요 주주 현황</h2>
                  <button class="btn ml5 mb5" @click="fn주요주주추가()">추가</button>
                </div>
                <div class="pt5">
                  <label for="date">기준일: </label>
                  <input type="date" id="date" v-model="입력개요.주요주주현황.기준일" />
                </div>
              </div>
              <div class="tbl_doc_scroll full">
                <div class="t_inner">
                  <div class="t_top">
                    <div class="item">
                      <div class="cel" style="width: 150px">주주명</div>
                      <div class="cel" style="width: 150px">보통주식수</div>
                      <div class="cel" style="width: 150px">지분율(%)</div>
                      <div class="cel" style="width: 150px">취득가</div>
                      <div class="cel" style="width: 150px">실권자관계</div>
                      <div class="cel" style="width: 150px">취득일</div>
                      <div class="cel" style="width: 150px">회사와의 관계</div>
                      <div class="cel" style="width: 150px">발기인</div>
                    </div>
                  </div>
                  <div class="t_cont">
                    <div class="item" v-for="(el, idx) in 입력개요.주요주주현황.주요주주" :key="idx">
                      <div class="cel" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="주주명" v-model="el.주주명" />
                      </div>
                      <div class="cel" style="width: 150px">{{ el.보통주식수 && fnFormatNumber(Number(el.보통주식수).toFixed(0)) }}</div>
                      <div class="cel" style="width: 150px">
                        <input
                          type="text"
                          class="ipt_cel"
                          placeholder="지분율(%)"
                          v-model="el.지분율"
                          @input="el.지분율 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn주요주주지분율갱신()" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.주요주주현황.주요주주.length - 1 == idx }" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="취득가" v-model="el.취득가" @input="el.취득가 = $event.target.value.replace(/[^0-9]/g, '')" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.주요주주현황.주요주주.length - 1 == idx }" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="실권자관계" v-model="el.실권자관계" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.주요주주현황.주요주주.length - 1 == idx }" style="width: 150px">
                        <input type="date" class="ipt_cel" v-model="el.취득일" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.주요주주현황.주요주주.length - 1 == idx }" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="회사와의관계" v-model="el.회사와의관계" />
                      </div>
                      <div class="cel" :class="{ 'border-bottom': 입력개요.주요주주현황.주요주주.length - 1 == idx }" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="발기인" v-model="el.발기인" />
                      </div>
                      <div><button class="btn dark ml5 mt5" @click="fn주요주주삭제(idx)">-</button></div>
                    </div>
                    <div class="item">
                      <div class="cel border-bottom" style="width: 150px">합계</div>
                      <div class="cel border-bottom" style="width: 150px">{{ 입력개요.주요주주현황.보통주식수 && fnFormatNumber(Number(입력개요.주요주주현황.보통주식수).toFixed(0)) }}</div>
                      <div class="cel border-bottom" style="width: 150px">{{ 입력개요.주요주주현황.지분율 && Number(입력개요.주요주주현황.지분율).toFixed(2) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt20 pb30" style="width: 1200px">
              <h2>종업원 현황</h2>
              <div class="tbl_doc_scroll full">
                <div class="t_inner">
                  <div class="t_top">
                    <div class="item">
                      <div class="cel" style="width: 150px">구분</div>
                      <div class="cel" style="width: 150px">생산직</div>
                      <div class="cel" style="width: 150px">사무직</div>
                      <div class="cel" style="width: 150px">총원</div>
                      <div class="cel" style="width: 150px">임금제도</div>
                      <div class="cel" style="width: 150px">근로계약서</div>
                      <div class="cel" style="width: 150px">취업규칙</div>
                      <div class="cel" style="width: 150px">성희롱예방교육</div>
                    </div>
                  </div>
                  <div class="t_cont">
                    <div class="item">
                      <div class="cel border-bottom" style="width: 150px">인원수</div>
                      <div class="cel border-bottom" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="생산직" v-model="입력개요.종업원현황.생산직" @input="입력개요.종업원현황.생산직 = $event.target.value.replace(/[^0-9]/g, '')" />
                      </div>
                      <div class="cel border-bottom" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="사무직" v-model="입력개요.종업원현황.사무직" @input="입력개요.종업원현황.사무직 = $event.target.value.replace(/[^0-9]/g, '')" />
                      </div>
                      <div class="cel border-bottom" style="width: 150px">
                        <input type="text" class="ipt_cel" placeholder="총원" v-model="입력개요.종업원현황.총원" @input="입력개요.종업원현황.총원 = $event.target.value.replace(/[^0-9]/g, '')" />
                      </div>
                      <div class="cel border-bottom" style="width: 150px">
                        <input
                          type="text"
                          class="ipt_cel"
                          placeholder="임금제도"
                          v-model="입력개요.종업원현황.임금제도"
                          @input="입력개요.종업원현황.임금제도 = $event.target.value.replace(/[^0-9]/g, '')" />
                      </div>
                      <div class="cel border-bottom" style="width: 150px">
                        <input
                          type="text"
                          class="ipt_cel"
                          placeholder="근로계약서"
                          v-model="입력개요.종업원현황.근로계약서"
                          @input="입력개요.종업원현황.근로계약서 = $event.target.value.replace(/[^0-9]/g, '')" />
                      </div>
                      <div class="cel border-bottom" style="width: 150px">
                        <input
                          type="text"
                          class="ipt_cel"
                          placeholder="취업규칙"
                          v-model="입력개요.종업원현황.취업규칙"
                          @input="입력개요.종업원현황.취업규칙 = $event.target.value.replace(/[^0-9]/g, '')" />
                      </div>
                      <div class="cel border-bottom" style="width: 150px">
                        <input
                          type="text"
                          class="ipt_cel"
                          placeholder="성희롱예방교육"
                          v-model="입력개요.종업원현황.성희롱예방교육"
                          @input="입력개요.종업원현황.성희롱예방교육 = $event.target.value.replace(/[^0-9]/g, '')" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt20">
              <div style="display: flex">
                <h2>요약 재무상태표</h2>
                <div class="ml10 pt10" style="font-weight: bold">(단위:백만원)</div>
              </div>
              <div class="tbl_doc_scroll full">
                <div class="t_inner">
                  <div class="t_top">
                    <div class="item">
                      <div class="cel" style="width: 100px">구분</div>
                      <div class="cel" style="width: 100px">
                        <Select defaultName="연도" :itemList="yearList" :value="입력재무.year1" @change="fnSelectYear" />
                      </div>
                      <div class="cel" style="width: 100px">{{ 입력재무.year2 && `${입력재무.year2}년` }}</div>
                      <div class="cel" style="width: 100px">{{ 입력재무.year3 && `${입력재무.year3}년` }}</div>
                    </div>
                  </div>
                  <div class="t_cont">
                    <div class="item">
                      <div class="cel" style="width: 100px">유동자산</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.유동자산.year1" @input="입력재무.유동자산.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.유동자산.year2" @input="입력재무.유동자산.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.유동자산.year3" @input="입력재무.유동자산.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 600px">가지급금 추정액</div>
                      <div class="cel" style="width: 150px">가지급 금액</div>
                      <div class="cel" style="width: 300px">차입금액</div>
                      <div class="cel" style="width: 150px">이자비용</div>
                    </div>
                    <div class="item">
                      <div class="t_group">
                        <div class="row">
                          <div class="cel" style="width: 100px">가지급금</div>
                          <div class="cel" style="width: 100px">
                            <input type="text" class="ipt_cel" v-model="입력재무.가지급금.year1" @input="입력재무.가지급금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                          </div>
                          <div class="cel" style="width: 100px">
                            <input type="text" class="ipt_cel" v-model="입력재무.가지급금.year2" @input="입력재무.가지급금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                          </div>
                          <div class="cel" style="width: 100px">
                            <input type="text" class="ipt_cel" v-model="입력재무.가지급금.year3" @input="입력재무.가지급금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                          </div>
                          <div class="cel" style="width: 300px">미수수익</div>
                          <div class="cel" style="width: 300px">이자수익</div>
                        </div>
                        <div class="row">
                          <div class="cel" style="width: 100px">주임종단기대여금</div>
                          <div class="cel" style="width: 100px">
                            <input type="text" class="ipt_cel" v-model="입력재무.주임종단기대여금.year1" @input="입력재무.주임종단기대여금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                          </div>
                          <div class="cel" style="width: 100px">
                            <input type="text" class="ipt_cel" v-model="입력재무.주임종단기대여금.year2" @input="입력재무.주임종단기대여금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                          </div>
                          <div class="cel" style="width: 100px">
                            <input type="text" class="ipt_cel" v-model="입력재무.주임종단기대여금.year3" @input="입력재무.주임종단기대여금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                          </div>
                          <div class="cel" style="width: 100px">{{ 입력재무.year1 && `${입력재무.year1}년` }}</div>
                          <div class="cel" style="width: 100px">{{ 입력재무.year2 && `${입력재무.year2}년` }}</div>
                          <div class="cel" style="width: 100px">{{ 입력재무.year3 && `${입력재무.year3}년` }}</div>
                          <div class="cel" style="width: 100px">{{ 입력재무.year1 && `${입력재무.year1}년` }}</div>
                          <div class="cel" style="width: 100px">{{ 입력재무.year2 && `${입력재무.year2}년` }}</div>
                          <div class="cel" style="width: 100px">{{ 입력재무.year3 && `${입력재무.year3}년` }}</div>
                        </div>
                      </div>
                      <div class="cel" style="width: 150px"></div>
                      <div class="t_group">
                        <div class="row">
                          <div class="cel" style="width: 100px">단기차입금</div>
                          <div class="cel" style="width: 200px">{{ 입력재무.차입금액.단기차입금 && fnFormatNumber(Number(입력재무.차입금액.단기차입금).toFixed(0)) }}</div>
                        </div>
                        <div class="row">
                          <div class="cel" style="width: 100px">장기차입금</div>
                          <div class="cel" style="width: 200px">{{ 입력재무.차입금액.장기차입금 && fnFormatNumber(Number(입력재무.차입금액.장기차입금).toFixed(0)) }}</div>
                        </div>
                      </div>
                      <div class="cel" style="width: 150px"></div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">미수수익</div>
                      <div class="cel" style="width: 100px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력재무.미수수익.year1"
                          @input="입력재무.미수수익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn가지급금추정액_미수수익계산('year1')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력재무.미수수익.year2"
                          @input="입력재무.미수수익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn가지급금추정액_미수수익계산('year2')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력재무.미수수익.year3"
                          @input="입력재무.미수수익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn가지급금추정액_미수수익계산('year3')" />
                      </div>
                      <div class="cel" style="width: 100px">{{ 입력재무.가지급금추정액.미수수익.year1 && fnFormatNumber(Number(입력재무.가지급금추정액.미수수익.year1).toFixed(0)) }}</div>
                      <div class="cel" style="width: 100px">{{ 입력재무.가지급금추정액.미수수익.year2 && fnFormatNumber(Number(입력재무.가지급금추정액.미수수익.year2).toFixed(0)) }}</div>
                      <div class="cel" style="width: 100px">{{ 입력재무.가지급금추정액.미수수익.year3 && fnFormatNumber(Number(입력재무.가지급금추정액.미수수익.year3).toFixed(0)) }}</div>
                      <div class="cel" style="width: 100px">{{ 입력재무.가지급금추정액.이자수익.year1 && fnFormatNumber(Number(입력재무.가지급금추정액.이자수익.year1).toFixed(0)) }}</div>
                      <div class="cel border-bottom" style="width: 100px">
                        {{ 입력재무.가지급금추정액.이자수익.year2 && fnFormatNumber(Number(입력재무.가지급금추정액.이자수익.year2).toFixed(0)) }}
                      </div>
                      <div class="cel border-bottom" style="width: 100px">
                        {{ 입력재무.가지급금추정액.이자수익.year3 && fnFormatNumber(Number(입력재무.가지급금추정액.이자수익.year3).toFixed(0)) }}
                      </div>
                      <div class="cel border-bottom" style="width: 150px">
                        <input type="text" class="ipt_cel" v-model="입력재무.가지급금액" @input="입력재무.가지급금액 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel border-bottom" style="width: 100px">합계</div>
                      <div class="cel border-bottom" style="width: 200px">{{ 입력재무.차입금액.합계 && fnFormatNumber(Number(입력재무.차입금액.합계).toFixed(0)) }}</div>
                      <div class="cel border-bottom" style="width: 150px">{{ 입력재무.이자비용 && fnFormatNumber(Number(입력재무.이자비용).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">비유동자산</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.비유동자산.year1" @input="입력재무.비유동자산.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.비유동자산.year2" @input="입력재무.비유동자산.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.비유동자산.year3" @input="입력재무.비유동자산.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">토지</div>
                      <div class="cel" style="width: 100px">건물</div>
                      <div class="cel" style="width: 100px">구축물</div>
                      <div class="cel" style="width: 100px">부동산합계</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">부동산 자산</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.부동산자산.year1" @input="입력재무.부동산자산.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.부동산자산.year2" @input="입력재무.부동산자산.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.부동산자산.year3" @input="입력재무.부동산자산.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel border-bottom" style="width: 100px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력재무.가지급금추정액.토지"
                          @input="입력재무.가지급금추정액.토지 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn가지급금추정액_부동산합계계산()" />
                      </div>
                      <div class="cel border-bottom" style="width: 100px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력재무.가지급금추정액.건물"
                          @input="입력재무.가지급금추정액.건물 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn가지급금추정액_부동산합계계산()" />
                      </div>
                      <div class="cel border-bottom" style="width: 100px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력재무.가지급금추정액.구축물"
                          @input="입력재무.가지급금추정액.구축물 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn가지급금추정액_부동산합계계산()" />
                      </div>
                      <div class="cel border-bottom" style="width: 100px">{{ 입력재무.가지급금추정액.부동산합계 && fnFormatNumber(Number(입력재무.가지급금추정액.부동산합계).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">자산총계</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자산총계.year1" @input="입력재무.자산총계.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자산총계.year2" @input="입력재무.자산총계.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자산총계.year3" @input="입력재무.자산총계.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">유동부채</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.유동부채.year1" @input="입력재무.유동부채.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.유동부채.year2" @input="입력재무.유동부채.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.유동부채.year3" @input="입력재무.유동부채.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">단기차입금</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.단기차입금.year1" @input="입력재무.단기차입금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.단기차입금.year2" @input="입력재무.단기차입금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력재무.단기차입금.year3"
                          @input="입력재무.단기차입금.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn차입금액_단기차입금계산()" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">가수금</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.가수금.year1" @input="입력재무.가수금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.가수금.year2" @input="입력재무.가수금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.가수금.year3" @input="입력재무.가수금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">비유동부채</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.비유동부채.year1" @input="입력재무.비유동부채.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.비유동부채.year2" @input="입력재무.비유동부채.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.비유동부채.year3" @input="입력재무.비유동부채.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">장기차입금</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.장기차입금.year1" @input="입력재무.장기차입금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.장기차입금.year2" @input="입력재무.장기차입금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력재무.장기차입금.year3"
                          @input="입력재무.장기차입금.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn차입금액_장기차입금계산()" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">퇴직급여충당부채</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.퇴직급여충당부채.year1" @input="입력재무.퇴직급여충당부채.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.퇴직급여충당부채.year2" @input="입력재무.퇴직급여충당부채.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.퇴직급여충당부채.year3" @input="입력재무.퇴직급여충당부채.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">퇴직연금운용자산</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.퇴직연금운용자산.year1" @input="입력재무.퇴직연금운용자산.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.퇴직연금운용자산.year2" @input="입력재무.퇴직연금운용자산.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.퇴직연금운용자산.year3" @input="입력재무.퇴직연금운용자산.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">부채총계</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.부채총계.year1" @input="입력재무.부채총계.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.부채총계.year2" @input="입력재무.부채총계.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.부채총계.year3" @input="입력재무.부채총계.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">자본금</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자본금.year1" @input="입력재무.자본금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자본금.year2" @input="입력재무.자본금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자본금.year3" @input="입력재무.자본금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">자본조정</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자본조정.year1" @input="입력재무.자본조정.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자본조정.year2" @input="입력재무.자본조정.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자본조정.year3" @input="입력재무.자본조정.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">이익준비금</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.이익준비금.year1" @input="입력재무.이익준비금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.이익준비금.year2" @input="입력재무.이익준비금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.이익준비금.year3" @input="입력재무.이익준비금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 100px">미처분이익잉여금</div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.미처분이익잉여금.year1" @input="입력재무.미처분이익잉여금.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.미처분이익잉여금.year2" @input="입력재무.미처분이익잉여금.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.미처분이익잉여금.year3" @input="입력재무.미처분이익잉여금.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel border-bottom" style="width: 100px">자본총계</div>
                      <div class="cel border-bottom" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자본총계.year1" @input="입력재무.자본총계.year1 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel border-bottom" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자본총계.year2" @input="입력재무.자본총계.year2 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                      <div class="cel border-bottom" style="width: 100px">
                        <input type="text" class="ipt_cel" v-model="입력재무.자본총계.year3" @input="입력재무.자본총계.year3 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt20 pb30">
              <div style="display: flex">
                <h2>요약 손익계산서</h2>
                <div class="ml10 pt10" style="font-weight: bold">(단위:백만원)</div>
              </div>
              <div class="tbl_doc_scroll full">
                <div class="t_inner">
                  <div class="t_top">
                    <div class="item">
                      <div class="cel" style="width: 120px">구분</div>
                      <div class="cel" style="width: 120px">{{ 입력손익.year1 && `${입력손익.year1}년` }}</div>
                      <div class="cel" style="width: 120px">{{ 입력손익.year2 && `${입력손익.year2}년` }}</div>
                      <div class="cel" style="width: 120px">{{ 입력손익.year3 && `${입력손익.year3}년` }}</div>
                      <div class="cel" style="width: 120px">3년평균</div>
                      <div class="cel" style="width: 240px; background-color: white">매출액증가율 가정(%)</div>
                    </div>
                  </div>
                  <div class="t_cont">
                    <div class="item">
                      <div class="cel" style="width: 120px">매출액</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.매출액.year1"
                          @input="입력손익.매출액.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('매출액');
                              fn영업이익률계산('year1');
                              fn순이익률계산('year1');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.매출액.year2"
                          @input="입력손익.매출액.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('매출액');
                              fn영업이익률계산('year2');
                              fn순이익률계산('year2');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.매출액.year3"
                          @input="입력손익.매출액.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('매출액');
                              fn영업이익률계산('year3');
                              fn순이익률계산('year3');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.매출액['3년평균'] && fnFormatNumber(Number(입력손익.매출액['3년평균']).toFixed(0)) }}</div>
                      <div class="cel border-bottom" style="width: 240px">
                        <input type="text" class="ipt_cel" v-model="입력손익.매출액증가율_가정" @input="입력손익.매출액증가율_가정 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">매출총이익</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.매출총이익.year1"
                          @input="입력손익.매출총이익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('매출총이익')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.매출총이익.year2"
                          @input="입력손익.매출총이익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('매출총이익')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.매출총이익.year3"
                          @input="입력손익.매출총이익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('매출총이익')" />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.매출총이익['3년평균'] && fnFormatNumber(Number(입력손익.매출총이익['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">판매비와관리비</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.판매비와관리비.year1"
                          @input="입력손익.판매비와관리비.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('판매비와관리비')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.판매비와관리비.year2"
                          @input="입력손익.판매비와관리비.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('판매비와관리비')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.판매비와관리비.year3"
                          @input="입력손익.판매비와관리비.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('판매비와관리비')" />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.판매비와관리비['3년평균'] && fnFormatNumber(Number(입력손익.판매비와관리비['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">영업이익</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.영업이익.year1"
                          @input="입력손익.영업이익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('영업이익');
                              fn영업이익률계산('year1');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.영업이익.year2"
                          @input="입력손익.영업이익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('영업이익');
                              fn영업이익률계산('year2');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.영업이익.year3"
                          @input="입력손익.영업이익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('영업이익');
                              fn영업이익률계산('year3');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.영업이익['3년평균'] && fnFormatNumber(Number(입력손익.영업이익['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">영업외수익</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.영업외수익.year1"
                          @input="입력손익.영업외수익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('영업외수익')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.영업외수익.year2"
                          @input="입력손익.영업외수익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('영업외수익')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.영업외수익.year3"
                          @input="입력손익.영업외수익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('영업외수익')" />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.영업외수익['3년평균'] && fnFormatNumber(Number(입력손익.영업외수익['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">이자수익</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.이자수익.year1"
                          @input="입력손익.이자수익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('이자수익');
                              fn가지급금추정액_이자수익계산('year1');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.이자수익.year2"
                          @input="입력손익.이자수익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('이자수익');
                              fn가지급금추정액_이자수익계산('year2');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.이자수익.year3"
                          @input="입력손익.이자수익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('이자수익');
                              fn가지급금추정액_이자수익계산('year3');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.이자수익['3년평균'] && fnFormatNumber(Number(입력손익.이자수익['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">기타</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.기타수익.year1"
                          @input="입력손익.기타수익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('기타수익')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.기타수익.year2"
                          @input="입력손익.기타수익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('기타수익')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.기타수익.year3"
                          @input="입력손익.기타수익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('기타수익')" />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.기타수익['3년평균'] && fnFormatNumber(Number(입력손익.기타수익['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">영업외비용</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.영업외비용.year1"
                          @input="입력손익.영업외비용.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('영업외비용')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.영업외비용.year2"
                          @input="입력손익.영업외비용.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('영업외비용')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.영업외비용.year3"
                          @input="입력손익.영업외비용.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('영업외비용')" />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.영업외비용['3년평균'] && fnFormatNumber(Number(입력손익.영업외비용['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">이자비용</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.이자비용.year1"
                          @input="입력손익.이자비용.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('이자비용')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.이자비용.year2"
                          @input="입력손익.이자비용.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('이자비용')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.이자비용.year3"
                          @input="입력손익.이자비용.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('이자비용');
                              fn이자비용계산();
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.이자비용['3년평균'] && fnFormatNumber(Number(입력손익.이자비용['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">기타</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.기타비용.year1"
                          @input="입력손익.기타비용.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('기타비용')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.기타비용.year2"
                          @input="입력손익.기타비용.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('기타비용')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.기타비용.year3"
                          @input="입력손익.기타비용.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('기타비용')" />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.기타비용['3년평균'] && fnFormatNumber(Number(입력손익.기타비용['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">비용차감전손익</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.비용차감전손익.year1"
                          @input="입력손익.비용차감전손익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('비용차감전손익')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.비용차감전손익.year2"
                          @input="입력손익.비용차감전손익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('비용차감전손익')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.비용차감전손익.year3"
                          @input="입력손익.비용차감전손익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('비용차감전손익')" />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.비용차감전손익['3년평균'] && fnFormatNumber(Number(입력손익.비용차감전손익['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">법인세비용</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.법인세비용.year1"
                          @input="입력손익.법인세비용.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('법인세비용')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.법인세비용.year2"
                          @input="입력손익.법인세비용.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('법인세비용')" />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.법인세비용.year3"
                          @input="입력손익.법인세비용.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="fn3년평균계산('법인세비용')" />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.법인세비용['3년평균'] && fnFormatNumber(Number(입력손익.법인세비용['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">당기순이익</div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.당기순이익.year1"
                          @input="입력손익.당기순이익.year1 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('당기순이익');
                              fn순이익률계산('year1');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.당기순이익.year2"
                          @input="입력손익.당기순이익.year2 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('당기순이익');
                              fn순이익률계산('year2');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">
                        <input
                          type="text"
                          class="ipt_cel"
                          v-model="입력손익.당기순이익.year3"
                          @input="입력손익.당기순이익.year3 = $event.target.value.replace(/[^0-9.]/g, '')"
                          @blur="
                            () => {
                              fn3년평균계산('당기순이익');
                              fn순이익률계산('year3');
                            }
                          " />
                      </div>
                      <div class="cel" style="width: 120px">{{ 입력손익.당기순이익['3년평균'] && fnFormatNumber(Number(입력손익.당기순이익['3년평균']).toFixed(0)) }}</div>
                    </div>
                    <div class="item">
                      <div class="cel" style="width: 120px">영업이익률</div>
                      <div class="cel" style="width: 120px">{{ 입력손익.영업이익률.year1 && (Number(입력손익.영업이익률.year1) * 100).toFixed(1) + '%' }}</div>
                      <div class="cel" style="width: 120px">{{ 입력손익.영업이익률.year2 && (Number(입력손익.영업이익률.year2) * 100).toFixed(1) + '%' }}</div>
                      <div class="cel" style="width: 120px">{{ 입력손익.영업이익률.year3 && (Number(입력손익.영업이익률.year3) * 100).toFixed(1) + '%' }}</div>
                      <div class="cel" style="width: 120px">{{ 입력손익.영업이익률['3년평균'] && (Number(입력손익.영업이익률['3년평균']) * 100).toFixed(1) + '%' }}</div>
                      <div class="cel" style="width: 240px">미래수익률 가정(%)</div>
                    </div>
                    <div class="item">
                      <div class="cel border-bottom" style="width: 120px">순이익률</div>
                      <div class="cel border-bottom" style="width: 120px">{{ 입력손익.순이익률.year1 && (Number(입력손익.순이익률.year1) * 100).toFixed(1) + '%' }}</div>
                      <div class="cel border-bottom" style="width: 120px">{{ 입력손익.순이익률.year2 && (Number(입력손익.순이익률.year2) * 100).toFixed(1) + '%' }}</div>
                      <div class="cel border-bottom" style="width: 120px">{{ 입력손익.순이익률.year3 && (Number(입력손익.순이익률.year3) * 100).toFixed(1) + '%' }}</div>
                      <div class="cel border-bottom" style="width: 120px">{{ 입력손익.순이익률['3년평균'] && (Number(입력손익.순이익률['3년평균']) * 100).toFixed(1) + '%' }}</div>
                      <div class="cel border-bottom" style="width: 240px">
                        <input type="text" class="ipt_cel" v-model="입력손익.미래수익률_가정" @input="입력손익.미래수익률_가정 = $event.target.value.replace(/[^0-9.]/g, '')" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import diagnosisService from '~/service/allOkMng/diagnosisService.js';
import Select from '../../common/Select.vue';
export default {
  components: {
    Select,
  },
  data() {
    return {
      bizNo: '',
      입력개요: {
        회사명: '',
        주소: '',
        설립일: '',
        연락처: '',
        업종: '',
        주요품목: '',
        자본금_억: '',
        자본금_주: '',
        자본금_원: '',
        신용등급: '',
        현금등급: '',
        경영진현황: {
          기준일: '',
          경영진: [],
        },
        주요주주현황: {
          기준일: '',
          주요주주: [],
          보통주식수: '',
          지분율: '',
        },
        종업원현황: {
          생산직: '',
          사무직: '',
          총원: '',
          임금제도: '',
          근로계약서: '',
          취업규칙: '',
          성희롱예방교육: '',
        },
      },
      입력재무: {
        year1: '',
        year2: '',
        year3: '',
        유동자산: { year1: '', year2: '', year3: '' },
        가지급금: { year1: '', year2: '', year3: '' },
        주임종단기대여금: { year1: '', year2: '', year3: '' },
        미수수익: { year1: '', year2: '', year3: '' },
        비유동자산: { year1: '', year2: '', year3: '' },
        부동산자산: { year1: '', year2: '', year3: '' },
        자산총계: { year1: '', year2: '', year3: '' },
        유동부채: { year1: '', year2: '', year3: '' },
        단기차입금: { year1: '', year2: '', year3: '' },
        가수금: { year1: '', year2: '', year3: '' },
        비유동부채: { year1: '', year2: '', year3: '' },
        장기차입금: { year1: '', year2: '', year3: '' },
        퇴직급여충당부채: { year1: '', year2: '', year3: '' },
        퇴직연금운용자산: { year1: '', year2: '', year3: '' },
        부채총계: { year1: '', year2: '', year3: '' },
        자본금: { year1: '', year2: '', year3: '' },
        자본조정: { year1: '', year2: '', year3: '' },
        이익준비금: { year1: '', year2: '', year3: '' },
        미처분이익잉여금: { year1: '', year2: '', year3: '' },
        자본총계: { year1: '', year2: '', year3: '' },
        가지급금추정액: {
          미수수익: { year1: '', year2: '', year3: '' },
          이자수익: { year1: '', year2: '', year3: '' },
          토지: '',
          건물: '',
          구축물: '',
          부동산합계: '',
        },
        가지급금액: '',
        차입금액: {
          단기차입금: '',
          장기차입금: '',
          합계: '',
        },
        이자비용: '',
      },
      입력손익: {
        year1: '',
        year2: '',
        year3: '',
        매출액: { year1: '', year2: '', year3: '', '3년평균': '' },
        매출총이익: { year1: '', year2: '', year3: '', '3년평균': '' },
        판매비와관리비: { year1: '', year2: '', year3: '', '3년평균': '' },
        영업이익: { year1: '', year2: '', year3: '', '3년평균': '' },
        영업외수익: { year1: '', year2: '', year3: '', '3년평균': '' },
        이자수익: { year1: '', year2: '', year3: '', '3년평균': '' },
        기타수익: { year1: '', year2: '', year3: '', '3년평균': '' },
        영업외비용: { year1: '', year2: '', year3: '', '3년평균': '' },
        이자비용: { year1: '', year2: '', year3: '', '3년평균': '' },
        기타비용: { year1: '', year2: '', year3: '', '3년평균': '' },
        비용차감전손익: { year1: '', year2: '', year3: '', '3년평균': '' },
        법인세비용: { year1: '', year2: '', year3: '', '3년평균': '' },
        당기순이익: { year1: '', year2: '', year3: '', '3년평균': '' },
        영업이익률: { year1: '', year2: '', year3: '', '3년평균': '' },
        순이익률: { year1: '', year2: '', year3: '', '3년평균': '' },
        매출액증가율_가정: '',
        미래수익률_가정: '',
      },
      yearList: [
        { name: '2024', value: '2024' },
        { name: '2023', value: '2023' },
        { name: '2022', value: '2022' },
        { name: '2021', value: '2021' },
        { name: '2020', value: '2020' },
        { name: '2019', value: '2019' },
        { name: '2018', value: '2018' },
        { name: '2017', value: '2017' },
        { name: '2016', value: '2016' },
        { name: '2015', value: '2015' },
      ],
    };
  },

  methods: {
    fn경영진추가() {
      this.입력개요.경영진현황.경영진.push({
        구분: '',
        성명: '',
        중임기산일: '',
        생년월일: '',
        실권자관계: '',
        임원취임일: '',
        근속연수: '',
        중임예정일: '',
      });
    },
    fn경영진삭제(idx) {
      this.입력개요.경영진현황.경영진.splice(idx, 1);
    },
    fn주요주주추가() {
      this.입력개요.주요주주현황.주요주주.push({
        주주명: '',
        보통주식수: '',
        지분율: '',
        취득가: '',
        실권자관계: '',
        취득일: '',
        회사와의관계: '',
        발기인: '',
      });
    },
    fn주요주주삭제(idx) {
      this.입력개요.주요주주현황.주요주주.splice(idx, 1);
      this.fn주요주주지분율갱신();
    },
    fn주요주주지분율갱신() {
      this.입력개요.주요주주현황.주요주주.forEach(el => {
        el.보통주식수 = ((Number(el.지분율) / 100) * Number(this.입력개요.자본금_주)).toString();
      });

      //주요 주주 보통주식수합계 갱신
      this.입력개요.주요주주현황.보통주식수 = this.입력개요.주요주주현황.주요주주
        .reduce((acc, el) => {
          return acc + Number(el.보통주식수);
        }, 0)
        .toString();

      // 주요 주주 지분율합계 갱신
      this.입력개요.주요주주현황.지분율 = this.입력개요.주요주주현황.주요주주
        .reduce((acc, el) => {
          return acc + Number(el.지분율);
        }, 0)
        .toString();
    },
    fn자본금_억갱신() {
      this.입력개요.자본금_억 = ((Number(this.입력개요.자본금_주) * Number(this.입력개요.자본금_원)) / 100000000).toString();
    },
    fnSelectYear({ value }) {
      const year1 = Number(value).toString();
      const year2 = (Number(value) + 1).toString();
      const year3 = (Number(value) + 2).toString();
      this.입력재무.year1 = year1;
      this.입력손익.year1 = year1;
      this.입력재무.year2 = year2;
      this.입력손익.year2 = year2;
      this.입력재무.year3 = year3;
      this.입력손익.year3 = year3;
    },
    fn가지급금추정액_미수수익계산(year) {
      const d = this.입력재무.미수수익[year];
      this.입력재무.가지급금추정액.미수수익[year] = ((Number(d) / 0.046) * 1000000).toString();
    },
    fn가지급금추정액_이자수익계산(year) {
      const d = this.입력손익.이자수익[year];
      this.입력재무.가지급금추정액.이자수익[year] = ((Number(d) / 0.046) * 1000000).toString();
    },
    fn차입금액_단기차입금계산() {
      const d = this.입력재무.단기차입금.year3;
      this.입력재무.차입금액.단기차입금 = (Number(d) * 1000000).toString();

      //차입금액 합계계산
      this.입력재무.차입금액.합계 = (Number(this.입력재무.차입금액.단기차입금) + Number(this.입력재무.차입금액.장기차입금)).toString();
    },
    fn차입금액_장기차입금계산() {
      const d = this.입력재무.장기차입금.year3;
      this.입력재무.차입금액.장기차입금 = (Number(d) * 1000000).toString();

      //차입금액 합계계산
      this.입력재무.차입금액.합계 = (Number(this.입력재무.차입금액.단기차입금) + Number(this.입력재무.차입금액.장기차입금)).toString();
    },
    fn이자비용계산() {
      const d = this.입력손익.이자비용.year3;
      this.입력재무.이자비용 = (Number(d) * 1000000).toString();
    },
    fn가지급금추정액_부동산합계계산() {
      this.입력재무.가지급금추정액.부동산합계 = (Number(this.입력재무.가지급금추정액.토지) + Number(this.입력재무.가지급금추정액.건물) + Number(this.입력재무.가지급금추정액.구축물)).toString();
    },
    fn3년평균계산(division) {
      const d1 = this.입력손익[division].year1;
      const d2 = this.입력손익[division].year2;
      const d3 = this.입력손익[division].year3;
      this.입력손익[division]['3년평균'] = ((Number(d1) + Number(d2) + Number(d3)) / 3).toString();
    },
    fn영업이익률계산(year) {
      const d1 = this.입력손익.영업이익[year];
      const d2 = this.입력손익.매출액[year];
      this.입력손익.영업이익률[year] = (Number(d1) / Number(d2)).toString();

      //영업이익률 3년평균 계산
      this.입력손익.영업이익률['3년평균'] = ((Number(this.입력손익.영업이익률.year1) + Number(this.입력손익.영업이익률.year2) + Number(this.입력손익.영업이익률.year3)) / 3).toString();
    },
    fn순이익률계산(year) {
      const d1 = this.입력손익.당기순이익[year];
      const d2 = this.입력손익.매출액[year];
      this.입력손익.순이익률[year] = (Number(d1) / Number(d2)).toString();

      //순이익률 3년평균 계산
      this.입력손익.순이익률['3년평균'] = ((Number(this.입력손익.순이익률.year1) + Number(this.입력손익.순이익률.year2) + Number(this.입력손익.순이익률.year3)) / 3).toString();
    },
    fnSaveData() {
      if (this.bizNo.length != 10) {
        this.$popupAlert({
          title: '경고',
          msg: '사업자번호 10자리를 입력해주세요.',
        });
        return;
      }
      const btn = {
        name: '저장',
        func: () => {
          this._closePop();
          const payload = {
            outlineData: JSON.stringify(this.입력개요),
            finData: JSON.stringify(this.입력재무),
            incomeData: JSON.stringify(this.입력손익),
            regId: this.$store.state.authUser.username,
          };
          diagnosisService.saveDiagnosis(this.$axios, this.bizNo, payload).then(({ result, data }) => {
            if (result) {
              this.$popupAlert({
                title: '저장',
                msg: '등록이 완료되었습니다.',
              });
              this.fnClosePop();
            } else {
              this.$popupAlert({
                title: '경고',
                msg: data.error.msg,
              });
            }
          });
        },
      };
      this._confirm('확인', '등록하시겠습니까?', btn);
    },
    fnFormatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    _confirm(title, msg, btn) {
      let data = {
        state: true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closePop() {
      this.$store.dispatch('ui/setPopAlertData', {
        state: false,
        type: null,
        title: null,
        msg: null,
        btn: {
          link: '',
          name: null,
        },
      });
    },
    fnClosePop() {
      this.$store.dispatch('ui/setPopCs2State', false);
      this.$emit('reload');
    },
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #ddd;
}
</style>
