<template>
  <Location :list="list"/>
</template>

<script>
import Location from "~/components/layouts/Location";
export default {
  components: {
    Location
  },
  data() {
    return {
      a: '',
    }
  },
  computed:{
    list(){
      let allMenus = this.$store.state.backoffice.configMenuStore.menus
      let list = [];
      if(allMenus){
        let currentMenu = {}
        let rootMenu = {}
        for(let i=0; i<allMenus.length; i++){
          currentMenu = allMenus[i].subList.find(el =>{
            return el.page_url==this.$route.path
          })
          if(currentMenu){
            rootMenu = allMenus[i]
            break;
          }
        }
        list.push(this.mapping(rootMenu));
        list.push(this.mapping(currentMenu));
      }
      return list;
    },
    menus(){
      const a = this.$store.state.backoffice.configMenuStore.menus
      return a
    }
  },
  methods:{
    mapping(menuItem){
      return {
        name: menuItem.title,
        link: menuItem.page_url ? menuItem.page_url : '',
      };
    }
  }
};
</script>
