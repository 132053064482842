<template>
  <div class="container area">
    <div class="c_top">
      <div class="taps_btn">
        <button class="tap on">전체</button>
        <button class="tap">추천</button>
        <button class="tap">A등급</button>
        <button class="tap">B등급</button>
        <button class="tap">C등급</button>
      </div>
    </div>
    <section class="list_finance patent">
      <article class="item" v-for="i in 10" :key="i">
        <div class="bx_label">
          <span class="txt_label full_blue">추천</span>
          <span class="txt_label full_red">A++</span>
          <span class="txt_label full_orange">B++</span>
          <span class="txt_label full_mint">C++</span>
        </div>
        <h3>실시간 저소음 다방향성 테니스 연습장치</h3>
        <dl>
          <dt>특허등급</dt>
          <dd>A+</dd>
        </dl>
        <dl>
          <dt>만료일</dt>
          <dd>2038.02.12</dd>
        </dl>
        <dl>
          <dt>특허추정가치</dt>
          <dd><b>1억 이상~5억 미만</b></dd>
        </dl>
      </article>
    </section>
  </div>
</template>

<script>
export default{
  
}
</script>
