const baseUrl = '/api/corp';

const getCorpList = (params, axios) => {
  const bizNo = params.bizNo;
  return axios.get(`${baseUrl}/${bizNo}`).then(response => {
    return response.data;
  });
};

export default {
  getCorpList,
};
