<template>
  <header>
    <div class="left">
      <button class="btn_menu" @click="fnToggleMenu">menu button</button>
    </div>
    <!-- <Gnb/> -->
    <div class="right">
      <!--
      <button class="btn_my">사용자정보</button>
      -->
      <button class="btn_logout" @click="fnMyPage">{{username}}({{gaName}} / {{name}})</button>
      <button class="btn_logout" @click="fnLogout">로그아웃</button>
    </div>
  </header>
</template>

<script>
import Gnb from "~/components/layouts/Gnb.vue";

export default {
  data() {
    return {
    };
  },
  components: {
    Gnb,
  },
  mounted() {
  },
  computed:{
    authInfo(){
      return this.$store.state.authUser ? this.$store.state.authUser : null;
    },
    gaName(){
      return this.authInfo ? this.authInfo.gaName : null;
    },
    username(){
      return this.authInfo ? this.authInfo.username : null;
    },
    name() {
      return this.authInfo ? this.authInfo.name : null;
    }
  },
  methods: {
    fnToggleMenu(){
      this.$store.dispatch("ui/setMenuState", !this.$store.state.ui.menuState);
    },
    async fnLogout(){
      if(this.$store.state.ui.popCsState==true){
        this.$store.dispatch('ui/setPopCsState', false);
        this.$store.dispatch('ui/setPopFcState', false);
        this.$store.dispatch('ui/setPopFc2State', false);
      }
      const success = await this.$store.dispatch('logout');
      if(success){
        const partnerInfo = this.$partnerInfoP_getPartnerInfo();
        this.$router.push(partnerInfo.loginPage);
      } else {
        this.$popupAlert({
          title:'로그아웃 실패',
          msg: '죄송합니다. <br/>로그아웃이 되지 않았습니다. <br/> 관리자에게 확인요청바랍니다.',
        });
      }
    },
    fnMyPage(){
      this.$store.dispatch('ui/setPopCsState', false);
      this.$store.dispatch('ui/setPopFcState', false);
      this.$store.dispatch('ui/setPopFc2State', false);
      this.$router.push('/mypage');
    }
  },
};
</script>
