const LANDING_PARTNER = {
  CPM0003 : {
    title: '메인비즈 경영지원 컨설팅 플랫폼',
    logo: 'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/mainbiz03.png',
    logoSmall: 'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/mainbiz03_mobile.png',
    backgroundSize:'80%',
    loginPage: '/mainbiz/login'
  },
  DEFAULT: {
    title: '컨설턴트 | 다된다',
    // logo: '/_nuxt/img/logo_admin.7e633e3.png',
    logo: '/IMG/logo_admin.png',
    logoSmall: '/IMG/logo_admin.png',
    backgroundSize:'50%',
    loginPage: '/login'
  }
}

const partnerInfoKey = 'partnerInfo'


export default (context, inject) => {
  inject('partnerInfoP_savePartnerId', (compId) => {
    if (process.client) {
      const companyInfo = LANDING_PARTNER[compId];
      if ( companyInfo ) {
        localStorage.setItem(partnerInfoKey, JSON.stringify(companyInfo));
        return true;
      }
      localStorage.removeItem(partnerInfoKey);
      return false;
    }
  });

  inject('partnerInfoP_getPartnerInfo', () => {
    if (process.client) {
      const companyInfo = localStorage.getItem(partnerInfoKey);
      if( companyInfo )
        return JSON.parse(companyInfo);
      return LANDING_PARTNER.DEFAULT;
    }
  });
}
