<template>
  <div class="login qr">
    <div class="inner">
      <h1 class="logo">다된다 관리</h1>
      <div class="form">
        <div class="item">
          <label for="id">아이디</label>
          <Input id="id" ref="id" name="email" :value.sync="email" placeholder="아이디를 입력해 주세요." :tabindex="1" @enter="fnLogin" />
        </div>
        <div class="item">
          <div class="colb">
            <div class="left">
              <label for="pw">비밀번호</label>
            </div>
            <!--
            <div class="right">
              <nuxt-link id="pw" to="" class="txt_gray">비밀번호를 잊으셨나요?</nuxt-link>
            </div>
            -->
          </div>
          <Input id="pw" ref="pw" type="password" name="password" :value.sync="password" placeholder="비밀번호를 입력해 주세요." :tabindex="2" @enter="fnLogin" />
        </div>
        <div class="btns">
          <button class="btn full" @click="fnLogin">로그인</button>
        </div>
        <div class="colb">
          <div></div>
          <button type="button" @click="fnClickConsultantJoin" style="text-decoration: underline">컨설턴트 회원가입</button>
        </div>
      </div>
    </div>
    <div class="bx qr">
      <span class="txt_gray"><b>모바일에서도</b> 쉽고 간편하게</span> <img :src="qrImgSrc" alt="qr코드" />
    </div>
    <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <ConsultantJoinModal v-if="popState == true" @closePop="popState = false" />
    </transition>
  </div>
</template>
<script>
import Header from '~/components/layouts/Header';
import Location from '~/components/layouts/Location';
import CheckBox from '~/components/common/CheckBox';
import Input from '~/components/common/Input';
import ConsultantJoinModal from '~/components/popup/ConsultantJoinModal.vue';

export default {
  layout: 'none',
  components: {
    Header,
    Location,
    CheckBox,
    Input,
    ConsultantJoinModal,
  },
  data: () => ({
    email: '',
    password: '',
    qrImgSrc: process.env.QR_IMG_SRC,
    popState: false,
  }),
  created() {
    this.$partnerInfoP_savePartnerId('ADM001');
  },
  mounted() {
    if (this.$store.state.ui.popCsState == true) {
      this.$store.dispatch('ui/setPopCsState', false);
    }
  },
  methods: {
    async fnLogin() {
      if (this.email === '') {
        this.$popupAlert({
          title: '경고 : 아이디',
          msg: '아이디를 입력해주세요',
        });
      } else if (this.password === '') {
        this.$popupAlert({
          title: '경고 : 비밀번호',
          msg: '비밀번호를 입력해주세요.',
        });
      } else {
        const data = await this.$store.dispatch('login', {
          user_id: this.email,
          user_pw: this.password,
        });
        if (data.result) {
          if (this.$store.state.ui.popCsState == true) {
            this.$store.dispatch('ui/setPopCsState', false);
          }
          await this.$router.push('/');
        } else {
          let msg = '알 수 없는 에러가 발생했습니다.';
          if (data.data.error.cd && data.data.error.msg) {
            msg = data.data.error.msg;
          }

          this.$popupAlert({
            title: '로그인 실패',
            msg: msg,
          });
          return;
        }
      }
    },
    fnClickConsultantJoin() {
      this.popState = true;
    },
  },
  beforeMount() {
    if (this.$isLogin()) {
      this.$router.push('/allOk/customers');
    }
  },
};
</script>

<style scoped>
.join .logo,
.login .logo {
  width: 143px;
  height: 28px;
  background: url('../static/IMG/logo_admin.png') no-repeat;
}
</style>
