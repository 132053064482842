<template>
  <section class="container">
    <div class="inner contents_col2 bg_white">
      <div class="left">
        <Tree
          class="ml10"
          :data="data"
          @onClick="fnOnClick"
          @onChangeName="fnOnChangeName"
          @onDelete="fnOnDelete"
          @onAddNode="fnOnAddNode"
          @onDrop="fnOnDrop"
          @onDropBefore="fnOnDropBefore"
          ref="tree" />
      </div>
      <div class="right">
        <div class="colb mb5">
          <div class="top">
            <div>
              <button class="btn" @click="fnClickMove">이동하기</button>
              <strong class="ml5">선택한 조직 : {{ selectedOrgNm || '전체' }}</strong>
            </div>
          </div>
          <div v-if="selectedOrg">
            <CheckBox
              label="하위조직 포함"
              name="org"
              :value.sync="isContainSub"
              @change="
                checked => {
                  if (checked) fnFetchOrgUser(selectedOrg, 'Y');
                  else fnFetchOrgUser(selectedOrg, null);
                }
              " />
          </div>
        </div>
        <div class="contents">
          <div class="tbl_area" style="min-width: auto">
            <table class="tbl">
              <colgroup>
                <col width="7%" />
                <col width="30%" />
                <col width="30%" />
                <col width="33%" />
              </colgroup>
              <thead>
                <tr>
                  <th><b></b></th>
                  <th><b>사용자번호</b></th>
                  <th><b>이름</b></th>
                  <th><b>조직명</b></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="el in userList" :key="el.userNo">
                  <td><CheckBox label="<br />" :name="el.userNo" :value="selectedUser.indexOf(el.userNo) > -1" @change="checked => fnClickCheck(el.userNo, checked)" /></td>
                  <td>
                    {{ el.userNo }}
                  </td>
                  <td>{{ el.userNm }}</td>
                  <td>{{ el.orgNm }}</td>
                </tr>
                <tr v-if="userList.length == 0">
                  <td colspan="4">데이터가 없습니다.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <MoveOrgUserModal v-if="popState == true" @closePop="popState = false" @onSelect="fnSelectOrg" />
    </transition>
  </section>
</template>

<script>
import { generateQueryParameter } from '~/util/HttpUtils';
import CheckBox from '../components/common/CheckBox.vue';
import MoveOrgUserModal from '../components/pages/org/MoveOrgUserModal.vue';
import Tree from '../components/pages/org/Tree.vue';
export default {
  components: { Tree, CheckBox, MoveOrgUserModal },
  data() {
    return {
      flatData: [],
      data: [],
      selectedOrg: null,
      selectedOrgNm: null,
      userList: [],
      selectedUser: [],
      isContainSub: false,
      popState: false,
    };
  },

  methods: {
    fnOnClick(v) {
      this.isContainSub = false;

      if (v.id == 'ROOT') {
        this.selectedOrg = null;
        this.selectedOrgNm = null;
        this.fnFetchOrgUser(null, null);
      } else {
        this.selectedOrg = v.id;
        this.selectedOrgNm = v.name;
        this.fnFetchOrgUser(v.id, null);
      }
    },
    fnOnChangeName(v) {
      if (!v.eventType) return;
      if (confirm('수정 하시겠습니까?')) {
        const org = this.flatData.find(el => el.orgNo == v.id);
        const params = {
          orgNm: v.newName || 'new node',
          orderNo: org.orderNo,
          modifyNo: this.$store.state.authUser.username,
        };
        this.$axios
          .$put(`http://dev-auth.10bagger.co.kr/org/${v.id}?${generateQueryParameter(params)}`)
          .then(() => {
            this.fnFetchData();
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        this.$refs.tree.fnSetTreeData();
      }
    },
    fnOnDelete(v) {
      if (confirm('삭제 하시겠습니까?')) {
        this.$axios
          .$delete(`http://dev-auth.10bagger.co.kr/org/${v.id}`)
          .then(() => {
            this.fnFetchData();
          })
          .catch(err => {
            alert(err.response.data.errorMessage);
          });
      } else {
        this.$refs.tree.fnSetTreeData();
      }
    },
    fnOnAddNode(v) {
      const children = v.parent.children;
      const previousIndex = children.findIndex(el => el.id == v.id) - 1;
      const params = {
        pOrgNo: v.pid,
        orgNm: v.name,
        orderNo: children[previousIndex] ? children[previousIndex].orderNo + 1 : 1,
        registNo: this.$store.state.authUser.username,
      };
      this.$axios
        .$post(`http://dev-auth.10bagger.co.kr/org?${generateQueryParameter(params)}`)
        .then(() => {
          this.fnFetchData();
        })
        .catch(err => {
          this.$refs.tree.fnSetTreeData();
          console.error(err);
        });
    },
    fnOnDrop(v) {
      if (v.node.id == v.target.id) return;

      if (confirm('조직을 이동 하시겠습니까?')) {
        this.fnMoveAndSort(v.node);
      } else {
        this.$refs.tree.fnSetTreeData();
      }
    },
    fnOnDropBefore(v) {
      if (confirm('조직을 이동 하시겠습니까?')) {
        this.fnMoveAndSort(v.node);
      } else {
        this.$refs.tree.fnSetTreeData();
      }
    },
    fnMakeTreeData(arrayList, rootId) {
      const arrayForTree = arrayList.map(el => ({
        name: el.orgNm,
        id: el.orgNo,
        pid: el.porgNo,
        orderNo: el.orderNo,
        isLeaf: false,
        dragDisabled: el.orgLevel == 0 ? true : false,
        addLeafNodeDisabled: true,
        editNodeDisabled: el.orgLevel == 0 ? true : false,
        delNodeDisabled: el.orgLevel == 0 ? true : false,
      }));

      var rootNodes = [];
      var traverse = function (nodes, item, index) {
        if (nodes instanceof Array) {
          return nodes.some(function (node) {
            if (node.id === item.pid) {
              node.children = node.children || [];
              return node.children.push(arrayForTree.splice(index, 1)[0]);
            }

            return traverse(node.children, item, index);
          });
        }
      };

      while (arrayForTree.length > 0) {
        arrayForTree.some(function (item, index) {
          if (item.pid === rootId) {
            return rootNodes.push(arrayForTree.splice(index, 1)[0]);
          }

          return traverse(rootNodes, item, index);
        });
      }

      return rootNodes;
    },

    fnFetchData() {
      this.$axios.$get('http://dev-auth.10bagger.co.kr/org').then(data => {
        this.flatData = data;
        this.data = this.fnMakeTreeData(data, null);
      });
    },
    fnMoveAndSort(node) {
      const id = this.$store.state.authUser.username;
      this.$axios
        .$put(`http://dev-auth.10bagger.co.kr/org/move/${node.id}?pOrgNo=${node.parent.id}&modifyNo=${id}`)
        .then(() => {
          Promise.all(
            node.parent.children.map((el, idx) => {
              const params = {
                orgNm: el.name,
                orderNo: idx + 1,
                modifyNo: id,
              };
              return this.$axios.put(`http://dev-auth.10bagger.co.kr/org/${el.id}?${generateQueryParameter(params)}`);
            }),
          )
            .then(data => {
              this.fnFetchData();
              this.fnFetchOrgUser(this.selectedOrg, this.isContainSub ? 'Y' : null);
            })
            .catch(err => {
              this.$refs.tree.fnSetTreeData();
              console.log(err);
            });
        })
        .catch(err => {
          this.$refs.tree.fnSetTreeData();
          console.error(err);
        });
    },
    fnFetchOrgUser(searchOrgNo, containSubOrg) {
      const params = {
        searchOrgNo,
        containSubOrg,
      };
      this.$axios
        .$get(`http://dev-auth.10bagger.co.kr/user?${generateQueryParameter(params)}`)
        .then(data => {
          this.userList = data;
          this.selectedUser = [];
        })
        .catch(err => {
          console.error(err);
        });
    },
    fnClickCheck(userNo, checked) {
      if (checked) {
        this.selectedUser.push(userNo);
      } else {
        const idx = this.selectedUser.indexOf(userNo);
        this.selectedUser.splice(idx, 1);
      }
    },
    fnClickMove() {
      if (this.selectedUser.length == 0) {
        alert('사용자를 선택해주세요');
        return;
      }
      this.popState = true;
    },
    fnSelectOrg(orgNo) {
      if (confirm('사용자를 이동하시겠습니까?')) {
        const params = {
          userNos: this.selectedUser,
        };
        this.$axios
          .$put(`http://dev-auth.10bagger.co.kr/org/${orgNo}/user?${generateQueryParameter(params)}`)
          .then(data => {
            this.fnFetchOrgUser(this.selectedOrg, this.isContainSub ? 'Y' : null);
          })
          .catch(err => {
            console.error(err);
          });
      }
    },
  },
  mounted() {
    this.fnFetchData();
    this.fnFetchOrgUser(null, null);
  },
};
</script>

<style></style>
