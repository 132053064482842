<template>
  <div class="c_contents contents_col2">
    <ol class="mt20">
      <ul class="form_list type2">
        <li>
          <h4>사업자 번호</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?$dataPattern('bizNo', contents.bizNo):''}}</span></div>
        </li>
        <li>
          <h4>기업명</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?contents.compNm:''}}</span></div>
        </li>
        <li>
          <h4>전화번호</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?contents.tel:''}}</span></div>
        </li>
        <li>
          <h4>이메일</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?contents.email:''}}</span></div>
        </li>
        <li>
          <h4>대표자 이름</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?contents.prstNm:''}}</span></div>
        </li>
        <li>
          <h4>대표자<br/>생년월일</h4>
          <div class="i_wrap" style="line-height: 3rem;"><span>{{contents?contents.prstBirth:''}}</span></div>
        </li>
        <li>
          <h4>대표자 성별</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?fnGender(contents.prstGender):''}}</span></div>
        </li>
        <li>
          <h4>사업장 주소</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?contents.korAddr:''}}</span></div>
        </li>
        <li>
          <h4>설립일자</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?contents.fndDt:''}}</span></div>
        </li>
        <li>
          <h4>업종</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?contents.sector:''}}</span></div>
        </li>
        <li>
          <h4>기업형태</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?fnCompTypes(contents.compTypes):''}}</span></div>
        </li>
        <li>
          <h4>고용인원</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?contents.empCnt:''}}</span></div>
        </li>
        <li>
          <h4>전년도 매출</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?contents.salesAmnt:''}}</span></div>
        </li>
        <li>
          <h4>컨설턴트코드</h4>
          <div class="i_wrap" style="line-height: 1.75rem;"><span>{{contents?contents.userCd:''}}</span></div>
        </li>
      </ul>
    </ol>
  </div>
</template>
<script>

export default{
  props: ['contents'],
  data() {
    return {
    }
  },
  mounted () {
  },
  methods:{
    fnCompTypes(payload){
      if(payload&&payload.length>0){
        return payload.map(p=>p).join(', ')
      }
      return payload
    },
    fnGender(payload){
      if(payload==='male'){
        return '남성';
      } else if(payload==='female'){
        return '여성';
      } else {
        return payload
      }
    }
  }
}
</script>
