<template>
  <div class="c_contents">
    <section class="container">
      <div class="colb">
        <div class="col unit_page">
          <h4 class="l_tit">비밀번호:</h4>
          {{message}}
        </div>
        <div>
          <button class="btn"
                  :class="{ dis: !resetAvail }"
                  :disabled="!resetAvail"
                  @click="fnResetPassword">
            비밀번호 초기화
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import customerService from "../../../service/allOkMng/customerService";

export default {
  data() {
    return {
      message:null,
      resetAvail:false,
    };
  },
  props: ['id'],
  async fetch(){
    const result = await customerService.getPwd(this.$axios, this.id);
    if ( result.data.result ) {
      this.message = result.data.data.contents;
    } else {
      this.message = result.data.data.error.msg;
      const errorCd = result.data.data.error.cd;
      console.log(errorCd)
      if( "CANT_READ_PWD"==errorCd ) {
        this.resetAvail = true;
      }
      else if ("NO_PWD"==errorCd) {
        this.resetAvail = true;
        this.message = '지정된 비밀번호가 없습니다. 비밀번호 초기화를 해주세요'
      }
    }
  },
  methods: {
    fnResetPassword() {
      this.$popupConfirm({
        title: '비밀번호 초기화',
        msg: `비밀번호를 초기화 하시겠습니까?`,
        btnName: '예',
        btnFunc: this._fnResetPassword,
        closeBtnName: '아니오'
      })
    },
    async _fnResetPassword(){
      const result = await customerService.resetPwd(this.$axios, this.id);
      if ( result.data.result ) {
        this.message = result.data.data.contents;
        this.resetAvail = false;
      } else {
        alert( result.data.data.error.msg );
      }
      this.fnCloseConfirm();
    },
    fnCloseConfirm(){
      this.$store.dispatch('ui/setPopAlertData', {state: false,id: null,title: null,msg: null,btn: {name: null,func: null,},});
    },
    fnClosePop() {
      this.$store.dispatch('ui/setPopCsState', false);
    },
  },
};
</script>
