<template>
  <div class="c_contents">
    <section class="container">
      <div class="col unit_page">
        <h4 class="l_tit">업로드 파일:</h4>
        <InputFile id="file" name="file" placeholder="파일을 선택하세요" :value.sync="file" :styleAttr="{ width: '25%' }" ref="inputFile" />
        <button class="btn ml5 mr5" @click="fnFileUpload">파일업로드</button>
        <Loading v-if="loading" />
      </div>
      <div class="colb">
        <h4 class="mt20">기준 정보</h4>
        <div class="mb5">
          <!-- <button class="btn" :class="{ dis: disablePdfDowload }" @click="fnClickPdfDownload" :disabled="disablePdfDowload">인쇄</button> -->
          <button class="btn" :class="{ dis: disableExcelDowload }" @click="fnClickExcelDownload" :disabled="disableExcelDowload">Excel 다운</button>
        </div>
      </div>
      <div class="area_search mt5">
        <div class="unit">
          <label class="tit" for="bizNo">기준월</label>
          <Select id="month" :itemList="laborFileList" :value="laborFileId" @change="fnSelectLaborFile" />
        </div>
      </div>
      <grid v-if="gridProp" class="pb20" :data="gridProp.data" :columns="gridProp.columns" :options="gridProp.options" ref="grid" :key="gridKey" />
    </section>
  </div>
</template>

<script>
import InputFile from '../../common/InputFile.vue';
import Select from '../../common/Select.vue';
import { CustomColumnHeader1 } from '~/util/TuiGridCustomClass.js';
import laborService from '~/service/allOkMng/laborService';
import Loading from '../../common/Loading.vue';
export default {
  props: ['id', 'uid'],
  components: {
    InputFile,
    Select,
    Loading,
  },

  data() {
    return {
      file: null,
      laborFileList: [],
      baseDt: null,
      laborFileId: null,
      compNm: null,
      customAllowance: {},
      baseMinimumHourlyPay: null,
      welfareRate: null,
      fixedBonusRate: null,
      empList: [],
      gridProp: null,
      gridKey: Math.random(),
      disableExcelDowload: false,
      disablePdfDowload: false,
      loading: false,
    };
  },

  methods: {
    fnFileUpload() {
      if (!this.file) {
        this._alert('알림', '파일을 선택해주세요');
        return;
      }

      const btn = {
        name: '확인',
        func: async () => {
          this._closePop();
          this.loading = true;

          // const res1 = await laborService.getLaborContractEmp(this.$axios, this.id)
          // if (!res1.result) {
          //   this._alert('알림', `파일을 업로드하는데 실패하였습니다(${res1.data.error.msg})`);
          //   console.error(res1.data);
          //   this.loading = false;
          //   return;
          // }

          const res2 = await laborService.uploadLaborFile(this.$axios, this.id, { userNo: this.$store.state.authUser.username, uploadFile: this.file })
          if (!res2.result) {
            this._alert('알림', `파일을 업로드하는데 실패하였습니다(${res2.data.error.msg})`);
            console.error(res2.data);
            this.loading = false;
            return;
          }

          // const success = await this._registContract('fileId', res1.data.contents);
          // if(!success){
          //   this._alert('알림', '근로계약서를 등록하는데 실패하였습니다');
          //   this.loading = false;
          //   return;
          // }

          this._alert('알림', '파일이 업로드되었습니다');
          this.loading = false;
          this.fnResetInputFile();
          this.fnSetLaborFileList()
            .then(this.fnSelectLaborFile)
            .catch(err => {
              console.error(err);
            });
        },
      };

      this._confirm('확인', `파일을 업로드하시겠습니까?`, btn);
    },
    // async _registContract(laborFileId, lastEmpList){
    //   //laborFileId로부터 파일상세에서 custom수당가져오기
    //   //laborFileId로부터 empList가져오기
    //   //lastEmpList에서 없는 empNo만 근로계약서 기본값 설정해서 밀어넣기
    //   return true
    // },
    fnSetLaborFileList() {
      return laborService.getLaborFileList(this.$axios, this.id).then(({ result, data }) => {
        if (result) {
          this.laborFileList = data.contents.map(el => ({
            name: el.baseDt.replace(/(\d{4})(\d{2})(\d{2})/g, '$1년 $2월'),
            value: el.laborFileId,
            compNm: el.compNm,
            customAllowance: this._parseCustomAllowanceName(el.uploadData),
            baseMinimumHourlyPay: el.baseMinimumHourlyPay,
            welfareRate: el.welfareRate,
            fixedBonusRate: el.fixedBonusRate,
          }));
          return this.laborFileList.length > 0 ? this.laborFileList[0] : Promise.reject('파일이 없습니다');
        } else {
          return Promise.reject(data.error.msg);
        }
      });
    },
    fnSelectLaborFile(payload) {
      this.baseDt = payload.name;
      this.laborFileId = payload.value;
      this.compNm = payload.compNm;
      this.customAllowance = payload.customAllowance;
      this.baseMinimumHourlyPay = payload.baseMinimumHourlyPay;
      this.welfareRate = payload.welfareRate;
      this.fixedBonusRate = payload.fixedBonusRate;
      return laborService.getLaborFileEmp(this.$axios, this.id, this.laborFileId).then(({ result, data }) => {
        if (result) {
          this.empList = data.contents;
          this.fnSettingGridProp();
        } else {
          return Promise.reject(data.error.msg);
        }
      });
    },
    fnSettingGridProp() {
      const columns = [
        {
          header: '<div style="font-weight: bold; font-size:11px">순번</div>',
          name: 'c1',
          width: 20,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">사번</div>',
          name: 'c2',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">입사일</div>',
          name: 'c3',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">성명</div>',
          name: 'c4',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">부서</div>',
          name: 'c5',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">직책</div>',
          name: 'c6',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">직급</div>',
          name: 'c7',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">통상시간급</div>',
          name: 'c8',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">기본 근로시간</div>',
          name: 'c9',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">연장근무시간</div>',
          name: 'c10',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">연장근무수당</div>',
          name: 'c11',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">휴일근무시간</div>',
          name: 'c12',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">휴일근무수당</div>',
          name: 'c13',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">휴일연장근무시간</div>',
          name: 'c14',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">휴일연장근무수당</div>',
          name: 'c15',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">야간근무시간</div>',
          name: 'c16',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">야간근무수당</div>',
          name: 'c17',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">당직근무시간</div>',
          name: 'c18',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">당직근무수당</div>',
          name: 'c19',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">연봉계약금액</div>',
          name: 'c20',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">통상시급</div>',
          name: 'c21',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">최저시급</div>',
          name: 'c22',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">월고정급여액</div>',
          name: 'c23',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">수습급여율</div>',
          name: 'c24',
          width: 100,
          // className: 'color3',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">고정상여금</div>',
          name: 'c25',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">추가연장수당</div>',
          name: 'c26',
          width: 100,
          // className: 'color4',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">근태공제</div>',
          name: 'c27',
          width: 100,
          // className: 'color4',
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name1)}</div>`,
          name: 'c28',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name2)}</div>`,
          name: 'c28_1',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name3)}</div>`,
          name: 'c28_2',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name4)}</div>`,
          name: 'c28_3',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name5)}</div>`,
          name: 'c28_4',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name6)}</div>`,
          name: 'c28_5',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name7)}</div>`,
          name: 'c28_6',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name8)}</div>`,
          name: 'c28_7',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name9)}</div>`,
          name: 'c28_8',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">6세미만보육수당<br/>(<span style="color:red">20만원까지</span>)</div>',
          name: 'c29',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">연구개발수당<br/>(<span style="color:red">20만원까지</span>)</div>',
          name: 'c30',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">식대<br/>(<span style="color:red">20만원까지</span>)</div>',
          name: 'c31',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">차량유지비<br/>(<span style="color:red">20만원까지</span>)</div>',
          name: 'c32',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">중간입퇴사자<br>근무일수</div>',
          name: 'c33',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">해당월총일수</div>',
          name: 'c34',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">복리후생률</div>',
          name: 'c35',
          width: 100,
          // className: 'color2',
          align: 'center',
          renderer: {
            styles: {
              color: 'red',
            },
          },
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">고정상여율</div>',
          name: 'c36',
          width: 100,
          // className: 'color2',
          align: 'center',
          renderer: {
            styles: {
              color: 'red',
            },
          },
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">기본근로시간</div>',
          name: 'c37',
          width: 250,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">기본급산정시간</div>',
          name: 'c38',
          width: 430,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">휴게시간</div>',
          name: 'c39',
          width: 250,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">보육수당</div>',
          name: 'c40',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">연구개발수당</div>',
          name: 'c41',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">당직근무수당</div>',
          name: 'c42',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">식대</div>',
          name: 'c43',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">차량유지비</div>',
          name: 'c44',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">비과세 합계액</div>',
          name: 'c45',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">최저시급</div>',
          name: 'c46',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">기본급</div>',
          name: 'c47',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
      ];

      const complexColumns = [
        {
          header: `<div style="font-weight: bold; font-size: 15px">${this.compNm}</div>`,
          name: 'x1',
          childNames: ['c1', 'c2', 'c3'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 15px; color:red">${this.baseDt}</div>`,
          name: 'x2',
          childNames: ['c4', 'c5'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 13px;">해당년도<br/>최저시급</div>`,
          name: 'x3',
          childNames: ['c6'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 18px; color:red">${this.baseMinimumHourlyPay}</div>`,
          name: 'x4',
          childNames: ['c7'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 18px; font-family:'Malgun Gothic'">계약 시간</div>`,
          name: 'x5',
          childNames: ['c8', 'c9', 'c10', 'c11', 'c12', 'c13', 'c14', 'c15', 'c16', 'c17', 'c18', 'c19'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 18px; font-family:'Malgun Gothic'">급여데이터</div>`,
          name: 'x6',
          childNames: ['c20', 'c21', 'c22', 'c23', 'c24', 'c25', 'c26', 'c27'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 18px; font-family:'Malgun Gothic'">고정성 금액</div>`,
          name: 'x6_1',
          childNames: ['c28', 'c28_1', 'c28_2', 'c28_3', 'c28_4'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 18px; font-family:'Malgun Gothic'">비고정성 금액</div>`,
          name: 'x6_2',
          childNames: ['c28_5', 'c28_6', 'c28_7', 'c28_8'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 18px; font-family:'Malgun Gothic'">비과세 금액</div>`,
          name: 'x7',
          childNames: ['c29', 'c30', 'c31', 'c32'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 18px; font-family:'Malgun Gothic'">일할계산</div>`,
          name: 'x8',
          childNames: ['c33', 'c34', 'c35', 'c36'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 18px; font-family:'Malgun Gothic'">근로계약 기본정보</div>`,
          name: 'x9',
          childNames: ['c37', 'c38', 'c39'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 18px; font-family:'Malgun Gothic'">비과세 내역</div>`,
          name: 'x10',
          childNames: ['c40', 'c41', 'c42', 'c43', 'c44', 'c45'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 18px; font-family:'Malgun Gothic'">최저시급 계산</div>`,
          name: 'x11',
          childNames: ['c46', 'c47'],
          renderer: CustomColumnHeader1,
        },
      ];

      const columnRenderer = columns.map(el => ({
        name: el.name,
        renderer: CustomColumnHeader1,
      }));

      const data = this.empList.map(el => ({
        c1: el.no,
        c2: el.empNo,
        c3: el.empStartDt,
        c4: el.name,
        c5: el.dept,
        c6: el.position,
        c7: el.rank,
        c8: el.regularHourlyPay,
        c9: el.basicWorkHours,
        c10: el.extendedWorkHours,
        c11: el.extendedWorkPay,
        c12: el.holidayWorkHours,
        c13: el.holidayWorkPay,
        c14: el.holidayOverWorkHours,
        c15: el.holidayOverWorkPay,
        c16: el.nightShiftHours,
        c17: el.nightShiftPay,
        c18: el.dutyHours,
        c19: el.dutyPay,
        c20: el.annualContractAmount,
        c21: el.regularHourlyPay,
        c22: el.minimumHourlyPay,
        c23: el.monthFixedSalary,
        c24: el.probationarySalaryRate,
        c25: el.fixedBonus,
        c26: el.extraOvertimePay,
        c27: el.attendanceDeduction,
        c28: el.annualAllowance,
        c28_1: el.customAllowance2,
        c28_2: el.customAllowance3,
        c28_3: el.customAllowance4,
        c28_4: el.customAllowance5,
        c28_5: el.customAllowance6,
        c28_6: el.customAllowance7,
        c28_7: el.customAllowance8,
        c28_8: el.customAllowance9,
        c29: el.childCareAllowance,
        c30: el.rndAllowance,
        c31: el.meals,
        c32: el.carMaintenanceCost,
        c33: el.midJoinLeaveWorkDay,
        c34: el.monthTotDay,
        c35: this.welfareRate,
        c36: this.fixedBonusRate,
        c37: el.basicWorkHoursDesc,
        c38: el.basicPayTimeDesc,
        c39: el.restTime,
        c40: el.tfChildCareAllowance,
        c41: el.tfRndAllowance,
        c42: el.tfDutyPay,
        c43: el.tfMeals,
        c44: el.tfCarMaintenanceCost,
        c45: el.tfTotal,
        c46: el.minimumHourlyPay,
        c47: el.basicPay,
      }));

      const options = {
        scrollX: true,
        scrollY: false,
        // minRowHeight: 20,
        // rowHeight: 20,
        minBodyHeight: 0,
        header: {
          height: 100,
          columns: columnRenderer,
          complexColumns,
        },
        columnOptions: {
          frozenCount: 7,
          frozenBorderWidth: 2,
        },
      };

      if (data.length > 11) {
        options.scrollY = true;
        options.bodyHeight = 460;
      }

      this.gridProp = {
        data: data,
        columns: columns,
        options: options,
      };
      this.gridKey++;
    },
    fnClickExcelDownload() {
      if (!this.laborFileId) return;

      location.href = `/apiMngAo/customer/service/labor/${this.id}/file/${this.laborFileId}/emp/excel`;
      this.disableExcelDowload = true;
      setTimeout(() => {
        this.disableExcelDowload = false;
      }, 5000);
    },
    fnClickPdfDownload() {
      if (!this.laborFileId) return;

      location.href = `/apiMngAo/customer/service/labor/${this.id}/file/${this.laborFileId}/emp/pdf`;
      this.disablePdfDowload = true;
      setTimeout(() => {
        this.disablePdfDowload = false;
      }, 5000);
    },
    fnResizeGrids() {
      this.$refs.grid && this.$refs.grid.invoke('refreshLayout');
    },
    fnResetInputFile() {
      this.$refs.inputFile.fnResetValue();
      this.file = null;
    },
    _alert(title, msg) {
      let data = {
        state: true,
        type: 'alert',
        title: title,
        msg: msg,
        btn: {
          name: '확인',
        },
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _confirm(title, msg, btn) {
      let data = {
        state: true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closePop() {
      this.$store.dispatch('ui/setPopAlertData', {
        state: false,
        type: null,
        title: null,
        msg: null,
        btn: {
          link: '',
          name: null,
        },
      });
    },
    _parseCustomAllowanceName(uploadData) {
      const data = JSON.parse(uploadData);
      return {
        name1: data.Title?.AB,
        name2: data.Title?.AC,
        name3: data.Title?.AD,
        name4: data.Title?.AE,
        name5: data.Title?.AF,
        name6: data.Title?.AG,
        name7: data.Title?.AH,
        name8: data.Title?.AI,
        name9: data.Title?.AJ,
      };
    },
    fnValue(v) {
      return (v = null || v == undefined ? '' : v);
    },
  },

  watch: {
    '$store.state.ui.menuState'() {
      setTimeout(() => {
        this.fnResizeGrids();
      }, 500);
    },
  },

  mounted() {
    this.fnSetLaborFileList()
      .then(this.fnSelectLaborFile)
      .catch(err => {
        console.error(err);
      });
  },
};
</script>

<style>
.tui-grid-cell.color1 {
  background-color: #ffffa3;
}
.tui-grid-cell.color2 {
  background-color: #ccffcc;
}
.tui-grid-cell.color3 {
  background-color: #dce6f1;
}
.tui-grid-cell.color4 {
  background-color: #ffff00;
}
.tui-grid-header-area {
  background-color: #eeeeee;
}
</style>
