<template>
  <div class="popup p_cs">
    <div class="p_wrap">
      <div class="p_top">
        <h2>새로운 FC</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button class="tab on">새로운 FC</button>
            </div>
            <div class="btns">
              <button class="btn" @click="fnSave">저장</button>
              <button class="btn dark" @click="fnClosePop">취소</button>
            </div>
          </div>
          <div class="c_contents">
            <div class="c_contents contents_col2">
              <div class="left">
                <ul class="form_list type2">
                  <li class="item_ipt">
                    <label for="fcCode"><h4><b>FC코드</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id="fcCode"
                        name="fcCode"
                        type="text"
                        :placeholder="formConfig.username.placeholder"
                        :disabled="formConfig.username.disabled"
                        :value.sync="form.username"
                      />
                    </div>
                  </li>
                  <li class="item_ipt">
                    <label for="fcName"><h4><b>FC이름</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id='fcName'
                        type='text'
                        name="fcName"
                        placeholder='문자만 입력'
                        :value.sync="form.name"
                      />
                    </div>
                  </li>
                  <li class="item_ipt">
                    <label for="tel"><h4><b>전화번호</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id="tel"
                        name="tel"
                        type="text"
                        placeholder="전화번호 입력"
                        :value.sync="form.tel"
                      />
                    </div>
                  </li>
                  <li class="item_ipt">
                    <label for="birth"><h4><b>생년월일</b></h4></label>
                    <div class="i_wrap">
                      <InputBox
                        id='birth'
                        type='text'
                        name="birth"
                        placeholder='yymmdd(숫자만, 6자리)'
                        :value.sync="form.birthDt"
                      />
                    </div>
                  </li>
                  <li class="item_ipt">
                    <h4>성별</h4>
                    <div class="i_wrap">
                      <Select title="성별" id="gender" :itemList="genderSelect" :value.sync="form.gender"/>
                    </div>
                  </li>
                  <li v-if="positionSelect"
                      class="item_ipt">
                    <h4>직책</h4>
                    <div class="i_wrap">
                      <Select title="직책" id="position" :itemList="positionSelect" :value.sync="form.positionCd"/>
                    </div>
                  </li>
                  <li class="item_ipt">
                    <h4>관리자여부</h4>
                    <div class="i_wrap">
                      <Select title="관리자여부" id="manager" :itemList="managerSelect" :value.sync="form.mngYn"/>
                    </div>
                  </li>
                </ul>
                <OrgSelectGroup :org-cd-list="orgCdList"
                                @change="(orgId) => this.form.orgId = orgId"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';
import Select from '~/components/common/Select';
import OrgSelectGroup from "./manageFC/OrgSelectGroup";
import organizationUserService from "../../../service/organizationUserService";

export default {
  name: 'NewFc',
  components: {
    InputBox, Select, OrgSelectGroup
  },
  props: {
    positionSelect: {
      type: Array
    },
    orgCdList: {
      type: Array
    },
    userCreateData: {
      type: Object
    }
  },
  created() {

  },
  mounted() {
    if ( this.userCreateData && this.userCreateData ) {
      this.formConfig.username.placeholder="코드는 저장시 생성됩니다."
      this.formConfig.username.disabled=true
    }
  },
  data() {
    return {
      formConfig:{
        username: {
          placeholder: '숫자만 입력',
          disabled: false,
        }
      },
      form:{
        username:null,
        name:null,
        tel:null,
        birthDt:null,
        gender:null,
        positionCd:null,
        mngYn:null,
        orgId:null,
        password: null
      },
      genderSelect:[
        {name: '남', value:'male'},
        {name: '여', value:'female'},
      ],
      managerSelect:[
        {name: 'Y', value:'Y'},
        {name: 'N', value:'N'},
      ],
    };
  },
  methods: {
    fnClosePop(){
      this.$store.dispatch('ui/setPopFcState', false);
    },
    async fnSave(){
      if( this._fnSaveValidation() ) {
        // this.form.orgId = this.branch ? this.branch : this.area ? this.area : this.business ? this.business : this.central
        this.form.password = this.form.birthDt
        const result = await organizationUserService.saveUser(this.form, this.$axios);
        if(result){
          await this.$emit('reload');
          await this.$store.dispatch('ui/setPopFcState', false);
        }
      }
    },
    _fnSaveValidation(){
      let success = true;
      let title = null;
      let msg = null;
      if(!this.form.username && !this.formConfig.username.disabled){
        title= '경고 : FC코드';
        msg = 'FC코드를 입력해 주십시오.';
        success = false;
      } else if(isNaN(this.form.username)){
          title= '경고 : FC코드';
          msg = '숫자로 입력해 주십시오.';
          success = false;
      } else if(!this.form.name){
          title= '경고 : FC이름';
          msg = 'FC이름을 입력해 주십시오.';
          success = false;
      } else if(!this.form.tel){
          title= '경고 : 전화번호';
          msg = '전화번호를 입력해 주십시오.';
          success = false;
      }
      // else if(!this.$validate('phone', this.form.tel)){
      //     title= '경고 : 전화번호';
      //     msg = '전화번호 형식으로 입력해 주십시오.';
      //     success = false;
      // }
      else if(!this.form.birthDt){
          title= '경고 : 생년월일';
          msg = '생년월일을 입력해 주십시오.';
          success = false;
      } else if(this.form.birthDt.length!=6){
          title= '경고 : 생년월일';
          msg = '생년월일을 yymmdd형식으로 입력해 주십시오.';
          success = false;
      } else if(isNaN(this.form.birthDt)){
          title= '경고 : 생년월일';
          msg = '숫자로 입력해 주십시오.';
          success = false;
      } else if(!this.form.gender){
          title= '경고 : 성별';
          msg = '성별을 선택해 주십시오.';
          success = false;
      } else if(this.positionSelect && !this.form.positionCd){
          title= '경고 : 직책';
          msg = '직책을 선택해 주십시오.';
          success = false;
      } else if(!this.form.mngYn){
          title= '경고 : 관리자여부';
          msg = '관리자여부를 선택해 주십시오.';
          success = false;
      }
      // else if(!this.central){
      //     title= '경고 : 본부';
      //     msg = '본부를 선택해 주십시오.';
      //     success = false;
      // }
      if( ! success )
        this.$popupAlert({title, msg});
      return success;
    },
  },
};
</script>
