<template>
  <div class="login app">
    <div class="inner">
      <img src="/IMG/logo_admin.png" alt="유니콘랜치">
      <div class="form ">
        <span>아래 링크를 이용하여 모바일에서<br/>앱을 다운로드 받으세요.</span>
        <div class="btns">
          <button type="button" class="link" @click="android">안드로이드</button>
          <button type="button" class="link" @click="ios">ios</button>
        </div>
        <div class="cont">
          <p>※갤럭시 기준 설치방법</p>
          <div class="manual">
            <p>설정 ></p>
            <p>생체 인식 및 보안 ></p>
            <p>출처를 알 수 없는 앱 설치 ></p>
            <p>내 파일 ></p>
            <p>앱 설치 허용</p>
          </div>
          <p>※기준 설치가 안되는 경우</p>
          <div class="manual">
            <p>설정 ></p>
            <p>어플리케이션 ></p>
            <p>연결메뉴 ></p>
            <p>특별한 접근 ></p>
            <p>출처를 알 수 없는 앱 설치 ></p>
            <p>내 파일 ></p>
            <p>앱 설치 허용</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "~/components/layouts/Header";
import Location from "~/components/layouts/Location";
import CheckBox from "~/components/common/CheckBox";
import InputBox from "~/components/common/Input";

export default {
  layout: "none",
  components: {
    Header,
    Location,
    CheckBox,
    InputBox
  },
  data(){
    return {
      androidLink:process.env.APP_ANDROID_LINK,
      iosLink:process.env.APP_IOS_LINK
    }
  },
  methods:{
    android(){
      if(this.androidLink){
        this.fnOpen(this.androidLink);
      }else{
        this.fnAlert('아직 ANDROID 앱이 준비되지 않았습니다.');
      }
    },
    ios(){
      if(this.iosLink){
        this.fnOpen(this.iosLink);
      }else{
        this.fnAlert('아직 IOS 앱이 준비되지 않았습니다.');
      }
    },
    fnOpen(url){
      window.open(url,'_blank')
    },
    fnAlert(msg){
      this.$popupAlert({
        title: '알림',
        msg : msg
      });
    }
  }
};
</script>
