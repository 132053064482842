<template>
  <section class="container">
    <Location />
    <div class="inner">
      <Search :organization="organization" @changed="fnSearchFormChanged"/>
      <client-only>
      <div class="colb">
        <div class="top">
          <div>
            <button class="btn" @click="fnOpenPop('newFcState')" v-if="$roleCheck(['ALL_OK_MNG']) && ![company.유니콘랜치.code, company.프리랜서FC.code, company.코니아.code, company.벤처.code].includes(gaId)">
              새로운 FC
            </button>
            <button class="btn" @click="fnOpenPop3('newFc2State')" v-if="$roleCheck(['ALL_OK_MNG']) && [company.코니아.code, company.벤처.code].includes(gaId)">
              새로운 FC
            </button>
            <button class="btn" @click="fnOpenPop2('fcDetail')" v-if="$roleCheck(['ALL_OK_MNG'])">
              열기
            </button>
            <SecedeBtn v-if="$roleCheck(['ALL_OK_MNG'])"
                       :user="user"
                       @reload="fnReload"/>
            <PwResetBtn v-if="$roleCheck(['ALL_OK_MNG'])"
                        :user="user"  />
          </div>
        </div>
        <div class="col unit_page">
          <h4 class="l_tit">페이지당 개수 : </h4>
          <Select
            id="perPage"
            title="20개"
            :itemList="perPageList"
            :value.sync="query.perPage"
          />
        </div>
      </div>
      </client-only>
      <div class="contents">
        <ContentsList :contents="contents"
                      :organizationList="organizationList"
                      :orgCdList="orgCdList"
                      :position-cd-list="positionCdList"
                      @click="fnOnClick"
                      @dblclick="fnUserDoubleClick"
                      @change="fnSort"
        />
        <Pagination
          :page="query.page"
          :perPage="query.perPage"
          :totalCount="totalCount"
          @change="p => {
            this.query.page=p;
            this.fnFetchList();
          }"
        />
      </div>
      <transition
        v-if="[company.유니콘랜치.code, company.프리랜서FC.code].includes(gaId)"
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <FCdetail2 v-if="$store.state.ui.fcDetail == true" :key="$store.state.ui.fcDetail" :user="user" @reload="fnReload"/>
      </transition>
      <transition
        v-else
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <NewFC v-if="$store.state.ui.newFcState == true"
               :key="$store.state.ui.newFcState"
               :positionSelect="positionCdList"
               :orgCdList="orgCdList"
               :userCreateData="userCreateData"
               @reload="fnReload"/>
        <NewFC2 v-if="$store.state.ui.newFc2State == true"
               :key="$store.state.ui.newFc2State"
                :positionSelect="positionCdList"
               @reload="fnReload"/>
        <FCdetail v-if="$store.state.ui.fcDetail == true" :key="$store.state.ui.fcDetail"
                  :user="user"
                  :organization="organization"
                  :organizationList="organizationList"
                  :orgCdList="orgCdList"
                  :positionSelect="positionCdList"
                  @reload="fnReload"/>
      </transition>
    </div>
  </section>
</template>

<script>
import Header from '~/components/layouts/Header';
import Location from '~/components/layouts/LocationDataWrapper';
import NewFC from '~/components/pages/allOk/NewFC.vue';
import NewFC2 from '~/components/pages/allOk/NewFC2.vue';
import CsPop from '~/components/popup/Cs.vue';
import FCdetail from '~/components/pages/allOk/FCdetail.vue';
import FCdetail2 from '~/components/pages/allOk/FCdetail2.vue';
import Input from '~/components/common/Input.vue';
import Pagination from "~/components/common/Pagination";
import Select from "~/components/common/Select";
import Search from '../../components/pages/allOk/manageFC/Search';
import organizationService from "../../service/organizationService";
import organizationUserService from "../../service/organizationUserService";
import companyService from "../../service/companyService";
import ContentsList from "../../components/pages/allOk/manageFC/ContentsList";
import PwResetBtn from "../../components/pages/allOk/manageFC/_share/PwResetBtn";
import SecedeBtn from "../../components/pages/allOk/manageFC/_share/SecedeBtn";
import * as company from '~/constants/company';
export default {
  components: {
    Header,
    Location,
    NewFC,
    NewFC2,
    CsPop,
    FCdetail,
    FCdetail2,
    Input,
    Select,
    Pagination,
    Search,
    ContentsList,
    PwResetBtn,
    SecedeBtn,
  },
  data() {
    return {
      roles:['ALL_OK_MNG'],
      query: {
        page:1,
        perPage: 20, // 한 페이지에 보여줄 rows의 갯수
      },
      user:null,
      contents:[],
      totalCount:0,
      reload:0,
      perPageList:[
        { name: '10개', value:10 },
        { name: '20개', value:20 },
        { name: '30개', value:30 },
      ],
      organization:[],
      organizationList:[],
      positionCdList: null,
      orgCdList: null,
      userCreateData:null,
      org:{},
      searchForm:{
        includeChildOrg: true,
      },
      company: company
    };
  },
  computed:{
    gaId(){
      return this.$store.state.authUser?.gaId;
    },
  },
  methods: {
    fnSort(e){
      this.query[e.key] = e.value;
      this.fnFetchList();
    },
    fnSearchFormChanged(e) {
      this.searchForm = e;
      this.query.page = 1;
      this.fnFetchList();
    },
    fnOnClick(e) {
      this.user = e;
    },
    fnUserDoubleClick(e) {
      this.user = e;
      if(this.$roleCheck(['ALL_OK_MNG'])){
        this.fnOpenPop2();
      }
    },
    fnOpenPop(payload) {
      this.$store.dispatch('ui/setPopFcState', true);
    },
    fnOpenPop3(payload) {
      this.$store.dispatch('ui/setPopFc3State', true);
    },
    fnOpenPop2(payload) {
      if(this.user){
        organizationUserService.getOrganizationsUserOne(this.$axios, this.user.id)
          .then(res => {
            this.user = {
              ...this.user,
              ...res.data.contents
            }
            this.$store.dispatch('ui/setPopFc2State', true);
          })
        } else{
          this.$popupAlert({
            title: '경고',
            msg: '고객을 선택해 주세요.',
          });
          return;
        }
    },
    fnFetchList(){
      const payload = {
        ...this.searchForm,
        ...this.query
      };

      organizationUserService.getOrganizationsUser(this.$axios, payload)
        .then(res => {
          this.contents = res.data.contents;
          this.totalCount = res.data.pagination.totalCount;
        })
    },
    fnReload(){
      // this.reload = Math.random();
      this.fnFetchList();
    },
    /*
    fnNewCustomer(){
      const id = this.$store.state.authUser.id;
      window.open('http://localhost:3000/lookup?q='+id)
    },
     */
  },
  async asyncData({$axios, store}){
    let organization = [];
    let organizationList = [];
    let positionCdList = null;
    let orgCdList = null;
    let userCreateData = null;
    let org = {};
    const result = await organizationService.getOrganiztions($axios)
    if(result.success){
      organization = result.data
      org = result.data[0]
    }
    const resultType2 = await organizationService.getOrganiztionsType2($axios)
    if(resultType2.success){
      organizationList = resultType2.data
    }
    // company
    // await companyService.getCompany($axios)
    if ( store.state.authUser && store.state.authUser.gaId ) {
      const companyResult = await companyService.getCompany($axios, store.state.authUser.gaId);
      if ( companyResult.result && companyResult.data.contents && companyResult.data.contents.metaData) {
        positionCdList = companyResult.data.contents.metaData.positionCdList;
        orgCdList = companyResult.data.contents.metaData.orgCdList;
        userCreateData = companyResult.data.contents.metaData.userCreateData;
      }
    }
    return {
      organization,
      organizationList,
      org,
      positionCdList,
      orgCdList,
      userCreateData
    }
  },
  created() {
    this.$store.dispatch('allOk/allOkStore/getOrganiztions')
  },
  mounted() {
    this.fnFetchList();
  },
  beforeMount(){
    if(!this.$roleCheck(['ALL_OK_MNG'])&&!this.$roleCheck(['ADMIN'])){
      this.$router.push('/')
    }
  }
};
</script>
