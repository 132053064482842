<template>
  <div class="c_contents">
    <section class="container">
      <div class="colb">
        <section class="list_goods mb10" style="width: 40%">
          <article class="unit">
            <h2><span class="txt_blue">{{compNm}}</span> 세금환급 분석결과 <span class="txt_org">[환급 대상 사업장]</span>입니다.</h2>
            <dl>
              <dd>최근 5년, 간편확인 결과<br/><b>공제 세액 예상 금액</b></dd>
            </dl>
            <h2>
              <label for="stk" v-if="result"><b style="color: #2f5ed8">{{resultPrice}}</b><small>원</small></label>
              <label for="stk" v-else><b>진단 중</b></label>
            </h2>
          </article>
        </section>
        <div style="display: flex; flex-direction: column; justify-content: space-between">

          <div class="col unit_page" style="align-items: flex-start">
            <InputFile id="file" name="file" placeholder="파일을 선택하세요" :value.sync="file"  :styleAttr="{width: '300px'}" ref="inputFile" />
            <button class="btn ml5 mr5" @click="fnFileUpload" style="margin-top:3px" :disabled="loading||!$roleCheck([ADMIN])" :class="{dis: loading||!$roleCheck([ADMIN])}">파일업로드</button>
            <Loading v-if="loading"  class="mt5"/>
          </div>

          <div v-if="result" style="display: flex; justify-content: flex-end">
            <button class="btn" :class="{dis: pdfDownloading}" @click="downloadPdf" :disabled="pdfDownloading">진단결과 리포트</button>
          </div>
        </div>
      </div>
      <hr style="background: #dddddd; height: 1px; border: 0" />
      <div v-if="gridVersion == '1'">
        <grid v-if="gridProp" class="pb20" :data="gridProp.data" :columns="gridProp.columns" :options="gridProp.options" ref="grid" :key="gridKey" />
        <div v-if="gridProp">
          <p>* 간편계산기준 공제가능세액(1) : [과세연도별 고용증대 및 사회보험료 공제세액계산서의 합계액] + [이월세액] - [공제항목대상세액] (- [추가납부], 고용증대인경우만)</p>
          <p>* 홈택스기준 추가공제가능세액(2) : 과세연도별 홈택스신고내역의 세금환급한도액</p>
          <p>* 환급예상세액(3) : [간편계산기준대상세액]과 [홈택스기준 추가공제가능세액]의 최소값</p>
          <p>* 이월세액(4) : 홈택스기준공제가능세액대비 간편계산기준공제대상세액의 차이액 (농특세 비과세 대상인 사회보험료 우선 환급처리)</p>
          <p>* 공제세액 (5) : 과세연도별 고용증대 및 사회보험료 공제세액</p>
          <p>* 추가납부(6) : 과세연도별 추가납부 합계액</p>
          <p>* 농어촌특별세 : 고용증대 세액공제의 공제대상세액 중 20%에 상당하는 금액</p>
          <p>* 공제항목 대상세액(7) : 과세연도별 기 공제된 고용증대 및 사회보험료 세액공제 대상금액</p>
          <p>* 체납세액 (8) : 귀속연월별 체납 중인 납부할 세액</p>
          <p>* 경정청구세액(9) : 경정청구 세금신고내역의 과세기간별 청구세액</p>
        </div>
      </div>
      <div v-else-if="gridVersion == '2'">
        <TaxTab6Grid2 :origin-data="data" />
        <TaxTab6Grid3 :origin-data="data" />
      </div>
      <br />
    </section>
  </div>
</template>

<script>
import Select from '../../common/Select.vue';
import { CustomColumnHeader1 } from '~/util/TuiGridCustomClass.js';
import Loading from '../../common/Loading.vue';
import taxService from '~/service/allOkMng/taxService'
import InputFile from '../../common/InputFile.vue';
import {ADMIN} from "~/constants/userRole";
import TaxTab6Grid2 from "./TaxTab6Grid2";
import TaxTab6Grid3 from "./TaxTab6Grid3";
export default {
  props: ['id', 'bizNo', 'compNm'],
  components: {
    Select,
    Loading,
    InputFile,
    TaxTab6Grid2, TaxTab6Grid3
  },

  data() {
    return {
      gridVersion: '1',
      file: null,
      result: false,
      resultPrice: 0,
      data: {},
      gridProp: null,
      gridKey: Math.random(),
      loading: false,
      pdfDownloading: false,
      ADMIN,
    };
  },

  methods: {
    fnFetchTax(){
      taxService.getSimpleCalculationResult(this.$axios, this.id).then(({result, data})=>{
        if(result && data.contents){
          this.result = true
          this.data = JSON.parse(data.contents);
          if( this.data.Tax ) {
            // 기존 형태
            if ( this.data.Tax.length == 20 ) {
              this.gridVersion = '1';
              this.fnSettingGridProp();
              this.resultPrice = this.fnFormatNumber(this.data.Tax?.[19]?.E);
            }
            else{
              this.gridVersion = '2';
              const _resultPrice = this.data.Tax[this.data.Tax.length-1].D;
              this.resultPrice = isNaN(_resultPrice) ? _resultPrice : parseInt(_resultPrice).toLocaleString()
            }
          }
        }
      })
    },
    fnFileUpload(){
      if (!this.file) {
        this._alert('알림', '파일을 선택해주세요');
        return;
      }

      const btn = {
        name: '확인',
        func: () => {
          this._closePop();
          const username = this.$store.state.authUser.username;
          this.loading = true;
          taxService.uploadSimpleCalculationResult(this.$axios, this.id, { userNo: username, uploadFile: this.file }).then(({ result, data }) => {
            this.loading = false;
            if (result) {
              this._alert('알림', '파일이 업로드되었습니다');
              this.fnFetchTax();
            } else {
              this._alert('알림', `파일을 업로드하는데 실패하였습니다(${data.error.msg})`);
              console.error(data);
            }
            this.fnResetInputFile();
          });
        },
      };
      this._confirm('확인', `파일을 업로드하시겠습니까?`, btn);
    },
    downloadPdf(){
      this.pdfDownloading = true;
      const url = process.env.allOkKKUrl
      if ( this.gridVersion == '1' ) {
        location.href = `${url}/api/pdf/tax?compNm=${this.compNm}&bizNo=${this.bizNo}&partnerName=다된다`;
      }
      else {
        location.href = `${url}/api/pdf/tax?compNm=${this.compNm}&bizNo=${this.bizNo}&partnerName=다된다&reportVer=${this.gridVersion}`;
      }
      setTimeout(()=> {
        this.pdfDownloading = false;
      }, 1000 * 6);
    },
    fnSettingGridProp() {
      const columns = [
        {
          header: '<div style="font-weight: bold; font-size:12px">과세년도</div>',
          name: 'c1',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:12px">공제세액항목</div>',
          name: 'c2',
          width: 300,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:12px">간편계산기준 공제가능세액(1)</div>',
          name: 'c3',
          width: 200,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:12px">홈택스기준 추가공제가능세액(2)</div>',
          name: 'c4',
          width: 200,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:12px">환급예상세액(3)</div>',
          name: 'c5',
          width: 150,
          className: 'color6',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:12px">이월세액(4)</div>',
          name: 'c6',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:12px">공제세액(5)</div>',
          name: 'c7',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:12px">추가납부(6)</div>',
          name: 'c8',
          width: 100,
          // className: 'color2',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:12px">공제항목 대상세액(7)</div>',
          name: 'c9',
          width: 150,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:12px">미체납세액(8)</div>',
          name: 'c10',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:12px">경정청구세액(8)</div>',
          name: 'c11',
          width: 100,
          // className: 'color1',
          align: 'center',
        },
      ];

      const complexColumns = [
        {
          header: `<div style="font-weight: bold; font-size: 15px">간편계산 상세정보</div>`,
          name: 'x1',
          childNames: ['c6', 'c7', 'c8','c9'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 15px;">중요 홈택스 신고내역</div>`,
          name: 'x2',
          childNames: ['c10', 'c11'],
          renderer: CustomColumnHeader1,
        },
      ];

      const columnRenderer = columns.map(el => ({
        name: el.name,
        renderer: CustomColumnHeader1,
      }));


      const list = this.data.Tax
      let data = [];
      let columnContent = {}
      if(list.length == 20) {
        data = list.slice(2, 17).map((el, idx)=>{
          if(idx % 3 == 0) {
            return {
              c1: el.A,
              c2: this.fnFormatNumber(el.B),
              c3: this.fnFormatNumber(el.C),
              c4: this.fnFormatNumber(el.D),
              c5: this.fnFormatNumber(el.E),
              c6: this.fnFormatNumber(el.F),
              c7: this.fnFormatNumber(el.G),
              c8: this.fnFormatNumber(el.H),
              c9: this.fnFormatNumber(el.I),
              c10: this.fnFormatNumber(el.J),
              c11: this.fnFormatNumber(el.K),
              _attributes: {
                rowSpan: {
                  c1: 3,
                  c4: 3,
                  c5: 3,
                  c10: 3,
                  c11: 3,
                },
              }
            }
          } else if(idx % 3 == 2){
            return {
              c2: this.fnFormatNumber(el.B),
              c3: this.fnFormatNumber(el.C),
              c6: this.fnFormatNumber(el.F),
              c7: this.fnFormatNumber(el.G),
              c8: this.fnFormatNumber(el.H),
              c9: this.fnFormatNumber(el.I),
              _attributes: {
                className: {
                  row: ['color7'],
                },
              },
            }
          }
          else {
            return {
              c2: this.fnFormatNumber(el.B),
              c3: this.fnFormatNumber(el.C),
              c6: this.fnFormatNumber(el.F),
              c7: this.fnFormatNumber(el.G),
              c8: this.fnFormatNumber(el.H),
              c9: this.fnFormatNumber(el.I),
            }
          }
        })

        columnContent = {
          c3: `<div style="text-align: center;font-weight: bold;">${list[17].A}<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp;<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp;</div>`,
          c4: `<div style="text-align: center;font-weight: bold;">${this.fnFormatNumber(list[17].D)}<hr style="background:#EEEEEE;height:1px;border:0" />${list[18].A}<hr style="background:#EEEEEE;height:1px;border:0" />${list[19].A}</div>`,
          c5: `<div style="text-align: center;font-weight: bold;">${this.fnFormatNumber(list[17].E)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnFormatNumber(list[18].E)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnFormatNumber(list[19].E)}</div>`,
          c6: `<div style="text-align: center;font-weight: bold;">${this.fnFormatNumber(list[17].F)}<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnFormatNumber(list[19].F)}</div>`,
          c7: `<div style="text-align: center;font-weight: bold;">${this.fnFormatNumber(list[17].G)}<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp</div>`,
          c8: `<div style="text-align: center;font-weight: bold;">${this.fnFormatNumber(list[17].H)}<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp</div>`,
          c9: `<div style="text-align: center;font-weight: bold;">${this.fnFormatNumber(list[17].I)}<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp</div>`,
          c10: `<div style="text-align: center;font-weight: bold;">${this.fnFormatNumber(list[17].J)}<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp</div>`,
          c11: `<div style="text-align: center;font-weight: bold;">${this.fnFormatNumber(list[17].K)}<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp<hr style="background:#EEEEEE;height:1px;border:0" />&nbsp</div>`,
        };
      }


      const options = {
        width:1600,
        scrollX: false,
        scrollY: false,
        // minRowHeight: 20,
        // rowHeight: 20,
        header: {
          height: 100,
          columns: columnRenderer,
          complexColumns,
        },
        // columnOptions: {
        //   frozenCount: 7,
        //   frozenBorderWidth: 2,
        // },
        summary: {
          height: 100,
          position: 'bottom', // or 'top'
          columnContent,
        },
      };

      this.gridProp = {
        data: data,
        columns: columns,
        options: options,
      };
      this.gridKey++;
    },
    fnResizeGrids() {
      this.$refs.grid && this.$refs.grid.invoke('refreshLayout');
    },
    fnResetInputFile() {
      this.$refs.inputFile.fnResetValue();
      this.file = null;
    },
    _alert(title, msg) {
      let data = {
        state: true,
        type: 'alert',
        title: title,
        msg: msg,
        btn: {
          name: '확인',
        },
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _confirm(title, msg, btn) {
      let data = {
        state: true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closePop() {
      this.$store.dispatch('ui/setPopAlertData', {
        state: false,
        type: null,
        title: null,
        msg: null,
        btn: {
          link: '',
          name: null,
        },
      });
    },
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  },

  watch: {
    '$store.state.ui.menuState'() {
      setTimeout(() => {
        this.fnResizeGrids();
      }, 500);
    },
  },

  mounted() {
    this.fnFetchTax();
  },
};
</script>

<style>
.tui-grid-cell.color6 {
  background-color: #ecf0ff
}
.tui-grid-cell.color7 {
  background-color: #F7F8FA
}
</style>
