<template>
  <div>
    <div class="colb area_tap">
      <div class="taps_btn">
        <button
          :class="{ tap:true, on: fundParam.loanPrdcType === fundParamData.loanPrdcType.보증형}"
          @click="()=>fnLoanPrdcTypeChange(fundParamData.loanPrdcType.보증형)">
          보증형 정책자금
        </button>
        <button
          :class="{ tap:true, on: fundParam.loanPrdcType === fundParamData.loanPrdcType.직접대출}"
          @click="()=>fnLoanPrdcTypeChange(fundParamData.loanPrdcType.직접대출)">
          직접대출형 정책자금
        </button>
      </div>
      <div class="c_right" v-if="fundParam.loanPrdcType === fundParamData.loanPrdcType.보증형">
        <button class="btn_arr" :class="{on:fundParam['LPT01'].sortColumn==='APLC_LMT'}" @click="()=>fnSortChange('APLC_LMT')">한도 높은순</button>
        <button class="btn_arr" :class="{on:fundParam['LPT01'].sortColumn==='GRNT_FEE_RATE'}" @click="()=>fnSortChange('GRNT_FEE_RATE')">보증료율 낮은순</button>
      </div>
      <div class="c_right" v-if="fundParam.loanPrdcType === fundParamData.loanPrdcType.직접대출">
        <button class="btn_arr" :class="{on:fundParam['LPT02'].sortColumn==='APLC_LMT'}" @click="()=>fnSortChange('APLC_LMT')">한도 높은순</button>
        <button class="btn_arr" :class="{on:fundParam['LPT02'].sortColumn==='LOAN_INTR_RATE'}" @click="()=>fnSortChange('LOAN_INTR_RATE')">금리 낮은순</button>
      </div>
    </div>
    <section class="list_goods">
      <article class="unit" v-for="product in productList" :key="product.loadnPrdcNo">
        <div class="bx_label">
          <span class="txt_label" v-if="productType().tcb">TCB기술금융</span>
          <span class="txt_label grean" v-if="productType().org">정부정책자금</span>
          <span class="txt_label orange" v-if="productType().ip">IP금융</span>
        </div>
        <h3>{{product.loanPrdcNm}}</h3>
        <dl>
          <dt>지원한도</dt>
          <dd><b>{{ product.aplcLmt }}</b></dd>
        </dl>
        <div class="col2">
          <dl class="col" v-if="product.displayRate">
            <dt>보증비율</dt>
            <dd>{{product.grntRate ? product.grntRate : '-'}}</dd>
          </dl>
          <dl class="col" v-if="product.displayFeeRate">
            <dt>보증료율</dt>
            <dd><b>{{product.grntFeeRate ? product.grntFeeRate : '-'}}</b></dd>
          </dl>
          <dl class="col" v-if="product.displayLoanIntrRate">
            <dt>대출금리</dt>
            <dd><b>{{product.loanIntrRate ? product.loanIntrRate : '-'}}</b></dd>
          </dl>
        </div>
        <dl>
          <dt>대출기간</dt>
          <dd>{{product.loanTerm}}</dd>
        </dl>
        <dl>
          <dt>금융기관</dt>
          <dd>{{ product.loanPrdcFnncInstTypeNm }}</dd>
        </dl>
        <dl>
          <dt>비고</dt>
          <dd>{{ product.loanPrdcDesc }}</dd>
        </dl>
      </article>
    </section>
  </div>
</template>

<script>

export default {
  name: "tab1",
  props:['customer'],
  data(){
    return {
      fundParamData:{
        loanPrdcType:{ 보증형:'LPT01', 직접대출:'LPT02'},
        sortColumn: {
          LPT01: { 한도:'APLC_LMT', 보증료율:'GRNT_FEE_RATE',},
          LPT02: { 한도:'APLC_LMT', 금리:'LOAN_INTR_RATE'}
        }
      },
      fundParam:{
        loanPrdcType:null,
        LPT01: { sortColumn:'APLC_LMT',sortOrdr:'ASC',},
        LPT02: { sortColumn:'APLC_LMT',sortOrdr:'ASC',}
      },
      fundResult:{ LPT01:[], LPT02:[],},
    }
  },
  computed: {
    sortData(){
      return this.fundParam[this.fundParam.loanPrdcType]
    },
    sortAplcUp(){
      return this.sortData.sortColumn == 'APLC_LMT';
    },
    sortGrntFreeUp(){
      return this.sortData.sortColumn == 'GRNT_FEE_RATE';
    },
    sortIntrUp(){
      return this.sortData.sortColumn == 'LOAN_INTR_RATE';
    },
    codeMap(){
      return this.$store.state.allOk.allOkStore.codeGroup;
    },
    productList() {
      const productResult = this.fundResult[this.fundParam.loanPrdcType];
      if(productResult){
        return productResult.map( d => {
          let displayRate = false; // 보증비율
          let displayFeeRate = false; // 보증료율
          let displayLoanIntrRate = false; // 대출금리
          // 보증형정책
          if(d.loanPrdcType === 'LPT01'){
            displayRate = true
            displayFeeRate = true;
            displayLoanIntrRate = false;
          }
          // 직접 대출형
          else {
            displayRate = false;
            displayFeeRate = false;
            displayLoanIntrRate = true;
          }
          return {
            ...d,
            displayRate,
            displayFeeRate,
            displayLoanIntrRate,
          }
        });
      }
      return [];
    },
    productLength(){
      return this.productList.length;
    },
  },
  methods:{
    async fetchAction(loanPrdcType, sortColumn, sortOrdr) {
      let params = {
        bizNo : this.customer.bizNo,
        empl : this.findOriginalValue(this.codeMap.ETC100, this.customer.empNumCd),
        hire : this.findOriginalValue(this.codeMap.ETC200, this.customer.hireCd),
        loanPrdcType,
        rprs : this.findOriginalValue(this.codeMap.ETC300, this.customer.prstAgeCd),
        tech : this.findOriginalValue(this.codeMap.ETC400, this.customer.techRcmCd),
        sortColumn,
        sortOrdr,
      }
      if(this.customer.notFoundBizNo){
        params = {
          ...params,
          addrYn: this.mappingAddrYn(this.customer.korAddr),
          crtfYn : this.isCertHolder2(this.customer),
          directYn: this.mappingDirectYn(this.customer),
          etbDate: this.mappingEtbDate(this.customer),
          idsCd: this.customer.idsCd,
          patnYn: this.customer.patnCnt > 0 ? 'Y' : 'N',
        }
      }
      const result = await this.$store.dispatch('allOk/allOkStore/fetchFund', params)
      if(result){
        this.fundResult[loanPrdcType] = result.data.contents;
      }
    },
    async fetchFund(){
      const loanPrdcType = this.fundParamData.loanPrdcType.보증형;
      const sortColumn = this.fundParamData.sortColumn.LPT01.한도;
      const sortOrdr = 'ASC';
      this.fundParam.loanPrdcType = loanPrdcType
      this.fetchAction(loanPrdcType, sortColumn, sortOrdr)
    },
    async fnLoanPrdcTypeChange(loanPrdcType){
      this.fundParam['LPT01'].sortColumn='APLC_LMT'
      this.fundParam['LPT01'].sortColumn='APLC_LMT'
      if(  this.fundParam.loanPrdcType !== loanPrdcType ) {
        this.fundParam.loanPrdcType = loanPrdcType
        const sortColumn = this.fundParamData.sortColumn.LPT01.한도;
        const sortOrdr = 'ASC';
        this.fetchAction(loanPrdcType, sortColumn, sortOrdr)
      }
    },
    fnSortChange( _sortColumn ) {
      const currentSortColumn = this.sortData.sortColumn;
      if ( currentSortColumn !== _sortColumn ) {
        const loanPrdcType = this.fundParam.loanPrdcType;
        const sortColumn = _sortColumn;
        const sortOrdr = this._fnSortOrder(_sortColumn);
        this.fundParam[loanPrdcType].sortColumn = sortColumn;
        this.fundParam[loanPrdcType].sortOrdr = sortOrdr;
        this.fetchAction(loanPrdcType, sortColumn, sortOrdr)
      }
    },
    _fnSortOrder(sortColumn){
      return ( sortColumn === 'APLC_LMT' ) ? 'ASC' : 'ASC';
    },
    findOriginalValue(codeGrp, value){
      for(let i = 0; i < codeGrp.length ; i++ ){
        if(codeGrp[i].cd === value){
          return codeGrp[i].value01;
        }
      }
      return null;
    },
    mappingAddrYn(korAddr){
      if(korAddr)
        return korAddr.indexOf('경기도') > -1 ? 'Y' : 'N'
      return 'N'
    },
    isCertHolder2(companyInfo){
      return companyInfo.labYn || companyInfo.ventureYn || companyInfo.innobizYn || companyInfo.deptYn ? 'Y' : 'N';
    },
    mappingDirectYn(companyInfo){
      return companyInfo.notFoundBizNo ? 'Y' : 'N';
    },
    mappingEtbDate(companyInfo){
      if(companyInfo.fndDt){
        return companyInfo.fndDt.replaceAll(".","");
      }else {
        return null;
      }
    },
    productType() {
      return {
        tcb: this.isCertHolder(this.customer) || this.isTechHolder(this.customer) || this.isAllowedSector(this.customer),
        org: true,
        ip: this.isTechHolder(this.customer),
      }
    },
    isCertHolder(companyInfo){
      if(companyInfo){
        return companyInfo.labYn || companyInfo.ventureYn || companyInfo.innobizYn || companyInfo.deptYn;
      }
    },
    isTechHolder(companyInfo){
      if(companyInfo){
        return companyInfo.patnCnt > 0;
      }
    },
    isAllowedSector(companyInfo){
      const SECTOR_INIT = ['C', 'E', 'F', 'G', 'J', 'M', 'N', 'P', "R"]
      if(companyInfo){
        if(companyInfo.idCd)
          return SECTOR_INIT.includes(companyInfo.idscd.sub(0,1));
      }
    }
  },
  // async fetch(){
  //   this.loanPrdcTypeValues = this.$store.state.lenderLookupStore.fundParamData.loanPrdcType;
  // },
  mounted() {
    // 추가 정보 입력을 위한 코드 가져오기
    // this.$store.dispatch('lenderLookupStore/fetchCodes');
    if(this.customer.bizNo){
      this.fetchFund()
    }
  },
  watch: {
    customer(newValue) {
      if(newValue){
        this.fetchFund()
      }
    }
  },
};
</script>
