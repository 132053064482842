<template>
  <div class="bx_file" :class="[classNm]" :style="styleAttr">
    <input type="file" class="ipt_file" ref="ipt_file" :id="id" @change="fnUpload" :disabled="disabled == true ? 'disabled' : false" />
    <span class="file_name"><em>파일선택</em>{{ value ? value.name : placeholder }}</span>
    <label :for="id" class="btn_file">파일선택</label>
  </div>
</template>
<script>
export default {
  name: 'Input',
  props: {
    // 클래스
    classNm: {
      type: String,
    },
    // 스타일
    styleAttr: {
      type: Object,
      required: false,
    },
    // 필드 명
    name: {
      type: String,
      required: true,
    },
    // 플레이스 홀드
    placeholder: {
      type: String,
    },
    // 사용여부
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      id: null,
      // fileNm: this.data.placeholder || '',
    };
  },
  methods: {
    fnUpload(e) {
      try {
        let files = e.target.files || e.dataTransfer.files;
        // this.fileNm = files[0].name || '';
        if (files[0]) {
          this.$emit('update:value', files[0]);
          this.$emit('change', files[0]);
        }
      } catch (e) {
        console.log(e);
        // this.fileNm = '';
      }
    },
    fnResetValue() {
      this.$refs.ipt_file.value = null;
    },
  },
  beforeMount() {
    // 아이디 생성
    this.id = 'ipt_' + this.name + '_' + Math.round(Math.random() * 10000);
  },
};
</script>
