var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"colr"},[_c('button',{staticClass:"btn_reset",on:{"click":_vm.fnResetForm}},[_vm._v("전체초기화")])]),_vm._v(" "),_c('div',{staticClass:"area_search"},[_c('div',{staticClass:"unit"},[_c('label',{staticClass:"tit",attrs:{"for":"bizNo"}},[_vm._v("사업자번호")]),_vm._v(" "),_c('Input',{attrs:{"id":"bizNo","name":"query.bizNo","shape":"search","placeholder":"검색어를 입력하세요.","value":_vm.query.bizNo},on:{"update:value":function($event){return _vm.$set(_vm.query, "bizNo", $event)},"enter":_vm.fnResetPageAndFetch}})],1)]),_vm._v(" "),_c('div',{staticClass:"colb"},[_c('div',{staticClass:"top"},[_c('div',[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.fnOpenPop('popCsState')}}},[_vm._v("열기")]),_vm._v(" "),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.fnOpenPop2('popCs2State')}}},[_vm._v("등록")]),_vm._v(" "),_c('button',{staticClass:"btn",on:{"click":_vm.fnReport}},[_vm._v("리포트")])])]),_vm._v(" "),_c('div',{staticClass:"col unit_page"},[_c('h4',{staticClass:"l_tit"},[_vm._v("페이지당 개수 :")]),_vm._v(" "),_c('Select',{attrs:{"id":"perPage","title":"20개","itemList":_vm.perPageList,"value":_vm.query.perPage},on:{"update:value":function($event){return _vm.$set(_vm.query, "perPage", $event)},"change":_vm.fnFetchList}}),_vm._v(" "),_c('button',{staticClass:"btn",on:{"click":() => {
              _vm.query.page = 1;
              _vm.fnFetchList();
            }}},[_vm._v("\n          검색\n        ")])],1)]),_vm._v(" "),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"tbl_area"},[_c('table',{staticClass:"tbl"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('tbody',[_vm._l((_vm.contents),function(item){return _c('tr',{key:item.id,class:{ on: item.bizNo === _vm.bizNo },on:{"click":function($event){return _vm.fnOnClick(item)},"dblclick":function($event){return _vm.fnOpenPop2('popCsState')}}},[_c('td',{staticClass:"c2",staticStyle:{"width":"10%"}},[_vm._v(_vm._s(item.bizNo))]),_vm._v(" "),_c('td',{staticClass:"c2",staticStyle:{"width":"10%"}},[_vm._v(_vm._s(JSON.parse(item.outlineData).회사명))]),_vm._v(" "),_c('td',{staticClass:"c4"},[_vm._v(_vm._s(_vm.mixCut(item.regDtm)))]),_vm._v(" "),_c('td',{staticClass:"c4"},[_vm._v(_vm._s(_vm.mixCut(item.modDtm)))])])}),_vm._v(" "),(_vm.contents && _vm.contents.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_vm._v("데이터가 없습니다.")])]):_vm._e()],2)])]),_vm._v(" "),_c('Pagination',{attrs:{"page":_vm.query.page,"perPage":_vm.query.perPage,"totalCount":_vm.pagination.totalCount},on:{"change":p => {
            this.query.page = p;
            this.fnFetchList();
          }}})],1),_vm._v(" "),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(_vm.$store.state.ui.popCsState == true)?_c('DiagDetail',{key:_vm.$store.state.ui.popCsState,attrs:{"bizNo":_vm.bizNo},on:{"reload":_vm.fnReload}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(_vm.$store.state.ui.popCs2State == true)?_c('DiagRegist',{key:_vm.$store.state.ui.popCs2State,attrs:{"bizNo":_vm.bizNo},on:{"reload":_vm.fnReload}}):_vm._e()],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"30%"}}),_vm._v(" "),_c('col',{attrs:{"width":"30%"}}),_vm._v(" "),_c('col',{attrs:{"width":"20%"}}),_vm._v(" "),_c('col',{attrs:{"width":"20%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"c2",staticStyle:{"width":"10%"}},[_c('b',[_vm._v("사업자번호")])]),_vm._v(" "),_c('th',{staticClass:"c2",staticStyle:{"width":"10%"}},[_c('b',[_vm._v("회사명")])]),_vm._v(" "),_c('th',{staticClass:"c4"},[_c('b',[_vm._v("신청일")])]),_vm._v(" "),_c('th',{staticClass:"c4"},[_c('b',[_vm._v("조회일")])])])])
}]

export { render, staticRenderFns }