<template>
  <div class="cover">
    <div class="cTit">
      <div class="unicornLogo">unicornranch</div>
      <div class="reportTit">비상장주식가치평가</div>
    </div>
    <div class="contents">
      <div class="Flist" style="display: flex;">3.<span class="Ftit">비상장기업 주식가치평가금액<br/><span class="titInfo">순자산가치 3과 순손익가치 2의 가중치를 적용하여 비상장주식의 가치를 산정하는 금액</span></span></div>
      <div class="compNm">{{입력개요?.회사명}}</div>
      <div>
        <div class="tit1">1) 주식가치평가금액</div>
        <div class="table1">
          <div class="row">
            <div class="cell1">기준연도</div>
            <div class="cell2">자산총계</div>
            <div class="cell2">부동산자산</div>
            <div class="cell2">부동산비율</div>
            <div class="cell2">부채총계</div>
            <div class="cell2">자본총계</div>
            <div class="cell3">자본금</div>
            <div class="cell1" style="color: #B50945;">미처분이익잉여금</div>
          </div>
          <div class="row2">
            <div class="cell4" style="color: #B50A45;">{{주식가치평가금액?.기준연도}}</div>
            <div class="cell5">{{fnFormatNumber(주식가치평가금액?.자산총계)}}</div>
            <div class="cell5">{{fnFormatNumber(주식가치평가금액?.부동산자산)}}</div>
            <div class="cell5" style="color: #B50A45;">{{(fnPercent(주식가치평가금액?.부동산비율)).toFixed(2)}}%</div>
            <div class="cell5">{{fnFormatNumber(주식가치평가금액?.부채총계)}}</div>
            <div class="cell5">{{fnFormatNumber(주식가치평가금액?.자본총계)}}</div>
            <div class="cell6">
              <div class="cell6-1">
                <div class="cell6-1-1">주당액면가</div>
                <div class="cell6-1-2">{{fnFormatNumber(주식가치평가금액?.주당액면가)}}</div>
              </div>
              <div class="cell6-1">
                <div class="cell6-1-3">주식</div>
                <div class="cell6-1-4">{{fnFormatNumber(주식가치평가금액?.주식수)}}</div>
              </div>
              <div class="cell6-2">{{(주식가치평가금액?.자본금).toFixed(2)}}억</div>
            </div>
            <div class="cell4" style="color: #B50A45;">{{fnFormatNumber(주식가치평가금액?.미처분이익잉여금)}}</div>
          </div>
        </div>

        <div class="tit2">2) 순손익가치(직전 3년 결산 기준)</div>
        <div class="table2">
          <div class="t2Row1">
            <div class="t2Cell1">년도</div>
            <div class="t2Cell2">매출액</div>
            <div class="t2Cell2" style="color: #B50A45;">당기순이익</div>
            <div class="t2Cell3">주당이익</div>
            <div class="t2Cell3">가중치</div>
            <div class="t2Cell3">가중이익</div>
          </div>
          <div class="t2Row1">
            <div class="t2Cell4">{{주식가치평가금액?.순손익가치.년도1.년도}}</div>
            <div class="t2Cell5">{{fnFormatNumber(주식가치평가금액?.순손익가치.년도1.매출액)}}</div>
            <div class="t2Cell5" style="color: #B50A45;">{{fnFormatNumber(주식가치평가금액?.순손익가치.년도1.당기순이익)}}</div>
            <div class="t2Cell6">{{fnFormatNumber((주식가치평가금액?.순손익가치.년도1.주당이익).toFixed(2))}}</div>
            <div class="t2Cell6">{{주식가치평가금액?.순손익가치.년도1.가중치}}</div>
            <div class="t2Cell6">{{fnFormatNumber((주식가치평가금액?.순손익가치.년도1.가중이익).toFixed(2))}}</div>
          </div>
          <div class="t2Row1">
            <div class="t2Cell4">{{주식가치평가금액?.순손익가치.년도2.년도}}</div>
            <div class="t2Cell5">{{fnFormatNumber(주식가치평가금액?.순손익가치.년도2.매출액)}}</div>
            <div class="t2Cell5" style="color: #B50A45;">{{fnFormatNumber(주식가치평가금액?.순손익가치.년도2.당기순이익)}}</div>
            <div class="t2Cell6">{{fnFormatNumber((주식가치평가금액?.순손익가치.년도2.주당이익).toFixed(2))}}</div>
            <div class="t2Cell6">{{주식가치평가금액?.순손익가치.년도2.가중치}}</div>
            <div class="t2Cell6">{{fnFormatNumber((주식가치평가금액?.순손익가치.년도2.가중이익).toFixed(2))}}</div>
          </div>
          <div class="t2Row1">
            <div class="t2Cell4">{{주식가치평가금액?.순손익가치.년도3.년도}}</div>
            <div class="t2Cell5">{{fnFormatNumber(주식가치평가금액?.순손익가치.년도3.매출액)}}</div>
            <div class="t2Cell5" style="color: #B50A45;">{{fnFormatNumber(주식가치평가금액?.순손익가치.년도3.당기순이익)}}</div>
            <div class="t2Cell6">{{fnFormatNumber((주식가치평가금액?.순손익가치.년도3.주당이익).toFixed(2))}}</div>
            <div class="t2Cell6">{{주식가치평가금액?.순손익가치.년도3.가중치}}</div>
            <div class="t2Cell6">{{fnFormatNumber((주식가치평가금액?.순손익가치.년도3.가중이익).toFixed(2))}}</div>
          </div>
          <div class="t2Row2">
            <div class="t2Cell7"><span style="font-family:'Pretendard-Bold'">가중평균이익</span><span style="font-family: 'Pretendard-Regular'">&nbsp;( ∑가중이익 ÷ ∑가중치 )</span></div>
            <div class="t2Cell8">{{fnFormatNumber((주식가치평가금액?.순손익가치.가중평균이익).toFixed(2))}}</div>
          </div>
          <div class="t2Row3">
            <div class="t2Cell7"><span style="font-size: 15px; font-family:'Pretendard-Bold'">1주당 순손익가치</span><span style="font-size: 13px; font-family: 'Pretendard-Regular'">&nbsp;( 기획재정부령 이자율 10% )</span></div>
            <div class="t2Cell9">{{fnFormatNumber((주식가치평가금액?.순손익가치.순손익가치_1주당).toFixed(2))}} 원/주</div>
          </div>
          <div class="t2Row4">
            <div class="t2Cell7"><span style="font-size: 15px; font-family: 'Pretendard-Bold'">평가 반영율</span></div>
            <div class="t2Cell9" style="font-family: 'Pretendard-SemiBold';">{{fnPercent(주식가치평가금액?.순손익가치.평가반영율)}}%</div>
          </div>
        </div>

        <div class="tit3">3) 순자산가치(현재기준)</div>
        <div class="table3">
          <div class="t2Row1">
            <div class="t3Cell1">년도</div>
            <div class="t3Cell2">가중이익</div>
          </div>
          <div class="t2Row1">
            <div class="t3Cell3">ⓐ 순손익가중평균 X 50%</div>
            <div class="t3Cell4">{{fnFormatNumber(주식가치평가금액?.순자산가치.a)}}</div>
          </div>
          <div class="t2Row1">
            <div class="t3Cell3">ⓑ 자본총계 X 10%</div>
            <div class="t3Cell4">{{fnFormatNumber(주식가치평가금액?.순자산가치.b)}}</div>
          </div>
          <div class="t2Row1">
            <div class="t3Cell3">ⓒ 영업권 (a-b)</div>
            <div class="t3Cell4">{{fnFormatNumber(주식가치평가금액?.순자산가치.c)}}</div>
          </div>
          <div class="t2Row1">
            <div class="t3Cell3">ⓓ 순자산가액(자본총계+영업권)</div>
            <div class="t3Cell4">{{fnFormatNumber(주식가치평가금액?.순자산가치.d)}}</div>
          </div>
          <div class="t2Row3">
            <div class="t2Cell7"><span style="font-size: 15px; font-family:'Pretendard-Bold'">1주당 순자산가치</span></div>
            <div class="t2Cell9">{{fnFormatNumber((주식가치평가금액?.순자산가치.순자산가치_1주당).toFixed(2))}} 원/주</div>
          </div>
          <div class="t2Row4">
            <div class="t2Cell7"><span style="font-size: 15px; font-family: 'Pretendard-Bold'">평가 반영율</span></div>
            <div class="t2Cell9" style="font-family: 'Pretendard-SemiBold';">{{fnPercent(주식가치평가금액?.순자산가치.평가반영율)}}%</div>
          </div>
        </div>
        
        <div class="tit4">4) 평가결과</div>
        <div class="table4">
          <div class="t2Row1">
            <div class="t3Cell1">기준</div>
            <div class="t3Cell2">평가액</div>
          </div>
          <div class="t2Row1">
            <div class="t4Cell1 fontSize15">1주당 평가금액</div>
            <div class="t4Cell2 fontSize15"><span style="margin-right: 30px;">{{fnFormatNumber((주식가치평가금액?.평가금액_1주당).toFixed(2))}} 원/주</span></div>
          </div>
          <div class="t2Row1">
            <div class="t4Cell1 fontSize15">비상장주식가치 평가금액</div>
            <div class="t4Cell2 fontSize15"><span style="margin-right: 30px;">{{fnFormatNumber((주식가치평가금액?.비상장주식가치평가금액).toFixed(2))}}원</span></div>
          </div>
        </div>

        <div class="tit5">※ 예외 평가 규정</div>
        <div class="table5">
          <div class="t2Row1">
            <div class="t3Cell1">제목</div>
            <div class="t3Cell2">내용</div>
          </div>
          <div class="t2Row1" style="font-family: 'Pretendard-SemiBold'">
            <div class="t4Cell1 fontSize13">1주당 최소 평가</div>
            <div class="t5Cell1 fontSize13"><span style="margin-right: 30px;">{{fnFormatNumber((주식가치평가금액?.평가금액_1주당_예외1).toFixed(2))}} 원/주</span></div>
          </div>
          <div class="t2Row1" style="font-family: 'Pretendard-SemiBold'">
            <div class="t4Cell1 fontSize13">3년 미만법인 or 부동산비율 80%이상</div>
            <div class="t5Cell1 fontSize13"><span style="margin-right: 30px;">{{fnFormatNumber((주식가치평가금액?.평가금액_1주당_예외2).toFixed(2))}} 원/주</span></div>
          </div>
          <div class="t2Row1" style="font-family: 'Pretendard-SemiBold'">
            <div class="t4Cell1 fontSize13">비상장주식가치 평가금액</div>
            <div class="t5Cell1 fontSize13"><span style="margin-right: 30px;">{{fnFormatNumber((주식가치평가금액?.비상장주식가치평가금액_예외1).toFixed(2))}}원</span></div>
          </div>
          <div class="t2Row1" style="font-family: 'Pretendard-SemiBold'">
            <div class="t4Cell1 fontSize13">비상장주식가치 평가금액</div>
            <div class="t5Cell1 fontSize13"><span style="margin-right: 30px;">{{fnFormatNumber((주식가치평가금액?.비상장주식가치평가금액_예외2).toFixed(2))}}원</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      03
    </div>
  </div>
</template>
<script>
export default {
  props: ['입력개요', '주식가치평가금액'],
  methods: {
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fnPercent(num){
      return num*100;
    }
  },
}
</script>
<style scoped>
  @import '~/static/css/report.css';
  .footer{
    width: 100px;
    left: 30px;
    position: absolute;
    border: solid 1px #001736;
    background-color: #001736;
    height: 60px;
    bottom: 0px;
    color: #1FBDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .Ftit{
    display: flex;
    flex-direction: column;
  }
  .titInfo{
    font-size: 10px;
    font-family: 'Pretendard-Light';
  }
  .compNm{
    color: #1FBDC6;
    font-size: 24px;
    font-family: 'Pretendard-SemiBold';
    position: absolute;
    top: 36px;
    right: 30px;
  }
  .tit1{
    position: absolute;
    top: 130px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .table1{
    position: absolute;
    top: 171px;
    left: 30px;
    width: 1160px;
    height: 100px;
    border-top: solid 1px #001736;
    border-bottom: solid 1px #001736;
    display: flex;
    flex-direction: column;
  }
  .row{
    display: flex;
    border-bottom: solid 1px #001736;
    height: 40px;
    color: #001737;
    font-family: 'Pretendard-Bold';
    font-size: 13px;
  }
  .cell1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 40px;
  }
  .cell2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 40px;
  }
  .cell3{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 40px;
  }
  .row2{
    display: flex;
    color: #001737;
    font-family: 'Pretendard-Bold';
    font-size: 13px;
  }
  .cell4{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 60px;
  }
  .cell5{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 60px;
  }
  .cell6{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 60px;
    font-family: 'Pretendard-Bold';
  }
  .cell6-1{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .cell6-1-1{
    background: linear-gradient(to top,  #001736 5%, #DBDCDE 7%);
    text-align: center;
    width: 75px;
    height: 28px;
  }
  .cell6-1-2{
    text-align: center;
    width: 75px;
    height: 28px;
  }
  .cell6-1-3{
    background: linear-gradient(to top,  #001736 5%, #DBDCDE 7%);
    text-align: center;
    width: 75px;
    height: 28px;
  }
  .cell6-1-4{
    text-align: center;
    width: 75px;
    height: 28px;
  }
  .cell6-2{
    width: 100px;
    text-align: center;
    font-size: 15px;
  }
  .tit2{
    position: absolute;
    top: 332px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .table2{
    position: absolute;
    top: 373px;
    left: 30px;
    width: 1160px;
    height: 280px;
    border-top: solid 1px #001736;
    display: flex;
    flex-direction: column;
    color: #001737;
  }
  .t2Row1{
    width: 1160px;
    height: 40px;
    border-bottom: solid 1px #001736;
    font-family: 'Pretendard-Bold';
    display: flex;
    font-size: 13px;
  }
  .t2Cell1{
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t2Cell2{
    width: 260px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
  }
  .t2Cell3{
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
  }
  .t2Cell4{
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Pretendard-SemiBold';
  }
  .t2Cell5{
    width: 260px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
    font-family: 'Pretendard-Regular';
  }
  .t2Cell6{
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
    font-family: 'Pretendard-Regular';
  }
  .t2Row2{
    display: flex;
    border-top: solid 2px #001736;;
  }
  .t2Cell7{
    width: 840px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t2Cell8{
    width: 320px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
    font-family: 'Pretendard-Regular';
  }
  .t2Row3{
    display: flex;
    background-color: #001736;
    color: #21BDC6;
  }
  .t2Cell9{
    width: 320px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    font-family: 'Pretendard-Bold';
    font-size: 15px;
  }
  .t2Row4{
    display: flex;
    border-bottom: solid 1px #001736;
  }
  .tit3{
    position: absolute;
    top: 714px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .table3{
    position: absolute;
    top: 755px;
    left: 30px;
    width: 1160px;
    height: 280px;
    border-top: solid 1px #001736;
    display: flex;
    flex-direction: column;
    color: #001737;
  }
  .t3Cell1{
    width: 300px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t3Cell2{
    width: 860px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
  }
  .t3Cell3{
    width: 300px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
  .t3Cell4{
    width: 860px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
  }
  .tit4{
    position: absolute;
    top: 1096px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .table4{
    position: absolute;
    top: 1137px;
    left: 30px;
    width: 1160px;
    height: 120px;
    border-top: solid 1px #001736;
    display: flex;
    flex-direction: column;
    color: #001737;
  }
  .t4Cell1{
    width: 840px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
  .t4Cell2{
    width: 320px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #001736;
    color: #21BDC6;
  }
  .fontSize13{
    font-size: 13px;
  }
  .fontSize15{
    font-size: 15px;
  }
  .tit5{
    position: absolute;
    top: 1318px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .table5 {
    position: absolute;
    top: 1359px;
    left: 30px;
    width: 1160px;
    height: 200px;
    border-top: solid 1px #001736;
    z-index: 2;
    display: flex;
    flex-direction: column;
    color: #001737;
  }
  .t5Cell1{
    width: 320px;
    height: 40px;
    display: flex;
    z-index: -5;
    align-items: center;
    justify-content: flex-end;
    background-color: #DBDCDE;
    color: #21BDC6;
    position: relative;
  }
  
</style>