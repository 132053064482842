<template>
  <ul class="form_list type2">
    <li class="item_add_file">
      <h4>서류제출 <span class="txt_red">{{fileStatus}}</span></h4>
      <div class="i_wrap">
        <template v-for="grp in docsGroup">
          <div class="wrap_file" :key="grp.codeGrp">
            <div class="w_top">
              <h5>{{ grp.title }}</h5>
            </div>
            <div class="bx_fileview" v-for="code in fnInputFiles(grp.codeGrp)" :key="'file_cd_'+code.cd">
              <dl>
                <dt>
                  <span>{{code.nm}}</span>
                  <button v-if="code.cd==='FA0202' && isPdfDownloadBtnAvail && $roleCheck(['FILE'])&&statusCd!='ST0121'&&statusCd!='ST0120'"
                          class="btn sm"
                          style="float: right;"
                          @click="fnPdfDownload">
                    다운로드
                  </button>
                  <button v-if="code.cd==='FA0202' && !isPdfDownloadBtnAvail && $roleCheck(['FILE'])&&statusCd!='ST0121'&&statusCd!='ST0120'"
                          class="btn gray sm"
                          disabled
                          style="float: right;">
                    다운로드
                  </button>
                  <button v-else-if="code.cd !== 'FA0202' && $roleCheck(['FILE'])&&statusCd!='ST0121'&&statusCd!='ST0120'"
                          class="btn sm"
                          style="float: right;"
                          @click="e=>fnOpenFileBrowser(code.cd)">
                    업로드
                  </button>
                </dt>
                <dd v-if="files[code.cd]">
                  <div class="item_file" v-for="file in files[code.cd]" :key="`${code.cd}_${file.id}`">
                    <span v-if="$roleCheck(['FILE'])&&statusCd!='ST0121'&&statusCd!='ST0120'" @click="()=>fnFileDownload(file.id)">{{file.fileName}}</span>
                    <span v-else>{{file.fileName}}</span>
                    <button v-if="$roleCheck(['FILE'])&&statusCd!='ST0121'&&statusCd!='ST0120'" class="btn_delete" @click="()=>fnFileDelete(file.id)">삭제</button>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </template>
        <div class="wrap_file" v-if="$roleCheck(['FILE'])&&statusCd!='ST0121'&&statusCd!='ST0120'">
          <div class="colr">
            <button class="btn b_down" v-if="$roleCheck(['FILE'])&&statusCd!='ST0121'&&statusCd!='ST0120'" @click="fnZipDownload">전체다운로드</button>
          </div>
        </div>
        <input v-if="$roleCheck(['FILE'])&&statusCd!='ST0121'&&statusCd!='ST0120'" ref="uploadFile" type="file" style="display:none;" @change="fnFileChange"/>
      </div>
    </li>
  </ul>
</template>

<script>
import consultingFilesService from "../../../../service/allOk/consultingFilesService";
export default {
  props:{
    id: {
      type: String,
      required: true,
    },
    customerId: {
      type: String,
      required: true,
    }
  },
  data(){
    return {
      docsGroup : [
        { title: '정책자금 검토 필수서류(필수)', codeGrp: 'FA0100'},
        { title: 'TCB 가평가 필요서류 (필수)', codeGrp: 'FA0200'},
        { title: '정책자금 검토 부가서류 (선택)', codeGrp: 'FA0300'}
      ],
      fileStatus: null, // [제출 완료],[임시저장]
      files:{},
    }
  },
  computed:{
    isPdfDownloadBtnAvail(){
      return this.$store.state.allOk.customersStore.companyInfo.techCreditInfo ? true : false
    },
    statusCd(){
      return this.$store.state.allOk.customersStore.statusCd
    }
  },
  methods:{
    techPdfDown(){
      const customerId = this.$store.state.allOk.customersStore.customerId;
      const customerName = this.$store.state.allOk.customersStore.customerName;
      window.open(`/apiAo/techCreditInfo?customerId=${customerId}&name=${customerName}`)
    },
    fnPdfDownload(){
      const customerId = this.$store.state.allOk.customersStore.customerId;
      const customerName = this.$store.state.allOk.customersStore.customerName;
      //window.open(`/api/allOk/report/techCreditInfo?customerId=${customerId}&name=${customerName}`)
      const url = process.env.allOkUrl
      window.open(`${url}/user/skillCredit?customerId=${customerId}&name=${customerName}`)
      // const pdfDown = window.open(`${url}/user/skillCredit?customerId=${customerId}&name=${customerName}`)
      // pdfDown.addEventListener('unload', () => {
      //   this.techPdfDown()
      // });
    },
    fnInputFiles(grpCd){
      return this.$store.state.allOk.allOkStore.codeGroup[grpCd];
    },
    fnFetchFiles(){
      const userId = this.$store.state.authUser.id;
      const cnsltId = this.id;
      consultingFilesService.fetchFiles(this.$axios, userId, cnsltId)
      .then(res => {
        if(res.data.result){
          const orgFileList = res.data.data.contents
          this.files = this._convertFiles(orgFileList);
        }
      })
    },
    _convertFiles(fileList){
      let map = {};
      if(fileList && fileList.length>0){
        fileList.forEach( file => {
          if(!map[file.fileCd]){
            map[file.fileCd] = [];
          }
          map[file.fileCd].push(file)
        })
      }
      return map;
    },
    // 22.12.23 업로드 기능 삭제
    fnOpenFileBrowser(code){
      console.log(code)
      this.uploadCode = code;
      this.$refs.uploadFile.click();
    },
    // 22.12.23 다운로드 기능  삭제
    fnFileDownload(fileId){
      if(this.$roleCheck(['ALL_OK_MNG'])){
        const userId = this.$store.state.authUser.id;
        const cnsltId = this.id;
        window.open(`/apiAo/users/${userId}/consulting/${cnsltId}/files/download/${fileId}`,'_blank');
      }
    },
    fnZipDownload(){
      if(this.$roleCheck(['ALL_OK_MNG'])) {
        const userId = this.$store.state.authUser.id;
        const cnsltId = this.id;
        window.open(`/apiAo/users/${userId}/consulting/${cnsltId}/files/download`, '_blank')
      }
    },
    // 22.12.23 삭제 기능 삭제
    fnFileDelete(fileId){
      const btn = {
        name : '삭제',
        func : ()=> {
          this._closePop();
          const userId = this.$store.state.authUser.id;
          const cnsltId = this.id;
          consultingFilesService.deleteFile(this.$axios, userId, cnsltId, fileId )
            .then(res => {
              this._alert('파일삭제', '삭제가 완료되었습니다.')
              this.fnFetchFiles()
            });
        }
      }
      this._confirm('파일삭제', '해당 파일을 삭제하시겠습니까?', btn);
    },
    // 22.12.23 파일 업로드 삭제
    fnFileChange(e) {
      console.log(e.target.files)
      const code = this.uploadCode;
      const files = e.target.files[0];
      const btn = {
        name : '업로드',
        func : ()=> {
          this._closePop()
          this.fnFileUpload(files, code)
          .then(result => {
            if(result.result){
              this._alert('파일업로드','업로드가 완료되었습니다.');
              this.fnFetchFiles()
              this.$refs.uploadFile.value=''
            } else{
              this._alert('파일업로드','업로드가 실패했습니다. 관리자에게 문의하세요');
            }
          });
        }
      }
      if(e.target.files.length>0)  
        this._confirm('파일 업로드', '파일을 업로드하시겠습니까?', btn);

    },
    fnFileUpload(files, code){
      if(files){
        const userId = this.$store.state.authUser.id;
        const cnsltId = this.id;
        const formData = new FormData();
        formData.append('customerId', this.customerId);
        formData.append('code', code)
        formData.append('upload', files);
        return consultingFilesService.postFile(this.$axios, userId, cnsltId, this.customerId, formData);
      }
      return null;
    },
    _alert(title, msg){
      let data = {
        state:true,
        type: "alert",
        title: title,
        msg: msg,
        btn:{
          name:"확인",
        }
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _confirm(title, msg, btn){
      let data  = {
        state:true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closePop(){
      this.$store.dispatch('ui/setPopAlertData', {
        state:false,
        type: null,
        title: null,
        msg: null,
        btn:{
          link:'',
          name:null,
        }
      });
    },
  },
  beforeMount() {
    this.fnFetchFiles();
  }
}
</script>
