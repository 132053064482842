import authService from '../service/authService';
import commonService from "../service/commonService";

export const state = () => ({
  authUser: null,
});

export const mutations = {
  LOGIN(state, payload) {
    state.authUser = payload;
  },
  LOGOUT(state) {
    state.authUser = null;
  },
  ROLE(state, payload) {
    state.role = payload;
  },
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  async nuxtServerInit({ dispatch, commit }, { req, $axios }) {
    // 인증
    if (req.session && req.session.authUser) {
      commit('LOGIN', req.session.authUser);
    }
    // 코드
    const data = await commonService.getCode($axios)
    if(data.data.contents)
      commit('allOk/allOkStore/UPDATE_CODE_GROUP', data.data.contents.codeMap);
  },
  async login({ commit }, body) {
    try {
      const { data } = await authService.login(body, this.$axios);
      if (data.result === true) {
        commit('LOGIN', data.data.contents);
      }
      return data;
    } catch (err) {
      return {
        result: false,
        data: {
          error: {
            cd: 'UNKNOWN_ERROR',
            msg: '알 수 없는 에러가 발생했습니다.',
          },
        },
      };
    }
  },
  async logout({ commit }) {
    const { data } = await authService.logout(this.$axios);
    if (data.result) {
      commit('LOGOUT');
    }
    return data.result;
  },
};
