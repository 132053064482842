import Vue from 'vue';
import 'tui-grid/dist/tui-grid.css';
import TuiGrid from 'tui-grid';
import { Grid } from '@toast-ui/vue-grid';

TuiGrid.applyTheme('default', {
  cell: {
    normal: {
      background: '#fff',
      border: '#e0e0e0',
      showVerticalBorder: true,
      showHorizontalBorder: true,
    },
    header: {
      background: '#D9E1F2',
    },
    selectedHeader: {
      background: '#D9E1F2',
    },
    summary: {
      showVerticalBorder: true,
    },
  },
  scrollbar: {
    emptySpace: '#fff',
    thumb: '#fff',
    active: '#fff',
  },
});

TuiGrid.setLanguage('ko', {
  display: { noData: '대상자가 없습니다.' },
});

Vue.component('grid', Grid);
