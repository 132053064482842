<template>
  <div class="wrap_file">
    <div class="w_top">
      <h5>{{ title }}</h5>
    </div>
    <div class="bx_fileview">
      <dl>
        <div class="item_file" v-for="(file, i) in prop_fileList" :key="i">
          <span @click="()=>fnFileDownload(file.fileId)">{{file.orgName}}</span>
          <button class="btn_delete"
                  v-if="$menuRoleCheck([mixMenuRoles.경정청구_제출서류_업로드_삭제,
                                        mixMenuRoles.고용지원금_제출서류_업로드_삭제,
                                        mixMenuRoles.사대보험_제출서류_업로드_삭제])"
                  @click="()=>fnFileDelete(file)">삭제</button>
        </div>
        <div v-if="!prop_fileList || prop_fileList.length===0">
          등록된 서류가 없습니다.
        </div>
        <button class="btn sm"
                style="float: right;"
                v-if="$menuRoleCheck([mixMenuRoles.경정청구_제출서류_업로드_삭제,
                                      mixMenuRoles.고용지원금_제출서류_업로드_삭제,
                                      mixMenuRoles.사대보험_제출서류_업로드_삭제])"
                @click="e=>fnOpenFileBrowser()">
          업로드
        </button>
      </dl>
    </div>
    <input ref="uploadFile" type="file" style="display:none;" @change="fnFileChange"/>
  </div>
</template>

<script>
import rolesMixin from "../../../mixins/rolesMixin";
import {ADMIN} from "~/constants/userRole";

export default {
  mixins:[rolesMixin],
  props:{
    title:{
      type:String,
      required:true,
    },
    customerId:{
      type:String,
      required:true,
    },
    path:{
      type:String,
      required:true,
    },
    fileList:{
      type:Array
    },
    fileType:{
      type:String,
      required:true,
    }
  },
  data(){
    return{
      prop_fileList:this.fileList,
      ADMIN
    }
  },
  methods:{
    fnOpenFileBrowser(){
      this.$refs.uploadFile.click();
    },
    fnFileChange(e) {
      const formData = new FormData();
      const uploadFile = e.target.files[0];
      formData.append('uploadFile', uploadFile);

      if(e.target.files.length>0){
        const payload = {
          title:'파일 업로드',
          msg:'파일을 업로드하시겠습니까?',
          btnName:'업로드',
          btnFunc: ()=> {
            this.$popupClose();
            const config = {headers: {'Content-Type' : 'multipart/form-data'}}
            this.$axios.post(`/apiMngAo/customers/service/${this.path}/${this.customerId}/files/${this.fileType}`, formData, config)
              .then(result => {
                if(result.data.result){
                  //this.$popupAlert({title:'파일업로드', msg:'업로드가 완료되었습니다.'});
                  this.$refs.uploadFile.value=''
                  this.$emit('updated');
                } else{
                  this.$popupAlert({title:'파일업로드', msg:'업로드가 실패했습니다. 관리자에게 문의하세요'});
                }
              });
          },
          closeBtnName: '닫기'
        }
        this.$popupConfirm(payload);
      }

    },
    fnFileDownload(fileId){
      if(this.$roleCheck([this.ADMIN]))
        window.open(`/apiMngAo/customers/service/${this.path}/${this.customerId}/filesDownloads/${fileId}`,'_blank');
    },
    fnFileDelete(deleteFile){
      const payload = {
        title: '파일삭제',
        msg: '해당 파일을 삭제하시겠습니까?',
        btnName: '삭제',
        btnFunc: ()=> {
          this.$popupClose();
          this.$axios.delete(`/apiMngAo/customers/service/${this.path}/${this.customerId}/files/${this.fileType}/${deleteFile.fileId}`)
            .then( result => {
              //this.$popupAlert({title: '파일삭제', msg: '삭제가 완료되었습니다.'})
              this.$emit('updated');
            });
        }
      }
      this.$popupConfirm(payload);
    },
  },
  watch:{
    fileList:{
      deep:true,
      handler(nv){
        this.prop_fileList = nv;
      }
    }
  }
}
</script>
