<template>
  <ul class="form_list type2">
    <label for="technology"><br><b>기술평가 예비등급</b></label>
    <li>
      <h4>등급 </h4>
      <div class="i_wrap">
        <Select title="등급" id="technology" :itemList="technologySelect" :value="form.technology" @change="fnTechChange"/>
      </div>
    </li>
  </ul>
</template>

<script>
import Select from '~/components/common/Select';
import consultingReportService from "../../../../service/allOk/consultingReportService";
export default {
  props:{
    id: {
      type: String,
      required: true,
    }
  },
  components: { Select },
  data(){
    return{
      form: {
        technology: '',
      },
      technologySelect:[
        {name: 'T-H', value: 'T-H'},
        {name: 'T-M', value: 'T-M'},
        {name: 'T-L', value: 'T-L'},
      ],
    }
  },
  methods: {
    _confirm(title, msg, btn){
      let data  = {
        state:true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
        closeBtn:{
          name:"취소"
        }
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    fnTechChange(item) {
      const btn = {
        name: '확인',
        func: () => {
          this.form.technology = item.value
          this.fnSave();
        }
      }
      this._confirm('확인', '저장하시겠습니까?', btn);
    },
    async fnSave() {
      const userId = this.$store.state.authUser.id;
      const cnsltId = this.id;
      const payload = {...this.form}
      const saveResult = await consultingReportService.postReport(this.$axios,userId, cnsltId, payload)
      console.log(saveResult)
      //const saveResult = await this.$store.dispatch('allOk/allOkStore/registReport', payload);
      // if(saveResult.success){
      //   this.$popupAlert({
      //     title: '저장',
      //     msg: '저장이 완료되었습니다.',
      //   });
      // }else{
      //   this.$popupAlert({
      //     title: '에러',
      //     msg: '저장에 실패했습니다.',
      //   });
      // }
    },
  },
  beforeMount() {
    const userId = this.$store.state.authUser.id;
    const cnsltId = this.id;
    consultingReportService.fetchReport(this.$axios, userId, cnsltId)
      .then(res => {
        if(res.data.result){
          if(res.data.data.contents && res.data.data.contents.technology)
            this.form.technology = res.data.data.contents.technology;
        }
      })
  }
}
</script>
