<template>
  <div>
    <grid v-if="gridProp1" :data="gridProp1.data" :columns="gridProp1.columns" :options="gridProp1.options" ref="grid1" :key="key"/>
  </div>
</template>

<script>
import educationService from '~/service/allOkMng/educationService';
import { CustomColumnHeader1 } from '~/util/TuiGridCustomClass.js';
export default {
  props:['id', 'bizNo', 'compNm'],
  name:"EducationTab1",
  data() {
    return {
      contents:[],
      gridProp1: null,
      key: +new Date(),
    };
  },
  async mounted() {
    const result = await educationService.getEducationDetail(this.$axios, this.id)
    if(result.result){
      this.contents = result.data.contents;
      if(this.contents)
        this.fnSettingGridProp(this.contents)
    }
  },
  methods:{
    fnSettingGridProp(list) {
      //gridprop1 세팅
      let data1 = []
      data1 = list.map(e => {
        return {
          c1: e.name,
          c2: e.jumin
        }
      })
      const columns = [
        {
          header: '<div style="font-weight: bold;">이름</div>',
          align: 'center',
          name: 'c1'
        },
        {
          header: '<div style="font-weight: bold;">주민등록번호</div>',
          align: 'center',
          name: 'c2'
        },
      ]
      const options1 = {
        scrollX: true,
        scrollY: true,
        minRowHeight: 20,
        rowHeight: 40,
        minBodyHeight: 0,
        header: {
          height: 80,
          columns: columns.map(c=>{
            return {
              name: c.name,
              renderer: CustomColumnHeader1
            }
          }),
        },
      };
      
      if(data1.length > 12) options1.bodyHeight = 500
      
      this.gridProp1 = {
        data: data1,
        columns: columns,
        options: options1,
      };
      
      this.key++
    },
  }
};
</script>
