<template>
  <Modal width="400px" height="710px">
    <div slot="header">
      <div v-if="type == 'input'" class="xl-flex-col xl-top title_fix">
        <div class="xl-flex-row xl-right" style="width: 100%"><div class="close close2" @click="$emit('closePop')"></div></div>
        <div class="xl-flex-row xl-left" style="width: 100%">
          <h1 class="title">컨설턴트 등록을 위해<br />아래 정보를 입력해주세요</h1>
        </div>
      </div>
      <div v-else-if="type == 'complete'" class="xl-flex-row xl-left title_complete">
        <h1 class="title2">컨설턴트 가입 완료!</h1>
      </div>
    </div>
    <div slot="body">
      <div v-if="type == 'input'" class="xl-flex-col xl-top" style="padding-top: 100px">
        <div class="xl-flex-col xl-top" style="width: 100%">
          <div class="xl-flex-row xl-left" style="width: 100%">
            <span class="label">FC 분류</span>
          </div>
          <div class="xl-flex-row xl-left" style="width: 100%">
            <div class="xl-flex-row xl-space" style="width: 70%">
              <div>
                <label for="ck1" style="font-weight: bold">개인</label>
                <input type="radio" id="ck1" class="cCheckBox" v-model="FC분류" value="개인" />
              </div>
              <div>
                <label for="ck2" style="font-weight: bold">소속</label>
                <input type="radio" id="ck2" class="cCheckBox" v-model="FC분류" value="소속" />
              </div>
            </div>
          </div>
        </div>
        <div class="xl-flex-col xl-top" style="width: 100%">
          <div class="xl-flex-row xl-left" style="width: 100%; margin-top: 25px">
            <span class="label">FC 코드(자동입력)</span>
          </div>
          <input type="text" class="cInput" :value="FC코드" disabled />
        </div>
        <div class="xl-flex-col xl-top" style="width: 100%; margin-top: 25px">
          <div class="xl-flex-row xl-left" style="width: 100%">
            <span class="label">이름</span>
          </div>
          <input type="text" class="cInput" placeholder="공백 없이 한글 입력" v-model="이름" />
        </div>
        <div class="xl-flex-col xl-top" style="width: 100%; margin-top: 25px">
          <div class="xl-flex-row xl-left" style="width: 100%">
            <span class="label">생년월일</span>
          </div>
          <input type="text" class="cInput" placeholder="생년월일 6자리 (ex.880819)" v-model="생년월일" maxlength="6" />
        </div>
        <div class="xl-flex-col xl-top" style="width: 100%; margin-top: 25px">
          <div class="xl-flex-row xl-left" style="width: 100%">
            <span class="label">전화번호</span>
          </div>
          <input type="text" class="cInput" placeholder="특수문자 제외 숫자만 입력" v-model="전화번호" />
        </div>
        <div v-if="FC분류 == '소속'" class="xl-flex-col xl-top" style="width: 100%; margin-top: 25px">
          <div class="xl-flex-row xl-left" style="width: 100%">
            <span class="label">소속</span>
          </div>
          <input type="text" class="cInput" placeholder="소속 회사, GA명" v-model="소속" />
        </div>
        <div v-if="FC분류 == '소속'" class="xl-flex-col xl-top" style="width: 100%; margin-top: 25px">
          <div class="xl-flex-row xl-left" style="width: 100%">
            <span class="label">직책</span>
          </div>
          <input type="text" class="cInput" placeholder="(ex. 본부장, 팀장, FC)" v-model="직책" />
        </div>
        <div class="xl-flex-col xl-top" style="width: 100%; margin-top: 30px">
          <div class="xl-flex-row xl-space" style="width: 100%">
            <div>
              <input type="checkbox" id="ck3" class="cCheckBox2" v-model="약관" value="약관1" />
              <label for="ck3" style="font-weight: bold">서비스 이용약관 동의 (필수)</label>
            </div>
            <span style="text-decoration: underline; cursor: pointer" @click="fnClickTerms('WebToS2')">보기</span>
          </div>
          <div class="xl-flex-row xl-space" style="width: 100%; margin-top: 5px">
            <div>
              <input type="checkbox" id="ck4" class="cCheckBox2" v-model="약관" value="약관2" />
              <label for="ck4" style="font-weight: bold">개인정보 처리방침 동의 (필수)</label>
            </div>
            <span style="text-decoration: underline; cursor: pointer" @click="fnClickTerms('WebToS3')">보기</span>
          </div>
          <div class="xl-flex-row xl-space" style="width: 100%; margin-top: 5px">
            <div>
              <input type="checkbox" id="ck5" class="cCheckBox2" v-model="약관" value="약관3" />
              <label for="ck5" style="font-weight: bold">마케팅 정보수신 동의 (선택)</label>
            </div>
            <span style="text-decoration: underline; cursor: pointer" @click="fnClickTerms('WebToS4')">보기</span>
          </div>
        </div>
      </div>
      <div v-else-if="type == 'complete'" class="xl-flex-col xl-top">
        <div class="xl-flex-row xl-left" style="width: 100%">
          <h1 class="title2">고객에게 컨설턴트 코드를 안내하고<br />손쉽게 고객관리를 시작하세요</h1>
        </div>
        <div class="my_consultant">내 컨설턴트 코드<br />{{ joincode }}</div>
        <p class="txt_gray indent">1. 고객과 기업 진단 시 컨설턴트 코드 입력란에 자신의 코드를 입력하세요.</p>
        <p class="txt_gray indent">2. 메인화면 우측 컨설턴트 전용 서비스를 통해 고객 관리를 시작하세요</p>
        <p class="txt_gray indent" style="width: 100%">3. 최초 비밀번호는 생년월일 6자리입니다.</p>
        <p class="txt_gray indent">4. 고객이 자신의 코드를 입력할 경우 관리자 사이트에서 개인 고객정보를 확인할 수 있습니다.</p>
        <p class="txt_gray indent">5. 컨설턴트 코드를 입력하지 않은 본인의 고객이 있을 경우 다된다(02-2088-1271)로 문의해주시기 바랍니다.</p>
        <p class="txt_gray mt15">* 허위 정보 입력 시 관리자에 의해 컨설턴트 아이디가 삭제될 수 있습니다.</p>
      </div>
    </div>
    <div slot="footer">
      <button v-if="type == 'input'" class="btn" :class="{ btn_dis: !isValidated }" style="width: 100%; margin-top: 15px; margin-bottom: 15px" :disabled="!isValidated" @click="fnRegist">
        가입 완료
      </button>
      <button v-if="type == 'complete'" class="btn" style="width: 100%; margin-top: 40px; margin-bottom: 15px" @click="$emit('closePop')">확인</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '~/components/common/Modal';
import * as company from '~/constants/company';
export default {
  components: {
    Modal,
  },

  computed: {
    isValidated() {
      if ([this.코드, this.이름, this.생년월일, this.전화번호].includes('')) return false;
      if (this.FC분류 == '소속' && [this.소속, this.직책].includes('')) return false;
      if (!this.약관.includes('약관1') || !this.약관.includes('약관2')) return false;
      return true;
    },
  },

  data() {
    return {
      type: 'input',
      FC분류: '개인',
      FC코드: '',
      이름: '',
      생년월일: '',
      전화번호: '',
      소속: '',
      직책: '',
      약관: [],
      joincode: ''
    };
  },

  methods: {
    fnRegist() {
      const payload = {
        username: this.FC코드,
        name: this.이름,
        tel: this.전화번호,
        birthDt: this.생년월일,
        affiliation: this.소속 || null,
        position: this.직책 || null,
        orgId: "AAAD01",//프리랜서 조직
        term01: this.약관.includes('약관1'),
        term02: this.약관.includes('약관2'),
        term03: this.약관.includes('약관3'),
      }
      this.$axios.$post('/apiAo/users', payload).then(({result, data}) => {
        if(result){
          this.joincode = data.contents
          this.type = 'complete'
        } else {
           this.$popupAlert({
            title:'회원가입 실패',
            msg: '죄송합니다. <br/>회원가입이 되지 않았습니다. <br/> 관리자에게 확인요청바랍니다.',
          });
        }
      })
    },
    fnClickTerms(term) {
      window.open(`${process.env.allOkKKUrl}/${term}`);
    },
  },

  mounted() {
    this.$axios.$get(`/apiAo/users/username/${company.프리랜서FC.prefix}`).then(({result, data})=>{
      if(result){
        this.FC코드 = data.contents
      } else {
        this.$emit('closePop')
      }
    })
  },

  watch: {
    생년월일() {
      this.생년월일 = this.생년월일.replace(/[^0-9]/g, '');
    },
    전화번호() {
      this.전화번호 = this.전화번호.replace(/[^0-9]/g, '');
    },
    FC분류() {
      this.소속 = '';
      this.직책 = '';
    },
  },
};
</script>

<style scoped>
.close {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
}
.close2:after {
  content: '\00d7';
  font-size: 25pt;
  line-height: 45px;
}

.xl-flex-col {
  display: flex;
  position: relative;
  overflow: hidden;

  flex-direction: column;
}

.xl-flex-row {
  display: flex;
  position: relative;
  overflow: hidden;

  flex-direction: row;
}

.xl-top,
.xl-left {
  justify-content: flex-start;
  align-items: center;
}

.xl-bottom,
.xl-right {
  justify-content: flex-end;
  align-items: center;
}

.xl-center {
  justify-content: center;
  align-items: center;
}

.xl-space {
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 600;
}

.title2 {
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 600;
}

.title_fix {
  padding-top: 20px;
  background-color: white;
  z-index: 999;
  position: fixed;
  width: 340px;
}

.title_complete {
  padding-top: 50px;
}

.label {
  font-weight: 600;
  color: #888;
  font-size: 0.9rem;
  line-height: 1.23rem;
}

.cInput {
  width: 100%;
  height: 40px;
  font-size: 15px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #555;
  box-sizing: border-box;
  font-family: 'Arvo';
  position: relative;
}

.cCheckBox {
  width: 15px;
  height: 15px;
  position: relative;
  top: 1.5px;
}

.cCheckBox2 {
  width: 15px;
  height: 15px;
  position: relative;
  top: 3px;
}

.btn_dis {
  border: 1px solid #ddd;
  background-color: #ddd;
  color: #b5b5b5;
}

.my_consultant {
  margin: 20px;
  padding: 10px;
  border: 1px solid black;
  background-color: #f2f2f2;
  width: 250px;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

.indent {
  margin-top: 10px;
  text-indent: -15px;
  padding-left: 15px;
}
</style>
