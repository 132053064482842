<template>
  <section class="container page_mobile">
    <div class="top">
      <div class="area">
        <InputBox
          shape='search'
          name="query.compNm"
          placeholder= '기업명으로 검색'
          :value.sync="query.compNm"
          @enter="fnCompNmSearch"
        />
        <div class="btns_filter">
          <button class="btn" :class="{ on: searchRangeType === '1m' }" @click="fnSearchRangeType('1m')">1개월</button>
          <button class="btn" :class="{ on: searchRangeType === '3m' }" @click="fnSearchRangeType('3m')">3개월</button>
          <button class="btn" :class="{ on: searchRangeType === 'etc' }" @click="fnSearchRangeType('etc')">직접설정</button>
        </div>
      </div>
      <div class="area_date" :style="{display: rangeFormDisplay }">
        <div class="a_inner">
          <div class="wrap_datepicker">
            <div class="tui-datepicker-input tui-datetime-input tui-has-focus">
              <input type="text" aria-label="Date-Time" @click="fnOpenPopup" :value="query.fromDate" />
              <span class="tui-ico-date"></span>
            </div>
          </div>
          <div class="wrap_datepicker">
            <div class="tui-datepicker-input tui-datetime-input tui-has-focus">
              <input type="text" aria-label="Date-Time" @click="fnOpenPopup" :value="query.toDate" />
              <span class="tui-ico-date"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner">
      <div class="contents">
        <div class="list_cs">
          <div class="unit" v-for="(item,key) in contents" :key="key" @click="fnOnClick({id:item.id})">
            <dl>
              <dt>기업명</dt>
              <dd><b>{{ item.comp_nm }}</b></dd>
            </dl>
            <dl>
              <dt>대표자명</dt>
              <dd>{{ item.prst_nm }}</dd>
            </dl>
            <dl class="full">
              <dt>소재지</dt>
              <dd>{{ item.kor_addr }}</dd>
            </dl>
            <dl>
              <dt>신청일</dt>
              <dd>{{ item.reg_dt }}</dd>
            </dl>
            <dl>
              <dt>진행상태</dt>
              <dd>{{ item.progress_nm }}</dd>
            </dl>
          </div>
          <button class="btn full mt15" @click="fnMoreBtn" v-if="moreBtnShow">더보기</button>
        </div>
        <div class="list_cs" v-if="totalCount === 0">
          <div class="unit">
            <p>검색결과가 없습니다.</p>
          </div>
        </div>
      </div>
    </div>
    <CustomerDetail v-if="$store.state.ui.popCsState == true" :key="$store.state.ui.popCsState" :id="id" @reload="fnReload"/>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut">
      <DatePicPopup
        v-if="datePicPopup == true"
        :fromDate="query.fromDate"
        :toDate="query.toDate"
        @close="()=>{this.datePicPopup=false;}"
        @date="e=>{
          this.query.fromDate = e.fromDate;
          this.query.toDate = e.toDate;
          this.fnFetchList(false);
        }"
      />
    </transition>
  </section>
</template>

<script>
import Header from "~/components/layouts/Header";
import Location from "~/components/layouts/Location";
import InputBox from "~/components/common/Input";
import CsPop from '~/components/popup/Cs.vue';
import CustomerDetail from '~/components/pages/allOk/CustomerDetail.vue';
import Select from "~/components/common/Select";
import DatePicPopup from "./DatePicPopup";

export default {
  name: 'MobileCustomerList',
  components: {
    Header,
    Location,
    InputBox, Select,
    CsPop,CustomerDetail,
    DatePicPopup,
  },
  data(){
    return {
      roles: ['ADMIN', 'ALL_OK_MNG', 'ALL_OK_CNSNLT'],
      id: '',
      query: {
        page:1,
        perPage:10,
        fromDate: null,
        toDate: null,
        compNm: null,
      },
      date: null,
      searchRangeType : '1m',
      rangeFormDisplay : 'none',
      contents: [],
      totalCount:0,
      moreBtnShow:false,
      datePicPopup:false,
    }
  },
  methods: {
    fnSearchRangeType(type){
      this.searchRangeType=type;
      if('etc' === type){
        this.rangeFormDisplay = 'block';
      }
      else if ('1m' === type){
        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 1)
        this.query.toDate = this._fnDateFormat(toDate);
        this.query.fromDate = this._fnDateFormat(fromDate);
        this.rangeFormDisplay = 'none';
      }
      else if ('3m' === type){
        this._fnSetDefaultDate();
        this.rangeFormDisplay = 'none';
      }
      else if ('6m' === type){
        this._fnSetDefaultDate();
        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 6)
        this.query.toDate = this._fnDateFormat(toDate);
        this.query.fromDate = this._fnDateFormat(fromDate);
        this.rangeFormDisplay = 'none';
      }
      this.query.page = 1;
      this.fnFetchList(false);
    },
    _fnSetDefaultDate(){
      const toDate = new Date();
      const fromDate = new Date();
      fromDate.setMonth(fromDate.getMonth() - 3);
      this.query.toDate = this._fnDateFormat(toDate);
      this.query.fromDate = this._fnDateFormat(fromDate);
    },
    _fnDateFormat(date){
      let year = date.getFullYear();
      let month = (date.getMonth() < 10) ? month = '0' + (date.getMonth() + 1) : month = date.getMonth() + 1;
      let day = (date.getDate() < 10) ? this.day = '0' + date.getDate() : this.day = date.getDate();
      return year + '-' + month + '-' + day;
    },
    fnFetchList(append){
      const _query = this.$generateQueryParam(this.query);
      this.$axios.get(`/api/allok/customers?${_query}`)
        .then(res => {
          if(append){
            res.data.data.contents.forEach( content => {
              this.contents.push(content);
            })
          } else {
            this.contents = res.data.data.contents;
          }
          this.totalCount = res.data.data.pagination.totalCount;
          if(this.contents.length < this.totalCount ){
            this.moreBtnShow = true
          } else {
            this.moreBtnShow = false
          }
        });
    },
    fnCompNmSearch(){
      this.query.page=1;
      this.fnFetchList(false)
    },
    fnMoreBtn(){
      this.query.page++;
      this.fnFetchList(true);
    },
    fnOnClick(e) {
      this.id = e.id;
      this.$store.commit('allOk/customersStore/UPDATE_CUSTOMER_ID', e.id);
      this.fnOpenPop2('popCsState')
    },
    fnOpenPop2(payload) {
      if (this.id) {
        this.$store.dispatch('ui/setMenuState', false);
        this.$store.dispatch('ui/setPopState', { key: payload, value: true });
      } else{
        this.$popupAlert({
          title: '경고',
          msg: '고객을 선택해 주세요.',
        });
        return;
      }
    },
    fnReload(){
      console.log('reload')
    },
    fnOpenPopup(){
      this.datePicPopup = true;
    },
  },
  mounted() {
    // DatePickerSetting

    const today = new Date();

    // Default Date Setting
    this._fnSetDefaultDate();

    // 조회
    this.fnFetchList();
  }

}
</script>
