import allOkService from '~/service/allOk/allOkService';
import organizationService from "../../service/organizationService";
import corpService from '~/service/commonTenbagger/corpService';

export const state = () => ({
  requestList: null,
  codeGroup: {},
  //codeGroup: { code: 'AA0000', text: '상담유형코드' },
  organiztions: [],

  query:{
    bizNo: null,
    pageNo: 1,
    pageSize: 10,
    searchCatgNo: 'TM0000000005',
    searchGrade: 'ALL'
  },
  fundParamData:{
    loanPrdcType:{ 보증형:'LPT01', 직접대출:'LPT02'},
    sortColumn: {
      LPT01: { 한도:'APLC_LMT', 보증료율:'GRNT_FEE_RATE',},
      LPT02: { 한도:'APLC_LMT', 금리:'LOAN_INTR_RATE'}
    }
  },
  fundParam:{
    loanPrdcType:null,
    LPT01: { sortColumn:'APLC_LMT',sortOrdr:'ASC',},
    LPT02: { sortColumn:'APLC_LMT',sortOrdr:'ASC',}
  },
  fundResult:{ LPT01:[], LPT02:[],},

  analyzeParamData:{
    rank:[
      {name:'전체', value:'ALL'},
      {name:'A등급', value:'A'},
      {name:'B등급', value:'B'},
      {name:'C등급', value:'C'},
    ]
  },
  analyzeParam:{
    pageNo: 1,
    pageSize: 10,
    searchGrade:'ALL',
  },
  analyzeResult: {
    totalCnt:0,
    patnAnlys:[],
  },
  analyzeRequest: [],

  patentParamData:{
    rank:[
      {name:'전체', value:'ALL'},
      {name:'추천', value:'R'},
      {name:'A등급', value:'A'},
      {name:'B등급', value:'B'},
      {name:'C등급', value:'C'},
    ]
  },
  patentResult:[],
  rPatn:[],
});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
  UPDATE_CODE_GROUP(state, payload){
    Object.keys(payload).forEach(key => {
      state.codeGroup[key] = payload[key];
    });
  }
};

export const actions = {
  consultingDetail(_,body){
    return allOkService.getConsultingDetail(body, this.$axios).then(data => {
      return data;
    });
  },

  fetchRequestList({ commit }) {
    return allOkService.getRequest(this.$axios).then(data => {
      commit('UPDATE_STATE', { requestList: data });
    });
  },

  fetchCorpList(_, params) {
    return corpService.getCorpList(params, this.$axios).then(data => {
      return data;
    });
  },

  fetchAddCustomer(_, params) {
    return allOkService.postCustomer(params, this.$axios).then(data => {
      return data;
    });
  },

  fetchCustomerDetail(_, params) {
    return allOkService.getCustomerDetail(params, this.$axios).then(data => {
      return data;
    });
  }, //고객 상세

  fetchConsultantList(_) {
    return allOkService.getConsultantList(this.$axios).then(data => {
      return data;
    });
  }, //컨설턴트 목록

  addContultant(_, params) {
    return allOkService.postConsultant(params, this.$axios).then(data => {
      return data;
    });
  }, //컨설턴트 할당

  fetchConsultingList(_, params) {
    return allOkService.getConsultingList(params, this.$axios).then(data => {
      return data;
    });
  }, //대출 상담 내역 조회

  addContulting(_, params) {
    return allOkService.postConsulting(params, this.$axios).then(data => {
      return data;
    });
  }, //대출 상담 등록

  fetchCodeList({ commit, state }) {
    //const code = state.codeGroup.code;
    return allOkService.getCode(this.$axios).then(data => {
      commit('UPDATE_CODE_GROUP', data.data.contents );
      return data.data.contents;
    });
  }, //코드 조회
  fetchFileCodeList({commit}){
    const payload = {
      cdGrpList: ['FA0100','FA0200','FA0300']
    }
    return allOkService.getCode(this.$axios, payload).then(data => {
      commit('UPDATE_CODE_GROUP', data.data.contents );
      return data.data.contents;
    });
  },
  registReport(_, payload) {
    return allOkService.registReport(payload, this.$axios).then(data => {
      return data
    })
  },
  getOrganiztions({ commit }) {
    return organizationService.getOrganiztions(this.$axios).then(data => {
      if(data.success){
        commit('UPDATE_STATE', {organiztions: data.data});
        return data;
      }
    })
  },
  fetchFund( _, params){
    return allOkService.fetchLoadPrdc(this.$axios, params)
    .then( result => {
      return result;
    })
  },
  fetchAnalyze(_, params) {
    return allOkService.fetchAnalyze(this.$axios, params)
      .then( result => {
        return result;
      });
  },
  fetchRecommendPatents(_, params) {
    return allOkService.fetchLoanPrdcRcmd(this.$axios, params)
      .then( result => {
        return result;
      });
  },
};
