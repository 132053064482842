<template>
  <div class="doc contract">
    <div v-if="type == 'R'" class="d_wrap">
      <div class="d_top">
        <h2 class="tit">연봉 근로계약서</h2>
        <p class="sub_tit">
          <b>{{ detailData.i1 }}</b> 와(과) 근로자 <b>{{ detailData.i2 }}</b> 는(은) 다음과 같은 근로계약을 체결하고 이를 성실히 이행할 것을 약정한다.
        </p>
        <p><b>- 다 음 -</b></p>
      </div>
      <div class="d_container">
        <div class="d_content">
          <h3>1.&nbsp;&nbsp;&nbsp; 근로계약기간</h3>
          <p><pre style="font-family: Pretendard">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ detailData.i3 }}</pre></p>
          <div class="d_unit">
            <h3>연봉계약기간</h3>
            <p><pre style="font-family: Pretendard">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ detailData.i4 }}</pre></p>
          </div>
        </div>
        <div class="d_content">
          <h3>2.&nbsp;&nbsp; 근무장소 및 업무내용</h3>
          <div class="d_unit">
            <table class="tbl_doc">
              <thead>
                <tr>
                  <th>근무장소</th>
                  <th>부서</th>
                  <th>직급</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>{{ detailData.i5 }}</span>
                  </td>
                  <td>
                    <span>{{ detailData.i6 }}</span>
                  </td>
                  <td>
                    <span>{{ detailData.i7 }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>사업주는 필요하다고 인정하는 경우에는 근로자의 의견을 들어 근무장소 또는 업무를 변경할 수 있다.</p>
          </div>
        </div>
        <div class="d_content">
          <h3>3.&nbsp;&nbsp; 근로시간(근로형태) 및 휴게시간</h3>
          <div class="d_unit">
            <p>
              가. 근로시간<br />
              <span>
                ① 기본근로시간&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
                <span>{{ detailData.i8 }}</span>
              </span>
              <br />
              <span>
                ② 기본급산정시간&nbsp;&nbsp;&nbsp;&nbsp; :
                <span>{{ detailData.i8_1 }}</span>
              </span>
            </p>
            <p style="display: block">
              나. 휴게시간&nbsp;&nbsp;&nbsp;&nbsp; :
              <span>{{ detailData.i9 }}</span>
            </p>
            <p style="display: block">
              다.
              <span>{{ detailData.i9_1 }}</span>
            </p>
            <p>
              라.
              <span>{{ detailData.i9_2 }}</span>
            </p>
          </div>
        </div>
        <div class="d_bottom" style="margin-top: 0">
          <div class="sign" style="margin-top: 0">&nbsp;<span>(인)</span></div>
        </div>
        <div class="d_content">
          <h3>4.&nbsp;&nbsp; 임금 ( 근로기준법상의 주40시간제, 월 209시간제에 의함)</h3>
          <div class="d_unit">
            <p>가. 급여내역</p>
            <div class="tbls">
              <table class="tbl_doc">
                <thead class="border_black">
                  <tr>
                    <th colspan="3">연봉계약금액 및 내역</th>
                  </tr>
                </thead>
                <colgroup>
                  <col width="150px" />
                  <col width="195px" />
                </colgroup>
                <tbody class="border_black">
                  <tr class="bg_yellow" style="font-weight: 500">
                    <td style="text-align: left">연봉계약금액</td>
                    <td>₩{{ fnFormatValue(detailData.i10) }}</td>
                    <td style="text-align: left">월급여 * 12개월분을 합산한 금액</td>
                  </tr>
                  <!-- <tr>
                    <td style="text-align: left">최저시급</td>
                    <td>₩{{ detailData.i11 }}</td>
                    <td style="text-align: left">
                      기본급, (상여금, 복리후생 등) 최저임금에 산입하는<br />
                      임금의 범위 합산액을 상기 기본급 산정시간으로 나눈 금액
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left">통상시급</td>
                    <td>₩{{ detailData.i12 }}</td>
                    <td style="text-align: left">
                      기본급, 연구개발수당, 식대 합산액을<br />
                      상기 기본급 산정시간으로 나눈 금액
                    </td>
                  </tr> -->
                  <tr>
                    <td style="text-align: left">연장근무시간</td>
                    <td>{{ fnFormatValue(detailData.i13) }}시간</td>
                    <td style="text-align: left">월간 연장근무에 대한 시간</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">휴일근무시간</td>
                    <td>{{ fnFormatValue(detailData.i14) }}시간</td>
                    <td style="text-align: left">월간 휴일근무에 대한 시간</td>
                  </tr>
                  <!-- <tr>
                    <td style="text-align: left">휴일연장근무시간</td>
                    <td>{{ detailData.i15 }}시간</td>
                    <td style="text-align: left">월간 휴일연장근무에 대한 시간</td>
                  </tr> -->
                  <tr>
                    <td style="text-align: left">당직근무시간</td>
                    <td>{{ fnFormatValue(detailData.i14_1) }}시간</td>
                    <td style="text-align: left">월간 당직근무에 대한 시간</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">야간근무시간</td>
                    <td>{{ fnFormatValue(detailData.i16) }}시간</td>
                    <td style="text-align: left">월간 야간근무에 대한 시간</td>
                  </tr>
                </tbody>
              </table>
              <!-- <div style="margin-top: 10px; page-break-before: always;"></div> -->
              <table class="tbl_doc">
                <colgroup>
                  <col width="90px" />
                  <col width="118px" />
                  <col width="118px" />
                </colgroup>
                <tbody class="border_black">
                  <tr class="border_black">
                    <td style="text-align: left">기본급</td>
                    <td colspan="2">₩{{ fnFormatValue(detailData.i17) }}</td>
                    <td style="text-align: left">기본급에는 상기 3항 가호의 기본급산정시간에 의한 금액을 지급</td>
                  </tr>
                  <tr>
                    <td rowspan="14"></td>
                    <td style="text-align: left">연장근무수당</td>
                    <td>₩{{ fnFormatValue(detailData.i18) }}</td>
                    <td style="text-align: left">연장근무시간 * 통상시급의 1.5배 지급</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">휴일근무수당</td>
                    <td>₩{{ fnFormatValue(detailData.i19) }}</td>
                    <td style="text-align: left">휴일근무시간 * 통상시급의 1.5배 지급</td>
                  </tr>
                  <!-- <tr>
                    <td style="text-align: left">휴일연장근무수당</td>
                    <td>₩{{ detailData.i20 }}</td>
                    <td style="text-align: left">휴일연장근무시간 * 통상시급의 2.0배 지급</td>
                  </tr> -->
                  <tr>
                    <td style="text-align: left">당직근무수당</td>
                    <td>₩{{ fnFormatValue(detailData.i19_1) }}</td>
                    <td style="text-align: left">당직근무시간 * 통상시간급의 1.5배 지급 (비과세항목)</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">야간근무수당</td>
                    <td>₩{{ fnFormatValue(detailData.i21) }}</td>
                    <td style="text-align: left">야간근무시간 * 통상시급의 0.5배 지급</td>
                  </tr>
                  <tr style="height: 36.5px" v-if="detailData.i16_1">
                    <td style="text-align: left">{{ detailData.i16_1 }}</td>
                    <td>₩{{ fnFormatValue(detailData.i16_2) }}</td>
                    <td style="text-align: left"></td>
                  </tr>
                  <tr style="height: 36.5px" v-if="detailData.i16_3">
                    <td style="text-align: left">{{ detailData.i16_3 }}</td>
                    <td>₩{{ fnFormatValue(detailData.i16_4) }}</td>
                    <td style="text-align: left"></td>
                  </tr>
                  <tr style="height: 36.5px" v-if="detailData.i16_5">
                    <td style="text-align: left">{{ detailData.i16_5 }}</td>
                    <td>₩{{ fnFormatValue(detailData.i16_6) }}</td>
                    <td style="text-align: left"></td>
                  </tr>
                  <tr style="height: 36.5px" v-if="detailData.i16_7">
                    <td style="text-align: left">{{ detailData.i16_7 }}</td>
                    <td>₩{{ fnFormatValue(detailData.i16_8) }}</td>
                    <td style="text-align: left"></td>
                  </tr>
                  <tr style="height: 36.5px" v-if="detailData.i16_9">
                    <td style="text-align: left">{{ detailData.i16_9 }}</td>
                    <td>₩{{ fnFormatValue(detailData.i16_10) }}</td>
                    <td style="text-align: left"></td>
                  </tr>
                  <tr>
                    <td style="text-align: left">보육수당</td>
                    <td>₩{{ fnFormatValue(detailData.i22) }}</td>
                    <td style="text-align: left">6세미만 유아 보육자에 한하여 10만원 지급 (비과세항목)</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">연구개발수당</td>
                    <td>₩{{ fnFormatValue(detailData.i23) }}</td>
                    <td style="text-align: left">연구직으로 등록된 자에 한하여 20만원 지급 (비과세항목)</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">식대</td>
                    <td>₩{{ fnFormatValue(detailData.i24) }}</td>
                    <td style="text-align: left">복리후생비로 10만원까지 지급 (비과세항목)</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">차량유지비</td>
                    <td>₩{{ fnFormatValue(detailData.i25) }}</td>
                    <td style="text-align: left">본인의 차량으로 업무용으로 사용하는 자에게 지급 (비과세항목)</td>
                  </tr>
                  <tr class="bg_yellow" style="font-weight: 500">
                    <td style="text-align: left">월급여 총계</td>
                    <td>₩{{ fnFormatNumber(fnFormatValue(detailData.i26)) }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pre style="padding-left: 0; text-indent: 0; font-family: Pretendard" v-html="fnChangeNewLine(detailData.i27)"></pre>
          </div>
        </div>
        <div class="d_content">
          <h3>5.&nbsp;&nbsp; 근로일 및 휴일</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i28)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>6.&nbsp;&nbsp; 연차유급휴가</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i29)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>7.&nbsp;&nbsp; 생리휴가</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i29_1)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>8.&nbsp;&nbsp; 근태사항</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i30)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>9.&nbsp;&nbsp; 시용 수습근로자</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0; white-space: pre-wrap" v-html="fnChangeNewLine(detailData.i31)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>10. 퇴직금</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i32)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>11.&nbsp; 퇴직절차</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i33)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>12. 비밀유지 및 계약내용 중도변경</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i34)"></p>
          </div>
        </div>
        <div class="d_bottom" style="margin-top: 0">
          <div class="sign" style="margin-top: 0">&nbsp;<span>(인)</span></div>
        </div>
        <!-- <div class="d_content">
          <h3>13. 계약갱신</h3>
          <div class="d_unit">
            <p>가. 차기 연봉계약은 인사위원회가 평정한 인사고과에 의하여 계약을 갱신한다.</p>
            <p>
              나. 근로계약 기간 만료일까지 새로운 근로계약이 체결되지 아니한 경우에는 당사자간 근로계약은 합의 연장된<br />
              것으로 본다.
            </p>
          </div>
        </div> -->
        <div class="d_content">
          <h3>13. 계약의 해지</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i35)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>14. 공제 동의</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i36)"></p>
          </div>
        </div>
        <div class="d_bottom" style="margin-top: 0; margin-bottom: 10px">
          <div class="sign" style="margin-top: 0">&nbsp;<span>(인)</span></div>
        </div>
        <div class="d_content">
          <h3>15. 이 계약서에서 정하지 아니한 사항은 근로기준법 등 노동관계법령, 취업규칙에서 정하는 바에 따른다.</h3>
        </div>
        <div class="d_bottom">
          <p>
            <span><pre style="font-family: Pretendard">{{ detailData.i37 }}년{{ detailData.i38 }}월{{ detailData.i39 }}일</pre></span>
          </p>
          <div class="d_unit">
            <dl>
              <dt>사업주</dt>
              <dt>주소</dt>
              <dd>
                <span>{{ detailData.i40 }}</span>
              </dd>
            </dl>
            <dl>
              <dt></dt>
              <dt>회사명</dt>
              <dd>
                <span>{{ detailData.i41 }}</span>
              </dd>
            </dl>
            <dl>
              <dt></dt>
              <dt>대표자</dt>
              <dd style="position: relative">
                <span>{{ detailData.i42 }}</span>
                <span style="position: absolute; left: 120px">(인)</span>
              </dd>
            </dl>
          </div>
          <div class="d_unit">
            <dl>
              <dt>근로자</dt>
              <dt>주소</dt>
              <dd></dd>
            </dl>
            <dl>
              <dt></dt>
              <dt style="width: 90px">주민등록번호</dt>
              <dd></dd>
            </dl>
            <dl>
              <dt></dt>
              <dt>성명</dt>
              <dd style="position: relative">
                <span style="position: absolute; left: 120px">(인)</span>
              </dd>
            </dl>
          </div>
          <h3>{{ detailData.i1 }}</h3>
          <div class="sign">근로기준법 제17조 2항에 따라 근로계약서를 교부 받았음을 확인합니다.<span>(인)</span></div>
        </div>
      </div>
    </div>
    <div v-if="type == 'C'" class="d_wrap">
      <div class="d_top">
        <h2 class="tit">계약직 근로계약서</h2>
        <p class="sub_tit">
          <b>{{ detailData.i1 }}</b> 와(과) 근로자 <b>{{ detailData.i2 }}</b> 는(은) 다음과 같은 근로계약을 체결하고 이를 성실히 이행할 것을 약정한다.
        </p>
        <p><b>- 다 음 -</b></p>
      </div>
      <div class="d_container">
        <div class="d_content">
          <h3>1.&nbsp;&nbsp;&nbsp; 근로계약기간</h3>
          <p><pre style="font-family: Pretendard">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ detailData.i3 }}</pre></p>
<!--          <div class="ml25" v-html="fnChangeNewLine(detailData.i4)"></div>-->
          <div class="d_unit">
            <h3 style="width: 70px">&nbsp;</h3>
            <p><pre style="font-family: Pretendard">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ detailData.i4 }}</pre></p>
          </div>
        </div>
        <div class="d_content">
          <h3>2.&nbsp;&nbsp; 근무장소 및 업무내용</h3>
          <div class="d_unit">
            <table class="tbl_doc">
              <thead>
                <tr>
                  <th>근무장소</th>
                  <th>부서</th>
                  <th>직급</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>{{ detailData.i5 }}</span>
                  </td>
                  <td>
                    <span>{{ detailData.i6 }}</span>
                  </td>
                  <td>
                    <span>{{ detailData.i7 }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>사업주는 필요하다고 인정하는 경우에는 근로자의 의견을 들어 근무장소 또는 업무를 변경할 수 있다.</p>
          </div>
        </div>
        <div class="d_content">
          <h3>3.&nbsp;&nbsp; 근로시간(근로형태) 및 휴게시간</h3>
          <div class="d_unit">
            <p>
              가. 근로시간<br />
              <span>
                ① 기본근로시간&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
                <span>{{ detailData.i8 }}</span>
              </span>
              <br />
              <span>
                ② 기본급산정시간&nbsp;&nbsp;&nbsp;&nbsp; :
                <span>{{ detailData.i8_1 }}</span>
              </span>
            </p>
            <p style="display: block">
              나. 휴게시간&nbsp;&nbsp;&nbsp;&nbsp; :
              <span>{{ detailData.i9 }}</span>
            </p>
            <p style="display: block">
              다.
              <span>{{ detailData.i9_1 }}</span>
            </p>
            <p>
              라.
              <span>{{ detailData.i9_2 }}</span>
            </p>
          </div>
        </div>
        <div class="d_bottom" style="margin-top: 0">
          <div class="sign" style="margin-top: 0">&nbsp;<span>(인)</span></div>
        </div>
        <div class="d_content">
          <h3>4.&nbsp;&nbsp; 임금</h3>
          <div class="d_unit">
            <p>가. 급여내역</p>
            <div class="tbls">
              <table class="tbl_doc">
                <thead class="border_black">
                  <tr>
                    <th colspan="3">계약금액 및 내역</th>
                  </tr>
                </thead>
                <colgroup>
                  <col width="150px" />
                  <col width="195px" />
                </colgroup>
                <tbody class="border_black">
                  <tr class="bg_yellow" style="font-weight: 500">
                    <td style="text-align: left">계약금액</td>
                    <td>₩{{ fnFormatValue(detailData.i10) }}</td>
                    <td style="text-align: left">월급여 * 12개월분을 합산한 금액</td>
                  </tr>
                  <!-- <tr>
                    <td style="text-align: left">최저시급</td>
                    <td>₩{{ detailData.i11 }}</td>
                    <td style="text-align: left">
                      기본급, (상여금, 복리후생 등) 최저임금에 산입하는<br />
                      임금의 범위 합산액을 상기 기본급 산정시간으로 나눈 금액
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left">통상시급</td>
                    <td>₩{{ detailData.i12 }}</td>
                    <td style="text-align: left">
                      기본급, 연구개발수당, 식대 합산액을<br />
                      상기 기본급 산정시간으로 나눈 금액
                    </td>
                  </tr> -->
                  <tr>
                    <td style="text-align: left">연장근무시간</td>
                    <td>{{ fnFormatValue(detailData.i13) }}시간</td>
                    <td style="text-align: left">월간 연장근무에 대한 시간</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">휴일근무시간</td>
                    <td>{{ fnFormatValue(detailData.i14) }}시간</td>
                    <td style="text-align: left">월간 휴일근무에 대한 시간</td>
                  </tr>
                  <!-- <tr>
                    <td style="text-align: left">휴일연장근무시간</td>
                    <td>{{ detailData.i15 }}시간</td>
                    <td style="text-align: left">월간 휴일연장근무에 대한 시간</td>
                  </tr> -->
                  <tr>
                    <td style="text-align: left">당직근무시간</td>
                    <td>{{ fnFormatValue(detailData.i14_1) }}시간</td>
                    <td style="text-align: left">월간 당직근무에 대한 시간</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">야간근무시간</td>
                    <td>{{ fnFormatValue(detailData.i16) }}시간</td>
                    <td style="text-align: left">월간 야간근무에 대한 시간</td>
                  </tr>
                </tbody>
              </table>
              <table class="tbl_doc">
                <colgroup>
                  <col width="90px" />
                  <col width="118px" />
                  <col width="118px" />
                </colgroup>
                <tbody class="border_black">
                  <tr class="border_black">
                    <td style="text-align: left">기본급</td>
                    <td colspan="2">₩{{ fnFormatValue(detailData.i17) }}</td>
                    <td style="text-align: left">기본급에는 상기 3항 가호의 기본급산정시간에 의한 금액을 지급</td>
                  </tr>
                  <tr>
                    <td rowspan="14"></td>
                    <td style="text-align: left">연장근무수당</td>
                    <td>₩{{ fnFormatValue(detailData.i18) }}</td>
                    <td style="text-align: left">연장근무시간 * 통상시급의 1.5배 지급</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">휴일근무수당</td>
                    <td>₩{{ fnFormatValue(detailData.i19) }}</td>
                    <td style="text-align: left">휴일근무시간 * 통상시급의 1.5배 지급</td>
                  </tr>
                  <!-- <tr>
                    <td style="text-align: left">휴일연장근무수당</td>
                    <td>₩{{ detailData.i20 }}</td>
                    <td style="text-align: left">휴일연장근무시간 * 통상시급의 2.0배 지급</td>
                  </tr> -->
                  <tr>
                    <td style="text-align: left">당직근무수당</td>
                    <td>₩{{ fnFormatValue(detailData.i19_1) }}</td>
                    <td style="text-align: left">당직근무시간 * 통상시간급의 1.5배 지급 (비과세항목)</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">야간근무수당</td>
                    <td>₩{{ fnFormatValue(detailData.i21) }}</td>
                    <td style="text-align: left">야간근무시간 * 통상시급의 0.5배 지급</td>
                  </tr>
                  <tr style="height: 36.5px" v-if="detailData.i16_1">
                    <td style="text-align: left">{{ detailData.i16_1 }}</td>
                    <td>₩{{ fnFormatValue(detailData.i16_2) }}</td>
                    <td style="text-align: left"></td>
                  </tr>
                  <tr style="height: 36.5px" v-if="detailData.i16_3">
                    <td style="text-align: left">{{ detailData.i16_3 }}</td>
                    <td>₩{{ fnFormatValue(detailData.i16_4) }}</td>
                    <td style="text-align: left"></td>
                  </tr>
                  <tr style="height: 36.5px" v-if="detailData.i16_5">
                    <td style="text-align: left">{{ detailData.i16_5 }}</td>
                    <td>₩{{ fnFormatValue(detailData.i16_6) }}</td>
                    <td style="text-align: left"></td>
                  </tr>
                  <tr style="height: 36.5px" v-if="detailData.i16_7">
                    <td style="text-align: left">{{ detailData.i16_7 }}</td>
                    <td>₩{{ fnFormatValue(detailData.i16_8) }}</td>
                    <td style="text-align: left"></td>
                  </tr>
                  <tr style="height: 36.5px" v-if="detailData.i16_9">
                    <td style="text-align: left">{{ detailData.i16_9 }}</td>
                    <td>₩{{ fnFormatValue(detailData.i16_10) }}</td>
                    <td style="text-align: left"></td>
                  </tr>
                  <tr>
                    <td style="text-align: left">보육수당</td>
                    <td>₩{{ fnFormatValue(detailData.i22) }}</td>
                    <td style="text-align: left">6세미만 유아 보육자에 한하여 10만원 지급 (비과세항목)</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">연구개발수당</td>
                    <td>₩{{ fnFormatValue(detailData.i23) }}</td>
                    <td style="text-align: left">연구직으로 등록된 자에 한하여 20만원 지급 (비과세항목)</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">식대</td>
                    <td>₩{{ fnFormatValue(detailData.i24) }}</td>
                    <td style="text-align: left">복리후생비로 10만원까지 지급 (비과세항목)</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">차량유지비</td>
                    <td>₩{{ fnFormatValue(detailData.i25) }}</td>
                    <td style="text-align: left">본인의 차량으로 업무용으로 사용하는 자에게 지급 (비과세항목)</td>
                  </tr>
                  <tr class="bg_yellow" style="font-weight: 500">
                    <td style="text-align: left">월급여 총계</td>
                    <td>₩{{ fnFormatNumber(fnFormatValue(detailData.i26)) }}</td>
                    <td>본 계약 급여의 수습급여로 최저임금의 90% 이상으로 한다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pre style="padding-left: 0; text-indent: 0; font-family: Pretendard" v-html="fnChangeNewLine(detailData.i27)"></pre>
          </div>
        </div>
        <div class="d_content">
          <h3>5.&nbsp;&nbsp; 근로일 및 휴일</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i28)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>6.&nbsp;&nbsp; 연차유급휴가</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i29)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>7.&nbsp;&nbsp; 생리휴가</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i29_1)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>8.&nbsp;&nbsp; 근태사항</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i29_2)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>9.&nbsp;&nbsp; 시용 수습근로자</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0; white-space: pre-wrap;" v-html="fnChangeNewLine(detailData.i30)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>10. 퇴직금</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i31)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>11.&nbsp; 퇴직절차</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i32)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>12. 비밀유지 및 계약내용 중도변경</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i33)"></p>
          </div>
        </div>
        <div class="d_bottom" style="margin-top: 0">
          <div class="sign" style="margin-top: 0">&nbsp;<span>(인)</span></div>
        </div>
        <div class="d_content">
          <h3>13. 계약의 해지</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i34)"></p>
          </div>
        </div>
        <div class="d_content">
          <h3>14. 공제 동의</h3>
          <div class="d_unit">
            <p style="padding-left: 0; text-indent: 0" v-html="fnChangeNewLine(detailData.i35)"></p>
          </div>
        </div>
        <div class="d_bottom" style="margin-top: 0; margin-bottom: 10px">
          <div class="sign" style="margin-top: 0">&nbsp;<span>(인)</span></div>
        </div>
        <div class="d_content">
          <h3>15. 이 계약서에서 정하지 아니한 사항은 근로기준법 등 노동관계법령, 취업규칙에서 정하는 바에 따른다.</h3>
        </div>
        <div class="d_bottom">
          <p>
            <span><pre style="font-family: Pretendard">{{ detailData.i36 }}년{{ detailData.i37 }}월{{ detailData.i38 }}일</pre></span>
          </p>
          <div class="d_unit">
            <dl>
              <dt>사업주</dt>
              <dt>주소</dt>
              <dd>
                <span>{{ detailData.i39 }}</span>
              </dd>
            </dl>
            <dl>
              <dt></dt>
              <dt>회사명</dt>
              <dd>
                <span>{{ detailData.i40 }}</span>
              </dd>
            </dl>
            <dl>
              <dt></dt>
              <dt>대표자</dt>
              <dd style="position: relative">
                <span>{{ detailData.i41 }}</span>
                <span style="position: absolute; left: 120px">(인)</span>
              </dd>
            </dl>
          </div>
          <div class="d_unit">
            <dl>
              <dt>근로자</dt>
              <dt>주소</dt>
              <dd></dd>
            </dl>
            <dl>
              <dt></dt>
              <dt style="width: 90px">주민등록번호</dt>
              <dd></dd>
            </dl>
            <dl>
              <dt></dt>
              <dt>성명</dt>
              <dd style="position: relative">
                <span style="position: absolute; left: 120px">(인)</span>
              </dd>
            </dl>
          </div>
          <h3>{{ detailData.i1 }}</h3>
          <div class="sign">근로기준법 제17조 2항에 따라 근로계약서를 교부 받았음을 확인합니다.<span>(인)</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  layout: 'blank',
  data() {
    return {
      detailData: {},
      type: 'R',
    };
  },

  methods: {
    fnChangeNewLine(str) {
      return str && str.replace(/\n/g, '<br/>');
    },
    fnFormatValue(value) {
      return value === null || value === '-' ? '0' : value;
    },
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },

  fetch() {
    const { customerId, contractId } = this.$route.query;
    return axios
      .get(`${process.env.api_all_ok_mng_url}/v2/customers/service/labor/${customerId}/contract/${contractId}`)
      .then(({ data }) => {
        if (data.data) {
          this.type = data.data.contractType;
          this.detailData = JSON.parse(data.data.detailData);
        }
      })
      .catch(err => {
        console.error(err.response.data);
      });
  },
};
</script>

<style scoped>
.contract .d_content .d_unit p {
  padding-left: 0;
  text-indent: 0;
}
</style>
