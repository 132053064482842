<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>4대보험절감 상세 : {{compNm}}</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button :class="{ tab: true, on: on == 'tab3' }"
                      @click="() => (this.on = 'tab3')">고객상세</button>
              <button :class="{ tab: true, on: on == 'tab2' }"
                      v-if="$menuRoleCheck([mixMenuRoles.사대보험_고용현황_및_진단과정_조회])"
                      @click="() => (this.on = 'tab2')">고용현황 및 진단과정</button>
              <button :class="{ tab: true, on: on == 'tab1' }"
                      v-if="$menuRoleCheck([mixMenuRoles.사대보험_진단결과_조회])"
                      @click="() => (this.on = 'tab1')">진단결과</button>
              <button :class="{ tab: true, on: on == 'tab4' }"
                      v-if="$menuRoleCheck([mixMenuRoles.사대보험_제출서류_조회])"
                      @click="() => (this.on = 'tab4')">제출서류 확인</button>
            </div>
            <div class="btns" v-if="on=='tab2'">
              <button class="btn" @click="fnExcelDownload">엑셀 다운</button>
            </div>
            <div class="btns">
              <button class="btn dark" @click="fnClosePop">닫기</button>
            </div>
          </div>
          <div class="c_contents" v-if="on == 'tab3'" >
            <BizInfoTab :id="id" :bizNo="bizNo" ref="save3" />
          </div>
          <div class="c_contents" v-if="on == 'tab1'">
            <InsuranceTab1 :estimate="contents.estimate" :id="id" :bizNo="bizNo" :compNm="compNm" ref="save1" />
          </div>
          <div class="c_contents" v-if="on == 'tab2'" >
            <InsuranceTab2 :employList="contents.employList" :id="id" ref="save2" />
          </div>
          <CommonSubmit v-if="on=='tab4'"
                        :customer-id="compObj.id"
                        :env="submitTab"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InsuranceTab1 from './InsuranceTab1.vue';
import InsuranceTab2 from './InsuranceTab2.vue';
import InsuranceTab4 from './InsuranceTab4.vue';
import InsuranceTab5 from './InsuranceTab5.vue';
import BizInfoTab from "./BizInfoTab";
import CommonSubmit from "./CommonSubmit";
import rolesMixin from "../../../mixins/rolesMixin";
export default {
  mixins:[rolesMixin],
  name: 'CustomerDetail',
  props: ['id', 'bizNo', 'compNm', 'consultantGaNm', 'consultantNm', 'compObj'],
  components: {
    InsuranceTab1,
    InsuranceTab2,
    InsuranceTab4,
    InsuranceTab5,
    BizInfoTab,
    CommonSubmit
  },
  data() {
    return {
      on: 'tab3',
      contents: {},
      submitTab:{
        path:'insurance',
        fileTypes:[
          {
            fileType:'salaryList',
            title:'급여대장',
          },
          {
            fileType:'contractList',
            title:'근로계약서',
          }
        ],
        fileMap:{
          salaryList:null,
          contractList:null,
        }
      }
    };
  },
  computed:{
    managerTab(){
      return{
        salesCompanyType:[this.mixCompanyTypes.ADMIN, this.mixCompanyTypes.GA],
        managerCompanyType:[this.mixCompanyTypes.LABOR],
        manageType:this.mixManageTypes.LABOR
      }
    }
  },
  methods: {
    fnClosePop() {
      this.$store.dispatch('ui/setPopCsState', false);
      this.$emit('reload');
    },
    fnExcelDownload(){
      window.open(`/apiMngAo/customer/service/insurance/${this.id}/excel?name=${this.compNm}`)
    }
  },
};
</script>
