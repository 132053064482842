import * as companyTypes from '../constants/companyType';
export default (store, allowedMenuIds) => {
  if(store.state.authUser){
    if( companyTypes.ADMIN == store.state.authUser.gaType ) {
      return true;
    }
    if( store.state.authUser.menuIds ) {
      return store.state.authUser.menuIds.find( menuId => {
              return allowedMenuIds.includes(menuId);
             })
             ? true
             : false;
    }
  }
  return false;
}

