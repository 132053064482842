<template>
  <div>
    <grid v-if="data.length>0" class="pb20" :data="data" :columns="columns" :options="options" ref="grid" :key="key" />
  </div>
</template>
<script>
import { CustomColumnHeader1 } from '~/util/TuiGridCustomClass.js';
export default {
  props: ['originData'],
  data() {
    return {
      complexColumnsData: [
        { label: `1. 과세연도별 환급예상 공제세액 간편계산결과`, name: 'A_F', childNames: ['A','B','C','D','E','F'],},
        { label: `3. 홈택스 기 신고내역`, name: 'G_J', childNames: ['G','H','I','J'],},
      ],
      columnsData: [
        { label: '과세년도',name: 'A', width: 100, },
        { label: '세액공제항목',name: 'B', width: 200, },
        { label: '① 공제세액',name: 'C', width: 200, },
        { label: '② 환급가능한도(최저한세 기준)', name: 'D', width: 200, },
        { label: '③ 환급예상공제세액 (=min(②, ①+전년도 ④))',name: 'E', width: 300, },
        { label: '④ 이월액',name: 'F', width: 200, },
        { label: '⑱ 신고된 대상세액',name: 'G', width: 200, },
        { label: '⑲ 체납(미납)된 세액',name: 'H', width: 200, },
        { label: '⑳ 경정 신청한 세액',name: 'I', width: 200, },
        { label: '㉑ 환급 받은 세액',name: 'J', width: 200, },
      ],
      key: Math.random()
    }
  },
  computed: {
    data() {
      let _data = [];
      let _taxData = ( this.originData && this.originData.Tax ) ? this.originData.Tax : null;
      if ( _taxData ) {;
        //
        let _taxDataMeta = [];
        let _yearIdx = 0;
        for ( let _taxDataIdx=2; _taxDataIdx<_taxData.length; _taxDataIdx++ ) {
          if( !_taxData[_taxDataIdx].A && !_taxData[_taxDataIdx].B ) {
            // // 2. 환급예상 공제세액 조정명세표 윗줄 빈공백
            break;
          }
          else if ( _taxData[_taxDataIdx].A && !isNaN(parseInt(_taxData[_taxDataIdx].A)) ) {
            _taxDataMeta.push({ idx: _taxDataIdx, type: 'year', rowSpanSize: 1, });
            _yearIdx = _taxDataIdx-2;
          }
          else if ( _taxData[_taxDataIdx].A && _taxData[_taxDataIdx].A == '소계' ) {
            _taxDataMeta.push({ idx: _taxDataIdx, type: 'summary', });
          }
          else {
            _taxDataMeta[_yearIdx].rowSpanSize++;
            _taxDataMeta.push({ idx: _taxDataIdx, type: 'data', });
          }
        }
        //
        _taxDataMeta.forEach( _meta => {
          if ( _meta.type == 'year' ) {
            _data.push({
              A: _taxData[_meta.idx].A,
              B: _taxData[_meta.idx].B,
              C: this.fnFormatNumber(_taxData[_meta.idx].C),
              D: this.fnFormatNumber(_taxData[_meta.idx].D),
              E: this.fnFormatNumber(_taxData[_meta.idx].E),
              F: this.fnFormatNumber(_taxData[_meta.idx].F),
              G: this.fnFormatNumber(_taxData[_meta.idx].G),
              H: this.fnFormatNumber(_taxData[_meta.idx].H),
              I: this.fnFormatNumber(_taxData[_meta.idx].I),
              J: this.fnFormatNumber(_taxData[_meta.idx].J),
              _attributes: {
                rowSpan: {
                  A: _meta.rowSpanSize,
                  D: _meta.rowSpanSize,
                  H: _meta.rowSpanSize,
                  I: _meta.rowSpanSize,
                  J: _meta.rowSpanSize,
                },
              }
            });
          }
          else if ( _meta.type == 'data' ) {
            _data.push({
              B: _taxData[_meta.idx].B,
              C: this.fnFormatNumber(_taxData[_meta.idx].C),
              E: this.fnFormatNumber(_taxData[_meta.idx].E),
              F: this.fnFormatNumber(_taxData[_meta.idx].F),
              G: this.fnFormatNumber(_taxData[_meta.idx].G),
            })
          }
          else if ( _meta.type =='summary' ) {
            _data.push({
              A: _taxData[_meta.idx].A,
              C: this.fnFormatNumber(_taxData[_meta.idx].C),
              D: this.fnFormatNumber(_taxData[_meta.idx].D),
              E: this.fnFormatNumber(_taxData[_meta.idx].E),
              F: this.fnFormatNumber(_taxData[_meta.idx].F),
              G: this.fnFormatNumber(_taxData[_meta.idx].G),
              H: this.fnFormatNumber(_taxData[_meta.idx].H),
              I: this.fnFormatNumber(_taxData[_meta.idx].I),
              J: this.fnFormatNumber(_taxData[_meta.idx].J),
              _attributes: {
                colSpan: {
                  A: 2,
                },
                className: {
                  row: ['color7'],
                },
              }
            })
          }
        })
      }
      return _data;
    },
    columns() {
      return this.columnsData.map( el => {
        return {
          header: `<div style="font-weight: bold; font-size:12px">${el.label}</div>`,
          name: el.name,
          width: el.width,
          align: 'center',
        }
      });
    },
    options() {
      return {
        width:1600,
        scrollX: true,
        scrollY: false,
        header: {
          height: 100,
          complexColumns: this.complexColumnsData.map( el => ({
            header: `<div style="font-weight: bold; font-size: 15px;">${el.label}</div>`,
            name: el.name,
            childNames: el.childNames,
            renderer: CustomColumnHeader1,
          })),
          columns: this.columns.map( el => ({
            name: el.name,
            renderer: CustomColumnHeader1,
          })),
        },
        // summary: {
        //   height: 100,
        //   position: 'bottom', // or 'top'
        // },
      }
    }
  },
  methods: {
    fnFormatNumber(num) {
      if ( num ) {
        return isNaN(num) ? num : parseInt(num).toLocaleString();
      }
      return '';
      // return num && parseInt(num).toLocaleString()
    }
  }
}
</script>

<style>
.tui-grid-cell.color6 {
  background-color: #ecf0ff
}
.tui-grid-cell.color7 {
  background-color: #F7F8FA
}
</style>
