import { render, staticRenderFns } from "./lastCover.vue?vue&type=template&id=08f74b16&scoped=true&"
var script = {}
import style0 from "./lastCover.vue?vue&type=style&index=0&id=08f74b16&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f74b16",
  null
  
)

export default component.exports