import validator from './validationPlugin';
import popup from './popupPlugin';
import roleCheck from './roleCheckPlugins';
import menuRoleCheck from './menuRoleCheckPlugins';
import codeMapperPlugin from './codeMapperPlugin';
import {generateQueryParameter} from '../util/HttpUtils';

export default (context, inject) => {
  const { router, store } = context;

  inject('isLogin', ()=> {
    const authInfo = store.state.authUser;
    return (authInfo) ? true : false;
  });

  inject('roleCheck', (alowedRoles, ignoreCompanyType) => {
    if(process.client) {
      if ( ignoreCompanyType == undefined ) {
        ignoreCompanyType = false;
      }
      return roleCheck(store, alowedRoles, ignoreCompanyType);
    }
    return false;
  });

  inject('menuRoleCheck', (menuIds) => {
    if(process.client)
      return menuRoleCheck(store, menuIds);
    return false;
  });

  inject('menuRoleAndUserRoleCheck', (menuIds) => {
    if(process.client)
      return menuRoleCheck(store, menuIds);
    return false;
  });

  inject('validate', validator);

  inject('popupAlert', data => {
    popup.alert(store, data);
  });
  inject('isCorporation', (bizNo) => {
    const midNo = parseInt(bizNo.slice(3,5));
    return midNo>=80 && midNo<=88;
  });
  inject('popupConfirm', data => {
    popup.confirm(store, data);
  });

  inject('dataPattern', (type, value) => {
    if(!value){
      return ''
    }
    if('bizNo' === type){
      return value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, "$1-$2-$3");
        //.replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
    }
  });

  inject('popupClose', ()=>{
    store.dispatch('ui/setPopAlertData', {
      state:false,
      type: null,
      title: null,
      msg: null,
      btn:{
        link:'',
        name:null,
      }
    });
  });

  inject('generateQueryParam', params => {
    return generateQueryParameter(params);
  });

  inject('fill0', number => {
    return number >= 10 ? number : '0'+number;
  });

  inject('allOkCodeMapper', (codeGroup, code) => {
    const codeStore = store.state.allOk.allOkStore.codeGroup;
    return codeMapperPlugin.allOkCodeMapper(codeStore, codeGroup, code);
  });

  inject('dpPatnPrice', (technicalAsset) => {
    if(technicalAsset){
      if(technicalAsset === 0)
        return '0원';
      const flooredAsset = Math.floor(technicalAsset/100000000);
      if(flooredAsset>0){
        return `${flooredAsset.toLocaleString()}억원 대`
      }else{
        return '1억원 미만'
      }
    }
    return '-';
  });

};
