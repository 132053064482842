<template>
  <div class="cover">
    <div class="cTit">
      <div class="unicornLogo">unicornranch</div>
      <div class="reportTit">비상장주식가치평가</div>
    </div>
    <div class="contents">
      <div class="Flist" style="display: flex;">2.<span class="Ftit">비상장기업 가치평가방법<br/><span class="titInfo">대주주들은 상장에 거부감을 느끼는 경우가 많다. 온전한 자기 기업에서 방법</span></span></div>
      <div class="compNm">{{입력개요?.회사명}}</div>
      <div>
        <div class="tit1">※ 비상장주식의 평가방법(상속세 및 증여세법 시행령 제 54조)</div>
        <div class="table1">
          <div class="tContents">
            <span class="fCont">구분</span>
            <span class="sCont">가치평가방법</span>
          </div>
          <div class="tContents divide">
            <span class="fCont">일반법인</span>
            <span class="sCont">순손익가치 X 60% + 순자산가치 X 40%</span>
          </div>
          <div class="tContents divide borderBottom">
            <span class="fCont">부동산과다보유법인</span>
            <span class="sCont">순손익가치 X 40% + 순자산가치 X 60%</span>
          </div>
        </div>
        <div class="tit2">※ 비상장주식의 평가방법(상속세 및 증여세법 시행령 제 54조)</div>
        <div class="table2">
          <div class="flex-container">
            <div class="label" style="margin-left:190px; color: #B50A47;">손수익가치</div>
            <div class="label">=</div>
            <div class="formula">
              <div>( D-1년 순손익액 X <span style="color: #B50A45;">3</span> ) + ( D-1년 순손익액 X <span style="color: #B40A46;">2</span> ) + ( D-1년 순손익액 X <span style="color: #B50A47;">1</span> )</div>
              <hr class="horizontal-line">
              <div>6</div>
            </div>
            <div class="label">≒</div>
            <div class="label" style="margin-right:190px;">순손익가치율 (10%)</div>
          </div>
        </div>
        <div class="tit3">※ 순자산가치의 계산(상속세 및 증여세법 시행령 제 55조)</div>
        <div class="table3">
          <span style="color: #B50A47; margin-right: 10px;">순자산가치</span> <span style="margin-right: 10px;">=</span> <span>순자산( 자산 - 부채 ) + 영업권( 3년간 순속인가중평균 X 50% ) - ( 자기자본 X 1년만기정기이자율 )</span>
        </div>
        <div class="tit4">※ 최대주주 할증과세(상속세 및 증여세법 시행령 제 63조)</div>
        <div class="table4">
          <div class="tContents">
            <div class="t4content1">구분</div>
            <div class="t4content2">특수관계자 주식비율 50%초과</div>
            <div class="t4content3">특수관계자 주식비율 50% 이하</div>
          </div>
          <div class="tContents divide2">
            <div class="t4content1">중소기업</div>
            <div class="t4content2">배제</div>
            <div class="t4content3">배제</div>
          </div>
          <div class="tContents divide2 borderBottom">
            <div class="t4content1">일반기업</div>
            <div class="t4content2">평가액 X <span style="color: #B50A47;">30</span> / 100</div>
            <div class="t4content3">평가액 X <span style="color: #B50A47;">20</span> / 100</div>
          </div>
        </div>
      </div>
      <div class="addEx">※ 추가 설명</div>
      <div class="ex"><span class="exText">투자한 주주들에게 나가야 할 배당금 의무도 무시할 수 없다. 사실 어떤 기업이든 자기 돈만 충분하다면 굳이 엄격한 심사와 자격까지 갖추면서 상장할 이유는 없다.[2] 실제로 2015년 기준 전경련에서 조사한 자료에서는 총 600여개 기업이 코스피 상장 요건을 갖추고 있었지만 단 7개 기업만이 상장을 했다. 그리고 다른 회사에게 자신의 회사를 팔 때도 매우 쉬운데. 상장회사들을 인수 할 때 과반의 주식을 확보하거나 합병까지 하려면 전체 주식을 사서 소각해야 하는데, 이 과정도 매우 복잡해서 전체 주주들에게 동의를 받아야 하고 주식시장을 관리감독하는 기관의 심사까지 받아야 해서 규모가 큰 회사들 끼리 합병시 년단위로 걸린다. 비상장은 경영권을 가진 임원들에게 만족하는 가격만 제시한다면 매우 수월하게 인수가 가능하다.</span></div>
    </div>
    <div class="footer">
      02
    </div>
  </div>
</template>
<script>
export default {
  props:['입력개요']
}
</script>
<style scoped>
  @import '~/static/css/report.css';
  .footer{
    width: 100px;
    right: 30px;
    position: absolute;
    border: solid 1px #001736;
    background-color: #001736;
    height: 60px;
    bottom: 0px;
    color: #1FBDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .Ftit{
    display: flex;
    flex-direction: column;
  }
  .titInfo{
    font-size: 10px;
    font-family: 'Pretendard-Light';
  }
  .compNm{
    color: #1FBDC6;
    font-size: 24px;
    font-family: 'Pretendard-SemiBold';
    position: absolute;
    top: 36px;
    right: 30px;
  }
  .tit1{
    color: #33455E;
    position: absolute;
    top: 130px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
  }
  .table1{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 171px;
    left: 30px;
  }
  .tContents{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid #001736 1px;
    width: 1160px;
    height: 40px;
    font-family: 'Pretendard-Bold';
    font-size: 13px;
  }
  .divide{
    background: linear-gradient(to right, transparent 50%, #DBDCDE 50%);
  }
  .borderBottom{
    border-bottom: solid #001736 1px;
  }
  .fCont{
    margin-left: 30px;
  }
  .sCont{
    margin-right: 30px;
  }
  .tit2{
    color: #33455E;
    position: absolute;
    top: 352px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
  }
  .table2{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 393px;
    left: 30px;
    width: 1160px;
    height: 120px;
    font-size: 16px;
    border-top: solid #000 1px;
    border-bottom: solid #000 1px;
  }
  .flex-container {
    width: 1160px;
    height: 120px;
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    justify-content: center;
  }
  .label {
    margin-right:10px; /* 텍스트 간격 조절 */
  }
  .horizontal-line {
    border: none;
    border-top: 1px solid #000; /* 가로 선의 두께와 색상 설정 */
    width: 482px;
    margin-right: 10px;
  }
  .formula{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .tit3{
    color: #33455E;
    position: absolute;
    top: 574px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
  }
  .table3{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 615px;
    left: 30px;
    width: 1160px;
    height: 120px;
    font-size: 16px;
    border-top: solid #000 1px;
    border-bottom: solid #000 1px;
  }
  .tit4{
    color: #33455E;
    position: absolute;
    top: 796px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
  }
  .table4{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 837px;
    left: 30px;
  }
  .t4content1{
    width: 360px;
    margin-left: 30px;
  }
  .t4content2{
    margin-left: 30px;
    width: 400px;
    text-align: right;
    margin-right: 30px;
  }
  .t4content3{
    width: 400px;
    text-align: right;
    margin-right: 30px;
  }
  .divide2{
    background: linear-gradient(to right, transparent 360px, #DBDCDE 360px);
  }
  .addEx{
    font-size: 18px;
    color: #33455E;
    position: absolute;
    left: 30px;
    bottom: 195px;
  }
  .ex{
    position: absolute;
    width: 1160.5px;
    height: 75px;
    border-top: solid 1px #001736;
    left: 30px;
    bottom: 100px;
  }
  .exText{
    display: block;
    margin-top: 15px;
    font-size: 13px;
    color: #33455E;
    font-family: 'Pretendard-Regular';
  }
</style>