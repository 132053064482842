<template>
  <div>
    <!--
    <client-only>
      <MobileCustomerList v-if="isMobile" key="MobileCustomerList" />
      <PcCustomerList v-else key="PcCustomerList" />
    </client-only>
    -->
    <PcCustomerList key="PcCustomerList" />
  </div>
</template>

<script>
import MobileCustomerList from '../../components/pages/allOk/MobileCustomerList.vue';
import PcCustomerList from "../../components/pages/allOk/PcCustomerList";
import {기업대출_매니저, 기업대출_컨설턴트} from '../../constants/userRole';

export default {
  components: {
    MobileCustomerList,
    PcCustomerList,
  },
  data() {
    return {
      roles: [기업대출_매니저, 기업대출_컨설턴트],
    };
  },
  computed: {
    isMobile(){
      const device = this.$store.state.ui.device;
      if(device){
        return device === 'mobile'
      }
      return true;
    }
  },
};
</script>
