<template>
  <div class="cover">
    <div class="cTit">
      <div class="unicornLogo">unicornranch</div>
      <div class="reportTit">비상장주식가치평가</div>
    </div>
    <div class="contents">
      <div class="Flist" style="display: flex;">▣<span class="Ftit">가업승계주식에 대한 증여세 과세특례<br/><span class="titInfo">순자산가치 3과 순손익가치 2의 가중치를 적용하여 비상장주식의 가치를 산정하는 금액</span></span></div>
      <div class="compNm">{{입력개요?.회사명}}</div>
      <div>
        <div class="tit1">1) 가업승계주식에 대한 증여세 과세특례 목록</div>
        <div style="position: absolute; top: 151px; right: 30px; height: 15px; color: #33455E; font-size: 13px; font-family: 'Pretendard-SemiBold';">(단위 : 원)</div>
        <table class="table1">
          <thead style="height: 70px;">
            <tr style="height: 30px;">
              <th rowspan="2" style="width: 100px; background-color: #DBDCDE; text-align: center;">지분율</th>
              <th rowspan="2" style="width: 100px; background-color: #DBDCDE; text-align: center;">주식수</th>
              <th rowspan="2" style="width: 100px; background-color: #DBDCDE; text-align: center;">1주당가치</th>
              <th rowspan="2" style="width: 172px; background-color: #DBDCDE; text-align: center;">지분평가금액</th>
              <th colspan="2" style="border-left: 1px solid #001736; color: #B50D47; text-align: center; border-right: 1px solid #001736; width: 336px;">증여특례</th>
              <th class="leRiBorder2" style="border-top: solid 3px #21BDC6; width: 170px; text-align: center;">일반</th>
              <th rowspan="2" style="width: 182px; background-color: #DBDCDE; text-align: right; padding-right: 30px; color: #B50D47;">절세액</th>
            </tr>
            <tr style="height: 40px;">
              <th style="border-left: 1px solid #001736; background-color: #DBDCDE; text-align: right; padding-right: 30px;">증여세과세가액</th>
              <th style="border-right: 1px solid #001736; background-color: #DBDCDE; text-align: right; padding-right: 30px;">증여세</th>
              <th class="leRiBorder2" style="background-color: #DBDCDE; text-align: right; padding-right: 30px;">증여세</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(지분율, i) in 증여세과세특례.지분율" :key="i">
              <td style="height: 40px; text-align: center; font-family: 'Pretendard-SemiBold'; color: #B50D47;">{{ fnPercent(지분율.지분율) }}%</td>
              <td style="height: 40px; text-align: center; font-family: 'Pretendard-Regular';">{{ fnFormatNumber((지분율.주식수).toFixed(2)) }}</td>
              <td v-if="i === 0" :rowspan="증여세과세특례.지분율.length" class="center" style="border-left: 1px solid #CCD1D7; font-family: 'Pretendard-SemiBold'; color: #B50D47; border-right: 1px solid #CCD1D7; height: 40px;">{{ fnFormatNumber((증여세과세특례.주당가치).toFixed(2)) }}</td>
              <td style="height: 40px; text-align: center; font-family: 'Pretendard-Regular';">{{ fnFormatNumber((지분율.지분평가금액).toFixed(2)) }}</td>
              <td style="border-left: 1px solid #001736; font-family: 'Pretendard-Regular'; height: 40px; text-align: right; padding-right: 30px;">{{ fnFormatNumber((지분율.증여특례_증여세과세가액).toFixed(2)) }}</td>
              <td style="border-right: 1px solid #001736; font-family: 'Pretendard-Regular'; height: 40px; text-align: right; padding-right: 30px;">{{ fnFormatNumber((지분율.증여특례_증여세).toFixed(2)) }}</td>
              <td :class="[i === 증여세과세특례?.지분율.length - 1 ? 'bottomBorder2' : '', 'leRiBorder2']" style="height: 40px; font-family: 'Pretendard-SemiBold'; text-align: right; padding-right: 30px;">{{ fnFormatNumber((지분율.일반_증여세).toFixed(2)) }}</td>
              <td style="height: 40px; text-align: right; font-family: 'Pretendard-SemiBold'; padding-right: 30px; color: #B50D47;">{{ fnFormatNumber((지분율.절세액).toFixed(2)) }}</td>
            </tr>
          </tbody>
        </table>
        <div class="tit2">2) 가업승계주식에 대한 증여세 과세특례 차트</div>
        <div class="chart">
          <div id="증여세과세특례" style="width:100%; height:100%;"></div>
        </div>
      </div>
      <div class="footer">
        <div class="addEx">※ 추가 설명</div>
        <div class="ex"><span class="exText">투자한 주주들에게 나가야 할 배당금 의무도 무시할 수 없다. 사실 어떤 기업이든 자기 돈만 충분하다면 굳이 엄격한 심사와 자격까지 갖추면서 상장할 이유는 없다.[2] 실제로 2015년 기준 전경련에서 조사한 자료에서는 총 600여개 기업이 코스피 상장 요건을 갖추고 있었지만 단 7개 기업만이 상장을 했다. 그리고 다른 회사에게 자신의 회사를 팔 때도 매우 쉬운데. 상장회사들을 인수 할 때 과반의 주식을 확보하거나 합병까지 하려면 전체 주식을 사서 소각해야 하는데, 이 과정도 매우 복잡해서 전체 주주들에게 동의를 받아야 하고 주식시장을 관리감독하는 기관의 심사까지 받아야 해서 규모가 큰 회사들 끼리 합병시 년단위로 걸린다. 비상장은 경영권을 가진 임원들에게 만족하는 가격만 제시한다면 매우 수월하게 인수가 가능하다.</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['입력개요', '증여세과세특례'],
  methods: {
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fnPercent(num){
      return num*100;
    },
    fnDrawChart(){
      const Chart = toastui.Chart;
      Chart.columnChart({
        el: document.getElementById('증여세과세특례'),
        data: {
          categories: this.증여세과세특례.지분율.map(el => (el.지분율 * 100).toFixed(1) + '%'),
          series: [
            {
              name: `증여세과세가액`,
              data: this.증여세과세특례.지분율.map(el => el.증여특례_증여세과세가액),
            },
            {
              name: `증여세`,
              data: this.증여세과세특례.지분율.map(el => el.증여특례_증여세),
            },
          ],
        },
        options: {
          plot:{
            visible: false,
          },
          chart: {
            title: '',
            width: 'auto',
          },
          yAxis: {
            label: {
              formatter: value => (value == 0 ? '-' : this.fnFormatNumber(value) + '원'),
            },
          },
          theme: {
            chart:{
              backgroundColor:'#EEEEEE',
            },
            series: {
              barWidth: 80,
              colors: ['#2D415A', '#21BDC6'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            yAxis: {
              color: '#EEEEEE',
              label: {
                color: '#EEEEEE',
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'top',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(0)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(0)),
          },
        },
      });
    }
  },
  mounted () {
    this.fnDrawChart();
  },
}
</script>

<style scoped>
  @import '~/static/css/report.css';
  .table1{
    margin-top: 20px;
  }
  .bottomBorder2{
    border-bottom: solid 3px #21BDC6;
  }
  .leRiBorder2{
    border-right: solid 3px #21BDC6;
    border-left: solid 3px #21BDC6;
  }
  .tit1{
    margin-top: -40px;
    color: #33455E;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
  }
  .tit2{
    margin-top: 61px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .chart{
    margin-top: 20px;
    width: 1160px;
    height: 800px;
    border-top: solid 1px #001736;
    border-bottom: solid 1px #001736;
  }
  table {
    font-family: 'Pretendard-Bold';
    border-collapse: collapse;
    font-size: 13px;
    width: 1160px;
    height: 100%;
  }

  table, th, td {
    border: 1px solid #001736;
    border-right: none;
    border-left: none;
  }

  th, td {
    padding: 10px;
  }

  .center {
    text-align: center;
    vertical-align: middle;
  }
  .Ftit{
    display: flex;
    flex-direction: column;
  }
  .titInfo{
    font-size: 10px;
    font-family: 'Pretendard-Light';
  }
  .compNm{
    color: #1FBDC6;
    font-size: 24px;
    font-family: 'Pretendard-SemiBold';
    position: absolute;
    top: 36px;
    right: 30px;
  }
  .footer{
    margin-bottom: 50px;
  }
  .footer::before{
    position: absolute;
    width: 100px;
    bottom: -30px;
    left: 0px;
    content: '17';
    width: 100px;
    border: solid 1px #001736;
    background-color: #001736;
    height: 60px;
    color: #1FBDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .addEx{
    font-size: 18px;
    color: #33455E;
  }
  .ex{
    width: 1160.5px;
    height: 75px;
    border-top: solid 1px #001736;
  }
  .exText{
    display: block;
    margin-top: 15px;
    font-size: 13px;
    color: #33455E;
    font-family: 'Pretendard-Regular';
  }
</style>