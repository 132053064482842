<template>
  <div :class="[className]">
    <ol>
      <li>
        <label for="stk"><b>{{label}}:</b></label>
        <Input
          id="stk"
          :value="prp_assignedName"
          name="consultant"
          type="text"
          placeholder="담당자를 선택해주세요"
          :disabled="true"
          :styleAttr="{ width: '100%' }"
        />
      </li>
    </ol>
    <div style="line-height:90%;"><br /></div>
    <div class="area_search">
      <div class="unit">
        <label class="tit" for="name">FC코드<br />담당자 이름</label>
        <Input
          id="name"
          :value.sync="search"
          name="name"
          type="text"
          shape="search"
          placeholder="FC코드 혹은 담당자 이름을 입력해주세요"
          :styleAttr="{ width: '100%' }"
        />
      </div>
    </div>
    <div class="contents">
      <div class="tbl_area" style="min-width: auto">
        <table class="tbl">
          <colgroup>
            <col width="20%" />
            <col width="65%" />
            <col width="15%" />
          </colgroup>
          <thead>
          <tr>
            <th>
              <b>코드</b>
            </th>
            <th>
              <b>담당자</b>
            </th>
            <th>
              <b>&nbsp;</b>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in fnPagingList(filteredList)" :key="item.id">
            <td>
              {{ item.consultant_cd }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              <!--
              <button class="btn" :class="{gray: !consultantGaNm2}" @click="fnSelect(item)" :disabled="!consultantGaNm2">선택</button>
              -->
              <button class="btn" @click="fnSelect(item)">선택</button>
            </td>
          </tr>
          <tr v-if="!filteredList || filteredList.length === 0">
            <td colspan="3">데이터가 없습니다.</td>
          </tr>
          </tbody>
        </table>
        <Pagination
          v-if="list"
          :page="pagination.num+1"
          :perPage="pagination.size"
          :totalCount="filteredList.length"
          @change="p => this.pagination.num=p-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from "../../common/Input";
import Pagination from "../../common/Pagination";
import consultantsService from "~/service/allOk/consultantsService";

export default {
  components:{Input, Pagination},
  props:{
    label:{type:String,default: '배정된 담당자'},
    assignedGaId:{type:String, required:false,},
    assignedId:{type:String, required:false},
    assignedName:{type:String,required:false,},
    className:{type:String,required: false,},
    manageType:{type:String,required: true},
    customerId:{type:String,required:true,}
  },
  beforeMount() {
    this.fetchConsultantList();
  },
  data(){
    return{
      search:null,
      list:null,
      selectedManager:null,
      prp_assignedName:this.assignedName,
      form:{
        id:null,
        name:null,
      },
      pagination:{
        size: 5,
        num: 0,
        totalCount:0,
      }
    }
  },
  computed:{
    filteredList(){
      if(this.search){
        return this.list.filter( mng => {
          return mng.consultant_cd.indexOf(this.search)>=0 || mng.name.indexOf(this.search)>=0;
        });
        return ;
      }
      return this.list;
    },
  },
  methods:{
    fnPagingList(list){
      if(list && list.length>0){
        const start = this.pagination.num * this.pagination.size;
        const end = start + this.pagination.size;
        return list.slice(start, end);
      }
      return list;
    },
    fnSelect(item){
      this.selectedManager = item;
      this.$popupConfirm({
        title:'확인',
        msg:'저징하시겠습니까?',
        btnName:'저장',
        btnFunc:this.fnSave,
        closeBtnName:'취소'
      });
    },
    fnSave(){
      const s = this.selectedManager;
      const payload={
        type: this.manageType,
        companyId: s.compId,
        companyNm: s.compName,
        orgId: s.orgId,
        orgNm: s.orgName,
        managerId: s.id,
        managerNm: s.name,
      }
      this.$axios.post(`/apiMngAo/customers/${this.customerId}/consultants`, payload)
        .then(res => {
          if(res.data.result == true){
            this.prp_assignedName = this.selectedManager.name;
            this.$emit('selected', this.selectedManager);
            this.$popupClose();
          }else{
            this.$popupClose();
            alert(res.data.data.error.msg);
          }
        })
    },
    fetchConsultantList() {
      if(this.assignedGaId){
        const query= {
          //serviceType: 'CPMNG_TAX'
          companyId: this.assignedGaId
        }
        consultantsService.fetchConsultants(this.$axios, query)
          .then(res => {
            const {data : {data,result} } = res;
            if(result === true){
              this.list = data.contents;
              this.pagination.totalCount = data.contents.length;
            } else {
              this.list = [];
            }
          });
      }
    },
  },
  watch:{
    assignedGaId(nv){
      this.fetchConsultantList();
    }
  }
}
</script>
