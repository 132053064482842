<template>
  <div :class="[className]">
    <ol>
      <li>
        <label for="stk"><b>{{label}}:</b></label>
        <Input
          id="stk"
          :value="assignedGaName"
          name="consultant"
          type="text"
          placeholder="GA를 선택해주세요"
          :disabled="true"
          :styleAttr="{ width: '100%' }"
        />
      </li>
    </ol>
    <div style="line-height:90%;"><br /></div>
    <div class="area_search">
      <div class="unit" :styleAttr="{ width: '100%' }">
        <label class="tit" for="consultantNm">GA코드<br />GA명</label>
        <Input
          id="consultantNm"
          :value.sync="search"
          name="search"
          type="text"
          shape="search"
          placeholder="GA코드 혹은 GA명을 입력해주세요"
          :styleAttr="{ width: '100%' }"
        />
      </div>
    </div>
    <div class="contents">
      <div class="tbl_area" style="min-width: auto">
        <table class="tbl">
          <colgroup>
            <col width="20%" />
            <col width="65%" />
            <col width="15%" />
          </colgroup>
          <thead>
            <tr>
              <th><b>GA코드</b></th>
              <th><b>GA명</b></th>
              <th><b>&nbsp;</b></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredGaList" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td><button class="btn" @click="fnSelect(item)">선택</button></td>
            </tr>
            <tr v-if="!filteredGaList || filteredGaList.length === 0">
              <td colspan="3">데이터가 없습니다.</td>
            </tr>
          </tbody>
        </table>
        <Pagination
          v-if="gaList"
          :page="pagination.num+1"
          :perPage="pagination.size"
          :totalCount="filteredGaList.length"
          @change="p => this.pagination.num=p-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../common/Pagination";
import Input from '../../common/Input';
import consultantsService from "~/service/allOk/consultantsService";
import search from "../allOk/manageFC/Search";
export default {
  components:{Input, Pagination},
  props:{
    label:{
      type:String,
      default: '배정된 GA'
    },
    assignedGaId:{
      type:String,
      required:false,
    },
    assignedGaName:{
      type:String,
      required:false,
    },
    className:{
      type:String,
      required: false,
    },
    manageType:{
      type:String,
      required: true
    },
    allowedGaType:{
      type:Array,
      required:false,
    },
    customerId:{
      type:String,
      required:true,
    }
  },
  beforeMount() {
    this.fetchConsultantGaList();
  },
  data(){
    return{
      search:null,
      gaList:null,
      selectedGa:null,
      form:{
        gaId:null,
        gaName:null,
      },
      pagination:{
        size: 15,
        num: 0,
      }
    }
  },
  computed:{
    filteredGaList(){
      if(this.search){
        return this.gaList.filter( ga => {
          return ga.id.indexOf(this.search)>=0 || ga.name.indexOf(this.search)>=0;
        });
      }
      return this.gaList;
    },
  },
  methods:{
    fnSelect(item){
      this.selectedGa=item;
      this.$popupConfirm({
        title:'확인',
        msg:'저징하시겠습니까?',
        btnName:'저장',
        btnFunc:this.fnSave,
        closeBtnName:'취소'
      });
    },
    fnSave(){
      const {id, name} = this.selectedGa;
      const payload = {
        type: this.manageType,
        companyId: id,
        companyNm: name,
      }
      this.$axios.post(`/apiMngAo/customers/${this.customerId}/consultantGa`, payload)
        .then(res => {
          if(res.data.result == true){
            this.$emit('selected', this.selectedGa);
            this.$popupClose();
          }else{
            this.$popupClose();
            alert(res.data.data.error.msg);
          }
        })
    },
    fetchConsultantGaList(){
      consultantsService.fetchConsultantGa(this.$axios)
        .then(res => {
          const {data : {data,result} } = res;
          if(result === true){
            this.gaList =
              (this.allowedGaType)
                ? data.contents.filter(ga => this.allowedGaType.includes(ga.type))
                : data.contents
            this.form.totalCount = data.contents.length;
          } else {
            this.gaList = [];
          }
        });
    },
  },
}
</script>
