<template>
  <div v-if="입력개요&&reportData">
    <!-- <Cover :입력개요="입력개요" /> -->
    <Cover2 :입력개요="입력개요" />
    <Page1 />
    <Page2 />
    <Page3 :입력개요="입력개요" :재무현황="reportData.재무현황" />
    <Page4 :입력개요="입력개요" />
    <Page5 :입력개요="입력개요" :주식가치평가금액="reportData.주식가치평가금액" />
    <Page6 :입력개요="입력개요" :주주별평가="reportData.주주별평가" />
    <Page7 :입력개요="입력개요" :유고시="reportData.유고시" />
    <Page8 :입력개요="입력개요" :양도시="reportData.양도시" />
    <Page9 :입력개요="입력개요" :청산시="reportData.청산시" />
    <Page10 :입력개요="입력개요" :자기주식="reportData.자기주식" />
    <Page11 :입력개요="입력개요" :이익소각="reportData.이익소각" />
    <Page12 :입력개요="입력개요" :증여후양도="reportData.증여후양도" />
    <Page13 :입력개요="입력개요" :증여후소각="reportData.증여후소각" />
    <Page14 :입력개요="입력개요" :양도후사내근로복지기금="reportData.양도후사내근로복지기금" />
    <Page15 :입력개요="입력개요" :법인순자산증가="reportData.법인순자산증가" />
    <Page16 :입력개요="입력개요" :미처분잉여금세부담액="reportData.미처분잉여금세부담액" />
    <Page17 :입력개요="입력개요" :주식가치미래주당="reportData.주식가치미래주당" :주식가치미래총액="reportData.주식가치미래총액" />
    <Page18 :입력개요="입력개요" :기업가치미래="reportData.기업가치미래" />
    <Page19 :입력개요="입력개요" :증여세과세특례="reportData.증여세과세특례" />
    <Page20 :입력개요="입력개요" :가지급금불이익="reportData.가지급금불이익" />
    <Page21 :입력개요="입력개요" :가지급금그래프="reportData.가지급금그래프" />
    <LastCover />
  </div>
</template>

<script>
  // import Cover from "~/components/pages/allOkMng/report/cover.vue"
  import Cover2 from "~/components/pages/allOkMng/report/cover2.vue"
  import Page1 from "~/components/pages/allOkMng/report/page1.vue"
  import Page2 from "~/components/pages/allOkMng/report/page2.vue"
  import Page3 from "~/components/pages/allOkMng/report/page3.vue"
  import Page4 from "~/components/pages/allOkMng/report/page4.vue"
  import Page5 from "~/components/pages/allOkMng/report/page5.vue"
  import Page6 from "~/components/pages/allOkMng/report/page6.vue"
  import Page7 from "~/components/pages/allOkMng/report/page7.vue"
  import Page8 from "~/components/pages/allOkMng/report/page8.vue"
  import Page9 from "~/components/pages/allOkMng/report/page9.vue"
  import Page10 from "~/components/pages/allOkMng/report/page10.vue"
  import Page11 from "~/components/pages/allOkMng/report/page11.vue"
  import Page12 from "~/components/pages/allOkMng/report/page12.vue"
  import Page13 from "~/components/pages/allOkMng/report/page13.vue"
  import Page14 from "~/components/pages/allOkMng/report/page14.vue"
  import Page15 from "~/components/pages/allOkMng/report/page15.vue"
  import Page16 from "~/components/pages/allOkMng/report/page16.vue"
  import Page17 from "~/components/pages/allOkMng/report/page17.vue"
  import Page18 from "~/components/pages/allOkMng/report/page18.vue"
  import Page19 from "~/components/pages/allOkMng/report/page19.vue"
  import Page20 from "~/components/pages/allOkMng/report/page20.vue"
  import Page21 from "~/components/pages/allOkMng/report/page21.vue"
  import LastCover from "~/components/pages/allOkMng/report/lastCover.vue"
  import diagnosisService from '~/service/allOkMng/diagnosisService.js';
  export default{
    components: {
      // Cover,
      Cover2, Page1, Page2, Page3, Page4, Page5, Page6, Page7, Page8, Page9, Page10, Page11, Page12, Page13, Page14, Page15, Page16, Page17, Page18, Page19, Page20, Page21, LastCover
    },
    layout: 'blank',
    data() {
      return {
        입력개요: null,
        입력재무: null,
        입력손익: null,
        reportData: {},
        result: null,
        errMsg: null
      }
    },
    fetch() {
      const bizNo = this.$route.query.bizNo;
      return diagnosisService.getDiagnosisReportNone(this.$axios, bizNo).then(({ success, data }) => {
        if (success) {
          this.입력개요 = data.입력개요;
          this.입력재무 = data.입력재무;
          this.입력손익 = data.입력손익;
          this.reportData = data.reportData;
          this.result = success;
          // setTimeout(() => {
          //   this.fnDrawChart();
          // }, 10);
        }
      });
    },
  }
</script>

<style scoped>
</style>