<template>
  <div class="cover">
    <div class="cTit">
      <div class="unicornLogo">unicornranch</div>
      <div class="reportTit">비상장주식가치평가</div>
    </div>
    <div class="contents">
      <div class="Flist" style="display: flex;">11.<span class="Ftit">자본거래 Case 4 : 증여 후 소각(자기주식)<br/><span class="titInfo">순자산가치 3과 순손익가치 2의 가중치를 적용하여 비상장주식</span></span></div>
      <div class="compNm">{{입력개요?.회사명}}</div>
      <div>
        <div class="tit1">1) 증여 후 소각(자기주식) 목록</div>
        <div style="position: absolute; top: 151px; right: 30px; height: 15px; color: #33455E; font-size: 13px; font-family: 'Pretendard-SemiBold';">(단위 : 원)</div>
        <div class="table1">
          <div class="column1">
            <div class="c1row1 bold">구분</div>
            <div class="c1row2 bold">
              <div class="c1row2-1">지분율</div>
              <div class="c1row2-1">주식수</div>
            </div>
            <div class="c1row3 regular" v-for="(지분율, i) in 증여후소각?.지분율" :key="i">
              <div class="c1row2-1 semibold" style="color: #B50A45;">{{fnPercent(지분율.지분율)}}%</div>
              <div class="c1row2-1">{{fnFormatNumber((지분율.주식수).toFixed(2))}}</div>
            </div>
          </div>
          <div class="column2">
            <div class="c2row1 bold">증여(배우자)</div>
            <div class="c2row2 bold">
              <div class="c1row2-1">1주당가치</div>
              <div class="c2row2-1 borderBottom" style="color: #B50A45;"><span style="margin-right: 30px;">증여액</span></div>
              <div class="c1row2-1 borderBottom">증여세</div>
            </div>
            <div class="c2row3 regular" v-for="(지분율, i) in 증여후소각?.지분율" :key="i">
              <div class="c1row2-1 borderRight borderBottom semibold" style="color: #B50A45;">{{fnFormatNumber((증여후소각.증여_주당가치).toFixed(2))}}</div>
              <div class="c2row2-1 borderBottom"><span style="margin-right: 30px;">{{fnFormatNumber((지분율.증여액).toFixed(2))}}</span></div>
              <div class="c1row2-1 borderBottom">{{fnFormatNumber((지분율.증여세).toFixed(2))}}</div>
            </div>
          </div>
          <div class="column3">
            <div class="c3row1 bold">소각(자기주식)</div>
            <div class="c3row2 bold">
              <div class="c1row2-1">1주당가치</div>
              <div class="c3row2-1"><span style="margin-right: 30px;">이익소각액</span></div>
              <div class="c3row2-1"><span style="margin-right: 30px;">의제배당액</span></div>
              <div class="c3row2-1 leRiBorder2" style="border-top: solid 3px #21BDC6;"><span style="margin-right: 30px;">배당세액</span></div>
            </div>
            <div class="c3row3 regular" v-for="(지분율, i) in 증여후소각?.지분율" :key="i">
              <div class="c1row2-1 borderRight borderBottom" style="color: #B50A45;">{{fnFormatNumber((증여후소각.소각_주당가치).toFixed(2))}}</div>
              <div class="c3row2-1 borderBottom semibold" style="color: #B50A45;"><span style="margin-right: 30px;">{{fnFormatNumber((지분율.이익소각액).toFixed(2))}}</span></div>
              <div class="c3row2-1 borderBottom"><span style="margin-right: 30px;">{{fnFormatNumber((지분율.의제배당액).toFixed(2))}}</span></div>
              <div :class="['c3row2-1', 'semibold', 'borderBottom', i === 증여후소각.지분율.length - 1 ? 'bottomBorder2' : '', 'leRiBorder2']">
                <span style="margin-right: 30px;">{{fnFormatNumber((지분율.배당세액).toFixed(2))}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tit2">2) 증여 후 소각(자기주식) 차트</div>
        <div class="chart">
          <div id="증여후소각" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <div class="footer">
        <div class="addEx">※ 추가 설명</div>
        <div class="ex"><span class="exText">투자한 주주들에게 나가야 할 배당금 의무도 무시할 수 없다. 사실 어떤 기업이든 자기 돈만 충분하다면 굳이 엄격한 심사와 자격까지 갖추면서 상장할 이유는 없다.[2] 실제로 2015년 기준 전경련에서 조사한 자료에서는 총 600여개 기업이 코스피 상장 요건을 갖추고 있었지만 단 7개 기업만이 상장을 했다. 그리고 다른 회사에게 자신의 회사를 팔 때도 매우 쉬운데. 상장회사들을 인수 할 때 과반의 주식을 확보하거나 합병까지 하려면 전체 주식을 사서 소각해야 하는데, 이 과정도 매우 복잡해서 전체 주주들에게 동의를 받아야 하고 주식시장을 관리감독하는 기관의 심사까지 받아야 해서 규모가 큰 회사들 끼리 합병시 년단위로 걸린다. 비상장은 경영권을 가진 임원들에게 만족하는 가격만 제시한다면 매우 수월하게 인수가 가능하다.</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['입력개요', '증여후소각'],
  methods: {
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fnPercent(num){
      return num*100;
    },
    fnDrawChart(){
      const Chart = toastui.Chart;
      Chart.columnChart({
        el: document.getElementById('증여후소각'),
        data: {
          categories: this.증여후소각.지분율.map(el => (el.지분율 * 100).toFixed(1) + '%'),
          series: [
            {
              name: `이익소각액`,
              data: this.증여후소각.지분율.map(el => el.이익소각액),
            },
            {
              name: `배당세액`,
              data: this.증여후소각.지분율.map(el => el.배당세액),
            },
          ],
        },
        options: {
          plot:{
            visible: false,
          },
          chart: {
            title: '',
            width: 'auto',
          },
          yAxis: {
            label: {
              formatter: value => (value == 0 ? '-' : this.fnFormatNumber(value) + '원'),
            },
          },
          theme: {
            chart:{
              backgroundColor:'#EEEEEE',
            },
            series: {
              barWidth: 80,
              colors: ['#2D415A', '#21BDC6'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            yAxis: {
              color: '#EEEEEE',
              label: {
                color: '#EEEEEE',
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'top',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(0)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(0)),
          },
        },
      });
    }
  },
  mounted () {
    this.fnDrawChart();
  },
}
</script>

<style scoped>
  @import '~/static/css/report.css';
  .Ftit{
    display: flex;
    flex-direction: column;
  }
  .titInfo{
    font-size: 10px;
    font-family: 'Pretendard-Light';
  }
  .compNm{
    color: #1FBDC6;
    font-size: 24px;
    font-family: 'Pretendard-SemiBold';
    position: absolute;
    top: 36px;
    right: 30px;
  }
  .tit1{
    margin-top: -10px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .table1{
    margin-top: 20px;
    width: 1160px;
    border-top: solid 1px #001736;
    display: flex;
  }

  .column1{
    width: 200px;
    border-right: solid 1px #001736;
  }
  .column2{
    width: 365px;
    border-right: solid 1px #001736;
  }
  .column3{
    width: 595px;
  }
  .c1row1{
    width: 200px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #001736;
  }
  .c2row1{
    width: 365px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #001736;
  }
  .c3row1{
    width: 595px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #001736;
  }
  .c1row2{
    width: 200px;
    height: 40px;
    border-bottom: solid 1px #001736;
    border-right: solid 1px #001736;
    background-color: #DBDCDE;
    display: flex;
  }
  .c1row2-1{
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .c2row2{
    width: 365px;
    height: 40px;
    border-bottom: solid 1px #001736;
    border-right: solid 1px #001736;
    background-color: #DBDCDE;
    display: flex;
  }
  .c2row2-1{
    width: 165px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .c3row2{
    width: 595px;
    height: 40px;
    border-bottom: solid 1px #001736;
    background-color: #DBDCDE;
    display: flex;
  }
  .c3row2-1{
    width: 165px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .c1row3{
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #001736;
  }
  .c2row3{
    width: 365px;
    height: 40px;
    display: flex;
  }
  .c3row3{
    width: 595px;
    height: 40px;
    display: flex;
  }
  .borderBottom{
    border-bottom: solid 1px #001736;
  }
  .borderRight{
    border-right: solid 1px #CCD1D7;
  }
  .bold{
    font-size: 13px;
    font-family: 'Pretendard-Bold';
    color: #001736;
  }
  .regular{
    font-size: 13px;
    font-family: 'Pretendard-Regular';
    color: #001736;
  }
  .semibold{
    font-size: 13px;
    font-family: 'Pretendard-SemiBold';
    color: #001736;
  }
  .bottomBorder2{
    border-bottom: solid 2px #21BDC6;
  }
  .leRiBorder2{
    border-right: solid 3px #21BDC6;
    border-left: solid 3px #21BDC6;
  }

  .tit2{
    margin-top: 61px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .chart{
    margin-top: 20px;
    width: 1160px;
    height: 840px;
    border-top: solid 1px #001736;
    border-bottom: solid 1px #001736;
  }
  .footer{
    margin-bottom: 50px;
  }
  .footer::before{
    position: absolute;
    width: 100px;
    bottom: -30px;
    left: 0px;
    content: '11';
    width: 100px;
    border: solid 1px #001736;
    background-color: #001736;
    height: 60px;
    color: #1FBDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .addEx{
    font-size: 18px;
    color: #33455E;
  }
  .ex{
    width: 1160.5px;
    height: 75px;
    border-top: solid 1px #001736;
  }
  .exText{
    display: block;
    margin-top: 15px;
    font-size: 13px;
    color: #33455E;
    font-family: 'Pretendard-Regular';
  }
</style>