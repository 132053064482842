<template>
  <div :class="['bx_' + shape, classNm]" :style="styleAttr">
    <input
      :type="type"
      class="ipt"
      ref="ipt"
      :tabindex="tabindex"
      :id="id"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="fnInput"
      @keyup.enter="fnSubmit"
    />

    <button
      :id="id+'_btn'"
      :class="btn.classNm"
      @click="btn.fnEvent"
      :disabled="disabled"
    >
      {{btn.text}}
    </button>
  </div>
</template>
<script>
export default {
  name:"Input",
  props: {
    //탭눌렀을 때
    tabindex: {
      type: Number,
      required: false,
      default: 1
    },
    // 클래스
    classNm: {
      type: String
    },
    type:{
      type: String,
      required: false,
      validator(value){
        if(value)
          return ['text', 'password', 'number'].includes(value);
        else
          return true;
      }
    },
    // 일반인풋 타입, 검색 타입
    shape:{
      type: String,
      default: 'ipt',
      validator(value){
        return ['ipt', 'search'].includes(value);
      }
    },
    // 스타일
    styleAttr:{
      type:Object,
      required: false,
    },
    // 라벨
    label: {
      type:String,
      default: null
    },
    // 필드 명
    name:{
      type:String,
      required:true,
    },
    // 필드 값
    value:{
      //type:String,
      default:null,
    },
    maxLength:{
      type:Number,
      default:255
    },
    // 플레이스 홀드
    placeholder:{
      type:String,
    },
    // 사용여부
    disabled:{
      type:Boolean,
      default: false
    },
    // 검색버튼 사용
    dpSearchBtn:{
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      id: null,
      focus: false,
      btnType: {
        ipt: {
          classNm: 'btn_remove',
          text: '지우기',
          fnEvent: this.fnRemove
        },
        search: {
          classNm: 'btn_search',
          text: '검색',
          fnEvent: this.fnSubmit
        }
      }
    };
  },
  computed:{
    btn(){
      return this.btnType[this.shape];
    }
  },
  methods: {
    onFocus(){
      this.$refs[this.id].focus();
      this.focus= true;
    },
    outFocus(){
      this.focus = false;
    },
    fnRemove() {
      this.$emit('update:value', '');
      this.$refs.ipt.focus();
    },
    fnSubmit(){
      this.$emit('enter', {
        name: this.name,
        value: this.$refs.ipt.value
      })
    },
    fnInput(e){
      this.$emit('update:value', e.target.value)
    }
  },
  watch:{
    value(nV, oV){

    }
  },
  beforeMount() {
    // 아이디 생성
    this.id = 'ipt_'+this.name+'_'+Math.round(Math.random()*10000);
  }
};
</script>
