var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container"},[_c('Location'),_vm._v(" "),_c('div',{staticClass:"inner"},[_c('div',{staticClass:"colr"},[_c('button',{staticClass:"btn_reset",on:{"click":_vm.fnResetForm}},[_vm._v("전체초기화")])]),_vm._v(" "),_c('div',{staticClass:"area_search"},[_c('div',{staticClass:"unit"},[_c('label',{staticClass:"tit",attrs:{"for":"bizNo"}},[_vm._v("사업자번호")]),_vm._v(" "),_c('Input',{attrs:{"id":"bizNo","name":"query.bizNo","shape":"search","placeholder":"검색어를 입력하세요.","value":_vm.query.bizNo},on:{"update:value":function($event){return _vm.$set(_vm.query, "bizNo", $event)},"enter":_vm.fnResetPageAndFetch}})],1),_vm._v(" "),_c('div',{staticClass:"unit"},[_c('label',{staticClass:"tit",attrs:{"for":"compNm"}},[_vm._v("회사명")]),_vm._v(" "),_c('Input',{attrs:{"id":"compNm","name":"query.compNm","shape":"search","placeholder":"검색어를 입력하세요.","value":_vm.query.compNm,"disabled":true},on:{"update:value":function($event){return _vm.$set(_vm.query, "compNm", $event)},"enter":_vm.fnResetPageAndFetch}})],1)]),_vm._v(" "),_c('div',{staticClass:"colb"},[_c('div',{staticClass:"top"},[_c('div',[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.fnOpenPop2('popCsState')}}},[_vm._v("\n            열기\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"col unit_page"},[_c('h4',{staticClass:"l_tit"},[_vm._v("페이지당 개수 : ")]),_vm._v(" "),_c('Select',{attrs:{"id":"perPage","title":"20개","itemList":_vm.perPageList,"value":_vm.query.perPage},on:{"update:value":function($event){return _vm.$set(_vm.query, "perPage", $event)},"change":_vm.fnFetchList}}),_vm._v(" "),_c('button',{staticClass:"btn",on:{"click":()=>{
          _vm.query.page=1;
          _vm.fnFetchList();
        }}},[_vm._v("검색")])],1)]),_vm._v(" "),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"tbl_area"},[_c('table',{staticClass:"tbl"},[_vm._m(0),_vm._v(" "),_c('tbody',[_vm._l((_vm.contents),function(item){return _c('tr',{key:item.id,class:{on:item.id===_vm.id},on:{"click":function($event){return _vm.fnOnClick({id:item.id, compNm:item.compNm, bizNo:item.bizNo})},"dblclick":function($event){return _vm.fnOpenPop2('popCsState')}}},[_c('td',{staticClass:"c1"},[_vm._v(_vm._s(item.bizNo))]),_vm._v(" "),_c('td',{staticClass:"c1"},[_vm._v(_vm._s(item.compNm))])])}),_vm._v(" "),(_vm.contents && _vm.contents.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_vm._v("데이터가 없습니다.")])]):_vm._e()],2)])]),_vm._v(" "),_c('Pagination',{attrs:{"page":_vm.query.page,"perPage":_vm.query.perPage,"totalCount":_vm.pagination.totalCount},on:{"change":p => {
          this.query.page=p;
          this.fnFetchList();
        }}})],1),_vm._v(" "),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(_vm.$store.state.ui.popCsState == true)?_c('EducationDetail',{key:_vm.$store.state.ui.popCsState,attrs:{"id":_vm.id,"bizNo":_vm.bizNo,"compNm":_vm.compNm},on:{"reload":_vm.fnReload}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"c1"},[_c('b',[_vm._v("사업자번호")])]),_vm._v(" "),_c('th',{staticClass:"c1"},[_c('b',[_vm._v("회사명")])])])])
}]

export { render, staticRenderFns }