var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container"},[_c('Location'),_vm._v(" "),_c('div',{staticClass:"inner"},[_c('div',{staticClass:"colr"},[_c('button',{staticClass:"btn_reset",on:{"click":_vm.fnResetForm}},[_vm._v("전체초기화")])]),_vm._v(" "),_c('div',{staticClass:"area_search"},[_c('div',{staticClass:"unit"},[_c('label',{staticClass:"tit",attrs:{"for":"compNm"}},[_vm._v("회사명")]),_vm._v(" "),_c('Input',{attrs:{"id":"compNm","name":"query.compNm","shape":"search","placeholder":"회사명을 입력하세요.","value":_vm.query.compNm},on:{"update:value":function($event){return _vm.$set(_vm.query, "compNm", $event)},"enter":_vm.fnResetPageAndFetch}})],1),_vm._v(" "),(_vm.showGaData)?_c('div',{staticClass:"unit"},[_c('label',{staticClass:"tit",attrs:{"for":"companyId"}},[_vm._v("GA")]),_vm._v(" "),_c('Select',{attrs:{"id":"companyId","defaultName":"GA를 선택하세요","itemList":_vm.gaList,"value":_vm.query.companyId},on:{"update:value":function($event){return _vm.$set(_vm.query, "companyId", $event)},"change":_vm.fnFetchList}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"colb"},[_c('div',{staticClass:"top"},[_c('div',[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.fnOpenPop2('popCsState')}}},[_vm._v("열기")]),_vm._v(" "),_c('button',{staticClass:"btn",class:{dis: _vm.loading||(_vm.contents && _vm.contents.length === 0)},attrs:{"disabled":_vm.loading||(_vm.contents && _vm.contents.length === 0)},on:{"click":_vm.fnExelDown}},[_vm._v("리스트 다운")]),_vm._v(" "),(_vm.$roleCheck([_vm.role.BIZ_REMOVER], true))?_c('button',{staticClass:"btn",class:{dis: _vm.loading||(_vm.contents && _vm.contents.length === 0)},attrs:{"disabled":_vm.loading||(_vm.contents && _vm.contents.length === 0)},on:{"click":()=> {
                    if( _vm.id ) {
                      _vm.openDeletePopup = true;
                    }
                  }}},[_vm._v("\n            기업삭제\n          ")]):_vm._e(),_vm._v(" "),(_vm.loading)?_c('Loading'):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"col unit_page"},[_c('h4',{staticClass:"l_tit"},[_vm._v("페이지당 개수 :")]),_vm._v(" "),_c('Select',{attrs:{"id":"perPage","title":"20개","itemList":_vm.perPageList,"value":_vm.query.perPage},on:{"update:value":function($event){return _vm.$set(_vm.query, "perPage", $event)},"change":_vm.fnFetchList}}),_vm._v(" "),_c('button',{staticClass:"btn",on:{"click":() => {
              _vm.query.page = 1;
              _vm.fnFetchList();
            }}},[_vm._v("\n          검색\n        ")])],1)]),_vm._v(" "),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"tbl_area"},[_c('table',{staticClass:"tbl"},[_c('thead',[_c('tr',[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),(_vm.showGaData)?_c('th',{staticClass:"c5",staticStyle:{"width":"8%"}},[_c('b',[_vm._v("담당자")])]):_vm._e(),_vm._v(" "),(_vm.showGaId)?_c('th',{staticClass:"c5",staticStyle:{"width":"8%"}},[_c('b',[_vm._v("회원구분")])]):_vm._e()])]),_vm._v(" "),_c('tbody',[_vm._l((_vm.contents),function(item){return _c('tr',{key:item.id,class:{ on: item.id === _vm.id },on:{"click":function($event){return _vm.fnOnClick(item)},"dblclick":function($event){return _vm.fnOpenPop2('popCsState')}}},[_c('td',{staticClass:"c2",staticStyle:{"width":"8%"}},[_vm._v(_vm._s(item.bizNo))]),_vm._v(" "),_c('td',{staticClass:"c2"},[_vm._v(_vm._s(_vm.mixCorpType(_vm.$isCorporation(item.bizNo)) + ' ' + item.compNm))]),_vm._v(" "),_c('td',{staticClass:"c4",staticStyle:{"width":"8%"}},[_vm._v(_vm._s(item.tel))]),_vm._v(" "),_c('td',{staticClass:"c4"},[_vm._v(_vm._s(item.email))]),_vm._v(" "),(_vm.showGaData)?_c('td',{staticClass:"c5",staticStyle:{"width":"8%"}},[_vm._v(_vm._s(item.gaNm && `영업 [${item.gaNm}] ${item.fcNm || ''}`))]):_vm._e(),_vm._v(" "),(_vm.showGaId)?_c('td',{staticClass:"c5",staticStyle:{"width":"8%"}},[_vm._v(_vm._s(_vm.fnMemberType(item.memberTypes)))]):_vm._e()])}),_vm._v(" "),(_vm.contents && _vm.contents.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":"6"}},[_vm._v("데이터가 없습니다.")])]):_vm._e()],2)])]),_vm._v(" "),_c('Pagination',{attrs:{"page":_vm.query.page,"perPage":_vm.query.perPage,"totalCount":_vm.pagination.totalCount},on:{"change":p => {
            this.query.page = p;
            this.fnFetchList();
          }}})],1),_vm._v(" "),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(_vm.$store.state.ui.popCsState == true)?_c('Detail',{key:_vm.$store.state.ui.popCsState,attrs:{"id":_vm.id,"compObj":_vm.compObj},on:{"reload":_vm.fnReload}}):_vm._e(),_vm._v(" "),(_vm.openDeletePopup)?_c('DeletePopup',{attrs:{"customer-id":_vm.id,"biz-no":_vm.bizNo},on:{"close":()=> _vm.openDeletePopup=false,"refresh":()=> {
                     _vm.fnFetchList();
                     _vm.openDeletePopup=false;
                   }}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"c2",staticStyle:{"width":"8%"}},[_c('b',[_vm._v("사업자번호")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"c2"},[_c('b',[_vm._v("회사(고객)명")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"c4",staticStyle:{"width":"8%"}},[_c('b',[_vm._v("전화번호")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"c1"},[_c('b',[_vm._v("이메일")])])
}]

export { render, staticRenderFns }