const url = '/apiAo/common/codes';

const getCode = (axios) => {
  return axios.get(url)
    .then(res => {
      return res.data;
    });
};

export default {
  getCode,
};
