<template>
  <div class="cover">
    <div class="cTit">
      <div class="unicornLogo">unicornranch</div>
      <div class="reportTit">비상장주식가치평가</div>
    </div>
    <div class="contents">
      <div class="Flist" style="display: flex;">▣<span class="Ftit">가지급금 불이익<br/><span class="titInfo">순자산가치 3과 순손익가치 2의 가중치를 적용하여 비상장주식의 가치를 산정하는 금액</span></span></div>
      <div class="compNm">{{입력개요?.회사명}}</div>
      <div>
        <div class="tit1">1) 가지급금 불이익 내역</div>
        <div class="table1">
          <div class="col1">
            <div class="cell1 backColor" style="border-bottom: 1px solid #21BDC6;">가지급금(주임종단기대여금)추정액</div>
            <div class="cell2" style="color: #EA6F6A; justify-content: flex-end; padding-right: 30px;">{{fnFormatNumber((가지급금그래프.가지급금추정액).toFixed(2))}}</div>
            <div class="cell2 backColor" style="color: #EA6F6A; border-bottom: 1px solid #21BDC6;">인정이자율</div>
            <div class="cell2" style="justify-content: flex-end; padding-right: 30px;">{{fnPercent(가지급금그래프.인정이자율)}}%</div>
          </div>
          <div class="col1">
            <div class="cell1 backColor">차입금(장단기)</div>
            <div class="cell2" style="justify-content: flex-end; padding-right: 30px;">{{fnFormatNumber(가지급금그래프.차입금)}}</div>
            <div class="cell2 backColor">이자비용</div>
            <div class="cell2" style="justify-content: flex-end; padding-right: 30px;">{{fnFormatNumber(가지급금그래프.이자비용)}}</div>
          </div>
        </div>
        <div class="tit2">2) 가지급금 불이익 - 세부담 누계액_10년가정 차트</div>
        <div class="chart">
          <div id="가지급금그래프" style="width:100%; height:100%;"></div>
        </div>
        <div class="tit3">3) 가지급금 불이익 - 세부담 누계액_10년가정 목록</div>
        <div style="position: absolute; top: 885px; right: 30px; height: 15px; color: #33455E; font-size: 13px; font-family: 'Pretendard-SemiBold';">(단위 : 원)</div>
        <div class="table2">
          <div class="title1">
            <div class="t2Cell">경과<br/>시간</div>
            <div class="tCell2">가지급금</div>
            <div class="tCell2"><span>연간 법인세</span><span>증가분①</span></div>
            <div class="tCell2"><span>연간 소득세</span><span>증가분②</span></div>
            <div class="tCell2"><span>연간 합계</span><span>①+②</span></div>
            <div class="tCell2">연간 추가세금</div>
            <div class="tCell2">추가세금누계액</div>
          </div>
          <div class="t2contents" v-for="(가지급금그래프, i) in 가지급금그래프.가지급금그래프" :key="i">
            <div class="t2Cell1">{{i===0 ? '현재' : i+'년'}}</div>
            <div class="t2Cell2">{{fnFormatNumber((가지급금그래프.가지급금).toFixed(2))}}</div>
            <div class="t2Cell2">{{fnFormatNumber((가지급금그래프.연간법인세증가분).toFixed(2))}}</div>
            <div class="t2Cell2">{{fnFormatNumber((가지급금그래프.연간소득세증가분).toFixed(2))}}</div>
            <div class="t2Cell2" style="color: #B50A45; font-family: 'Pretendard-SemiBold';">{{fnFormatNumber((가지급금그래프.연간합계).toFixed(2))}}</div>
            <div class="t2Cell2">{{fnFormatNumber((가지급금그래프.연간추가세금).toFixed(2))}}</div>
            <div class="t2Cell2">{{fnFormatNumber((가지급금그래프.추가세금누계액).toFixed(2))}}</div>
          </div>
          <div class="t2contents2">
            <div style="display: flex; width: 620px; height: 40px; justify-content: center; align-items: center;">합계</div>
            <div style="display: flex; width: 180px; padding-right: 30px; height: 40px; justify-content: flex-end; align-items: center; background-color: #B50A45; color: #FFFFFF;">{{fnFormatNumber((가지급금그래프.합계).toFixed(2))}}</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="addEx">※ 추가 설명</div>
        <div class="ex">
          <span class="exText">
            ※ 가정 : 인정이자 법인세율 20%, 인정상여 소득세율 24% 가정
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['입력개요', '가지급금그래프'],
  methods: {
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fnPercent(num){
      return num*100;
    },
    fnDrawChart(){
      const Chart = toastui.Chart;
      Chart.columnChart({
        el: document.getElementById('가지급금그래프'),
        data: {
          categories: [
            '현재', '1년', '2년', '3년', '4년', '5년', '6년', '7년', '8년', '9년', '10년'
          ],
          series: [
            {
              name: `연간 추가세금`,
              data: this.가지급금그래프.가지급금그래프.map(el => el.연간추가세금),
            },
            {
              name: `추가세금누계액`,
              data: this.가지급금그래프.가지급금그래프.map(el => el.추가세금누계액),
            },
          ],
        },
        options: {
          plot:{
            visible: false,
          },
          chart: {
            title: '',
            width: 'auto',
          },
          yAxis: {
            label: {
              formatter: value => (value == 0 ? '-' : this.fnFormatNumber(value) + '원'),
            },
          },
          theme: {
            chart:{
              backgroundColor:'#EEEEEE',
            },
            series: {
              barWidth: 30,
              colors: ['#2D415A', '#21BDC6'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            yAxis: {
              color: '#EEEEEE',
              label: {
                color: '#EEEEEE',
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'top',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(2)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(2)),
          },
        },
      });
    }
  },
  mounted () {
    this.fnDrawChart();
  },
}
</script>

<style scoped>
  @import '~/static/css/report.css';
  .t2Cell2{
    width: 180px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
    font-size: 13px;
    font-family: 'Pretendard-Regular';
  }
  .t2Cell{
    width: 80px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #001736;
    font-size: 13px;
    font-family: 'Pretendard-SemiBold';
  }
  .t2Cell1{
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #001736;
    font-size: 13px;
    font-family: 'Pretendard-SemiBold';
  }
  .t2contents2{
    width: 800px;
    height: 40px;
    display: flex;
    border-bottom: 1px solid #001736;
  }
  .t2contents{
    width: 1160px;
    height: 40px;
    display: flex;
    border-bottom: 1px solid #001736;
  }
  .tCell1{
    width: 80px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #001736;
    font-size: 13px;
    font-family: 'Pretendard-Bold';
  }
  .tCell2{
    width: 180px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #001736;
    font-size: 13px;
    flex-direction: column;
    font-family: 'Pretendard-Bold';
  }
  .title1{
    width: 1160px;
    height: 68px;
    background-color: #DBDCDE;
    display: flex;
    border-bottom: 1px solid #001736;
  }
  .cell1{
    width: 440px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: 'Pretendard-Bold';
  }
  .cell2{
    width: 240px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: 'Pretendard-Bold';
  }
  .backColor{
    background-color: #001736;
    color: #21BDC6;
    justify-content: center;
  }
  .col1{
    width: 1160px;
    height: 40px;
    border-bottom: 1px solid #001736;
    font-family: 'Pretendard-Bold';
    font-size: 13px;
    display: flex;
  }
  .Ftit{
    display: flex;
    flex-direction: column;
  }
  .tit1{
    color: #33455E;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
  }
  .table1{
    margin-top: 20px;
    width: 1160px;
    height: 80px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #001736;
  }
  .table2{
    margin-top: 20px;
    width: 1160px;
    height: 550px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #001736;
  }
  .tit2{
    margin-top: 61px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .tit3{
    margin-top: 61px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .chart{
    margin-top: 20px;
    width: 1160px;
    height: 450px;
    border-top: solid 1px #001736;
    border-bottom: solid 1px #001736;
  }
  .titInfo{
    font-size: 10px;
    font-family: 'Pretendard-Light';
  }
  .compNm{
    color: #1FBDC6;
    font-size: 24px;
    font-family: 'Pretendard-SemiBold';
    position: absolute;
    top: 36px;
    right: 30px;
  }
  .footer{
    margin-bottom: 50px;
  }
  .footer::before{
    position: absolute;
    width: 100px;
    bottom: -30px;
    left: 0px;
    content: '19';
    width: 100px;
    border: solid 1px #001736;
    background-color: #001736;
    height: 60px;
    color: #1FBDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .addEx{
    font-size: 18px;
    color: #33455E;
  }
  .ex{
    width: 1160.5px;
    height: 75px;
    border-top: solid 1px #001736;
  }
  .exText{
    display: block;
    margin-top: 15px;
    font-size: 13px;
    color: #33455E;
    font-family: 'Pretendard-Regular';
  }
</style>