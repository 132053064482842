<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>사내근로복지기금 상세 : {{compNm}}</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button :class="{ tab: true, on: on == 'tab3' }"
                      @click="() => (this.on = 'tab3')">고객상세</button>
            </div>
            <div class="btns">
              <button class="btn dark" @click="fnClosePop">닫기</button>
            </div>
          </div>
          <div class="c_contents" v-if="on == 'tab3'" >
            <BizInfoTab :id="id" :bizNo="bizNo" ref="save3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BizInfoTab from "./BizInfoTab";
import rolesMixin from "../../../mixins/rolesMixin";
export default {
  mixins:[rolesMixin],
  name: 'CustomerDetail',
  props: ['id', 'bizNo', 'compNm', 'consultantGaNm', 'consultantNm', 'compObj'],
  data() {
    return {
      on: 'tab3',
      contents: {},
      submitTab:{
        path:'insurance',
        fileTypes:[
          {
            fileType:'salaryList',
            title:'급여대장',
          },
          {
            fileType:'contractList',
            title:'근로계약서',
          }
        ],
        fileMap:{
          salaryList:null,
          contractList:null,
        }
      }
    };
  },
  components: {BizInfoTab},
  computed:{
    managerTab(){
      return{
        salesCompanyType:[this.mixCompanyTypes.ADMIN, this.mixCompanyTypes.GA],
        managerCompanyType:[this.mixCompanyTypes.LABOR],
        manageType:this.mixManageTypes.LABOR
      }
    }
  },
  methods: {
    fnClosePop() {
      this.$store.dispatch('ui/setPopCsState', false);
      this.$emit('reload');
    },
    fnExcelDownload(){
      window.open(`/apiMngAo/customer/service/insurance/${this.id}/excel?name=${this.compNm}`)
    }
  },
};
</script>
