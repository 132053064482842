<template>
  <div class="search_date" :class="{on:onOff}">
    <div class="s_top">
      <span class="start">{{from.year}}-{{$fill0(from.month)}}-{{$fill0(from.date)}}</span>
      <span class="end">{{to.year}}-{{$fill0(to.month)}}-{{$fill0(to.date)}}</span>
    </div>
    <div class="s_cont">
      <div class="item">
        <h4 class="tit">
          시작일
        </h4>
        <div class="bx">
          <Select defaultName="년" :itemList="fromYearList" classNm="ipt_year" :value.sync="from.year" />
          <Select defaultName="월" :itemList="fromMonthList" classNm="ipt_month" :value.sync="from.month" />
          <Select defaultName="일" :itemList="fromDateList" classNm="ipt_day" :value.sync="from.date" />
        </div>
      </div>
      <div class="item">
        <h4 class="tit">
          종료일
        </h4>
        <div class="bx">
          <Select defaultName="년" :value.sync="to.year" :itemList="toYearList" classNm="ipt_year" />
          <Select defaultName="월" :value.sync="to.month" :itemList="toMonthList" classNm="ipt_month"/>
          <Select defaultName="일" :value.sync="to.date" :itemList="toDateList" classNm="ipt_day"/>
        </div>
      </div>
    </div>
    <div class="colb mr5 mb5">
      <div class="col">
      </div>
      <div class="col">
        <button class="btn" @click="(e)=>{
          e.stopPropagation();
          this.fnPropagationDate();
        }">
          확인
        </button>
        <button class="btn l_gray" @click="(e)=>{
          e.stopPropagation();
          this.fnRestore();
          this.$emit('cancel');
        }">취소</button>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "~/components/common/Select";
import DatePickerMixin from "~/mixins/DatePickerMixin";

export default {
  props:{
    onOff: {
      type: Boolean,
      default: false
    },
    dpType: {
      type: String,
      default: 'range'
    }
  },
  data(){
    return {
      showRange : this.dpType === 'range'
    }
  },
  mixins: [DatePickerMixin],
  components: {
    Select,
  },
};
</script>
