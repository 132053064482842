import signUpService from '~/service/backoffice/signUpService';

export const state = () => ({});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  sendMail(_, body) {
    return signUpService
      .postEmail(body, this.$axios)
      .then(status => {
        return status === 200;
      })
      .catch(err => {
        console.log(err);
        return false;
      });
  },
  emailValidation(_, body) {
    return signUpService
      .emailValidation(body, this.$axios)
      .then(data => {
        console.log(data);
        return data;
      })
      .catch(err => {
        console.log(err);
        return false;
      });
  },
  signUp(_, body) {
    return signUpService
      .postSignUp(body, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return false;
      });
  },
};
