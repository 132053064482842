var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cover"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"contents"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"compNm"},[_vm._v(_vm._s(_vm.입력개요?.회사명))]),_vm._v(" "),_c('div',[_c('div',{staticClass:"tit1"},[_vm._v("※ 가정")]),_vm._v(" "),_c('div',{staticClass:"table1"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"tContents divide"},[_vm._m(3),_vm._v(" "),_c('div',{staticClass:"sCont semibold"},[_vm._v(_vm._s(_vm.fnPercent(_vm.주식가치미래주당?.매출액증가율))+"%")])]),_vm._v(" "),_c('div',{staticClass:"tContents divide borderBottom"},[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"sCont semibold"},[_vm._v(_vm._s(_vm.fnPercent(_vm.주식가치미래주당?.순이익률))+"%")])])]),_vm._v(" "),_c('div',{staticClass:"tit2"},[_vm._v("A. 주식가치평가액 전망")]),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('div',{staticClass:"tit3"},[_vm._v("B. 비상장주식가치평가금액 전망")]),_vm._v(" "),_vm._m(6)]),_vm._v(" "),_c('div',{staticClass:"addEx"},[_vm._v("※ 추가 설명")]),_vm._v(" "),_vm._m(7)]),_vm._v(" "),_c('div',{staticClass:"footer"},[_vm._v("\n    15\n  ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cTit"},[_c('div',{staticClass:"unicornLogo"},[_vm._v("unicornranch")]),_vm._v(" "),_c('div',{staticClass:"reportTit"},[_vm._v("비상장주식가치평가")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Flist",staticStyle:{"display":"flex"}},[_vm._v("15."),_c('span',{staticClass:"Ftit"},[_vm._v("주식가치전망"),_c('br'),_c('span',{staticClass:"titInfo"},[_vm._v("순자산가치 3과 순손익가치 2의 가중치를 적용하여 비상장주식")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tContents"},[_c('div',{staticClass:"fCont bold"},[_c('span',{staticStyle:{"display":"flex","justify-content":"center"}},[_vm._v("제목")])]),_vm._v(" "),_c('div',{staticClass:"sCont bold"},[_vm._v("내용")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fCont semibold"},[_c('span',{staticStyle:{"margin-left":"30px"}},[_vm._v("매출액증가율")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fCont semibold"},[_c('span',{staticStyle:{"margin-left":"30px"}},[_vm._v("순이익률")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chart1"},[_c('div',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"주식가치미래주당"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chart2"},[_c('div',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"주식가치미래총액"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ex"},[_c('span',{staticClass:"exText"},[_vm._v("투자한 주주들에게 나가야 할 배당금 의무도 무시할 수 없다. 사실 어떤 기업이든 자기 돈만 충분하다면 굳이 엄격한 심사와 자격까지 갖추면서 상장할 이유는 없다.[2] 실제로 2015년 기준 전경련에서 조사한 자료에서는 총 600여개 기업이 코스피 상장 요건을 갖추고 있었지만 단 7개 기업만이 상장을 했다. 그리고 다른 회사에게 자신의 회사를 팔 때도 매우 쉬운데. 상장회사들을 인수 할 때 과반의 주식을 확보하거나 합병까지 하려면 전체 주식을 사서 소각해야 하는데, 이 과정도 매우 복잡해서 전체 주주들에게 동의를 받아야 하고 주식시장을 관리감독하는 기관의 심사까지 받아야 해서 규모가 큰 회사들 끼리 합병시 년단위로 걸린다. 비상장은 경영권을 가진 임원들에게 만족하는 가격만 제시한다면 매우 수월하게 인수가 가능하다.")])])
}]

export { render, staticRenderFns }