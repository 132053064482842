<template>
  <div class="cover">
    <div class="cTit">
      <div class="unicornLogo">unicornranch</div>
    </div>
    <div class="reportTit">
      <div>비상장주식가치평가</div>
      <div style="display: flex; align-items: center; width: 540px; justify-content: flex-end;">
        <div class="round" style="margin-right: 32px;"></div>
        <div class="round" style="margin-right: 33px;"></div>
        <div class="round" style="margin-right: 32px;"></div>
        <div class="round" style="margin-right: 32px;"></div>
        <div class="round" style="margin-right: 33px;"></div>
        <div class="round" style="margin-right: 32px;"></div>
        <div class="round" style="margin-right: 30px;"></div>
        <div>리포트</div>
      </div>
    </div>
    <div style="margin-top: 495px; display: flex; flex-direction: column; align-content: flex-end; flex-wrap: wrap;">
      <div class="compNm">{{입력개요?.회사명}}</div>
      <div style="color: #FFFFFF; margin-right: 72px; font-size: 36px; display: flex; height: 43px; align-items: center; justify-content: center; font-family: 'Pretendard-Regular';">{{date}}년 결산기준</div>
    </div>
    <div style="margin-top: 588px; color: #001736; text-align: center; font-size: 18px; height: 42px; font-family: 'Pretendard-Light';">비상장 주식가치 평가 서비스는 위즈도메인의 기술가치 평가 리포트와<br/>유니콘랜치의 기업평가 AI를 통한 평가 서비스입니다.</div>
    
    <div class="footer">
      Copyright 2021-2023 Unicorn Ranch Co., Ltd. All right reserved
    </div>
  </div>
</template>

<script>
export default {
  props: ['입력개요'],
  layout: 'blank',
  data() {
    return {
      date: null
    }
  },
  mounted () {
    const today = new Date();
    this.date = today.getFullYear();
  },
}
</script>

<style scoped>
  .footer{
    width: 600px;
    left: 340px;
    position: absolute;
    border: solid 1px #001736;
    background-color: #001736;
    height: 60px;
    bottom: 0px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
  }
  .round{
    width: 10px;
    height: 10px;
    background: #21BDC6 0% 0% no-repeat padding-box;
    border-radius: 50%;
  }
  .cTit{
    padding: 32px 0px 23px 30px;
    display: flex;
    justify-content: space-between;
  }
  .cover{
    margin: 0 auto;
    width: 1280px;
    height: 1810px;
    background: url('~static/report/이미지 1.png') no-repeat;
    position: relative;
  }
  .unicornLogo{
    overflow: hidden;
    text-indent: -2000em;
    background: url('~static/report/unicornranch_logo.svg') no-repeat;
    background-size: cover;
    width: 254px;
    height: 26.48px;
  }
  .reportTit{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 66px;
    padding-right: 70px;
    font-size: 60px;
    color: #1FBDC6;
    font-family: 'Pretendard-Bold';
  }
  .img{
    background: url('~static/report/리포트 표지.PNG');
    margin-top: 48.43px;
    width: 1280px;
    height: 483.86px;
  }
  .copyRight{
    margin-top: 50.71px;
    color: #FFFFFF;
    font-size: 13px;
    text-align: center;
    height: 13px;
  }
  .compNm{
    height: 160px;
    margin-right: 72px;
    color: #FFFFFF;
    font-size: 120px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Pretendard-Bold';
  }
</style>