<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>법정의무교육 상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button :class="{ tab: true, on: on == 'tab1' }" @click="() => (this.on = 'tab1')">교육자명단</button>
            </div>
            <div class="btns" v-if="on=='tab1'">
              <button class="btn" @click="fnExcelDownload">엑셀 다운</button>
            </div>
            <div class="btns">
              <button class="btn dark" @click="fnClosePop">닫기</button>
            </div>
          </div>
          <div class="c_contents" v-if="on == 'tab1'" >
            <EducationTab1 :id="id" :bizNo="bizNo" :compNm="compNm" ref="save3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EducationTab1 from './EducationTab1.vue';

export default {
  name: 'CustomerDetail',
  data() {
    return {
      on: 'tab1',
      contents: {}
    };
  },
  props: ['id', 'bizNo', 'compNm'],
  components: {
    EducationTab1,
  },
  methods: {
    fnClosePop() {
      this.$store.dispatch('ui/setPopCsState', false);
      this.$emit('reload');
    },
    fnExcelDownload(){
      window.open(`/apiMngAo/customer/service/education/${this.id}/excel?name=${this.compNm}`)
    }
  },
};
</script>