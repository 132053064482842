<template>
  <div class="pagination">
    <button class="btn_start" @click="fnClick(firstPage)">맨 앞으로</button>
    <button class="btn_prev" @click="fnClick(prevPage)">이전</button>
    <button class="btn_page"
            v-for="p in pageList" :key="p"
            :class="{on:p===page}"
            @click="fnClick(p)"
    >{{p}}</button>
    <button class="btn_next" @click="fnClick(nextPage)">다음</button>
    <button class="btn_end" @click="fnClick(lastPage)">맨 뒤로</button>
  </div>
</template>

<script>
export default {
  props:{
    page:{
      type:Number,
      default: 1,
    },
    perPage:{
      type: Number,
      default: 10,
    },
    totalCount: {
      type: Number,
      default: 0,
    }
  },
  data(){
    return {
      // currentPage: 1,
      firstPage:1,
      pageSize:10,
    }
  },
  computed:{
    lastPage(){
      const lastPage = Math.ceil(this.totalCount / this.perPage);
      return lastPage;
    },
    prevPage(){
      const prevPage = this.fromPage - this.pageSize;
      return prevPage > this.firstPage ? prevPage : this.firstPage;
    },
    fromPage(){
      return (Math.ceil(this.page / this.pageSize)-1)*this.pageSize + 1;
    },
    toPage(){
      const toPage = this.fromPage + this.pageSize - 1;
      return toPage < this.lastPage ? toPage : this.lastPage;
    },
    nextPage(){
      const nextPage = this.toPage + 1;
      return nextPage < this.lastPage ? nextPage : this.lastPage;
    },
    pageList(){
      const pages = [];
      for(let p = this.fromPage; p<=this.toPage; p++){
        pages.push(p);
      }
      return pages;
    }
  },
  methods: {
    fnClick(page){
      // this.currentPage = page;
      this.$emit('change', page);
    }
  },
  watch:{
    page(nv, ov){
      // this.currentPage = nv;
    }
  }
}
</script>
