<template>
  <section class="container">
    <Location />
    <div class="inner">
      <div class="colr">
        <button class="btn_reset" @click="fnResetForm">전체초기화</button>
      </div>
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="bizNo">사업자번호</label>
          <Input id="bizNo" name="query.bizNo" shape="search" placeholder="검색어를 입력하세요." :value.sync="query.bizNo" @enter="fnResetPageAndFetch" />
        </div>
        <div class="unit">
          <label class="tit" for="compNm">회사명</label>
          <Input id="compNm" name="query.compNm" shape="search" placeholder="검색어를 입력하세요." :value.sync="query.compNm" @enter="fnResetPageAndFetch" :disabled="false" />
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <div>
            <button class="btn" @click="fnOpenPop2('popCsState')">열기</button>
            <button class="btn" @click="fnDelete">삭제</button>
          </div>
        </div>
        <div class="col unit_page">
          <h4 class="l_tit">페이지당 개수 :</h4>
          <Select id="perPage" title="20개" :itemList="perPageList" :value.sync="query.perPage" @change="fnFetchList" />
          <button
            class="btn"
            @click="
              () => {
                query.page = 1;
                fnFetchList();
              }
            ">
            검색
          </button>
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <thead>
              <tr>
                <th class="c2" style="width: 10%;"><b>사업자번호</b></th>
                <th class="c2"><b>회사명</b></th>
                <th class="c4" style="width: 20%;"><b>신청자 이메일</b></th>
                <th class="c4" style="width: 10%;"><b>신청자 연락처</b></th>
                <th class="c5" style="width: 10%;">
                  <b>담당자</b>
                </th>
                <th class="c7" style="width: 8%"><b>신청</b></th>
                <th class="c6">
                  <b>신청일</b>
                  <div class="bx_srot">
                    <button class="btn_up" :class="{ on: query.sortReq === null || query.sortReq === 'asc' }" @click="() => fnSortValue({ key: 'sortReq', value: 'asc' })">오름차순정렬</button>
                    <button class="btn_down" :class="{ on: query.sortReq === null || query.sortReq === 'desc' }" @click="() => fnSortValue({ key: 'sortReq', value: 'desc' })">내림차순정렬</button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in contents"
                :key="item.id"
                :class="{ on: item.id === id }"
                @click="fnOnClick({ id: item.id, compNm: item.compNm, bizNo: item.bizNo, consultantGaNm: item.consultantGaNm, consultantNm: item.consultantNm })"
                @dblclick="fnOpenPop2('popCsState')">
                <td class="c2" style="width: 10%;">{{ item.bizNo }}</td>
                <td class="c2">{{ item.compNm }}</td>
                <td class="c4" style="width: 20%;">{{ item.email }}</td>
                <td class="c4" style="width: 1%;">{{ item.tel }}</td>
                <td class="c5">{{ fnConsultant(item.consultantGaNm, item.consultantNm) }}</td>
                <td class="c7" style="width: 8%;">{{ fnItemData(item.crpDtm, item.reqDtm) }}</td>
                <td class="c6">{{ fnCut(item.reqDtm) }}</td>
              </tr>
              <tr v-if="contents && contents.length === 0">
                <td colspan="7">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="query.page"
          :perPage="query.perPage"
          :totalCount="pagination.totalCount"
          @change="
            p => {
              this.query.page = p;
              this.fnFetchList();
            }
          " />
      </div>
      <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
        <LaborDetail
          v-if="$store.state.ui.popCsState == true"
          :id="id"
          :bizNo="bizNo"
          :compNm="compNm"
          :consultantGaNm="consultantGaNm"
          :consultantNm="consultantNm"
          :key="$store.state.ui.popCsState"
          @reload="fnReload" />
      </transition>
    </div>
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper';
import LaborDetail from '~/components/pages/allOkMng/LaborDetail.vue';
import Input from '~/components/common/Input.vue';
import Pagination from '~/components/common/Pagination';
import Select from '~/components/common/Select';

export default {
  components: {
    Location,
    LaborDetail,
    Input,
    Select,
    Pagination,
  },
  data() {
    return {
      roles: ['CPMNG_LABOR'],
      id: '',
      compNm: '',
      bizNo: '',
      consultantGaNm: '',
      consultantNm: '',
      query: {
        page: 1,
        perPage: 20, // 한 페이지에 보여줄 rows의 갯수
        bizNo: null,
        compNm: null,
      },
      contents: [],
      pagination: {
        totalCount: 0,
      },
      perPageList_: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
        { name: '30개', value: 30 },
      ],
    };
  },
  computed: {
    perPageList() {
      return this.perPageList_.map(item => {
        return {
          ...item,
          select: item.value === this.query.perPage ? true : false,
        };
      });
    },
  },
  methods: {
    fnConsultant(consultantGaNm, consultantNm) {
      let result = '';
      if (consultantGaNm) {
        result += `[${consultantGaNm}]`;
        if (consultantNm) {
          result += ` ${consultantNm}`;
        }
      }
      return result;
    },
    fnItemData(crpDtm, regDtm) {
      if (crpDtm && regDtm) {
        return '신청';
      } else if (crpDtm && !regDtm) {
        return '미신청';
      }
    },
    async fnDelete() {
      if (!this.id) return;

      const btn = {
        name: '확인',
        func: async () => {
          this._closeConfirm();
          const result = await this.$axios.delete(`/apiMngAo/customers/service/labor/${this.id}`);
          if (result.data.result) {
            this.$popupAlert({
              title: '확인',
              msg: '삭제되었습니다.',
            });
            this.fnFetchList();
          } else {
            this.$popupAlert({
              title: '경고',
              msg: '실패하였습니다.',
            });
            return;
          }
        },
      };
      this._confirm('확인', '삭제하시겠습니까?', btn);
    },
    fnResetForm() {
      const newQuery = {
        page: 1,
        bizNo: null,
        compNm: null,
      };
      this.query = {
        ...this.query,
        ...newQuery,
      };
      this.fnFetchList();
    },
    fnOnClick(e) {
      if (this.id !== e.id) {
        this.id = e.id;
        this.compNm = e.compNm;
        this.bizNo = e.bizNo;
        this.consultantGaNm = e.consultantGaNm;
        this.consultantNm = e.consultantNm;
      }
    },
    fnOpenPop2(payload) {
      if (this.id) {
        this.$store.dispatch('ui/setPopState', { key: payload, value: true });
      } else {
        this.$popupAlert({
          title: '경고',
          msg: '고객을 선택해 주세요.',
        });
        return;
      }
    },
    fnResetPageAndFetch() {
      this.query.page = 1;
      this.fnFetchList();
    },
    fnFetchList() {
      const params = this.$generateQueryParam(this.query);
      this.$axios.get('/apiMngAo/customer/service/labor?' + params).then(res => {
        if (res.data.result === true) {
          const { contents, pagination } = res.data.data;
          this.contents = contents;
          this.pagination = pagination;
        }
      });
    },
    fnReload() {
      this.fnFetchList();
    },
    fnSortValue({ key, value }) {
      if (this.query[key] !== value) {
        this.query[key] = value;
      } else {
        this.query[key] = null;
      }
      this.fnFetchList();
    },
    fnCut(payload) {
      if (payload) {
        return payload.slice(0, 10) + ' ' + payload.slice(11, 19);
      }
    },
    _confirm(title, msg, btn) {
      let data = {
        state: true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
        closeBtn: {
          name: '취소',
        },
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closeConfirm() {
      this.$store.dispatch('ui/setPopAlertData', {
        state: false,
        type: null,
        title: null,
        msg: null,
        btn: {
          link: '',
          name: null,
        },
      });
    },
  },
  mounted() {
    this.fnFetchList();
  },
};
</script>
