<template>
  <section class="container">
    <Location />
    <div class="inner">
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <thead>
            <tr>
              <th class="c1"><b>GA코드</b></th>
              <th class="c1"><b>GA명</b></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in consultantGaList" :key="i">
              <td class="c1">{{ item.id }}</td>
              <td class="c1">{{ item.name }}</td>
            </tr>
              <tr v-if="consultantGaList.length === 0">
                <td colspan="7">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="pageNum+1"
          :perPage="pageSize"
          :totalCount="pagination.totalCount"
          @change="p => {
            this.pageNum=p-1;
          }"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Header from '~/components/layouts/Header';
import Location from '~/components/layouts/LocationDataWrapper';
import Pagination from "~/components/common/Pagination";
import consultantsService from "~/service/allOk/consultantsService";

export default {
  components: {
    Header,
    Location,
    Pagination,
  },
  data() {
    return {
      consultantGa:[],
      pageSize: 15,
      pageNum: 0,
      pagination: {
        totalCount: 0,
      },
    };
  },
  computed:{
    consultantGaList(){
      const start = this.pageNum*this.pageSize
      const end = start+this.pageSize
      return this.consultantGa.slice(start, end)
    },
  },
  methods: {
    fetchConsultantGaList(){
      consultantsService.fetchConsultantGa(this.$axios)
      .then(res => {
        const {data : {data,result} } = res;
        if(result === true){
          this.consultantGa = data.contents;
          this.pagination.totalCount = data.contents.length;
        } else {
          this.consultantGa = [];
        }
      });
    },
  },
  mounted() {
    this.fetchConsultantGaList();
  },
};
</script>
