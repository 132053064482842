<template>
  <Modal>
    <div slot="header">
      <h2>조직 선택</h2>
    </div>
    <div slot="body">
      <Tree :data="data" @onClick="fnOnClick" />
    </div>
    <div slot="footer">
      <div class="colb">
        <div></div>
        <button class="btn dark" @click="$emit('closePop')">취소</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Tree from '../../pages/org/Tree.vue';
import Modal from './Modal';
export default {
  components: {
    Modal,
    Tree,
  },

  data() {
    return {
      data: [],
    };
  },

  methods: {
    fnOnClick(v) {
      if (v.id == 'ROOT') {
        alert(`${v.name}으로 이동할 수 없습니다`);
        return;
      }
      this.$emit('onSelect', v.id);
      this.$emit('closePop');
    },

    fnMakeTreeData(arrayList, rootId) {
      const arrayForTree = arrayList.map(el => ({
        name: el.orgNm,
        id: el.orgNo,
        pid: el.porgNo,
        orderNo: el.orderNo,
        isLeaf: false,
        dragDisabled: true,
        addTreeNodeDisabled: true,
        addLeafNodeDisabled: true,
        editNodeDisabled: true,
        delNodeDisabled: true,
      }));

      var rootNodes = [];
      var traverse = function (nodes, item, index) {
        if (nodes instanceof Array) {
          return nodes.some(function (node) {
            if (node.id === item.pid) {
              node.children = node.children || [];
              return node.children.push(arrayForTree.splice(index, 1)[0]);
            }

            return traverse(node.children, item, index);
          });
        }
      };

      while (arrayForTree.length > 0) {
        arrayForTree.some(function (item, index) {
          if (item.pid === rootId) {
            return rootNodes.push(arrayForTree.splice(index, 1)[0]);
          }

          return traverse(rootNodes, item, index);
        });
      }

      return rootNodes;
    },
  },

  mounted() {
    this.$axios.$get('http://dev-auth.10bagger.co.kr/org').then(data => {
      this.data = this.fnMakeTreeData(data, null);
    });
  },
};
</script>

<style></style>
