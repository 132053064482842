import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _df47b13e = () => interopDefault(import('../pages/appDownload.vue' /* webpackChunkName: "pages/appDownload" */))
const _8f67481c = () => interopDefault(import('../pages/landing/index.vue' /* webpackChunkName: "pages/landing/index" */))
const _64a81dfe = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _579b328c = () => interopDefault(import('../pages/mobile.vue' /* webpackChunkName: "pages/mobile" */))
const _30f78fd4 = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _7f2a5afc = () => interopDefault(import('../pages/org.vue' /* webpackChunkName: "pages/org" */))
const _6638ade6 = () => interopDefault(import('../pages/org2.vue' /* webpackChunkName: "pages/org2" */))
const _7e40c4bb = () => interopDefault(import('../pages/allOk/customers.vue' /* webpackChunkName: "pages/allOk/customers" */))
const _ac796f28 = () => interopDefault(import('../pages/allOk/manageFC.vue' /* webpackChunkName: "pages/allOk/manageFC" */))
const _a9481bee = () => interopDefault(import('../pages/allOk/manageGA.vue' /* webpackChunkName: "pages/allOk/manageGA" */))
const _d4b8bd92 = () => interopDefault(import('../pages/allOkMng/articleIncorp.vue' /* webpackChunkName: "pages/allOkMng/articleIncorp" */))
const _fbf36f7a = () => interopDefault(import('../pages/allOkMng/diagnosis.vue' /* webpackChunkName: "pages/allOkMng/diagnosis" */))
const _d8a45d0c = () => interopDefault(import('../pages/allOkMng/education.vue' /* webpackChunkName: "pages/allOkMng/education" */))
const _69b7d02c = () => interopDefault(import('../pages/allOkMng/employ.vue' /* webpackChunkName: "pages/allOkMng/employ" */))
const _9733b0a8 = () => interopDefault(import('../pages/allOkMng/insurance.vue' /* webpackChunkName: "pages/allOkMng/insurance" */))
const _c4c5d9fc = () => interopDefault(import('../pages/allOkMng/labor.vue' /* webpackChunkName: "pages/allOkMng/labor" */))
const _082cefe8 = () => interopDefault(import('../pages/allOkMng/mainbiz.vue' /* webpackChunkName: "pages/allOkMng/mainbiz" */))
const _2242f454 = () => interopDefault(import('../pages/allOkMng/reports/index.vue' /* webpackChunkName: "pages/allOkMng/reports/index" */))
const _4de1e37d = () => interopDefault(import('../pages/allOkMng/tax.vue' /* webpackChunkName: "pages/allOkMng/tax" */))
const _48f3ac43 = () => interopDefault(import('../pages/allOkMng/welfareFund.vue' /* webpackChunkName: "pages/allOkMng/welfareFund" */))
const _dada7e8a = () => interopDefault(import('../pages/customer/contract.vue' /* webpackChunkName: "pages/customer/contract" */))
const _b1cebc60 = () => interopDefault(import('../pages/customer/input-form.vue' /* webpackChunkName: "pages/customer/input-form" */))
const _75c2d90c = () => interopDefault(import('../pages/customer/payroll.vue' /* webpackChunkName: "pages/customer/payroll" */))
const _899117c6 = () => interopDefault(import('../pages/customer/report.vue' /* webpackChunkName: "pages/customer/report" */))
const _a8628e36 = () => interopDefault(import('../pages/customer/report2.vue' /* webpackChunkName: "pages/customer/report2" */))
const _44ea85ec = () => interopDefault(import('../pages/mainbiz/login.vue' /* webpackChunkName: "pages/mainbiz/login" */))
const _c47a01c8 = () => interopDefault(import('../pages/tab1example/CheckBox.vue' /* webpackChunkName: "pages/tab1example/CheckBox" */))
const _8d6202f0 = () => interopDefault(import('../pages/tab1example/CustomerDetail.vue' /* webpackChunkName: "pages/tab1example/CustomerDetail" */))
const _0a42d558 = () => interopDefault(import('../pages/tab1example/CustomerPrdcTab1.vue' /* webpackChunkName: "pages/tab1example/CustomerPrdcTab1" */))
const _0a26a656 = () => interopDefault(import('../pages/tab1example/CustomerPrdcTab2.vue' /* webpackChunkName: "pages/tab1example/CustomerPrdcTab2" */))
const _0a0a7754 = () => interopDefault(import('../pages/tab1example/CustomerPrdcTab3.vue' /* webpackChunkName: "pages/tab1example/CustomerPrdcTab3" */))
const _09ee4852 = () => interopDefault(import('../pages/tab1example/CustomerPrdcTab4.vue' /* webpackChunkName: "pages/tab1example/CustomerPrdcTab4" */))
const _34f9e2d6 = () => interopDefault(import('../pages/tab1example/tab1.vue' /* webpackChunkName: "pages/tab1example/tab1" */))
const _4ba563ab = () => interopDefault(import('../pages/ui/loginApp.vue' /* webpackChunkName: "pages/ui/loginApp" */))
const _5cb01b32 = () => interopDefault(import('../pages/ui/loginQR.vue' /* webpackChunkName: "pages/ui/loginQR" */))
const _04044dea = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/appDownload",
    component: _df47b13e,
    name: "appDownload"
  }, {
    path: "/landing",
    component: _8f67481c,
    name: "landing"
  }, {
    path: "/login",
    component: _64a81dfe,
    name: "login"
  }, {
    path: "/mobile",
    component: _579b328c,
    name: "mobile"
  }, {
    path: "/mypage",
    component: _30f78fd4,
    name: "mypage"
  }, {
    path: "/org",
    component: _7f2a5afc,
    name: "org"
  }, {
    path: "/org2",
    component: _6638ade6,
    name: "org2"
  }, {
    path: "/allOk/customers",
    component: _7e40c4bb,
    name: "allOk-customers"
  }, {
    path: "/allOk/manageFC",
    component: _ac796f28,
    name: "allOk-manageFC"
  }, {
    path: "/allOk/manageGA",
    component: _a9481bee,
    name: "allOk-manageGA"
  }, {
    path: "/allOkMng/articleIncorp",
    component: _d4b8bd92,
    name: "allOkMng-articleIncorp"
  }, {
    path: "/allOkMng/diagnosis",
    component: _fbf36f7a,
    name: "allOkMng-diagnosis"
  }, {
    path: "/allOkMng/education",
    component: _d8a45d0c,
    name: "allOkMng-education"
  }, {
    path: "/allOkMng/employ",
    component: _69b7d02c,
    name: "allOkMng-employ"
  }, {
    path: "/allOkMng/insurance",
    component: _9733b0a8,
    name: "allOkMng-insurance"
  }, {
    path: "/allOkMng/labor",
    component: _c4c5d9fc,
    name: "allOkMng-labor"
  }, {
    path: "/allOkMng/mainbiz",
    component: _082cefe8,
    name: "allOkMng-mainbiz"
  }, {
    path: "/allOkMng/reports",
    component: _2242f454,
    name: "allOkMng-reports"
  }, {
    path: "/allOkMng/tax",
    component: _4de1e37d,
    name: "allOkMng-tax"
  }, {
    path: "/allOkMng/welfareFund",
    component: _48f3ac43,
    name: "allOkMng-welfareFund"
  }, {
    path: "/customer/contract",
    component: _dada7e8a,
    name: "customer-contract"
  }, {
    path: "/customer/input-form",
    component: _b1cebc60,
    name: "customer-input-form"
  }, {
    path: "/customer/payroll",
    component: _75c2d90c,
    name: "customer-payroll"
  }, {
    path: "/customer/report",
    component: _899117c6,
    name: "customer-report"
  }, {
    path: "/customer/report2",
    component: _a8628e36,
    name: "customer-report2"
  }, {
    path: "/mainbiz/login",
    component: _44ea85ec,
    name: "mainbiz-login"
  }, {
    path: "/tab1example/CheckBox",
    component: _c47a01c8,
    name: "tab1example-CheckBox"
  }, {
    path: "/tab1example/CustomerDetail",
    component: _8d6202f0,
    name: "tab1example-CustomerDetail"
  }, {
    path: "/tab1example/CustomerPrdcTab1",
    component: _0a42d558,
    name: "tab1example-CustomerPrdcTab1"
  }, {
    path: "/tab1example/CustomerPrdcTab2",
    component: _0a26a656,
    name: "tab1example-CustomerPrdcTab2"
  }, {
    path: "/tab1example/CustomerPrdcTab3",
    component: _0a0a7754,
    name: "tab1example-CustomerPrdcTab3"
  }, {
    path: "/tab1example/CustomerPrdcTab4",
    component: _09ee4852,
    name: "tab1example-CustomerPrdcTab4"
  }, {
    path: "/tab1example/tab1",
    component: _34f9e2d6,
    name: "tab1example-tab1"
  }, {
    path: "/ui/loginApp",
    component: _4ba563ab,
    name: "ui-loginApp"
  }, {
    path: "/ui/loginQR",
    component: _5cb01b32,
    name: "ui-loginQR"
  }, {
    path: "/",
    component: _04044dea,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
