<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>경영진단 상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button :class="{ tab: true, on: tab == 'tab1' }" @click="tab = 'tab1'">입력개요</button>
              <button :class="{ tab: true, on: tab == 'tab2' }" @click="tab = 'tab2'">입력재무, 손익</button>
              <button :class="{ tab: true, on: tab == 'tab3' }" @click="tab = 'tab3'">기본정보</button>
              <!-- <button :class="{ tab: true, on: tab == 'tab4' }" @click="tab = 'tab4'">리포트</button> -->
            </div>
            <div class="btns">
              <button class="btn dark" @click="fnClosePop">닫기</button>
            </div>
          </div>
          <DiagTab1 :bizNo="bizNo" v-if="tab == 'tab1'" />
          <DiagTab2 :bizNo="bizNo" v-if="tab == 'tab2'" />
          <DiagTab3 :bizNo="bizNo" v-if="tab == 'tab3'" />
          <!-- <DiagTab4 :bizNo="bizNo" v-if="tab == 'tab4'" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DiagTab1 from './DiagTab1.vue';
import DiagTab2 from './DiagTab2.vue';
import DiagTab3 from './DiagTab3.vue';
import DiagTab4 from './DiagTab4.vue';

export default {
  components: {
    DiagTab1,
    DiagTab2,
    DiagTab3,
    DiagTab4,
  },
  name: 'DiagDetail',
  props: ['bizNo'],
  data() {
    return {
      tab: 'tab1',
    };
  },
  methods: {
    fnClosePop() {
      this.$store.dispatch('ui/setPopCsState', false);
      this.$emit('reload');
    },
  },
};
</script>
