<template>
  <div class="cover">
    <div class="cTit">
      <div class="unicornLogo">unicornranch</div>
      <div class="reportTit">비상장주식가치평가</div>
    </div>
    <div class="contents">
      <div class="Flist" style="display: flex;">▣<span class="Ftit">가지급금 불이익<br/><span class="titInfo">순자산가치 3과 순손익가치 2의 가중치를 적용하여 비상장주식의 가치를 산정하는 금액</span></span></div>
      <div class="compNm">{{입력개요?.회사명}}</div>
      <div>
        <div class="tit1">1) 가지급금 불이익 내역</div>
        <div class="table1">
          <div class="col1">
            <div class="cell1 backColor" style="border-bottom: 1px solid #21BDC6;">가지급금(주임종단기대여금)추정액</div>
            <div class="cell2" style="color: #EA6F6A; justify-content: flex-end; padding-right: 30px;">{{fnFormatNumber((가지급금불이익.가지급금추정액).toFixed(2))}}</div>
            <div class="cell2 backColor" style="color: #EA6F6A; border-bottom: 1px solid #21BDC6;">인정이자율</div>
            <div class="cell2" style="justify-content: flex-end; padding-right: 30px;">{{fnPercent(가지급금불이익.인정이자율)}}%</div>
          </div>
          <div class="col1">
            <div class="cell1 backColor">차입금(장단기)</div>
            <div class="cell2" style="justify-content: flex-end; padding-right: 30px;">{{fnFormatNumber(가지급금불이익.차입금)}}</div>
            <div class="cell2 backColor">이자비용</div>
            <div class="cell2" style="justify-content: flex-end; padding-right: 30px;">{{fnFormatNumber(가지급금불이익.이자비용)}}</div>
          </div>
          <div class="col2">
            <div class="cell3 backColor2">인정이자(법인)</div>
            <div class="cell4 backColor3">익금산입액</div>
            <div class="cell3" style="justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.인정이자_법인_익금산입액).toFixed(2))}}</div>
            <div class="cell4 backColor3">법인세 증가액</div>
            <div class="cell5">
              <div class="cell5-1" style="border-bottom: 1px solid #001736;">
                <div style="width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">{{fnPercent((가지급금불이익.인정이자_법인_법인세증가액[0].v1).toFixed(2))}}%</div>
                <div style="width: 240px; height: 40px; display: flex; justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.인정이자_법인_법인세증가액[0].v2).toFixed(2))}}</div>
              </div>
              <div class="cell5-1">
                <div style="width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">{{fnPercent((가지급금불이익.인정이자_법인_법인세증가액[1].v1).toFixed(2))}}%</div>
                <div style="width: 240px; height: 40px; display: flex; justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.인정이자_법인_법인세증가액[1].v2).toFixed(2))}}</div>
              </div>
            </div>
          </div>
          <div class="col2">
            <div class="cell3 backColor2">지급이자(법인)</div>
            <div class="cell4 backColor3">손금불산입액</div>
            <div class="cell3" style="justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.지급이자_법인_손금불산입액).toFixed(2))}}</div>
            <div class="cell4 backColor3">법인세 증가액</div>
            <div class="cell5">
              <div class="cell5-1" style="border-bottom: 1px solid #001736;">
                <div style="width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">{{fnPercent(가지급금불이익.지급이자_법인_법인세증가액[0].v1)}}%</div>
                <div style="width: 240px; height: 40px; display: flex; justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.지급이자_법인_법인세증가액[0].v2).toFixed(2))}}</div>
              </div>
              <div class="cell5-1">
                <div style="width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">{{fnPercent(가지급금불이익.지급이자_법인_법인세증가액[1].v1)}}%</div>
                <div style="width: 240px; height: 40px; display: flex; justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.지급이자_법인_법인세증가액[1].v2).toFixed(2))}}</div>
              </div>
            </div>
          </div>
          <div class="col3">
            <div class="cell6 backColor2">인정이자(대표)</div>
            <div class="cell7 backColor3">인정상여액</div>
            <div class="cell6" style="justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.인정이자_대표_인정상여액).toFixed(2))}}</div>
            <div class="cell7 backColor3">소득세 증가액</div>
            <div class="cell8">
              <div class="cell8-1" style="border-bottom: 1px solid #001736;">
                <div style="width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">{{fnPercent(가지급금불이익.인정이자_대표_소득세증가액[0].v1)}}%</div>
                <div style="width: 240px; height: 40px; display: flex; justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.인정이자_대표_소득세증가액[0].v2).toFixed(2))}}</div>
              </div>
              <div class="cell8-1" style="border-bottom: 1px solid #001736;">
                <div style="width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">{{fnPercent(가지급금불이익.인정이자_대표_소득세증가액[1].v1)}}%</div>
                <div style="width: 240px; height: 40px; display: flex; justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.인정이자_대표_소득세증가액[1].v2).toFixed(2))}}</div>
              </div>
              <div class="cell8-1" style="border-bottom: 1px solid #001736;">
                <div style="width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">{{fnPercent(가지급금불이익.인정이자_대표_소득세증가액[2].v1)}}%</div>
                <div style="width: 240px; height: 40px; display: flex; justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.인정이자_대표_소득세증가액[2].v2).toFixed(2))}}</div>
              </div>
              <div class="cell8-1" style="border-bottom: 1px solid #001736;">
                <div style="width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">{{fnPercent(가지급금불이익.인정이자_대표_소득세증가액[3].v1)}}%</div>
                <div style="width: 240px; height: 40px; display: flex; justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.인정이자_대표_소득세증가액[3].v2).toFixed(2))}}</div>
              </div>
              <div class="cell8-1" style="border-bottom: 1px solid #001736;">
                <div style="width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">{{fnPercent(가지급금불이익.인정이자_대표_소득세증가액[4].v1)}}%</div>
                <div style="width: 240px; height: 40px; display: flex; justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.인정이자_대표_소득세증가액[4].v2).toFixed(2))}}</div>
              </div>
              <div class="cell8-1">
                <div style="width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">{{fnPercent(가지급금불이익.인정이자_대표_소득세증가액[5].v1)}}%</div>
                <div style="width: 240px; height: 40px; display: flex; justify-content: flex-end; padding-right: 30px; align-items: center; color: #B50A45;">{{fnFormatNumber((가지급금불이익.인정이자_대표_소득세증가액[5].v2).toFixed(2))}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="addEx">※ 추가 설명</div>
        <div class="ex">
          <span class="exText">
            ㆍ기업가치 평가시 자산으로 인식 → 증여ㆍ상속세 증가<br/>
            ㆍ대손처리 불인정 → 법인세 증가, 대손처리시→업무상 횡령/배임죄<br/>
            ㆍ퇴임, 청산, 지분정리시 → 상여처분 → 소득세 부담액 대폭 증가<br/>
            ㆍ기업신용평가시 감점요인 → 자금조달비용 증가<br/>
            ㆍ세무조사 선정대상 가능성 상승
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['입력개요', '가지급금불이익'],
  methods: {
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fnPercent(num){
      return num*100;
    },
  },
}
</script>

<style scoped>
  @import '~/static/css/report.css';
  .cell1{
    width: 440px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: 'Pretendard-Bold';
  }
  .cell2{
    width: 240px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: 'Pretendard-Bold';
  }
  .cell3{
    width: 240px;
    height: 80px;
    border-bottom: 1px solid #001736;
    display: flex;
    font-size: 13px;
    font-family: 'Pretendard-SemiBold';
  }
  .cell4{
    width: 200px;
    height: 80px;
    border-bottom: 1px solid #001736;
    display: flex;
    font-size: 13px;
    font-family: 'Pretendard-SemiBold';
  }
  .cell5{
    width: 280px;
    height: 80px;
    font-size: 13px;
    font-family: 'Pretendard-SemiBold';
  }
  .cell5-1{
    display: flex;
  }
  .cell6{
    width: 240px;
    height: 240px;
    border-bottom: 1px solid #001736;
    display: flex;
    font-size: 13px;
    font-family: 'Pretendard-SemiBold';
  }
  .cell7{
    width: 200px;
    height: 240px;
    border-bottom: 1px solid #001736;
    display: flex;
    font-size: 13px;
    font-family: 'Pretendard-SemiBold';
  }
  .cell8{
    width: 280px;
    height: 240px;
    border-bottom: 1px solid #001736;
    font-size: 13px;
    font-family: 'Pretendard-SemiBold';
  }
  .cell8-1{
    display: flex;
  }
  .backColor{
    background-color: #001736;
    color: #21BDC6;
    justify-content: center;
  }
  .backColor2{
    background-color: #DBDCDE;
    color: #001736;
    justify-content: center;
    align-items: center;
  }
  .backColor3{
    background-color: #EAEAEB;
    color: #001736;
    justify-content: center;
    align-items: center;
  }
  .col1{
    width: 1160px;
    height: 40px;
    border-bottom: 1px solid #001736;
    font-family: 'Pretendard-Bold';
    font-size: 13px;
    display: flex;
  }
  .col2{
    width: 1160px;
    height: 80px;
    border-bottom: 1px solid #001736;
    display: flex;
  }
  .col3{
    width: 1160px;
    height: 240px;
    display: flex;
  }
  .Ftit{
    display: flex;
    flex-direction: column;
  }
  .tit1{
    margin-top: -660px;
    color: #33455E;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
  }
  .table1{
    margin-top: 20px;
    width: 1160px;
    height: 480px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #001736;
  }
  .titInfo{
    font-size: 10px;
    font-family: 'Pretendard-Light';
  }
  .compNm{
    color: #1FBDC6;
    font-size: 24px;
    font-family: 'Pretendard-SemiBold';
    position: absolute;
    top: 36px;
    right: 30px;
  }
  .footer{
    margin-bottom: 50px;
  }
  .footer::before{
    position: absolute;
    width: 100px;
    bottom: -30px;
    right: 0px;
    content: '18';
    width: 100px;
    border: solid 1px #001736;
    background-color: #001736;
    height: 60px;
    color: #1FBDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .addEx{
    font-size: 18px;
    color: #33455E;
  }
  .ex{
    width: 1160.5px;
    height: 75px;
    border-top: solid 1px #001736;
  }
  .exText{
    display: block;
    margin-top: 15px;
    font-size: 13px;
    color: #33455E;
    font-family: 'Pretendard-Regular';
  }
</style>