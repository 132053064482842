<template>
  <div class="c_contents">
    <section class="container">
      <div class="colb">
        <h4 class="mt10">기준 정보</h4>
        <div class="mb5">
          <button class="btn" @click="fnSaveBtn">년/월 저장</button>
          <button class="btn" :class="{ dis: disablePdfDowload }" @click="fnClickPdfDownload" :disabled="disablePdfDowload">PDF 다운</button>
          <button class="btn" :class="{ dis: disableExcelDowload }" @click="fnClickExcelDownload" :disabled="disableExcelDowload">Excel 다운</button>
        </div>
      </div>
<!--      <div class="area_search mt5">-->
<!--        <div class="unit">-->
<!--          <label class="tit" for="month">기준월</label>-->
<!--          <Select id="month" :itemList="laborFileList" :value="laborFileId" @change="fnSelectLaborFile" />-->
<!--        </div>-->
<!--      </div>-->
      <div v-if="gridProp" style="text-align: center" class="mt15">
<!--        <h2>{{ this.baseDt && this.baseDt.replace(/(\d{4})(\d{2})(\d{2})/g, '$1년 $2월') }} 급여대장</h2>-->
        <h2>
          <input type="text" id="baseDtYear" v-model="baseDtYear" ref="baseDtYear" style="width: 100px; text-align: center" >&nbsp;년&nbsp;&nbsp;
          <input type="text" id="baseDtMonth" v-model="baseDtMonth" ref="baseDtMonth" style="width: 50px; text-align: center">&nbsp;월&nbsp;
          급여대장
        </h2>
      </div>
      <div class="colb mt10 pb30">
        <div></div>
        <grid v-if="gridProp" :data="gridProp.data" :columns="gridProp.columns" :options="gridProp.options" ref="grid" :key="gridKey" />
        <div></div>
      </div>
      <!-- <div v-if="gridProp2" style="text-align: center" class="mt30">
        <h2>4대보험료 절감검토 결과[연간]</h2>
      </div>
      <div class="colb mt10 pb20">
        <div></div>
        <grid v-if="gridProp2" :data="gridProp2.data" :columns="gridProp2.columns" :options="gridProp2.options" ref="grid2" :key="grid2Key" />
        <div></div>
      </div> -->
    </section>
  </div>
</template>

<script>
import InputFile from '../../common/InputFile.vue';
import Select from '../../common/Select.vue';
import laborService from '~/service/allOkMng/laborService';
import { CustomColumnHeader1 } from '~/util/TuiGridCustomClass.js';
export default {
  props: ['id'],
  components: {
    InputFile,
    Select,
  },

  data() {
    return {
      laborFileList: [],
      baseDt: null,
      baseDtYear: null,
      baseDtMonth: null,
      laborFileId: null,
      compNm: null,
      customAllowance: {},
      payrollEmp: {},
      gridProp: null,
      gridProp2: null,
      gridKey: Math.random(),
      grid2Key: Math.random(),
      disableExcelDowload: false,
      disablePdfDowload: false,
    };
  },

  methods: {
    fnSaveBtn(){
      //
      let validMsg;
      if ( !this.baseDtYear || this.baseDtYear.length<1 ) validMsg = "'년도'를 입력해주세요";
      else if ( !this.baseDtMonth || this.baseDtMonth.length<1 ) validMsg = "'월'을 입력해주세요";
      if ( validMsg ) {
        alert(validMsg);
        return false;
      }
      //
      const payload = {
        payrollDt: `${this.baseDtYear}-${this.baseDtMonth}`
      }
      //
      try{
        laborService.putLaborFile(this.$axios, this.id, this.laborFileId, payload )
          .then( res => {
            if ( res.result ) {
              alert('저장이 완료되었습니다.');
            } else {
              alert('저장 중 오류가 발생했습니다.');
            }
          })
          .catch( err => console.log(err) );
      }
      catch ( err ){
        console.log(err)
      }
    },
    fnSetLaborFileList() {
      return laborService.getLaborFileList(this.$axios, this.id).then(({ result, data }) => {
        if (result) {
          this.laborFileList = data.contents.map(el => ({
            //name: el.baseDt.replace(/(\d{4})(\d{2})(\d{2})/g, '$1년 $2월'),
            name: el.payrollDt,
            value: el.laborFileId,
            compNm: el.compNm,
            customAllowance: this._parseCustomAllowanceName(el.uploadData),
          }));
          return this.laborFileList.length > 0 ? this.laborFileList[0] : Promise.reject('파일이 없습니다');
        } else {
          return Promise.reject(data.error.msg);
        }
      });
    },
    fnSelectLaborFile(payload) {
      const payrollDt = payload.name;
      if ( payrollDt && payrollDt.includes("-") ) {
        const _dt = payrollDt.split("-");
        this.baseDtYear = _dt[0];
        this.baseDtMonth = _dt[1];
        this.baseDt = `${this.baseDtYear}년 ${this.baseDtMonth}월`
      }
      this.laborFileId = payload.value;
      this.compNm = payload.compNm;
      this.customAllowance = payload.customAllowance;
      return laborService.getLaborFilePayrollEmp(this.$axios, this.id, this.laborFileId).then(({ result, data }) => {
        if (result) {
          this.payrollEmp = data.contents;
          this.fnSettingGridProp();
          // this.fnSettingGridProp2();
        } else {
          return Promise.reject(data.error.msg);
        }
      });
    },
    fnSettingGridProp() {
      const columns = [
        {
          header: '<div style="font-weight: bold; font-size:11px">입사일</div>',
          name: 'c1',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">부서</div>',
          name: 'c2',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">직급</div>',
          name: 'c3',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">식대</div>',
          name: 'c4',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">차량유지비</div>',
          name: 'c5',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">당직근무수당</div>',
          name: 'c6',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">보육수당</div>',
          name: 'c7',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">연구개발비</div>',
          name: 'c8',
          width: 100,
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name1)}</div>`,
          name: 'c9',
          width: 100,
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name3)}</div>`,
          name: 'c9_1',
          width: 100,
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name5)}</div>`,
          name: 'c9_2',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">근태공제</div>',
          name: 'c10',
          width: 100,
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name7)}</div>`,
          name: 'c10_1',
          width: 100,
          align: 'center',
        },
        {
          header: `<div style="font-weight: bold; font-size:11px">${this.fnValue(this.customAllowance.name9)}</div>`,
          name: 'c10_2',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">급여총액</div>',
          name: 'c11',
          width: 120,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">과세신고금액</div>',
          name: 'c12',
          width: 120,
          align: 'center',
        },
      ];

      const complexColumns = [
        {
          header: `<div style="font-weight: bold; font-size: 11px">사원번호</div>`,
          name: 'x1',
          childNames: ['c1'],
          renderer: CustomColumnHeader1,
        },
        {
          header: '',
          name: 'x2',
          childNames: ['c2'],
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">성명</div>`,
          name: 'x3',
          childNames: ['c3'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">기본급</div>`,
          name: 'x4',
          childNames: ['c4'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">연장근무수당</div>`,
          name: 'x5',
          childNames: ['c5'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">휴일근무수당</div>`,
          name: 'x6',
          childNames: ['c6'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">휴일연장근무수당</div>`,
          name: 'x7',
          childNames: ['c7'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">야간근무수당</div>`,
          name: 'x8',
          childNames: ['c8'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">고정상여금</div>`,
          name: 'x9',
          childNames: ['c9'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">${this.fnValue(this.customAllowance.name2)}</div>`,
          name: 'x9_1',
          childNames: ['c9_1'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">${this.fnValue(this.customAllowance.name4)}</div>`,
          name: 'x9_2',
          childNames: ['c9_2'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">추가연장수당</div>`,
          name: 'x10',
          childNames: ['c10'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">${this.fnValue(this.customAllowance.name6)}</div>`,
          name: 'x10_1',
          childNames: ['c10_1'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">${this.fnValue(this.customAllowance.name8)}</div>`,
          name: 'x10_2',
          childNames: ['c10_2'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 15px">${this.compNm}</div>`,
          name: 'z1',
          childNames: ['x1', 'x2', 'x3'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 10px">일할계산 O</div>`,
          name: 'z2',
          childNames: ['x4', 'x5', 'x6', 'x7', 'x8'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 10px">일할계산 X</div>`,
          name: 'z3',
          childNames: ['x9'],
          renderer: CustomColumnHeader1,
        },
        {
          header: '',
          name: 'z4',
          childNames: ['x9_1', 'x9_2', 'x10', 'x10_1', 'x10_2', 'c11', 'c12'],
          renderer: CustomColumnHeader1,
        },
      ];

      const columnRenderer = columns.map(el => ({
        name: el.name,
        renderer: CustomColumnHeader1,
      }));

      const data = this.payrollEmp.empPayrollList.reduce((arr, el, idx) => {
        arr.push({
          c1: el.empNo,
          c3: el.name,
          c4: el.basicPay,
          c5: el.extendedWorkPay,
          c6: el.holidayWorkPay,
          c7: el.holidayOverWorkPay,
          c8: el.nightShiftPay,
          c9: el.fixedBonus,
          c9_1: el.customAllowance2,
          c9_2: el.customAllowance4,
          c10: el.extraOvertimePay,
          c10_1: el.customAllowance6,
          c10_2: el.customAllowance8,
          c11: el.payAmount,
          c12: el.taxDeclareAmount,
          _attributes: {
            rowSpan: {
              c11: 2,
              c12: 2,
            },
            ...(idx % 2 == 1 ? { className: { row: ['back-green'] } } : {}),
          },
        });
        arr.push({
          c1: el.empStartDt,
          c2: el.dept,
          c3: el.rank,
          c4: el.meals,
          c5: el.carMaintenanceCost,
          c6: el.dutyPay,
          c7: el.childCareAllowance,
          c8: el.rndAllowance,
          c9: el.annualAllowance,
          c9_1: el.customAllowance3,
          c9_2: el.customAllowance5,
          c10: el.attendanceDeduction,
          c10_1: el.customAllowance7,
          c10_2: el.customAllowance9,
          _attributes: {
            ...(idx % 2 == 1 ? { className: { row: ['back-green'] } } : {}),
          },
        });
        return arr;
      }, []);

      const columnContent = {
        c3: `<div style="text-align: center;font-weight: bold;font-size: 14px;">인원 : 총 ${this.fnValue(this.payrollEmp.empCntTot)}명</div>`,
        c4: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.basicPayTot)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnValue(
          this.payrollEmp.mealsTot,
        )}</div>`,
        c5: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.extendedWorkPayTot)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnValue(
          this.payrollEmp.carMaintenanceCostTot,
        )}</div>`,
        c6: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.holidayWorkPayTot)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnValue(
          this.payrollEmp.dutyPayTot,
        )}</div>`,
        c7: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.holidayOverWorkPayTot)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnValue(
          this.payrollEmp.childCareAllowanceTot,
        )}</div>`,
        c8: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.nightShiftPayTot)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnValue(
          this.payrollEmp.rndAllowanceTot,
        )}</div>`,
        c9: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.fixedBonusTot)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnValue(
          this.payrollEmp.annualAllowanceTot,
        )}</div>`,
        c9_1: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.customAllowance2Tot)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnValue(
          this.payrollEmp.customAllowance3Tot,
        )}</div>`,
        c9_2: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.customAllowance4Tot)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnValue(
          this.payrollEmp.customAllowance5Tot,
        )}</div>`,
        c10: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.extraOvertimePayTot)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnValue(
          this.payrollEmp.attendanceDeductionTot,
        )}</div>`,
        c10_1: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.customAllowance6Tot)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnValue(this.payrollEmp.customAllowance7Tot)}</div>`,
        c10_2: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.customAllowance8Tot)}<hr style="background:#EEEEEE;height:1px;border:0" />${this.fnValue(this.payrollEmp.customAllowance9Tot)}</div>`,
        c11: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.payAmountTot)}</div>`,
        c12: `<div style="text-align: center;font-weight: bold;">${this.fnValue(this.payrollEmp.taxDeclareAmountTot)}</div>`,
      };

      const options = {
        width: 1550,
        scrollX: true,
        scrollY: false,
        minRowHeight: 30,
        rowHeight: 30,
        minBodyHeight: 0,
        header: {
          height: 120,
          columns: columnRenderer,
          complexColumns,
        },
        summary: {
          height: 80,
          position: 'bottom', // or 'top'
          columnContent,
        },
      };

      if (data.length > 15) {
        options.scrollY = true;
        options.bodyHeight = 450;
      }

      this.gridProp = {
        data: data,
        columns: columns,
        options: options,
      };
      this.gridKey++;
    },
    fnSettingGridProp2() {
      const columns = [
        {
          header: '<div style="font-weight: bold; font-size:11px">입사일</div>',
          name: 'c1',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">부서</div>',
          name: 'c2',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">직급</div>',
          name: 'c3',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:13px">비과세<br/>증가금액</div>',
          name: 'c4',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:13px">기존<br/>과세신고<br/>금액</div>',
          name: 'c5',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">회사(4.5%)</div>',
          name: 'c6',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">근로자(4.5%)</div>',
          name: 'c7',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">회사(3.545%)</div>',
          name: 'c8',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">근로자(3.545%)</div>',
          name: 'c9',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">회사(50%)</div>',
          name: 'c10',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">근로자(50%)</div>',
          name: 'c11',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">회사(0.9%)</div>',
          name: 'c12',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">근로자(0.9%)</div>',
          name: 'c13',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">회사(1%)</div>',
          name: 'c14',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:11px">근로자(0%)</div>',
          name: 'c15',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:13px; color:red">회사</div>',
          name: 'c16',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:13px; color:red">근로자</div>',
          name: 'c17',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:13px; color:red">TOTAL</div>',
          name: 'c18',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:13px;">기존소득세</div>',
          name: 'c19',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:13px;">변경소득세</div>',
          name: 'c20',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:13px;">절감소득세</div>',
          name: 'c21',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:13px;">기존지방세</div>',
          name: 'c22',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:13px;">변경지방세</div>',
          name: 'c23',
          width: 100,
          align: 'center',
        },
        {
          header: '<div style="font-weight: bold; font-size:13px;">절감지방세</div>',
          name: 'c24',
          width: 100,
          align: 'center',
        },
      ];

      const complexColumns = [
        {
          header: `<div style="font-weight: bold; font-size: 11px">사원번호</div>`,
          name: 'x1',
          childNames: ['c1'],
          renderer: CustomColumnHeader1,
        },
        {
          header: '',
          name: 'x2',
          childNames: ['c2'],
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">성명</div>`,
          name: 'x3',
          childNames: ['c3'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">국민연금 보험료 (9%)<br/><span style="color:red">상한액 590만원</span></div>`,
          name: 'x4',
          childNames: ['c6', 'c7'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">건강보험료 (7.09%)</div>`,
          name: 'x5',
          childNames: ['c8', 'c9'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">장기요양보험료 (0.9%)<br/>(건강보험료의 12.81%)</div>`,
          name: 'x6',
          childNames: ['c10', 'c11'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">고용보험료 (1.8%)</div>`,
          name: 'x7',
          childNames: ['c12', 'c13'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 11px">산재보험료 (평균 1%)</div>`,
          name: 'x8',
          childNames: ['c14', 'c15'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 13px; color: red">4대보험료 연간 절감 총액</div>`,
          name: 'x9',
          childNames: ['c16', 'c17', 'c18'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 13px;">소득세 절감 총액</div>`,
          name: 'x10',
          childNames: ['c19', 'c20', 'c21'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 13px;">지방세 절감 총액</div>`,
          name: 'x11',
          childNames: ['c22', 'c23', 'c24'],
          renderer: CustomColumnHeader1,
        },
        {
          header: `<div style="font-weight: bold; font-size: 15px">${this.compNm}</div>`,
          name: 'z1',
          childNames: ['x1', 'x2', 'x3'],
          renderer: CustomColumnHeader1,
        },
        {
          header: '',
          name: 'z2',
          childNames: ['c4', 'c5', 'x4', 'x5', 'x6', 'x7', 'x8', 'x9', 'x10', 'x11'],
        },
      ];

      const columnRenderer = columns.map(el => ({
        name: el.name,
        renderer: CustomColumnHeader1,
      }));

      const data = this.payrollEmp.empPayrollList.reduce((arr, el, idx) => {
        arr.push({
          c1: el.empNo,
          c3: el.name,
          c4: el.tfIncreaseAmount,
          c5: el.asTaxDeclareAmount,
          c6: el.npsPremCorp,
          c7: el.npsPremEmp,
          c8: el.nhisPremCorp,
          c9: el.nhisPremEmp,
          c10: el.ltcPremCorp,
          c11: el.ltcPremEmp,
          c12: el.eiPremCorp,
          c13: el.eiPremEmp,
          c14: el.iaiPremCorp,
          c15: el.iaiPremEmp,
          c16: el.insurePremCorp,
          c17: el.insurePremEmp,
          c18: el.insurePremTot,
          c19: el.orgIncomeTax,
          c20: el.chgIncomeTax,
          c21: el.rdcIncomeTax,
          c22: el.orgLocalTax,
          c23: el.chgLocalTax,
          c24: el.rdcLocalTax,
          _attributes: {
            rowSpan: {
              c4: 2,
              c5: 2,
              c6: 2,
              c7: 2,
              c8: 2,
              c9: 2,
              c10: 2,
              c11: 2,
              c12: 2,
              c13: 2,
              c14: 2,
              c15: 2,
              c16: 2,
              c17: 2,
              c18: 2,
              c19: 2,
              c20: 2,
              c21: 2,
              c22: 2,
              c23: 2,
              c24: 2,
            },
            ...(idx % 2 == 1 ? { className: { row: ['back-green'] } } : {}),
          },
        });
        arr.push({
          c1: el.empStartDt,
          c2: el.dept,
          c3: el.rank,
          _attributes: {
            ...(idx % 2 == 1 ? { className: { row: ['back-green'] } } : {}),
          },
        });
        return arr;
      }, []);

      const columnContent = {
        c4: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.tfIncreaseAmountTot}</div>`,
        c5: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.asTaxDeclareAmountTot}</div>`,
        c6: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.npsPremCorpTot}</div>`,
        c7: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.npsPremEmpTot}</div>`,
        c8: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.nhisPremCorpTot}</div>`,
        c9: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.nhisPremEmpTot}</div>`,
        c10: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.ltcPremCorpTot}</div>`,
        c11: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.ltcPremEmpTot}</div>`,
        c12: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.eiPremCorpTot}</div>`,
        c13: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.eiPremEmpTot}</div>`,
        c14: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.iaiPremCorpTot}</div>`,
        c15: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.iaiPremEmpTot}</div>`,
        c16: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.insurePremCorpTot}</div>`,
        c17: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.insurePremEmpTot}</div>`,
        c18: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.insurePremTotTot}</div>`,
        c19: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.orgIncomeTaxTot}</div>`,
        c20: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.chgIncomeTaxTot}</div>`,
        c21: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.rdcIncomeTaxTot}</div>`,
        c22: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.orgLocalTaxTot}</div>`,
        c23: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.chgLocalTaxTot}</div>`,
        c24: `<div style="text-align: center;font-weight: bold;">${this.payrollEmp.rdcLocalTaxTot}</div>`,
      };

      const options = {
        width: 1260,
        scrollX: true,
        scrollY: false,
        minRowHeight: 30,
        rowHeight: 30,
        minBodyHeight: 0,
        header: {
          height: 120,
          columns: columnRenderer,
          complexColumns,
        },
        columnOptions: {
          frozenCount: 3,
          frozenBorderWidth: 2,
        },
        summary: {
          height: 80,
          position: 'bottom', // or 'top'
          columnContent,
        },
      };

      if (data.length > 14) {
        options.scrollY = true;
        options.bodyHeight = 450;
      }

      this.gridProp2 = {
        data: data,
        columns: columns,
        options: options,
      };
      this.grid2Key++;
    },
    fnClickExcelDownload() {
      if (!this.laborFileId) return;

      location.href = `/apiMngAo/customer/service/labor/${this.id}/file/${this.laborFileId}/payroll/emp/excel`;
      this.disableExcelDowload = true;
      setTimeout(() => {
        this.disableExcelDowload = false;
      }, 5000);
    },
    fnClickPdfDownload() {
      if (!this.laborFileId) return;

      location.href = `/apiMngAo/customer/service/labor/${this.id}/file/${this.laborFileId}/payroll/emp/pdf?baseDt=${this.baseDt}&compNm=${this.compNm}&customAllowance=${JSON.stringify(this.customAllowance)}`;
      this.disablePdfDowload = true;
      setTimeout(() => {
        this.disablePdfDowload = false;
      }, 5000);
    },
    fnResizeGrids() {
      this.$refs.grid && this.$refs.grid.invoke('refreshLayout');
      this.$refs.grid2 && this.$refs.grid2.invoke('refreshLayout');
    },
    _alert(title, msg) {
      let data = {
        state: true,
        type: 'alert',
        title: title,
        msg: msg,
        btn: {
          name: '확인',
        },
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _parseCustomAllowanceName(uploadData) {
      const data = JSON.parse(uploadData);
      return {
        name1: data.Title?.AB,
        name2: data.Title?.AC,
        name3: data.Title?.AD,
        name4: data.Title?.AE,
        name5: data.Title?.AF,
        name6: data.Title?.AG,
        name7: data.Title?.AH,
        name8: data.Title?.AI,
        name9: data.Title?.AJ,
      };
    },
    fnValue(v) {
      return (v = null || v == undefined ? '' : v);
    },
  },

  watch: {
    '$store.state.ui.menuState'() {
      setTimeout(() => {
        this.fnResizeGrids();
      }, 500);
    },
  },

  mounted() {
    this.fnSetLaborFileList()
      .then(this.fnSelectLaborFile)
      .catch(err => {
        console.error(err);
      });
  },
};
</script>

<style>
.tui-grid-cell.color5 {
  background-color: #ebf1de;
}
.tui-grid-cell.tui-grid-cell-summary {
  padding: 0;
}
</style>
