<template>
  <div class="cover">
    <div class="cTit">
      <div class="unicornLogo">unicornranch</div>
      <div class="reportTit">비상장주식가치평가</div>
    </div>
    <div class="contents">
      <div class="Flist" style="display: flex;">15.<span class="Ftit">주식가치전망<br/><span class="titInfo">순자산가치 3과 순손익가치 2의 가중치를 적용하여 비상장주식</span></span></div>
      <div class="compNm">{{입력개요?.회사명}}</div>
      <div>
        <div class="tit1">※ 가정</div>
        <div class="table1">
          <div class="tContents">
            <div class="fCont bold"><span style="display: flex; justify-content: center;">제목</span></div>
            <div class="sCont bold">내용</div>
          </div>
          <div class="tContents divide">
            <div class="fCont semibold"><span style="margin-left: 30px;">매출액증가율</span></div>
            <div class="sCont semibold">{{fnPercent(주식가치미래주당?.매출액증가율)}}%</div>
          </div>
          <div class="tContents divide borderBottom">
            <div class="fCont semibold"><span style="margin-left: 30px;">순이익률</span></div>
            <div class="sCont semibold">{{fnPercent(주식가치미래주당?.순이익률)}}%</div>
          </div>
        </div>
        <div class="tit2">A. 주식가치평가액 전망</div>
        <div class="chart1">
          <div id="주식가치미래주당" style="width:100%; height:100%;"></div>
        </div>
        <div class="tit3">B. 비상장주식가치평가금액 전망</div>
        <div class="chart2">
          <div id="주식가치미래총액" style="width:100%; height:100%;"></div>
        </div>
      </div>
      <div class="addEx">※ 추가 설명</div>
      <div class="ex"><span class="exText">투자한 주주들에게 나가야 할 배당금 의무도 무시할 수 없다. 사실 어떤 기업이든 자기 돈만 충분하다면 굳이 엄격한 심사와 자격까지 갖추면서 상장할 이유는 없다.[2] 실제로 2015년 기준 전경련에서 조사한 자료에서는 총 600여개 기업이 코스피 상장 요건을 갖추고 있었지만 단 7개 기업만이 상장을 했다. 그리고 다른 회사에게 자신의 회사를 팔 때도 매우 쉬운데. 상장회사들을 인수 할 때 과반의 주식을 확보하거나 합병까지 하려면 전체 주식을 사서 소각해야 하는데, 이 과정도 매우 복잡해서 전체 주주들에게 동의를 받아야 하고 주식시장을 관리감독하는 기관의 심사까지 받아야 해서 규모가 큰 회사들 끼리 합병시 년단위로 걸린다. 비상장은 경영권을 가진 임원들에게 만족하는 가격만 제시한다면 매우 수월하게 인수가 가능하다.</span></div>
    </div>
    <div class="footer">
      15
    </div>
  </div>
</template>
<script>
export default {
  props: ['입력개요', '주식가치미래주당', '주식가치미래총액'],
  methods: {
    fnFormatNumber(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fnPercent(num){
      return num*100;
    },
    fnDrawChart(){
      const Chart = toastui.Chart;
      Chart.columnLineChart({
        el: document.getElementById('주식가치미래주당'),
        data: {
          categories: this.주식가치미래주당.주식가치미래주당.map(el => el.연도 + '년'),
          series: {
            column:[
              {
                name: `1주당 주식가치`,
                data: this.주식가치미래주당.주식가치미래주당.map(el => el.주식가치_1주당),
              },
            ],
            line: [
              {
                name: '선형(1주당 주식가치)',
                data: this.주식가치미래주당.주식가치미래주당.map(el => el.주식가치_1주당),
              },
            ],
          },
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
          },
          yAxis: {
            label: {
              formatter: value => (value == 0 ? '-' : this.fnFormatNumber((Number(value)).toFixed(1))),
            },
          },
          plot:{
            visible: false,
          },
          theme: {
            chart:{
              backgroundColor:'#EEEEEE',
            },
            series: {
              barWidth: 40,
              colors: ['#2D415A', '#B50A45'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            yAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'top',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            column:{
              dataLabels: {
                visible: true,
                formatter: (value, tooltipDataInfo) => this.fnFormatNumber((Number(value)).toFixed(1) + '억원')
              }
            }
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber((Number(value)).toFixed(1) + '억원'),
          },
        },
      });
      
      Chart.columnLineChart({
        el: document.getElementById('주식가치미래총액'),
        data: {
          categories: this.주식가치미래총액.주식가치미래총액.map(el => el.연도 + '년'),
          series: {
            column:[
              {
                name: `비상장주식가치평가금액`,
                data: this.주식가치미래총액.주식가치미래총액.map(el => el.비상장주식가치평가금액),
              },
            ],
            line: [
              {
                name: '선형(비상장주식가치평가금액)',
                data: this.주식가치미래총액.주식가치미래총액.map(el => el.비상장주식가치평가금액),
              },
            ],
          },
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
          },
          yAxis: {
            label: {
              formatter: value => (value == 0 ? '-' : this.fnFormatNumber((Number(value)).toFixed(1)) + '억원'),
            },
          },
          plot:{
            visible: false,
          },
          theme: {
            chart:{
              backgroundColor:'#EEEEEE',
            },
            series: {
              barWidth: 40,
              colors: ['#21BDC6', '#B50A45'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            yAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'top',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            column:{
              dataLabels: {
                visible: true,
                formatter: (value, tooltipDataInfo) => this.fnFormatNumber((Number(value)).toFixed(1) + '억원')
              }
            }
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber((Number(value)).toFixed(1) + '억원'),
          },
        },
      });
      
    }
  },
  mounted () {
    this.fnDrawChart();
  },
}
</script>

<style scoped>
  @import '~/static/css/report.css';
  .footer{
    width: 100px;
    left: 30px;
    position: absolute;
    border: solid 1px #001736;
    background-color: #001736;
    height: 60px;
    bottom: 0px;
    color: #1FBDC6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .Ftit{
    display: flex;
    flex-direction: column;
  }
  .titInfo{
    font-size: 10px;
    font-family: 'Pretendard-Light';
  }
  .compNm{
    color: #1FBDC6;
    font-size: 24px;
    font-family: 'Pretendard-SemiBold';
    position: absolute;
    top: 36px;
    right: 30px;
  }
  .tit1{
    color: #33455E;
    position: absolute;
    top: 130px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
  }
  .table1{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 171px;
    left: 30px;
  }
  .tContents{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid #001736 1px;
    width: 1160px;
    height: 40px;
    font-family: 'Pretendard-Bold';
    font-size: 13px;
  }
  .divide{
    background: linear-gradient(to right, transparent 840px, #DBDCDE 840px);
  }
  .borderBottom{
    border-bottom: solid #001736 1px;
  }
  .fCont{
    width: 300px;
  }
  .sCont{
    margin-right: 30px;
  }
  .tit2{
    position: absolute;
    top: 352px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .chart1{
    position: absolute;
    top: 393px;
    left: 30px;
    width: 1160px;
    height: 495px;
    border-top: solid 1px #001736;
    border-bottom: solid 1px #001736;
  }
  .tit3{
    position: absolute;
    top: 918px;
    left: 30px;
    font-size: 18px;
    font-family: 'Pretendard-SemiBold';
    color: #33455E;
  }
  .chart2{
    position: absolute;
    top: 959px;
    left: 30px;
    width: 1160px;
    height: 495px;
    border-top: solid 1px #001736;
    border-bottom: solid 1px #001736;
  }
  .addEx{
    font-size: 18px;
    color: #33455E;
    position: absolute;
    left: 30px;
    bottom: 195px;
  }
  .ex{
    position: absolute;
    width: 1160.5px;
    height: 75px;
    border-top: solid 1px #001736;
    left: 30px;
    bottom: 100px;
  }
  .exText{
    display: block;
    margin-top: 15px;
    font-size: 13px;
    color: #33455E;
    font-family: 'Pretendard-Regular';
  }
</style>