<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>경정청구 상세 : {{compNm}}</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button :class="{ tab: true, on: tab == 'tab1' }"
                      @click="tab = 'tab1'">고객상세</button>
              <button :class="{ tab: true, on: tab == 'tab6' }"
                      v-if="$menuRoleCheck([mixMenuRoles.경정청구_진단결과_조회])"
                      @click="tab = 'tab6'">간편계산결과</button>
            </div>
            <div class="btns">
              <button class="btn dark" @click="fnClosePop">닫기</button>
            </div>
          </div>
          <BizInfoTab :id='id' :bizNo="bizNo" v-if="tab=='tab1'"/>
          <TaxTab6 :id="id" :bizNo="bizNo" :compNm="compNm" v-if="tab=='tab6'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaxTab6 from './TaxTab6.vue';
import BizInfoTab from "./BizInfoTab";
import rolesMixin from "../../../mixins/rolesMixin";

export default {
  mixins:[rolesMixin],
  components: {
    BizInfoTab,
    TaxTab6,
  },
  name: 'TaxDetail',
  props: ['id', 'compNm', 'bizNo', 'taxations', 'compObj', 'consultantGaNm', 'consultantNm'],
  data() {
    return {
      tab: 'tab1',
      // managerTab:{
      //   salesCompanyType:[mixCompanyTypes.ADMIN, this.mixCompanyTypes.GA],
      //   managerCompanyType:[this.mixCompanyTypes.TAX]
      // },
      submitTab:{
        path:'tax',
        fileTypes:[
          {
            fileType:'incomeList',
            title:'소득자별근로소득',
          },
          {
            fileType:'adjustList',
            title:'세무조정계산서',
          }
        ],
        fileMap:{
          incomeList:null,
          adjustList:null,
        }
      }
    };
  },
  computed:{
    managerTab(){
      return {
        salesCompanyType:[this.mixCompanyTypes.ADMIN, this.mixCompanyTypes.GA],
        managerCompanyType:[this.mixCompanyTypes.TAX]
      }
    },
  },
  methods: {
    fnClosePop() {
      this.$store.dispatch('ui/setPopCsState', false);
      this.$emit('reload');
    },
  },
};
</script>
