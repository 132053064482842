<template>
  <div class="login qr">
    <div class="inner">
      <h1 class="logo">유니콘랜치</h1>
      <div class="form ">
        <div class="item">
          <label for="id">아이디</label>
          <InputBox id="id" ref="id"
                 name="email"
                 :value.sync="email"
                 placeholder="이메일형식의 아이디를 입력해 주세요."
                 :tabindex="1"
                 @enter="fnLogin"/>
        </div>
        <div class="item">
          <div class="colb">
            <div class="left">
              <label for="pw">비밀번호</label>
            </div>
          </div>
          <InputBox id="pw"
                 ref="pw"
                 type="password"
                 name="password"
                 :value.sync="password"
                 placeholder='비밀번호를 입력해 주세요.'
                 :tabindex="2"
                 @enter="fnLogin"/>
        </div>
        <div class="btns">
          <button class="btn full">로그인</button>
        </div>
      </div>
    </div>
    <div class="bx qr">
      <span class="txt_gray"><b>모바일에서도</b> 쉽고 간편하게</span> <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/backoffice/img/QR.svg" alt="qr코드">
    </div>
  </div>
</template>

<script>
import Header from "~/components/layouts/Header";
import Location from "~/components/layouts/Location";
import CheckBox from "~/components/common/CheckBox";
import InputBox from "~/components/common/Input";

export default {
  layout: "none",
  components: {
    Header,
    Location,
    CheckBox,
    InputBox
  },
  data: () => ({
    email: '',
    password: '',
  }),
  mounted(){
    if(this.$store.state.ui.popCsState==true){
      this.$store.dispatch('ui/setPopCsState', false);
    }
  },
  methods: {
    async fnLogin() {
      if(this.email === '' ){
        this.$popupAlert({
          title: '경고 : 아이디',
          msg:'아이디를 입력해주세요'
        });
      }
      else if( this.password === ''){
        this.$popupAlert({
          title: '경고 : 비밀번호',
          msg:'비밀번호를 입력해주세요.'
        });
      }
      else {
        const data = await this.$store.dispatch('login', {
          user_id: this.email,
          user_pw: this.password,
        });
        if (data.result) {
           if(this.$store.state.ui.popCsState==true){
            this.$store.dispatch('ui/setPopCsState', false);
          }
          await this.$router.push('/allOk/customers');
        } else {
          let errorMessage = '';
          switch (data.data.error.cd){
            case "autherror" :
              errorMessage = '사용자 아이디 또는 비밀번호가 올바르지 않습니다';
              break;
            case "LOGIN_REQ_AUTH" :
              errorMessage = '관리자의 승인이 필요한 계정입니다.';
              break;
            case "GET_USER_01" :
              errorMessage = '사용자 아이디 또는 비밀번호가 올바르지 않습니다';
              break;
            default :
              errorMessage = '알 수 없는 에러가 발생했습니다.';
              break;
          }
          this.$popupAlert({
            title: '로그인 실패',
            msg : errorMessage
          });
          return;
        }
      }
    },
  },
  // beforeMount() {
  //   if(this.$isLogin()){
  //     this.$router.push("/allOk/customers")
  //   }
  // }
};
</script>