<template>
  <div class="c_contents">
    <div v-if="result" style="display: flex">
      <pre>{{ JSON.stringify(reportData, null, 2) }}</pre>
      <div style="width: 100%">
        <div id="chart1" style="width: 100%"></div>
        <div id="chart2" style="width: 100%"></div>
        <div id="chart3" style="width: 100%"></div>
        <div id="chart4" style="width: 100%"></div>
        <div id="chart5" style="width: 100%"></div>
        <div id="chart6" style="width: 100%"></div>
        <div id="chart7" style="width: 100%"></div>
        <div id="chart8" style="width: 100%"></div>
        <div id="chart9" style="width: 100%"></div>
        <div id="chart10" style="width: 100%"></div>
        <div id="chart11" style="width: 100%"></div>
        <div id="chart12" style="width: 100%"></div>
        <div id="chart13" style="width: 100%"></div>
        <div id="chart14" style="width: 100%"></div>
        <div id="chart15" style="width: 100%"></div>
      </div>
    </div>
    <div v-else>
      <h2>{{ errMsg }}</h2>
    </div>
  </div>
</template>

<script>
import diagnosisService from '~/service/allOkMng/diagnosisService.js';
export default {
  props: ['bizNo'],
  data() {
    return {
      reportData: null,
      result: false,
      errMsg: null,
    };
  },

  methods: {
    fnFormatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fnDrawChart() {
      const Chart = toastui.Chart;

      Chart.columnChart({
        el: document.getElementById('chart1'),
        data: {
          categories: this.reportData.주주별평가.주주.map(el => el.주주명),
          series: [
            {
              name: `${this.reportData.주주별평가.결산기준년도}년 결산기준`,
              data: this.reportData.주주별평가.주주.map(el => el.결산기준),
            },
          ],
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
            height: 300,
          },
          yAxis: {
            label: {
              formatter: value => '',
            },
          },
          theme: {
            series: {
              barWidth: 50,
              colors: ['#F79646'],
              dataLabels: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'red',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
          },
          legend: {
            visible: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(0)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(0)),
          },
        },
      });

      Chart.columnChart({
        el: document.getElementById('chart2'),
        data: {
          categories: this.reportData.유고시.주주.map(el => el.주주명),
          series: [
            {
              name: `증여세율 최고(50%)`,
              data: this.reportData.유고시.주주.map(el => el.증여세율최고),
            },
          ],
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
            height: 300,
          },
          yAxis: {
            label: {
              formatter: value => '',
            },
          },
          theme: {
            series: {
              barWidth: 50,
              colors: ['#9BBB59'],
              dataLabels: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'red',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
          },
          legend: {
            visible: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(0)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(0)),
          },
        },
      });

      Chart.columnChart({
        el: document.getElementById('chart3'),
        data: {
          categories: this.reportData.양도시.주주.map(el => el.주주명),
          series: [
            {
              name: `증여세율 최고(50%)`,
              data: this.reportData.양도시.주주.map(el => el.양도세),
            },
          ],
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
            height: 300,
          },
          yAxis: {
            label: {
              formatter: value => '',
            },
          },
          theme: {
            series: {
              barWidth: 50,
              colors: ['#9BBB59'],
              dataLabels: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'red',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
          },
          legend: {
            visible: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(0)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(0)),
          },
        },
      });

      Chart.columnChart({
        el: document.getElementById('chart4'),
        data: {
          categories: this.reportData.청산시.주주.map(el => el.주주명),
          series: [
            {
              name: `의제배당액`,
              data: this.reportData.청산시.주주.map(el => el.의제배당액),
            },
            {
              name: `산출세액`,
              data: this.reportData.청산시.주주.map(el => el.산출세액),
            },
          ],
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
            height: 300,
          },
          yAxis: {
            label: {
              formatter: value => '',
            },
          },
          theme: {
            series: {
              barWidth: 40,
              colors: ['#336AAC', '#AC3330'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'top',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(0)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(0)),
          },
        },
      });

      Chart.columnChart({
        el: document.getElementById('chart4'),
        data: {
          categories: this.reportData.자기주식.지분율.map(el => (el.지분율 * 100).toFixed(1) + '%'),
          series: [
            {
              name: `양도차익`,
              data: this.reportData.자기주식.지분율.map(el => el.양도차익),
            },
            {
              name: `양도세`,
              data: this.reportData.자기주식.지분율.map(el => el.양도세),
            },
          ],
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
            height: 300,
          },
          yAxis: {
            label: {
              formatter: value => (value == 0 ? '-' : this.fnFormatNumber(value) + '원'),
            },
          },
          theme: {
            series: {
              barWidth: 40,
              colors: ['#336AAC', '#AC3330'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            yAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'bottom',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(0)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(0)),
          },
        },
      });

      Chart.columnChart({
        el: document.getElementById('chart5'),
        data: {
          categories: this.reportData.이익소각.지분율.map(el => (el.지분율 * 100).toFixed(1) + '%'),
          series: [
            {
              name: `의제배당액`,
              data: this.reportData.이익소각.지분율.map(el => el.의제배당액),
            },
            {
              name: `배당세액`,
              data: this.reportData.이익소각.지분율.map(el => el.배당세액),
            },
          ],
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
            height: 300,
          },
          yAxis: {
            label: {
              formatter: value => (value == 0 ? '-' : this.fnFormatNumber(value) + '원'),
            },
          },
          theme: {
            series: {
              barWidth: 40,
              colors: ['#336AAC', '#AC3330'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            yAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'bottom',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(0)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(0)),
          },
        },
      });

      Chart.columnChart({
        el: document.getElementById('chart6'),
        data: {
          categories: this.reportData.증여후양도.지분율.map(el => (el.지분율 * 100).toFixed(1) + '%'),
          series: [
            {
              name: `양도액`,
              data: this.reportData.증여후양도.지분율.map(el => el.양도액),
            },
            {
              name: `양도세`,
              data: this.reportData.증여후양도.지분율.map(el => el.양도세),
            },
          ],
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
            height: 300,
          },
          yAxis: {
            label: {
              formatter: value => (value == 0 ? '-' : this.fnFormatNumber(value) + '원'),
            },
          },
          theme: {
            series: {
              barWidth: 40,
              colors: ['#336AAC', '#AC3330'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            yAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'bottom',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(0)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(0)),
          },
        },
      });

      Chart.columnChart({
        el: document.getElementById('chart7'),
        data: {
          categories: this.reportData.증여후소각.지분율.map(el => (el.지분율 * 100).toFixed(1) + '%'),
          series: [
            {
              name: `이익소각액`,
              data: this.reportData.증여후소각.지분율.map(el => el.이익소각액),
            },
            {
              name: `배당세액`,
              data: this.reportData.증여후소각.지분율.map(el => el.배당세액),
            },
          ],
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
            height: 300,
          },
          yAxis: {
            label: {
              formatter: value => (value == 0 ? '-' : this.fnFormatNumber(value) + '원'),
            },
          },
          theme: {
            series: {
              barWidth: 40,
              colors: ['#336AAC', '#AC3330'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            yAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'bottom',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(0)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(0)),
          },
        },
      });

      Chart.columnChart({
        el: document.getElementById('chart8'),
        data: {
          categories: this.reportData.양도후사내근로복지기금.지분율.map(el => (el.지분율 * 100).toFixed(1) + '%'),
          series: [
            {
              name: `양도액`,
              data: this.reportData.양도후사내근로복지기금.지분율.map(el => el.양도액),
            },
            {
              name: `실질세부담액`,
              data: this.reportData.양도후사내근로복지기금.지분율.map(el => el.실질세부담액),
            },
          ],
        },
        options: {
          chart: {
            title: '',
            width: 'auto',
            height: 300,
          },
          yAxis: {
            label: {
              formatter: value => (value == 0 ? '-' : this.fnFormatNumber(value) + '원'),
            },
          },
          theme: {
            series: {
              barWidth: 40,
              colors: ['#336AAC', '#AC3330'],
              dataLabels: {
                fontSize: 12,
                fontWeight: 'bold',
                color: 'black',
              },
            },
            xAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
            yAxis: {
              label: {
                color: 'black',
                fontWeight: 'bold',
              },
            },
            legend: {
              label: {
                fontSize: 15,
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
          legend: {
            align: 'bottom',
            showCheckbox: false,
          },
          exportMenu: {
            visible: false,
          },
          series: {
            dataLabels: {
              visible: true,
              formatter: value => this.fnFormatNumber(value.toFixed(0)),
            },
          },
          tooltip: {
            formatter: (value, tooltipDataInfo) => this.fnFormatNumber(value.toFixed(0)),
          },
        },
      });
    },
  },

  mounted() {
    diagnosisService.getDiagnosisReport(this.$axios, this.bizNo).then(({ result, data }) => {
      if (result) {
        this.reportData = data.reportData;
        this.result = result;
        setTimeout(() => {
          this.fnDrawChart();
        }, 10);
      } else {
        this.errMsg = data.error.msg;
      }
    });
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #ddd;
}

.tbl_doc_scroll .t_cont .item {
  min-height: 40px;
}

.bg {
  background-color: #f4f4f4;
}

.bg2 {
  background-color: #fde9d9;
}

.bg3 {
  background-color: #ebf1de;
}

.bg4 {
  background-color: #daeef3;
}
</style>
