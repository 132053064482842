<template>
  <div class="container">
    <div class="c_top">
      <div class="taps_btn">
        <button class="tap on">전체</button>
        <button class="tap">A등급</button>
        <button class="tap">B등급</button>
        <button class="tap">C등급</button>
      </div>
    </div>
    <section class="list_finance">
      <article class="item" v-for="i in 5" :key="i">
        <div class="i_top">
          <h3>지능형 디스플레이 장치</h3>
          <div class="right">
            <CheckBox
              :data="{
                title:'분석요청',
                id: '1',
              }"
              ref="agrTm01"
            />
          </div>
        </div>
        <dl>
          <dt>특허등급</dt>
          <dd><b>AA</b></dd>
        </dl>
        <dl>
          <dt>출원일</dt>
          <dd>2009-11-16</dd>
        </dl>
        <dl>
          <dt>특허추정가치</dt>
          <dd>1억원 이상 ~ 5억원 미만</dd>
        </dl>
      </article>
    </section>
  </div>
</template>

<script>
import CheckBox from "./CheckBox.vue";
export default {
  name: "tab3",
  components:{CheckBox},
}
</script>
