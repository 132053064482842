<template>
  <div class="c_contents contents_col2">
    <div class="left" v-if="$store.state.authUser.gaId.indexOf('TXO')!==-1||$store.state.authUser.gaName==='유니콘랜치'">
      <ol>
        <li>
          <label for="stk"><b>배정된 GA:</b></label>
          <Input
            id="stk"
            :value.sync="consultantGaNm2"
            name="consultant"
            type="text"
            placeholder="GA를 선택해주세요"
            :disabled="true"
            :styleAttr="{ width: '100%' }"
          />
        </li>
      </ol>
      <div style="line-height:90%;"><br /></div>
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="consultantNm">GA코드<br />GA명</label>
          <Input
            id="consultantNm"
            :value.sync="form2.consultantNm"
            name="consultantNm"
            type="text"
            shape="search"
            placeholder="GA코드 혹은 GA명을 입력해주세요"
            :styleAttr="{ width: '100%' }"
          />
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area" style="min-width: auto">
          <table class="tbl">
            <colgroup>
              <col width="20%" />
              <col width="63%" />
              <col width="17%" />
            </colgroup>
            <thead>
            <tr>
              <th>
                <b>GA코드</b>
              </th>
              <th>
                <b>GA명</b>
              </th>
              <th>
                <b>&nbsp;</b>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in consultantGaList" :key="item.id">
              <td>
                {{ item.id }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                <button class="btn" @click="fnSelect2(item)">선택</button>
              </td>
            </tr>
            <tr v-if="consultantGaList.length === 0">
              <td colspan="3">데이터가 없습니다.</td>
            </tr>
            </tbody>
          </table>
          <Pagination
              v-if="!form.consultantNm"
              :page="pageNum2+1"
              :perPage="pageSize2"
              :totalCount="gaPagination.totalCount"
              @change="p => {
                this.pageNum2=p-1;
              }"
            />
        </div>
      </div>
    </div>
    <div class="right">
      <ol>
        <li>
          <label for="stk"><b>배정된 담당자:</b></label>
          <Input
            id="stk"
            :value="consultantNm2"
            name="consultant"
            type="text"
            placeholder="담당자를 선택해주세요"
            :disabled="true"
            :styleAttr="{ width: '100%' }"
          />
        </li>
      </ol>
      <div style="line-height:90%;"><br /></div>
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="consultantNm">FC코드<br />담당자 이름</label>
          <Input
            id="'consultantNm'"
            :value.sync="form.consultantNm"
            name="consultantNm"
            type="text"
            shape="search"
            placeholder="FC코드 혹은 담당자 이름을 입력해주세요"
          />
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area" style="min-width: auto">
          <table class="tbl">
            <colgroup>
              <col width="20%" />
              <col width="63%" />
              <col width="17%" />
            </colgroup>
            <thead>
            <tr>
              <th>
                <b>FC코드</b>
              </th>
              <th>
                <b>담당자</b>
              </th>
              <th>
                <b>&nbsp;</b>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in consultantList" :key="item.id">
              <td>
                {{ item.consultant_cd }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                <button class="btn" :class="{gray: !consultantGaNm2}" @click="fnSelect(item)" :disabled="!consultantGaNm2">선택</button>
              </td>
            </tr>
            <tr v-if="consultants.length === 0">
              <td colspan="3">데이터가 없습니다.</td>
            </tr>
            </tbody>
          </table>
          <Pagination
              v-if="!form.consultantNm"
              :page="pageNum+1"
              :perPage="pageSize"
              :totalCount="pagination.totalCount"
              @change="p => {
                this.pageNum=p-1;
              }"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import ItemPatent from '~/components/common/ItemPatent';
import Select from '~/components/common/Select';
import consultantsService from "~/service/allOk/consultantsService";
import Pagination from "~/components/common/Pagination";
import laborService from '~/service/allOkMng/laborService';

export default {
  name: 'LaborTab7',
  props: ['id', 'consultantGaNm', 'consultantNm'],
  components: {
    Input,
    ItemPatent,
    Select,
    Pagination,
  },
  data() {
    return {
      consultant_id: null,
      customer: {},
      consultants:[],
      consultantGa:[],
      currentPage: 1,
      consultantNm2: null,
      consultantGaNm2: null,
      form:{
        page: 1,
        pageSize: 20,
        consultantNm: null,
      },
      form2:{
        page: 1,
        pageSize: 20,
        consultantNm: null,
      },
      pagination: {
        totalCount: 0,
      },
      gaPagination:{
        totalCount: 0
      },
      dataList:[],
      pageSize: 15,
      pageNum: 0,
      pageSize2: 15,
      pageNum2: 0,
      gaNm: null
    };
  },
  computed: {
    consultantList(){
      if(this.form.consultantNm){
        return this.consultants.filter( c => c.name.indexOf(this.form.consultantNm) >= 0|| c.consultant_cd.indexOf(this.form.consultantNm) >= 0)
      } else {
        const start = this.pageNum*this.pageSize
        const end = start+this.pageSize
        return this.consultants.slice(start, end)
      }
    },
    consultantGaList(){
      if(this.form2.consultantNm){
        return this.consultantGa.filter( c => c.name.indexOf(this.form2.consultantNm) >= 0|| c.id.indexOf(this.form2.consultantNm) >= 0)
      } else {
        const start = this.pageNum2*this.pageSize2
        const end = start+this.pageSize2
        return this.consultantGa.slice(start, end)
      }
    },
  },
  beforeMount() {
    this.fetchConsultantList();
    this.fetchConsultantGaList();
  },
  mounted(){
    if(this.consultantGaNm){
      this.consultantGaNm2 = this.consultantGaNm;
    }
    if(this.consultantNm){
      this.consultantNm2 = this.consultantNm
    }
  },
  methods: {
    fetchConsultantList() {
      const query= {
        serviceType: 'CPMNG_LABOR'
      }
      consultantsService.fetchConsultants(this.$axios, query)
      .then(res => {
        const {data : {data,result} } = res;
        if(result === true){
          this.consultants = data.contents;
          this.pagination.totalCount = data.contents.length;
        } else {
          this.consultants = [];
        }
      });
    },
    fetchConsultantGaList(){
      consultantsService.fetchConsultantGa(this.$axios)
      .then(res => {
        const {data : {data,result} } = res;
        if(result === true){
          this.consultantGa = data.contents.filter(c=>c.id!='CPM0001');
          this.gaPagination.totalCount = data.contents.length;
        } else {
          this.consultantGa = [];
        }
      });
    },
    fnSelect(item){
      this.customer.consultantId = item.id;
      this.customer.consultantNm = item.name;
      this.customer.consultantCd = item.consultant_cd;
      const btn = {
        name: '확인',
        func: () => {
          this.consultantNm2 = item.name;
          this._closeConfirm();
          this.fnSave();
        }
      }
      this._confirm('확인', '저징하시겠습니까?', btn);
    },
    fnSelect2(item){
      this.customer.gaId = item.id;
      this.gaNm=item.name;
      const btn = {
        name: '확인',
        func: () => {
          this.consultantGaNm2 = item.name;
          this._closeConfirm();
          this.fnGaSave();
        }
      }
      this._confirm('확인', '저징하시겠습니까?', btn);
    },
    async fnSave(){
      const res = await laborService.addLaborConsultant(this.$axios, this.id, {
        cnsltntId : this.customer.consultantId,
      })
      const { result } = res.data;
      if(result === true){
        let data = {
          state:true,
          type: "alert",
          title: "완료",
          msg: `${this.customer.consultantNm}(담당자)를 배정완료했습니다.`,
          btn:{
            name:"확인",
          }
        };
        this.$store.dispatch('ui/setPopAlertData', data);
      } else {
        const {data: {error}} = res.data;
        let errorMsg = '';
        switch (error.cd){
          case 'E0001':
            errorMsg="해당 건은 '미신청'건으로 담당자 배정이 불가능합니다.";
            break;
          default :
            errorMsg='담당자 배정중 오류가 발생했습니다. ';
        }
        let data = {
          state:true,
          type: "alert",
          title: "실패",
          msg: errorMsg,
          btn:{
            name:"확인",
          }
        };
        this.$store.dispatch('ui/setPopAlertData', data);
      }
    },
    async fnGaSave(){
      const res = await laborService.addLaborConsultantGa(this.$axios, this.id, {
        gaId : this.customer.gaId,
      })
      const { result } = res.data;
      if(result === true){
        let data = {
          state:true,
          type: "alert",
          title: "완료",
          msg: `${this.gaNm}(담당자)를 배정완료했습니다.`,
          btn:{
            name:"확인",
          }
        };
        this.$store.dispatch('ui/setPopAlertData', data);
      } else {
        const {data: {error}} = res.data;
        let errorMsg = '';
        switch (error.cd){
          case 'E0001':
            errorMsg="해당 건은 '미신청'건으로 담당자 배정이 불가능합니다.";
            break;
          default :
            errorMsg='담당자 배정중 오류가 발생했습니다. ';
        }
        let data = {
          state:true,
          type: "alert",
          title: "실패",
          msg: errorMsg,
          btn:{
            name:"확인",
          }
        };
        this.$store.dispatch('ui/setPopAlertData', data);
      }
    },
    _alert(title, msg){
      let data = {
        state:true,
        type: "alert",
        title: title,
        msg: msg,
        btn:{
          name:"확인",
        }
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _confirm(title, msg, btn){
      let data  = {
        state:true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
        closeBtn:{
          name:"취소"
        }
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closeConfirm(){
      this.$store.dispatch('ui/setPopAlertData', {
        state:false,
        type: null,
        title: null,
        msg: null,
        btn:{
          link:'',
          name:null,
        }
      });
    },
    _closePop(){
      this.$store.dispatch('ui/setPopAlertData', {
        state:false,
        type: null,
        title: null,
        msg: null,
        btn:{
          link:'',
          name:null,
        }
      });
    },
  }
};
</script>
