<template>
  <div class="c_contents contents_col2">
    <div v-if="currentDp.showLeftDiv"
         :class="[currentDp.leftDivClass]">
      <Ga
        v-if="currentDp.showSalesComp"
        label="배정된 영업 GA"
        class-name="none"
        :assignedGaId="prp_salesCompId"
        :assignedGaName="prp_salesCompNm"
        :allowed-ga-type="allowedSalesCompTypes"
        :manage-type="salesType"
        :customer-id="id"
        @selected="fnSalesCompSelected"
      />
    </div>
    <div v-if="currentDp.showRightDiv"
         :class="[currentDp.rightDivClass]">
      <Manager
        v-if="currentDp.showSales"
        label="배정된 영업 담당자"
        class-name="none"
        :assigned-ga-id="prp_salesCompId"
        :assigned-id="salesId"
        :assigned-name="salesNm"
        :manage-type="salesType"
        :customer-id="id"
      />
    </div>
  </div>
</template>

<script>
import Ga from '../consaltant/Ga.vue';
import Manager from '../consaltant/Manager.vue';
import * as companyTypes from '~/constants/companyType';
import * as manageTypes from '../../../constants/managerType';
export default {
  components: {
    Ga, Manager
  },
  props: {
    id: {type:String, required:true},
    allowedSalesCompTypes:{
      type: Array,
      default: ()=>{return ['ADMIN']}
    },
    salesType:{
      type:String,
      default:manageTypes.SALES
    },
    salesCompId:{type:String},
    salesCompNm:{type:String},
    salesId:{type:String},
    salesNm:{type:String},
  },
  data() {
    return {
      prp_salesCompId:null,
      prp_salesCompNm:null,
      dpType:'sales',
      dpTypes:{
        all:{
          showLeftDiv:true,
          leftDivClass:'left',
          showSalesComp:true,
          showSales:true,
          showRightDiv:true,
          rightDivClass:'right',
        },
        sales:{
          showLeftDiv:true,
          leftDivClass:null,
          showSalesComp:false,
          showSales:true,
          showRightDiv:true,
          rightDivClass:true,
        },
      }
    };
  },
  computed:{
    currentDp(){
      if(this.dpType){
        return this.dpTypes[this.dpType];
      }
      return {
        showLeftDiv:false,
        leftDivClass:null,
        showSalesComp:false,
        showSales:false,
        showRightDiv:false,
        rightDivClass:null,
      }
    }
  },
  beforeMount() {
    const companyType = this.$store.state.authUser.gaType;
    switch (companyType){
      case companyTypes.ADMIN:
        this.dpType = 'all';
        break;
      default:
        this.dpType = 'sales';
        break;
    }
  },
  mounted() {
    this.prp_salesCompId = this.salesCompId;
    this.prp_salesCompNm = this.salesCompNm;
  },
  methods: {
    fnSalesCompSelected(item){
      this.prp_salesCompId = item.id;
      this.prp_salesCompNm = item.name;
    },
  },
}
</script>