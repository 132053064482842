<template>
  <div class="area_menu">
    <div class="am_top">
      <h1 class="logo"><nuxt-link to="/" @click.native="()=>{
        $store.dispatch('ui/setPopCsState', false);
        $store.dispatch('ui/setPopFcState', false);
        $store.dispatch('ui/setPopFc2State', false);
      }">FC BACKOFFICE</nuxt-link></h1>
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <!-- <Alert v-if="$store.state.ui.popAlertData.state == true" :key="$store.state.ui.popAlertData.state"/> -->
        <button class="btn_close" @click="fnClosePop" v-if="view == 'mobile'" :key="$store.state.ui.view">닫기</button>
      </transition>
    </div>
    <nav>
      <ul v-if="!toggled" class="list_menu">
        <li class="dep1 open" v-for="mainMenu in menus" :key="mainMenu.id">
          <button class="menu" @click="(e)=>fnToggle(e, mainMenu.id)">{{mainMenu.title}}</button>
          <ul class="dep2">
            <li v-for="item in fnSubMenus(mainMenu.subList)" :key="item.id">
              <nuxt-link
                :to="item.page_url"
                :class="{'sub_menu': true, 'on': $route.path==item.page_url }"
                @click.native="(e)=>fnSubToggle(e, item.id)"
              >
                {{ item.title }}
              </nuxt-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: ['classNm'],
  data() {
    return {
      view: undefined,
      toggled: true,
    };
  },
  created() {
    const partnerInfo = this.$partnerInfoP_getPartnerInfo();
    console.log('menu created', partnerInfo)
    if ( partnerInfo ){
      const _a = document.querySelector('.area_menu .logo a');
      if( _a ){
        _a.style.backgroundImage=`url('${partnerInfo.logo}')`;
        _a.style.backgroundSize=partnerInfo.backgroundSize;
      }
    }
  },
  computed: {
    menus() {
      if(this.$store.state.authUser){
        let menus = this.$store.state.backoffice.configMenuStore.menus;
        return menus
        // return menus.filter(root=>{
        //   for(const sub of root.subList){
        //     if(!sub.roles)
        //       return true
        //     for(const subRole of sub.roles){
        //       if(this.$store.state.authUser.roles.includes(subRole)){
        //         return true
        //       }
        //     }
        //   }
        // })
      }
      return []
    },
  },
  components: {},
  mounted() {
    //
    const partnerInfo = this.$partnerInfoP_getPartnerInfo();
    console.log('menu mounted', partnerInfo)
    if ( partnerInfo ){
      const _a = document.querySelector('.area_menu .logo a');
      if( _a ){
        _a.style.backgroundImage=`url('${partnerInfo.logo}')`;
        _a.style.backgroundSize=partnerInfo.backgroundSize;
      }
    }
    //
    this.toggled = false
    this.view = this.$store.state.ui.view || "mobile";
    if(this.$store.state.ui.view == "pc") this.$store.dispatch("ui/setMenuState", true);
    window.addEventListener("resize",() => {
      this.view = this.$store.state.ui.view;
      if(this.view == "mobile") this.$store.dispatch("ui/setMenuState", false);
    },true);
    $nuxt.$router.beforeEach((to, from, next) => {
    if(this.$store.state.ui.view == "mobile") this.fnClosePop();
      next();
    });
  },
  methods: {
    fnSubMenus(payload) {
      // if (this.$store.state.authUser.gaName === '메인비즈') {
      //   payload = payload.filter(p => {
      //     return p.title !== '사용자관리';
      //   });
      // }
      return payload;
    },
    fnToggle(e, id) {
      let target = e.target.parentNode;
      let on = target.classList.contains("open");
      if(on == true){
        target.classList.remove("open");
      }else {
        target.classList.add("open");
      }
      this.$store.commit('backoffice/configMenuStore/SELECT_ROOT_MENU', 1);
      // document.querySelector(".btn_top").classList.add("show");
    },
    fnClosePop() {
      this.$store.dispatch('ui/setMenuState', false);
    },
    fnSubToggle(e, id){
      this.$store.commit('backoffice/configMenuStore/SELECT_DEPTH1_MENU', id);
      this.$store.dispatch('ui/setPopCsState', false);
      this.$store.dispatch('ui/setPopFcState', false);
      this.$store.dispatch('ui/setPopFc2State', false);
    }
  },
};
</script>

<style scoped>
.area_menu .logo a {
  /*background: url("../../static/IMG/logo_admin.png") no-repeat 50% 50%;*/
  background-repeat: no-repeat;
  background-position: 50% 50%;
  /*background-size: 80px;*/
}
</style>
