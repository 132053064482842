<template>
  <div class="bx_radio">
    <input
      type="radio"
      :id="id"
      :name="name"
      :value="defaultValue"
      class="radio"
      :checked="defaultValue === value"
      :disabled="disabled"
      @click="fnClick"
    />
    <label :for="id" v-html="label"></label>
  </div>
</template>
<script>
export default {
  props:{
    // 라벨
    label: {
      type:String,
      default: null
    },
    // Form name
    name:{
      type:String,
      required: true,
    },
    value:{
      //type:String,
      default:null,
    },
    defaultValue:{
      //type:String,
      required: true,
    },
    disabled:{
      type:Boolean,
      default: false
    },
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    fnClick(e){
      this.$emit('update:value', e.target.value);
    }
  },
  beforeMount() {
    // 아이디 생성
    this.id = 'ipt_'+this.name+'_'+Math.round(Math.random()*10000);
  }
};
</script>
