<template>
  <div>
    <div class="tbl_doc_scroll full mt10">
      <h3 style="margin-left: 950px">{{this.baseDt}}분 급여대장</h3>
      <small>회사명: {{this.compNm}}</small>
      <div class="t_inner">
        <div class="t_top table1">
          <div class="item">
            <div class="t_group">
              <div class="row">
                <div class="cel" style="width: 100px">사원번호</div>
                <div class="cel" style="width: 150px">성명</div>
                <div class="cel" style="width: 145px">기본급</div>
                <div class="cel" style="width: 145px">연장근무수당</div>
                <div class="cel" style="width: 145px">휴일근무수당</div>
                <div class="cel" style="width: 145px">휴일연장근무수당</div>
                <div class="cel" style="width: 145px">야간근무수당</div>
                <div class="cel" style="width: 145px">고정상여금</div>
                <div class="cel" style="width: 145px">{{customAllowance.name2}}</div>
                <div class="cel" style="width: 145px">{{customAllowance.name4}}</div>
                <div class="cel" style="width: 145px">추가연장수당</div>
                <div class="cel" style="width: 145px">{{customAllowance.name6}}</div>
                <div class="cel" style="width: 145px">{{customAllowance.name8}}</div>
              </div>
              <div class="row">
                <div class="cel" style="width: 100px">입사일</div>
                <div class="c_group" style="width: 150px">
                  <div class="cel_col" style="width: 75px">부서</div>
                  <div class="cel_col" style="width: 75px">직급</div>
                </div>
                <div class="cel" style="width: 145px">식대</div>
                <div class="cel" style="width: 145px">차량유지비</div>
                <div class="cel" style="width: 145px">당직근무수당</div>
                <div class="cel" style="width: 145px">보육수당</div>
                <div class="cel" style="width: 145px">연구개발비</div>
                <div class="cel" style="width: 145px">{{customAllowance.name1}}</div>
                <div class="cel" style="width: 145px">{{customAllowance.name3}}</div>
                <div class="cel" style="width: 145px">{{customAllowance.name5}}</div>
                <div class="cel" style="width: 145px">근태공제</div>
                <div class="cel" style="width: 145px">{{customAllowance.name7}}</div>
                <div class="cel" style="width: 145px">{{customAllowance.name9}}</div>
              </div>
            </div>
            <div class="cel" style="width: 145px">급여총액</div>
            <div class="cel" style="width: 145px">과세신고금액</div>
          </div>
        </div>
        <div class="t_cont table1">
          <div class="item b_double prevent-page-break" v-for="(el, idx) in payrollEmp.empPayrollList" :key="idx">
            <div class="t_group">
              <div class="row">
                <div class="cel" style="width: 100px">{{ el.empNo }}</div>
                <div class="cel" style="width: 150px">{{ el.name }}</div>
                <div class="cel" style="width: 145px">{{ el.basicPay }}</div>
                <div class="cel" style="width: 145px">{{ el.extendedWorkPay }}</div>
                <div class="cel" style="width: 145px">{{ el.holidayWorkPay }}</div>
                <div class="cel" style="width: 145px">{{ el.holidayOverWorkPay }}</div>
                <div class="cel" style="width: 145px">{{ el.nightShiftPay }}</div>
                <div class="cel" style="width: 145px">{{ el.fixedBonus }}</div>
                <div class="cel" style="width: 145px">{{ el.customAllowance2 }}</div>
                <div class="cel" style="width: 145px">{{ el.customAllowance4 }}</div>
                <div class="cel" style="width: 145px">{{ el.extraOvertimePay }}</div>
                <div class="cel" style="width: 145px">{{ el.customAllowance6 }}</div>
                <div class="cel" style="width: 145px">{{ el.customAllowance8 }}</div>
              </div>
              <div class="row">
                <div class="cel" style="width: 100px">{{ el.empStartDt }}</div>
                <div class="c_group" style="width: 150px">
                  <div class="cel_col" style="width: 75px">{{ el.dept }}</div>
                  <div class="cel_col" style="width: 75px">{{ el.rank }}</div>
                </div>
                <div class="cel" style="width: 145px">{{ el.meals }}</div>
                <div class="cel" style="width: 145px">{{ el.carMaintenanceCost }}</div>
                <div class="cel" style="width: 145px">{{ el.dutyPay }}</div>
                <div class="cel" style="width: 145px">{{ el.childCareAllowance }}</div>
                <div class="cel" style="width: 145px">{{ el.rndAllowance }}</div>
                <div class="cel" style="width: 145px">{{ el.annualAllowance }}</div>
                <div class="cel" style="width: 145px">{{ el.customAllowance3 }}</div>
                <div class="cel" style="width: 145px">{{ el.customAllowance5 }}</div>
                <div class="cel" style="width: 145px">{{ el.attendanceDeduction }}</div>
                <div class="cel" style="width: 145px">{{ el.customAllowance7 }}</div>
                <div class="cel" style="width: 145px">{{ el.customAllowance9 }}</div>
              </div>
            </div>
            <div class="cel" style="width: 145px">{{ el.payAmount }}</div>
            <div class="cel" style="width: 145px">{{ el.taxDeclareAmount }}</div>
          </div>
          <div class="item b_double prevent-page-break">
            <div class="cel border-bottom" style="width: 250px; background-color: #E6E6FA">인원 : 총 {{ payrollEmp.empCntTot }}명</div>
            <div class="t_group">
              <div class="row">
                <div class="cel" style="width: 145px">{{ payrollEmp.basicPayTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.extendedWorkPayTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.holidayWorkPayTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.holidayOverWorkPayTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.nightShiftPayTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.fixedBonusTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.customAllowance2Tot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.customAllowance4Tot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.extraOvertimePayTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.customAllowance6Tot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.customAllowance8Tot }}</div>
              </div>
              <div class="row">
                <div class="cel" style="width: 145px">{{ payrollEmp.mealsTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.carMaintenanceCostTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.dutyPayTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.childCareAllowanceTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.rndAllowanceTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.annualAllowanceTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.customAllowance3Tot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.customAllowance5Tot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.attendanceDeductionTot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.customAllowance7Tot }}</div>
                <div class="cel" style="width: 145px">{{ payrollEmp.customAllowance9Tot }}</div>
              </div>
            </div>
            <div class="cel border-bottom" style="width: 145px; background-color: #E6E6FA">{{ payrollEmp.payAmountTot }}</div>
            <div class="cel border-bottom" style="width: 145px; background-color: #E6E6FA">{{ payrollEmp.taxDeclareAmountTot }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="tbl_doc_scroll full mt40" style="page-break-before: always">
      <h3 style="margin-left: 680px">4대보험료 절감검토 결과[연간]</h3>
      <small>회사명: {{this.compNm}}</small>
      <div class="t_inner">
        <div class="t_top table2">
          <div class="item">
            <div class="t_group">
              <div class="row">
                <div class="cel" style="width: 108px">사원번호</div>
                <div class="cel" style="width: 130px">성명</div>
              </div>
              <div class="row">
                <div class="cel" style="width: 108px">입사일</div>
                <div class="c_group" style="width: 130px">
                  <div class="cel_col" style="width: 65px">부서</div>
                  <div class="cel_col" style="width: 65px">직급</div>
                </div>
              </div>
            </div>
            <div class="cel" style="width: 63px; font-size: 8px" v-html="'비과세<br>증가금액'"></div>
            <div class="cel" style="width: 63px; font-size: 8px" v-html="'기존<br>과세신고<br>금액'"></div>
            <div class="t_group">
              <div class="row">
                <div class="cel" style="width: 126px; font-size: 8px" v-html="'국민연금 보험료 (9%)<br>상한액 590만원'"></div>
                <div class="cel" style="width: 126px; font-size: 8px" v-html="'건강보혐료 (7.09%)'"></div>
                <div class="cel" style="width: 126px; font-size: 8px" v-html="'장기요양보험료 (0.9%)<br>(건강보험료의 12.81%)'"></div>
                <div class="cel" style="width: 126px; font-size: 8px" v-html="'고용보험료 (1.8%)'"></div>
                <div class="cel" style="width: 126px; font-size: 8px" v-html="'산재보험료 (평균 1%)'"></div>
                <div class="cel" style="width: 189px; font-size: 8px" v-html="'4대보험료 연간 절감 총액'"></div>
                <div class="cel" style="width: 189px; font-size: 8px" v-html="'소득세 절감 총액'"></div>
                <div class="cel" style="width: 189px; font-size: 8px" v-html="'지방세 절감 총액'"></div>
              </div>
              <div class="row">
                <div class="cel" style="width: 63px; font-size: 8px">회사(4.5%)</div>
                <div class="cel" style="width: 63px; font-size: 8px">근로자(4.5%)</div>
                <div class="cel" style="width: 63px; font-size: 8px">회사(3.545%)</div>
                <div class="cel" style="width: 63px; font-size: 8px">근로자(3.545%)</div>
                <div class="cel" style="width: 63px; font-size: 8px">회사(50%)</div>
                <div class="cel" style="width: 63px; font-size: 8px">근로자(50%)</div>
                <div class="cel" style="width: 63px; font-size: 8px">회사(0.9%)</div>
                <div class="cel" style="width: 63px; font-size: 8px">근로자(0.9%)</div>
                <div class="cel" style="width: 63px; font-size: 8px">회사(1%)</div>
                <div class="cel" style="width: 63px; font-size: 8px">근로자(0%)</div>
                <div class="cel" style="width: 63px; font-size: 8px">회사</div>
                <div class="cel" style="width: 63px; font-size: 8px">근로자</div>
                <div class="cel" style="width: 63px; font-size: 8px">TOTAL</div>
                <div class="cel" style="width: 63px; font-size: 8px">기존 소득세</div>
                <div class="cel" style="width: 63px; font-size: 8px">변경 소득세</div>
                <div class="cel" style="width: 63px; font-size: 8px">절감 소득세</div>
                <div class="cel" style="width: 63px; font-size: 8px">기존 지방세</div>
                <div class="cel" style="width: 63px; font-size: 8px">변경 지방세</div>
                <div class="cel" style="width: 63px; font-size: 8px">절감 지방세</div>
              </div>
            </div>
          </div>
        </div>
        <div class="t_cont table2">
          <div class="item b_double prevent-page-break" v-for="(el, idx) in payrollEmp.empPayrollList" :key="idx">
            <div class="t_group">
              <div class="row">
                <div class="cel" style="width: 108px">{{ el.empNo }}</div>
                <div class="cel" style="width: 130px">{{ el.name }}</div>
              </div>
              <div class="row">
                <div class="cel" style="width: 108px">{{ el.empStartDt }}</div>
                <div class="c_group" style="width: 130px">
                  <div class="cel_col" style="width: 65px">{{ el.dept }}</div>
                  <div class="cel_col" style="width: 65px">{{ el.rank }}</div>
                </div>
              </div>
            </div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.tfIncreaseAmount }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.asTaxDeclareAmount }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.npsPremCorp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.npsPremEmp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.nhisPremCorp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.nhisPremEmp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.ltcPremCorp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.ltcPremEmp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.eiPremCorp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.eiPremEmp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.iaiPremCorp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.iaiPremEmp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.insurePremCorp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.insurePremEmp }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.insurePremTot }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.orgIncomeTax }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.chgIncomeTax }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.rdcIncomeTax }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.orgLocalTax }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.chgLocalTax }}</div>
            <div class="cel" style="width: 63px; font-size: 8px">{{ el.rdcLocalTax }}</div>
          </div>
          <div class="item b_double prevent-page-break">
            <div class="cel border-bottom" style="width: 238px; background-color: #E6E6FA"></div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.tfIncreaseAmountTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.asTaxDeclareAmountTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.npsPremCorpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.npsPremEmpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.nhisPremCorpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.nhisPremEmpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.ltcPremCorpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.ltcPremEmpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.eiPremCorpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.eiPremEmpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.iaiPremCorpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px;">{{ payrollEmp.iaiPremEmpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px; background-color: #E6E6FA">{{ payrollEmp.insurePremCorpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px; background-color: #E6E6FA">{{ payrollEmp.insurePremEmpTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px; background-color: #E6E6FA">{{ payrollEmp.insurePremTotTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px; background-color: #E6E6FA">{{ payrollEmp.orgIncomeTaxTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px; background-color: #E6E6FA">{{ payrollEmp.chgIncomeTaxTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px; background-color: #E6E6FA">{{ payrollEmp.rdcIncomeTaxTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px; background-color: #E6E6FA">{{ payrollEmp.orgLocalTaxTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px; background-color: #E6E6FA">{{ payrollEmp.chgLocalTaxTot }}</div>
            <div class="cel border-bottom" style="width: 63px; font-size: 8px; background-color: #E6E6FA">{{ payrollEmp.rdcLocalTaxTot }}</div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios';
export default {
  layout: 'blank',
  data() {
    return {
      payrollEmp: {},
      baseDt: null,
      compNm: null,
      customAllowance: {}
    };
  },

  fetch() {
    const { customerId, laborFileId, baseDt, compNm, customAllowance } = this.$route.query;
    this.baseDt = baseDt;
    this.compNm = compNm;
    this.customAllowance = JSON.parse(customAllowance)
    return axios
      .get(`${process.env.api_all_ok_mng_url}/v2/customers/service/labor/${customerId}/file/${laborFileId}/payroll/emp`)
      .then(({ data }) => {
        if (data.data) this.payrollEmp = data.data;
      })
      .catch(err => {
        console.error(err.response.data);
      });
  },
};
</script>

<style scoped>
.prevent-page-break {
  page-break-inside:avoid; 
  page-break-after:auto;
}

.border-bottom {
  border-bottom: 1px solid #ddd
}

.tbl_doc_scroll .t_top .cel, .tbl_doc_scroll .t_top .cel_col {
  background-color: #E6E6FA;
  color: black;
  font-weight: 500;
}

.tbl_doc_scroll .cel, .tbl_doc_scroll .cel_col{ 
  color: black;
  font-weight: 500;
}
</style>
