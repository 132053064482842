<template>
  <section class="container">
    <div class="inner contents_col2 bg_white">
      <div class="left">
        <grid v-if="gridProp" :data="gridProp.data" :columns="gridProp.columns" :options="gridProp.options" ref="grid" :key="gridKey"  @drop="fnOnDrop2" @editingFinish="fnOnChangeName2" @click="fnOnClick2"/>
      </div>
      <div class="right">
        <div class="colb mb5">
          <div class="top">
            <div>
              <button class="btn" @click="fnClickMove">이동하기</button>
              <strong class="ml5">선택한 조직 : {{ selectedOrgNm || '전체' }}</strong>
            </div>
          </div>
          <div v-if="selectedOrg">
            <CheckBox
              label="하위조직 포함"
              name="org"
              :value.sync="isContainSub"
              @change="
                checked => {
                  if (checked) fnFetchOrgUser(selectedOrg, 'Y');
                  else fnFetchOrgUser(selectedOrg, null);
                }
              " />
          </div>
        </div>
        <div class="contents">
          <div class="tbl_area" style="min-width: auto">
            <table class="tbl">
              <colgroup>
                <col width="7%" />
                <col width="30%" />
                <col width="30%" />
                <col width="33%" />
              </colgroup>
              <thead>
                <tr>
                  <th><b></b></th>
                  <th><b>사용자번호</b></th>
                  <th><b>이름</b></th>
                  <th><b>조직명</b></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="el in pagingUserList" :key="el.userNo">
                  <td><CheckBox label="<br />" :name="el.userNo" :value="selectedUser.indexOf(el.userNo) > -1" @change="checked => fnClickCheck(el.userNo, checked)" /></td>
                  <td>
                    {{ el.userNo }}
                  </td>
                  <td>{{ el.userNm }}</td>
                  <td>{{ el.orgNm }}</td>
                </tr>
                <tr v-if="userList.length == 0">
                  <td colspan="4">데이터가 없습니다.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination
              :page="pagination.pageNo"
              :perPage="pagination.pageSize"
              :totalCount="pagination.totalCnt"
              @change="p => {
                this.pagination.pageNo = p;
              }"
            />
        </div>
      </div>
    </div>
    <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <MoveOrgUserModal v-if="popState == true" @closePop="popState = false" @onSelect="fnSelectOrg" />
    </transition>
  </section>
</template>

<script>
import { generateQueryParameter } from '~/util/HttpUtils';
import CheckBox from '../components/common/CheckBox.vue';
import MoveOrgUserModal from '../components/pages/org/MoveOrgUserModal.vue';
import Tree from '../components/pages/org/Tree.vue';
import { CustomColumnHeader1 } from '~/util/TuiGridCustomClass.js';
import Pagination from '../components/common/Pagination.vue';
export default {
  components: { Tree, CheckBox, MoveOrgUserModal, Pagination },
  data() {
    return {
      selectedOrg: null,
      selectedOrgNm: null,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCnt: 0,
      },
      userList: [],
      selectedUser: [],
      isContainSub: false,
      popState: false,
      data2: [],
      gridProp: null,
      gridKey: Math.random(),
    };
  },

  computed: {
    pagingUserList() {
      const start = (this.pagination.pageNo-1)*this.pagination.pageSize
      const end = start + this.pagination.pageSize
      return this.userList.slice(start, end)
    }
  },

  methods: {
    fnFetchData() {
      this.$axios.$get('http://dev-auth.10bagger.co.kr/org').then(data => {
        this.data2 =this.fnMakeTreeData2(data, null);
        this.fnSettingGridProp()
      });
    },
    fnFetchOrgUser(searchOrgNo, containSubOrg) {
      const params = {
        searchOrgNo,
        containSubOrg,
      };
      this.$axios
        .$get(`http://dev-auth.10bagger.co.kr/user?${generateQueryParameter(params)}`)
        .then(data => {
          this.userList = data;
          this.pagination.totalCnt = this.userList.length
          this.selectedUser = [];
        })
        .catch(err => {
          console.error(err);
        });
    },
    fnClickCheck(userNo, checked) {
      if (checked) {
        this.selectedUser.push(userNo);
      } else {
        const idx = this.selectedUser.indexOf(userNo);
        this.selectedUser.splice(idx, 1);
      }
    },
    fnClickMove() {
      if (this.selectedUser.length == 0) {
        alert('사용자를 선택해주세요');
        return;
      }
      this.popState = true;
    },
    fnSelectOrg(orgNo) {
      if (confirm('사용자를 이동하시겠습니까?')) {
        const params = {
          userNos: this.selectedUser,
        };
        this.$axios
          .$put(`http://dev-auth.10bagger.co.kr/org/${orgNo}/user?${generateQueryParameter(params)}`)
          .then(data => {
            this.fnFetchOrgUser(this.selectedOrg, this.isContainSub ? 'Y' : null);
          })
          .catch(err => {
            console.error(err);
          });
      }
    },
    fnSettingGridProp() {
      const columns = [
        {
          header: '<div style="font-weight: bold; font-size:11px">조직명</div>',
          name: 'name',
          editor: 'text'
          // width: 400,
          // align: 'center',
        },
      ];

      const columnRenderer = columns.map(el => ({
        name: el.name,
        renderer: CustomColumnHeader1,
      }));

      const data = JSON.parse(JSON.stringify(this.data2))

      const contextMenu =  ({ rowKey }) => (
        [
          [
            {
              name: 'id1',
              label: '조직추가',
              action: () => {
                const parent = this.$refs.grid.invoke('getRow', rowKey)
                this.fnOnAddNode2(parent)
              },
            },
            {
              name: 'id2',
              label: '조직삭제',
              action: () => {
                const node = this.$refs.grid.invoke('getRow', rowKey)
                this.fnOnDelete2(node)
              },
            },
          ],
        ]
      )

      const options = {
        scrollX: false,
        scrollY: false,
        // minRowHeight: 20,
        // rowHeight: 20,
        header: {
          // height: 100,
          columns: columnRenderer,
        },
        treeColumnOptions: {
          name: 'name', //트리형태로 표현할 컬럼 명
          useIcon: true,//아이콘의 사용여부를 설정한다
          useCascadingCheckbox: false //체크박스 설정시 부모 체크시 자식 전체 체크됨
        },
        draggable: true,
        contextMenu
      };

      this.gridProp = {
        data: data,
        columns: columns,
        options: options,
      };
      this.gridKey++;
      this.fnResizeGrids();
    },
    fnResizeGrids() {
      setTimeout(() => {
        this.$refs.grid && this.$refs.grid.invoke('refreshLayout');
      }, 500);
    },
    fnOnDrop2({rowKey, targetRowKey, appended, instance}){
      console.log(rowKey, targetRowKey, appended)
      setTimeout(() => {
        const parent = instance.getParentRow(rowKey)
        if(parent){
          if (confirm('조직을 이동 하시겠습니까?')) {
            const { id } = instance.getRow(rowKey)
            this.fnMoveAndSort2(id, parent);
          } else {
            this.fnSettingGridProp();
          }
        } else {
          this.fnSettingGridProp();
        }
      }, 30);
    },
    fnMakeTreeData2(arrayList, rootId) {
      const arrayForTree = arrayList.map(el => ({
        id: el.orgNo,
        pid: el.porgNo,
        name: el.orgNm,
        orderNo: el.orderNo,
        _attributes: { expanded: true }
      }));

      var rootNodes = [];
      var traverse = function (nodes, item, index) {
        if (nodes instanceof Array) {
          return nodes.some(function (node) {
            if (node.id === item.pid) {
              node._children = node._children || [];
              return node._children.push(arrayForTree.splice(index, 1)[0]);
            }

            return traverse(node._children, item, index);
          });
        }
      };

      while (arrayForTree.length > 0) {
        arrayForTree.some(function (item, index) {
          if (item.pid === rootId) {
            return rootNodes.push(arrayForTree.splice(index, 1)[0]);
          }

          return traverse(rootNodes, item, index);
        });
      }

      return rootNodes;
    },
    fnMoveAndSort2(id, parent) {
      const userId = this.$store.state.authUser.username;
      this.$axios
        .$put(`http://dev-auth.10bagger.co.kr/org/move/${id}?pOrgNo=${parent.id}&modifyNo=${userId}`)
        .then(() => {
          Promise.all(
            parent._children.map((el, idx) => {
              const params = {
                orgNm: el.name,
                orderNo: idx + 1,
                modifyNo: userId,
              };
              return this.$axios.put(`http://dev-auth.10bagger.co.kr/org/${el.id}?${generateQueryParameter(params)}`);
            }),
          )
            .then(_ => {
              this.fnFetchData();
              this.fnFetchOrgUser(this.selectedOrg, this.isContainSub ? 'Y' : null);
            })
            .catch(err => {
              this.fnSettingGridProp();
              console.log(err);
            });
        })
        .catch(err => {
          this.fnSettingGridProp();
          console.error(err);
        });
    },
    fnOnAddNode2(parent) {
      const children = parent._children;
      const orderNo = children.length != 0 ? children[children.length - 1].orderNo + 1 : 1 
      const params = {
        pOrgNo: parent.id,
        orgNm: '새로운 조직',
        orderNo,
        registNo: this.$store.state.authUser.username,
      };
      this.$axios
        .$post(`http://dev-auth.10bagger.co.kr/org?${generateQueryParameter(params)}`)
        .then(() => {
          this.fnFetchData();
        })
        .catch(err => {
          this.fnSettingGridProp();
          console.error(err);
        });
    },
    fnOnDelete2(node) {
      if (confirm('삭제 하시겠습니까?')) {
        this.$axios
          .$delete(`http://dev-auth.10bagger.co.kr/org/${node.id}`)
          .then(() => {
            this.fnFetchData();
          })
          .catch(err => {
            alert(err.response.data.errorMessage);
          });
      }
    },
    fnOnChangeName2({instance, rowKey, value}) {
      if (confirm('수정 하시겠습니까?')) {
        const org = instance.getRow(rowKey)
        const params = {
          orgNm: value || '새로운 조직',
          orderNo: org.orderNo,
          modifyNo: this.$store.state.authUser.username,
        };
        this.$axios
          .$put(`http://dev-auth.10bagger.co.kr/org/${org.id}?${generateQueryParameter(params)}`)
          .then(() => {
            this.fnFetchData();
          })
          .catch(err => {
            this.fnSettingGridProp();
            console.error(err);
          });
      } else {
        this.fnSettingGridProp();
      }
    },
    fnOnClick2({targetType, rowKey, instance}) {
      if(targetType != 'cell') return;
      const node = instance.getRow(rowKey)
      this.isContainSub = false;

      if (node.id == 'ROOT') {
        this.selectedOrg = null;
        this.selectedOrgNm = null;
        this.fnFetchOrgUser(null, null);
      } else {
        this.selectedOrg = node.id;
        this.selectedOrgNm = node.name;
        this.fnFetchOrgUser(node.id, null);
      }
    },
  },

  watch: {
    '$store.state.ui.menuState'() {
      this.fnResizeGrids();
    },
  },

  mounted() {
    this.fnFetchData();
    this.fnFetchOrgUser(null, null);
  },
};
</script>

<style></style>
