<template>
  <div class="popup alert">
    <div class="p_wrap">
      <!-- p_inner -->
      <div class="p_top">
        <h3>날짜 선택</h3>
        <button class="btn_close" @click="fnClosePop">닫기</button>
      </div>
      <div class="p_container">
        FROM
        <div class="msg col3 mb20">
          <div class="col pr5">
            <Select title="년" id="from.year" :list="fromYearList" @change="fnOnChange" />
          </div>
          <div class="col pr5">
            <Select title="월" id="from.month" :list="fromMonthList" @change="fnOnChange"  />
          </div>
          <div class="col">
            <Select title="일" id="from.date" :list="fromDateList" @change="fnOnChange" />
          </div>
        </div>
        TO
        <div class="msg col3 mb20">
          <div class="col pr5">
            <Select title="년" id="to.year" :list="toYearList" @change="fnOnChange" />
          </div>
          <div class="col pr5">
            <Select title="월" id="to.month" :list="toMonthList" @change="fnOnChange" />
          </div>
          <div class="col">
            <Select title="일" id="to.date" :list="toDateList" @change="fnOnChange" />
          </div>
        </div>
      </div>
      <div class="p_bottom">
        <button @click="fnClosePop" class="btn"><b>확인</b></button>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "~/components/common/Select";

export default {
  props:['fromDate', 'toDate'],
  data(){
    return {
      minYear: 2021,
      minMonth: 1,
      maxMonth: 12,
      minDate: 1,
      today: {
        year:null,
        month:null,
        date:null
      },
      from:{
        year:null,
        month:null,
        date:null
      },
      to:{
        year:null,
        month:null,
        date:null
      },
    }
  },
  components: {
    Select,
  },
  computed: {
    fromYearList(){
      const min = this.minYear;
      const max = this.to.year;
      const current = this.from.year;
      return this.fnSelectList(min, max, current, '년');
    },
    fromMonthList(){
      const min = this.minMonth;
      const max = this.fnFromMaxMonth();
      const current = this.from.month;
      return this.fnSelectList(min, max, current, '월');
    },
    fromDateList(){
      const min = this.minDate;
      const max = this.fnFromMaxDate();
      const current = this.from.date;
      return this.fnSelectList(min, max, current, '일');
    },
    toYearList(){
      const min = this.from.year;
      const max = this.today.year;
      const current = this.to.year;
      return this.fnSelectList(min, max, current, '년');
    },
    toMonthList(){
      const min = this.fnToMinMonth();
      const max = this.fnToMaxMonth();
      const current = this.to.month;
      return this.fnSelectList(min, max, current, '월');
    },
    toDateList(){
      const min = this.fnToMinDate();
      const max = this.fnToMaxDate();
      const current = this.to.date;
      return this.fnSelectList(min, max, current, '일');
    }
  },

  mounted() {
    const today = new Date();
    this.today.year = today.getFullYear();
    this.today.month = today.getMonth() + 1;
    this.today.date = today.getDate();

    if(this.fromDate){
      const {year, month, date} = this.fnParseDate(this.fromDate);
      this.from.year=year;
      this.from.month=month;
      this.from.date=date;
    } else {
      const fromDate = new Date(today.getFullYear(), today.getMonth()-3, today.getDate());
      this.from.year = fromDate.getFullYear();
      this.from.month = fromDate.getMonth() + 1;
      this.from.date = fromDate.getDate();
    }

    if(this.toDate){
      const {year, month, date} = this.fnParseDate(this.toDate);
      this.to.year=year;
      this.to.month=month;
      this.to.date=date;
    } else {
      const toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      this.to.year = toDate.getFullYear();
      this.to.month = toDate.getMonth() + 1;
      this.to.date = toDate.getDate();
    }
  },
  methods: {
    fnClosePop(){
      this.$emit('close');
      this.$emit('date', {
        fromDate: `${this.from.year}-${this.$fill0(this.from.month)}-${this.$fill0(this.from.date)}`,
        toDate: `${this.to.year}-${this.$fill0(this.to.month)}-${this.$fill0(this.to.date)}`,
      });
    },
    fnParseDate(yyyyMMdd){
      const splitDate = yyyyMMdd.split('-');
      return {
        year : parseInt(splitDate[0]),
        month : parseInt(splitDate[1]),
        date : parseInt(splitDate[2])
      }
    },
    fnSelectList(min, max, current, unit){
      let list = [];
      if(min && max && current){
        for(let a = min; a <= max; a++){
          list.push({
            name:a+' '+unit,
            value:a,
            select:a===current
          })
        }
      }
      return list;
    },
    fnOnChange({id, value}){
      const _s = id.split('.');
      // Change Data Value
      this[_s[0]][_s[1]]=value;
      // Adjust
      if(_s[1]==='year' || _s[1]==='month'){
        const type = _s[1] === 'year' ? 'month' :
                     _s[1] === 'month' ? 'date' : '';
        this.fnAdjustData(_s[0], type);
      }
    },
    fnFromMaxMonth(){
      if(this.from && this.to){
        console.log('fnFromMaxMonth', this.from.year, this.to.year, this.to.month)
        return this.from.year < this.to.year ? 12 : this.to.month;
      }
      return 12;
    },
    fnFromMaxDate(){
      if(this.from && this.to)
        return (this.from.year === this.to.year && this.from.month === this.to.month)
          ? this.to.date
          : new Date(this.from.year, this.from.month, 0).getDate();
      return 31;
    },
    fnToMinMonth(){
      if(this.from && this.to)
        return this.to.year === this.from.year ? this.from.month : this.minMonth;
      return 1;
    },
    fnToMaxMonth(){
      if(this.from && this.to)
        return this.to.year === this.today.year ? this.today.month : 12;
      return 12;
    },
    fnToMinDate(){
      if(this.from && this.to)
        return (this.from.year === this.to.year && this.from.month === this.to.month)
          ? this.from.date
          : this.minDate;
      return 1;
    },
    fnToMaxDate(){
      if(this.from && this.to)
        return (this.to.year === this.today.year && this.to.month === this.today.month)
          ? this.today.date
          : new Date(this.to.year, this.to.month, 0).getDate();
      return 31;
    },
    /**
     * 선택되어있는 월와 일이 논리적으로 맞지 않는 부분을 수정한다.
     * @param target - { from | to }
     * @param type - { month | date }
     */
    async fnAdjustData(target, type){
      const fnMax = {
        from: {
          month : this.fnFromMaxMonth,
            date : this.fnFromMaxDate
        },
        to: {
          month: this.fnToMaxMonth,
            date: this.fnToMaxDate,
        }
      }

      let max = fnMax[target][type]();
      if(this[target][type] > max){
        this[target][type] = max;
      }
      if( type === 'month' ){
        await this.fnAdjustData(target, 'date');
      }
    },
  },
};
</script>
