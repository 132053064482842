<template>
  <div class="c_contents">
    <div class="colb mt10">
      <div></div>
      <div>
        <button class="btn" @click="fnSaveData">저장</button>
      </div>
    </div>
    <hr style="background: #dddddd; height: 1px; border: 0" />
    <div class="tbl_doc_scroll full mt10">
      <div class="t_inner">
        <div class="t_cont">
          <div class="item b_double">
            <div class="cel" style="width: 100px">사업자번호</div>
            <div class="cel" style="width: 300px">{{ bizNo }}</div>
          </div>
          <div class="item b_double">
            <div class="cel" style="width: 400px"><input type="text" class="ipt_cel" placeholder="회사명" v-model="입력개요.회사명" /></div>
            <div class="t_group">
              <div class="row">
                <div class="cel" style="width: 100px; background-color: #f4f4f4">주소</div>
                <div class="cel" style="width: 500px"><input type="text" class="ipt_cel" placeholder="주소" v-model="입력개요.주소" /></div>
              </div>
              <div class="row">
                <div class="cel" style="width: 100px; background-color: #f4f4f4">설립일</div>
                <div class="c_group" style="width: 700px">
                  <div class="cel_col" style="width: 200px"><input type="date" class="ipt_cel" placeholder="설립일" v-model="입력개요.설립일" /></div>
                  <div class="cel_col" style="width: 100px; background-color: #f4f4f4">연락처</div>
                  <div class="cel_col" style="width: 200px"><input type="text" class="ipt_cel" placeholder="연락처" v-model="입력개요.연락처" /></div>
                  <div class="cel_col" style="width: 100px; background-color: #f4f4f4">신용등급</div>
                  <div class="cel_col" style="width: 100px; background-color: #f4f4f4">현금등급</div>
                </div>
              </div>
            </div>
          </div>
          <div class="item b_double">
            <div class="cel border-bottom" style="width: 100px; background-color: #f4f4f4">업종</div>
            <div class="cel border-bottom" style="width: 100px"><input type="text" class="ipt_cel" placeholder="업종" v-model="입력개요.업종" /></div>
            <div class="cel border-bottom" style="width: 100px; background-color: #f4f4f4">주요품목</div>
            <div class="cel border-bottom" style="width: 100px"><input type="text" class="ipt_cel" placeholder="주요품목" v-model="입력개요.주요품목" /></div>
            <div class="cel border-bottom" style="width: 100px; background-color: #f4f4f4">자본금</div>
            <div class="cel border-bottom" style="width: 200px">{{ 입력개요.자본금_억 && Number(입력개요.자본금_억).toFixed(1) + '억' }}</div>
            <div class="cel border-bottom" style="width: 100px">
              <input
                type="text"
                class="ipt_cel"
                placeholder="(단위:주)"
                v-model="입력개요.자본금_주"
                @input="입력개요.자본금_주 = $event.target.value.replace(/[^0-9]/g, '')"
                @blur="
                  () => {
                    fn자본금_억갱신();
                    fn주요주주지분율갱신();
                  }
                " />
            </div>
            <div class="cel border-bottom" style="width: 200px">
              <input
                type="text"
                class="ipt_cel"
                placeholder="(단위:원)"
                v-model="입력개요.자본금_원"
                @input="입력개요.자본금_원 = $event.target.value.replace(/[^0-9]/g, '')"
                @blur="fn자본금_억갱신()" />
            </div>
            <div class="cel border-bottom" style="width: 100px"><input type="text" class="ipt_cel" placeholder="신용등급" v-model="입력개요.신용등급" /></div>
            <div class="cel border-bottom" style="width: 100px"><input type="text" class="ipt_cel" placeholder="현금등급" v-model="입력개요.현금등급" /></div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt20" style="width: 1200px; min-height: 200px">
      <div class="colb">
        <div style="display: flex">
          <h2>1.경영진 현황</h2>
          <button class="btn ml5 mb5" @click="fn경영진추가()">추가</button>
        </div>
        <div class="pt5">
          <label for="date">기준일: </label>
          <input type="date" id="date" v-model="입력개요.경영진현황.기준일" />
        </div>
      </div>
      <div class="tbl_doc_scroll full">
        <div class="t_inner">
          <div class="t_top">
            <div class="item">
              <div class="cel" style="width: 150px">구분</div>
              <div class="cel" style="width: 150px">성명</div>
              <div class="cel" style="width: 150px">중임기산일</div>
              <div class="cel" style="width: 150px">생년월일</div>
              <div class="cel" style="width: 150px">실권자관계</div>
              <div class="cel" style="width: 150px">임원취임일</div>
              <div class="cel" style="width: 150px">근속연수</div>
              <div class="cel" style="width: 150px">중임예정일</div>
            </div>
          </div>
          <div class="t_cont">
            <div class="item" v-for="(el, idx) in 입력개요.경영진현황.경영진" :key="idx">
              <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="구분" v-model="el.구분" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="성명" v-model="el.성명" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                <input type="date" class="ipt_cel" placeholder="설립일" v-model="el.중임기산일" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="생년월일" v-model="el.생년월일" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="실권자관계" v-model="el.실권자관계" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                <input type="date" class="ipt_cel" placeholder="설립일" v-model="el.임원취임일" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="근속연수" v-model="el.근속연수" @input="el.근속연수 = $event.target.value.replace(/[^0-9]/g, '')" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.경영진현황.경영진.length - 1 == idx }" style="width: 150px">
                <input type="date" class="ipt_cel" placeholder="설립일" v-model="el.중임예정일" />
              </div>
              <div><button class="btn dark ml5 mt5" @click="fn경영진삭제(idx)">-</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt20 pb20" style="width: 1200px">
      <div class="colb">
        <div style="display: flex">
          <h2>2.주요 주주 현황</h2>
          <button class="btn ml5 mb5" @click="fn주요주주추가()">추가</button>
        </div>
        <div class="pt5">
          <label for="date">기준일: </label>
          <input type="date" id="date" v-model="입력개요.주요주주현황.기준일" />
        </div>
      </div>
      <div class="tbl_doc_scroll full">
        <div class="t_inner">
          <div class="t_top">
            <div class="item">
              <div class="cel" style="width: 150px">주주명</div>
              <div class="cel" style="width: 150px">보통주식수</div>
              <div class="cel" style="width: 150px">지분율(%)</div>
              <div class="cel" style="width: 150px">취득가</div>
              <div class="cel" style="width: 150px">실권자관계</div>
              <div class="cel" style="width: 150px">취득일</div>
              <div class="cel" style="width: 150px">회사와의 관계</div>
              <div class="cel" style="width: 150px">발기인</div>
            </div>
          </div>
          <div class="t_cont">
            <div class="item" v-for="(el, idx) in 입력개요.주요주주현황.주요주주" :key="idx">
              <div class="cel" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="주주명" v-model="el.주주명" />
              </div>
              <div class="cel" style="width: 150px">{{ el.보통주식수 && fnFormatNumber(Number(el.보통주식수).toFixed(0)) }}</div>
              <div class="cel" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="지분율(%)" v-model="el.지분율" @input="el.지분율 = $event.target.value.replace(/[^0-9.]/g, '')" @blur="fn주요주주지분율갱신()" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.주요주주현황.주요주주.length - 1 == idx }" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="취득가" v-model="el.취득가" @input="el.취득가 = $event.target.value.replace(/[^0-9]/g, '')" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.주요주주현황.주요주주.length - 1 == idx }" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="실권자관계" v-model="el.실권자관계" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.주요주주현황.주요주주.length - 1 == idx }" style="width: 150px">
                <input type="date" class="ipt_cel" v-model="el.취득일" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.주요주주현황.주요주주.length - 1 == idx }" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="회사와의관계" v-model="el.회사와의관계" />
              </div>
              <div class="cel" :class="{ 'border-bottom': 입력개요.주요주주현황.주요주주.length - 1 == idx }" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="발기인" v-model="el.발기인" />
              </div>
              <div><button class="btn dark ml5 mt5" @click="fn주요주주삭제(idx)">-</button></div>
            </div>
            <div class="item">
              <div class="cel border-bottom" style="width: 150px">합계</div>
              <div class="cel border-bottom" style="width: 150px">{{ 입력개요.주요주주현황.보통주식수 && fnFormatNumber(Number(입력개요.주요주주현황.보통주식수).toFixed(0)) }}</div>
              <div class="cel border-bottom" style="width: 150px">{{ 입력개요.주요주주현황.지분율 && Number(입력개요.주요주주현황.지분율).toFixed(2) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt20 pb30" style="width: 1200px">
      <h2>3.종업원 현황</h2>
      <div class="tbl_doc_scroll full">
        <div class="t_inner">
          <div class="t_top">
            <div class="item">
              <div class="cel" style="width: 150px">구분</div>
              <div class="cel" style="width: 150px">생산직</div>
              <div class="cel" style="width: 150px">사무직</div>
              <div class="cel" style="width: 150px">총원</div>
              <div class="cel" style="width: 150px">임금제도</div>
              <div class="cel" style="width: 150px">근로계약서</div>
              <div class="cel" style="width: 150px">취업규칙</div>
              <div class="cel" style="width: 150px">성희롱예방교육</div>
            </div>
          </div>
          <div class="t_cont">
            <div class="item">
              <div class="cel border-bottom" style="width: 150px">인원수</div>
              <div class="cel border-bottom" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="생산직" v-model="입력개요.종업원현황.생산직" @input="입력개요.종업원현황.생산직 = $event.target.value.replace(/[^0-9]/g, '')" />
              </div>
              <div class="cel border-bottom" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="사무직" v-model="입력개요.종업원현황.사무직" @input="입력개요.종업원현황.사무직 = $event.target.value.replace(/[^0-9]/g, '')" />
              </div>
              <div class="cel border-bottom" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="총원" v-model="입력개요.종업원현황.총원" @input="입력개요.종업원현황.총원 = $event.target.value.replace(/[^0-9]/g, '')" />
              </div>
              <div class="cel border-bottom" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="임금제도" v-model="입력개요.종업원현황.임금제도" @input="입력개요.종업원현황.임금제도 = $event.target.value.replace(/[^0-9]/g, '')" />
              </div>
              <div class="cel border-bottom" style="width: 150px">
                <input
                  type="text"
                  class="ipt_cel"
                  placeholder="근로계약서"
                  v-model="입력개요.종업원현황.근로계약서"
                  @input="입력개요.종업원현황.근로계약서 = $event.target.value.replace(/[^0-9]/g, '')" />
              </div>
              <div class="cel border-bottom" style="width: 150px">
                <input type="text" class="ipt_cel" placeholder="취업규칙" v-model="입력개요.종업원현황.취업규칙" @input="입력개요.종업원현황.취업규칙 = $event.target.value.replace(/[^0-9]/g, '')" />
              </div>
              <div class="cel border-bottom" style="width: 150px">
                <input
                  type="text"
                  class="ipt_cel"
                  placeholder="성희롱예방교육"
                  v-model="입력개요.종업원현황.성희롱예방교육"
                  @input="입력개요.종업원현황.성희롱예방교육 = $event.target.value.replace(/[^0-9]/g, '')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import diagnosisService from '~/service/allOkMng/diagnosisService.js';
export default {
  props: ['bizNo'],
  data() {
    return {
      입력개요: {
        회사명: '',
        주소: '',
        설립일: '',
        연락처: '',
        업종: '',
        주요품목: '',
        자본금_억: '',
        자본금_주: '',
        자본금_원: '',
        신용등급: '',
        현금등급: '',
        경영진현황: {
          기준일: '',
          경영진: [],
        },
        주요주주현황: {
          기준일: '',
          주요주주: [],
          보통주식수: '',
          지분율: '',
        },
        종업원현황: {
          생산직: '',
          사무직: '',
          총원: '',
          임금제도: '',
          근로계약서: '',
          취업규칙: '',
          성희롱예방교육: '',
        },
      },
    };
  },

  methods: {
    fn경영진추가() {
      this.입력개요.경영진현황.경영진.push({
        구분: '',
        성명: '',
        중임기산일: '',
        생년월일: '',
        실권자관계: '',
        임원취임일: '',
        근속연수: '',
        중임예정일: '',
      });
    },
    fn경영진삭제(idx) {
      this.입력개요.경영진현황.경영진.splice(idx, 1);
    },
    fn주요주주추가() {
      this.입력개요.주요주주현황.주요주주.push({
        주주명: '',
        보통주식수: '',
        지분율: '',
        취득가: '',
        실권자관계: '',
        취득일: '',
        회사와의관계: '',
        발기인: '',
      });
    },
    fn주요주주삭제(idx) {
      this.입력개요.주요주주현황.주요주주.splice(idx, 1);
      this.fn주요주주지분율갱신();
    },
    fn주요주주지분율갱신() {
      this.입력개요.주요주주현황.주요주주.forEach(el => {
        el.보통주식수 = ((Number(el.지분율) / 100) * Number(this.입력개요.자본금_주)).toString();
      });

      //주요 주주 보통주식수합계 갱신
      this.입력개요.주요주주현황.보통주식수 = this.입력개요.주요주주현황.주요주주
        .reduce((acc, el) => {
          return acc + Number(el.보통주식수);
        }, 0)
        .toString();

      // 주요 주주 지분율합계 갱신
      this.입력개요.주요주주현황.지분율 = this.입력개요.주요주주현황.주요주주
        .reduce((acc, el) => {
          return acc + Number(el.지분율);
        }, 0)
        .toString();
    },
    fn자본금_억갱신() {
      this.입력개요.자본금_억 = ((Number(this.입력개요.자본금_주) * Number(this.입력개요.자본금_원)) / 100000000).toString();
    },
    fnSaveData() {
      const btn = {
        name: '저장',
        func: () => {
          this._closePop();
          const payload = {
            outlineData: JSON.stringify(this.입력개요),
            regId: this.$store.state.authUser.username,
          };
          diagnosisService.updateDiagnosis(this.$axios, this.bizNo, payload).then(({ result, data }) => {
            if (result) {
              this.$popupAlert({
                title: '저장',
                msg: '저장이 완료되었습니다.',
              });
            } else {
              this.$popupAlert({
                title: '경고',
                msg: data.error.msg,
              });
            }
          });
        },
      };
      this._confirm('확인', '저장하시겠습니까?', btn);
    },
    fnFormatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    _confirm(title, msg, btn) {
      let data = {
        state: true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closePop() {
      this.$store.dispatch('ui/setPopAlertData', {
        state: false,
        type: null,
        title: null,
        msg: null,
        btn: {
          link: '',
          name: null,
        },
      });
    },
  },

  mounted() {
    diagnosisService.getDiagnosis(this.$axios, this.bizNo).then(({ result, data }) => {
      if (result) {
        this.입력개요 = JSON.parse(data.contents.outlineData);
      } else {
        console.error(data.error);
      }
    });
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #ddd;
}
</style>
