<template>
  <div class="tbl_area">
    <table class="tbl">
      <colgroup>
        <col v-for="(el, idx) in headers" :key="idx" :width="el.colWidth" />
      </colgroup>
      <thead>
      <tr>
        <th v-for="(el, idx) in headers" :key="idx">
          <b>{{ el.colName }}</b>
          <div class="bx_srot" v-if="el.colSortNm">
            <button class="btn_up" :class="{on: sort[el.colSortNm]===null || sort[el.colSortNm]==='asc'}" @click="()=>fnSortValue({key: el.colSortNm, value:'asc'})">오름차순정렬</button>
            <button class="btn_down" :class="{on:sort[el.colSortNm]===null || sort[el.colSortNm]==='desc'}" @click="()=>fnSortValue({key: el.colSortNm, value:'desc'})">내림차순정렬</button>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item) in userList" :key="item.id" :class="{on:item.id===id}"
          @click="fnOnClick(item)"
          @dblclick="fnOnDoubleClick(item)">
        <td v-for="(el, idx) in headers" :key="idx">{{ item[el.colValue] }}</td>
      </tr>
      <tr v-if="contents.length === 0">
        <td :colspan="headers.length">데이터가 없습니다.</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import * as company from '~/constants/company';
export default {
  props: {
    contents: {
      type: Array,
    },
    organizationList: {
      type: Array,
    },
    orgCdList: {
      type: Array,
    },
    positionCdList: {
      type: Array
    },
  },
  data(){
    return {
      id: null,
      sort:{
        sortUsername:null,
        sortName:null,
        sortPositionCd:null,
        sortPrg:null,
        sortReq:'desc',
        sortUp:null
      },
    }
  },
  computed: {
    gaId(){
      return this.$store.state.authUser?.gaId;
    },
    headers(){
      const _base = [
            { colName: 'FC코드', colValue: 'username', colSortNm: 'sortUsername', colWidth: null },
            { colName: 'FC이름', colValue: 'name', colSortNm: 'sortName', colWidth: null },
      ];
      if ( this.positionCdList ) {
        const posColumn = { colName: '직책', colValue: 'positionNm', colSortNm: 'sortPositionCd', colWidth: '15%' }
        _base.push(posColumn);
      }
      if ( this.orgCdList ) {
        _base.push(...this.orgCdList);
      }
      return _base;
    },
    userList(){
      if(this.contents){
        return this.contents.map( u => {
          let orgs = this.fnFind(u.orgId)
          let names = this.fnOrgNames(orgs);
          return {
            ...u,
            ...names,
            positionNm: this.fnMappingPosition(u.position_cd)
          }
        })
      }
      return []
    }
  },
  methods: {
    fnSortValue({key,value}){
      if(this.sort[key] !== value ){
        this.sort[key]=value;
        this.$emit('change', {key, value});
      } else {
        this.sort[key] = null;
        this.$emit('change', {key, value: null});
      }
    },
    fnOnClick(user){
      this.id = user.id;
      this.$emit('click', user);
    },
    fnOnDoubleClick(user){
      this.$emit('dblclick', user);
    },
    fnFind(orgId){
      const found = this.organizationList.find( o => o.id === orgId)
      // console.log(found)
      let foundList = [];
      if(found){
        const parent_id = found.parent_id;
        if(parent_id) {
          const parentList = this.fnFind(parent_id);
          foundList = foundList.concat(parentList);
        }
        foundList.push(found);
      }
      return foundList;
    },
    fnOrgNames(orgList){
      let result = {}
      if ( this.orgCdList ) {
        for ( let i = 0; i < this.orgCdList.length; i++ ) {
          let nameKey = `orgName${i}`;
          let nameValue = (orgList[i]) ? orgList[i].name : '-';
          let idKey = `orgId${i}`;
          let idValue = (orgList[i]) ? orgList[i].id : null;
          result[nameKey] = nameValue;
          result[idKey] = idValue;
        }
      }
      return result;
      // let orgName0 = (orgList[0]) ? orgList[0].name : '-';
      // let orgName1 = (orgList[1]) ? orgList[1].name : '-';
      // let orgName2 = (orgList[2]) ? orgList[2].name : '-';
      // let orgName3 = (orgList[3]) ? orgList[3].name : '-';
      // let orgId0 = (orgList[0]) ? orgList[0].id : null;
      // let orgId1 = (orgList[1]) ? orgList[1].id : null;
      // let orgId2 = (orgList[2]) ? orgList[2].id : null;
      // let orgId3 = (orgList[3]) ? orgList[3].id : null;
      // return {
        // orgName0, orgName1, orgName2, orgName3,
        // orgId0, orgId1, orgId2, orgId3
      // }
    },
    fnMappingPosition(positionCd){
      if ( ! this.positionCdList ) {
        return null;
      }
      //const found = this.positionSelect.find( o => o.value === positionCd );
      const found = this.positionCdList.find( o => o.value === positionCd );
      return (found) ? found.name : '';
    }
  }
}
</script>
